import axios from 'axios';
import React from 'react';
import './LandingPage.css';
import { useState } from 'react';
import { useEffect } from 'react';
import '@reach/combobox/styles.css';
import Tooltip from '@mui/material/Tooltip';
import AuthToken from '../../context/AuthToken';
import HouseIcon from '@mui/icons-material/House';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { useLoader } from '../../context/LoaderProvider';
import { ALL_PROPERTIES_URL } from '../../helper/ApiUrl';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import AddressSearch from '../../components/AddressSearch/AddressSearch';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';

const LandingPage = ({ handleError, handleSuccess }) => {
  const token = AuthToken();
  const [completedProp, setCompletedProp] = useState([]);
  const [draft, setDraft] = useState([]);
  const { startLoader, stopLoader } = useLoader();

  const getCompletedProp = () => {
    startLoader();
    axios
      .get(`${ALL_PROPERTIES_URL}&status=draft,measurement_completed`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setCompletedProp(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getInProgressProp = () => {
    axios
      .get(`${ALL_PROPERTIES_URL}&status=draft`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setDraft(response.data.data);
        stopLoader();
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getCompletedProp();
    getInProgressProp();
  }, []);

  return (
    <div className="landing-main-container">
      <div className="landing-left-container">
        <div className="landing-left-top">
          <div className="landing-left-top-one">
            <h3>Recent Properties</h3>
            <div>
              <a href="/dashboard">
                <h4>View all</h4>
              </a>
              <KeyboardArrowRightOutlinedIcon className="view-all-icon" />
            </div>
          </div>
          <div className="landing-left-top-two">
         
            {completedProp &&
              completedProp.map((item, index) => {
                return (
                  <div className=' landing-left-top-two-inner'> 
                  <a
                    key={index}
                    target="_blank"
                    href={`/order/${item.order_id}`}
                  >
                    <div
                      className="landing-top-inner"
                      key={item.order_id}
                      id={item.order_id}
                    >
                      <div className="landing-inner-one">
                        <HouseIcon className="house-icon" />
                        <p>{item.address.slice(0, 40)}</p>
                      </div>
                      <div className="landing-inner-two">
                        <div className="landing-details">
                          <ArchitectureIcon className="inner-span-icon" />
                          <span className="inner-span-key">Area size:</span>
                          <span className="inner-span-value">
                            {item.area} acre
                          </span>
                        </div>
                        <div className="landing-details">
                          <AccessTimeIcon className="inner-span-icon" />
                          <span className="inner-span-key">Created At:</span>
                          <span className="inner-span-value">
                            {item.created_at.slice(0, 10)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                  </div>
                 

                 
                );
              })}

          </div>
          {/* <div className="empty-for-phone"></div> */}
        </div>
        <div className="landing-left-bottom">
          <div className="landing-left-bottom-one">
            <h3>Unfinished Project</h3>
            <div>
              <a href="/dashboard">
                <h4>View all</h4>
              </a>
              <KeyboardArrowRightOutlinedIcon className="view-all-icon" />
            </div>
          </div>
          <div className="landing-left-bottom-two">
            {draft &&
              draft.map((item, index) => {
                return (
                  <div
                    className="landing-bottom-inner"
                    key={item.order_id}
                    id={item.order_id}
                  >
                    <FmdGoodIcon className="bottom-inner-icon" />
                    <Tooltip title={item.address}>
                      <p>{item.address}</p>
                    </Tooltip>
                    <a target="_blank" href={`/order/${item.order_id}`}>
                      <button>Preview</button>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="landing-right-container">
        <div className="landing-search-bar">
          <AddressSearch
            handleError={handleError}
            handleSuccess={handleSuccess}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
