import {
  faFileAlt,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFilePowerpoint,
} from "@fortawesome/free-regular-svg-icons";
import path from "path";
import React, { useState } from "react";
import "./OrderNoteModal.css";
import moment from "moment-timezone";
import Avatar from "@mui/material/Avatar";
import { Tooltip } from "@material-ui/core";
import { Typography, Box } from "@mui/material";
import NoteIcon from "@mui/icons-material/Note";
import { useScreenType } from "../../utils/commonMethods";
import { useUserContext } from "../../context/UserContext";
import { useLayerContext } from "../../context/LayerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import hoverEffect from "../../OpenLayers/OLInteractions/HoverInteraction";
import CommentFormat from "../OrderNotesCommentSection/CommentFormat";
import { unByKey } from "ol/Observable";

export default function OrderNoteModal({
  hex2rgba,
  hoverElem,
  notesModal,
  hoverMapKey,
  notesButton,
  mapClickable,
  toggleSlideBtn,
  setHoverMapKey,
  orderNotesLayer,
  setOrderNoteData,
  toggleMapClickable,
  setOrderNotesModalShow,
  setOrderNoteLayerVisible,
  toggleOrderNoteMarkerVisible,
}) {
  const isMobile = useScreenType();
  const { olMap } = useLayerContext();
  const userTimezone = moment.tz.guess();

  // 
  
  const [savedQData, setSavedQData] = useState([]);

  const addMoreOrderNotes = () => {
    toggleMapClickable(true);
    if (isMobile) setOrderNotesModalShow(false);
  };

  const FileIcon = ({ url }) => {
    let extname = path.extname(url);
    let icon;
    switch (extname) {
      case ".pdf":
        icon = faFilePdf;
        break;
      case ".doc":
      case ".docx":
        icon = faFileWord;
        break;
      case ".ppt":
      case ".pptx":
        icon = faFilePowerpoint;
        break;
      case ".xls":
      case ".xlsx":
        icon = faFileExcel;
        break;
      case ".jpg":
      case ".jpeg":
      case ".png":
      case ".gif":
        return <img src={url} alt={url} />;
      case ".mp3":
      case ".wav":
        icon = faFileAudio;
        break;
      case ".mp4":
      case ".mov":
        icon = faFileVideo;
        break;
      default:
        icon = faFileAlt;
        break;
    }
    return <FontAwesomeIcon className="doc-type-attachment-icon" icon={icon} />;
  };

  const IconWithText = ({ text, iconName }) => {
    return (
      <Box position="relative" display="inline-block">
        {iconName}
        <Typography
          variant="body1"
          component="span"
          sx={{
            top: "50%",
            left: "50%",
            width: "100%",
            color: "white",
            fontSize: "0.7rem",
            textAlign: "center",
            position: "absolute",
            transform: "translate(-50%, -50%)",
          }}
        >
          {text}
        </Typography>
      </Box>
    );
  };

  return (
    <div className="order-notes-main-container">
      <div className="order-notes-main-head">
        <div className="notes-slide-close-btn">
          <Tooltip title="Close">
            <button
              onClick={() => {
                // toggleSlide();
                unByKey(hoverMapKey);
                toggleSlideBtn();
                toggleMapClickable(false);
                setOrderNotesModalShow(false);
                setOrderNoteLayerVisible(false);
                toggleOrderNoteMarkerVisible(false);
                notesModal.current.style.display = "none";
                notesButton.current.style.display = "block";
                hoverEffect(
                  olMap,
                  hex2rgba,
                  hoverMapKey,
                  setHoverMapKey,
                  hoverElem
                );
              }}
            >
              <KeyboardDoubleArrowRightIcon />
            </button>
          </Tooltip>
        </div>
        <div className="order-notes-modal-heading">
          <span>Add Order Notes</span>
          <div className="feedback-modal-head-2">(in the map)</div>
        </div>
      </div>
      <div className="order-notes-list-container">
        {orderNotesLayer &&
          orderNotesLayer.map((item, key) => {
            let tag = item.meta_data?.fontSize;
            {/* if(item.meta_data?.Qdescription){
              setSavedQData(JSON.parse(item.meta_data?.Qdescription))
             } */}
           
            const color = item.meta_data?.color || "black";
            const fontSize = item.meta_data?.fontSize || "p";
            
            const styles = {
              h1: { fontSize: "2em", fontWeight: "bold", margin: "0.67em 0" },
              h2: { fontSize: "1.5em", fontWeight: "bold", margin: "0.75em 0" },
              h3: {
                fontSize: "1.17em",
                fontWeight: "bold",
                margin: "0.83em 0",
              },
              h4: { fontSize: "1em", fontWeight: "bold", margin: "1.12em 0" },
              h5: { fontSize: "0.83em", fontWeight: "bold", margin: "1.5em 0" },
              h6: {
                fontSize: "0.67em",
                fontWeight: "bold",
                margin: "1.67em 0",
              },
              p: { fontSize: "1em", fontWeight: "normal", margin: "1em 0" },
            };
            const style = { ...styles[fontSize], color };
            {
              /* console.log(Tag , "Fonstsize tag ") */
            }
            const TagComponent = tag;

            {
              /* console.log(item,"item", typeof(item),"typeof Item") */
            }
            const userLocalTime = moment
              .utc(item.created_at)
              .tz(userTimezone)
              .format("h:mm A, MMM DD, YYYY");
            return (
              <div
                key={key}
                className="order-notes-list-item"
                onClick={() => {
                  setOrderNoteData(item);
                }}
              >
                <div className="order-notes-list-item-top">
                  <div className="notes-list-item-top-one">
                    <Avatar
                      sx={{
                        width: "25px",
                        height: "25px",
                        fontSize: "0.9rem",
                        bgcolor: `#1B9C85`,
                      }}
                    >
                      {item.created_by.name.charAt(0).toUpperCase()}
                    </Avatar>
                    <div>{item.created_by.name}</div>
                  </div>
                  <div className="notes-list-item-top-two">
                    <IconWithText
                      text={key + 1}
                      iconName={
                        <NoteIcon
                          sx={{
                            color: `#1B9C85`,
                          }}
                        />
                      }
                    />
                  </div>
                </div>
                <div className="order-notes-list-item-mid">
                  {/* <p>{item.title}</p> */}
                  {/* {console.log(typeof(item.title),"item.title")} */}
                  <div
                    style={
                      // color: ` ${item.meta_data?.color}`,
                      style
                    }
                  >
                    {item.title}
                    {console.log(
                      item.meta_data,
                      "item.metData",
                      item.title,
                      "item.title"
                    )}
                  </div>
                  <span>
                 
                  {/* {savedQData} */}
                  <div dangerouslySetInnerHTML={{ __html: item.meta_data?.Qdescription }} />
                    <CommentFormat comment={item.description} />
                   
                  </span>
                </div>
                <div className="order-notes-list-item-bottom">
                  <div className="attachments-cont">
                    {item.attachments &&
                      Array.isArray(item.attachments) &&
                      item.attachments.map((attachment, key) => {
                        return (
                          <Tooltip title={path.basename(attachment)}>
                            <div key={key} className="attachment-item-cont">
                              <FileIcon
                                className="attachment-item"
                                url={attachment}
                              />
                            </div>
                          </Tooltip>
                        );
                      })}
                  </div>
                </div>
                <div className="note-time">Created Time : {userLocalTime}</div>
              </div>
            );
          })}
      </div>
      <div
        onClick={addMoreOrderNotes}
        style={{ width: "auto" }}
        className={`add-feedback-button add-order-notes-btn ${
          mapClickable ? "add-note-clicked" : ""
        }`}
      >
        <AddCircleOutlineIcon className="icon" />
        <span style={{ textAlign: "center" }}>Add more order notes</span>
      </div>
    </div>
  );
}
