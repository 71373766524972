import axios from "axios";
import VectorSource from "ol/source/Vector";
import selectedStyle from "../OLStyles/EditStyle";
import { Select, Modify, Snap } from "ol/interaction";
import { altShiftKeysOnly } from "ol/events/condition";
import { REMOVE_HOLES_URL } from "../../helper/ApiUrl";
import calculateArea from "../DIYFeatures/CalculateArea";
import updateLayerData from "../DIYFeatures/UpdateLayerData";
import calculateLineArea from "../DIYFeatures/CalculateLineArea";
import removeAllInteraction from "../OLFeatures/RemoveInteraction";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import coordinatesToLatLong from "../DIYFeatures/TransformCoordinates";

const editLayer = (
  id,
  map,
  name,
  type,
  viewId,
  layerId,
  setZoomLayer,
  uploadedLayer,
  setUploadedLayer,
  setUndoStack, 
  setRedoStack,
  toolbarRef
) => {
  const select = new Select({
    wrapX: false,
    style: selectedStyle,
    condition: altShiftKeysOnly,
  });

  const modify = new Modify({
    features: select.getFeatures(),
  });

  const pointStyle = new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: "#FF7B54",
      }),
      stroke: new Stroke({
        color: "#000",
        width: 2,
      }),
    }),
  });

  var layerType = type === "polygon" ? "Polygon" : type === "line" ? "LineString" : "Point";

  setZoomLayer(false);
  removeAllInteraction(map);
  map.addInteraction(select);
  map.addInteraction(modify);
  // document.body.classList.remove("cut-cursor");
  // document.body.classList.remove('split-cursor');
  // document.body.classList.remove('crosshair-cursor');
  // document.body.classList.add('edit-cursor');

  
  // const mapContainer = document.getElementById('map-cont');
  const mapContainer = document.querySelector('.ol-viewport');

  mapContainer.classList.remove('cut-cursor');
  mapContainer.classList.remove('split-cursor');
  mapContainer.classList.remove('crosshair-cursor');
  mapContainer.classList.add('edit-cursor');
  // let mapContainer = document.getElementById('map-cont');
  // mapContainer.classList.add('edit-cursor');

  map.getLayers().forEach((layer) => {
    if (layer.get("title") && layer.get("title") === "Order") {
      layer.getLayers().forEach((item) => {
        if (item.get("title") && item.get("title") === name) {
          if (item.get('visible'))
            item.getSource().forEachFeature((feature) => {
              select.getFeatures().push(feature);
              if (layerType === "Point") {
                feature.setStyle([pointStyle]);
              }
            });
        }
      });
    }
  });

  var mergedSource = new VectorSource();
  map.getLayers().forEach((layer) => {
    if (layer.get('title') && layer.get('title') === 'Order') {
      layer.getLayers().forEach((item) => {
        var layerFeatures = item.getSource().getFeatures();
        if (layerFeatures && layerFeatures.length) mergedSource.addFeatures(layerFeatures);
      });
    }
  });
  const snap = new Snap({
    source: mergedSource,
  });
  map.addInteraction(snap);

  modify.on("modifyend", function (e) {
    const featureId = e.features.getArray()[0].values_.id;
    const coords = e.features.getArray()[0].getGeometry().getCoordinates();
    const point = e.features.getArray()[0].getGeometry().getCoordinates()[0];

    // undo redo context, passing the previous layer in the undo stack
    let stateUpdate = JSON.parse(JSON.stringify(uploadedLayer));
    setUndoStack((prevUndoStack) => [...prevUndoStack, stateUpdate]);
    setRedoStack([]);

    if (coords.length > 1 && type === "polygon") {
      const data = { parcel: coords };
      axios({
        url: `${REMOVE_HOLES_URL}`,
        method: "post",
        timeout: 8000,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then((res) => {
          uploadedLayer.map((layer) => {
            if (layer.name === name) {
              layer.data.map((item) => {
                if (item.id === featureId) {
                  item.data = res.data.data;
                }
              });
            }
          });


          setUploadedLayer([...uploadedLayer]);
          editLayer(
            id,
            map,
            name,
            type,
            viewId,
            layerId,
            setZoomLayer,
            uploadedLayer,
            setUploadedLayer,
            setUndoStack,
            setRedoStack,
            toolbarRef,
          );

          const data = {
            layer_data: {
              type: "Feature",
              properties: {
                id: featureId,
                Area: calculateArea(res.data.data, true),
              },
              geometry: {
                type: layerType,
                coordinates: coordinatesToLatLong(res.data.data, type),
              },
            },
          };
          updateLayerData(id, viewId, layerId, data, featureId);
        })
        .catch((err) => console.error(err));
    } else {
      uploadedLayer.map((layer) => {
        if (layer.name === name) {
          layer.data.map((item) => {
            if (item.id === featureId) {
              item.data = coords;
              item.measurement =
                type === "polygon"
                  ? calculateArea(coords, true)
                  : type === "line"
                    ? calculateLineArea(coords)
                    : 0;
            }
          });
        }
      });

      setUploadedLayer([...uploadedLayer]);
      editLayer(
        id,
        map,
        name,
        type,
        viewId,
        layerId,
        setZoomLayer,
        uploadedLayer,
        setUploadedLayer,
        setUndoStack,
        setRedoStack,
        toolbarRef,
      );

      const data = {
        layer_data: {
          type: "Feature",
          properties: {
            id: featureId,
            Area: calculateArea(coords, true),
          },
          geometry: {
            type: layerType,
            coordinates:
              layerType !== "Point"
                ? coordinatesToLatLong(coords, type)
                : coordinatesToLatLong(point, type),
          },
        },
      };

      updateLayerData(id, viewId, layerId, data, featureId);
    }
  });


  

};

export default editLayer;
