import Box from '@mui/material/Box';
import './MobileMeasurementPanel.css';
import React, { useState } from 'react';
import { Global } from '@emotion/react';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Accordian from '../Accordian/Accordian';
import { MobileOrderNotes } from './MobileOrderNotes';
import ToggleButton from '@mui/material/ToggleButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ParcelAccordian from '../ParcelAccordian/ParcelAccordian';

const drawerBleeding = 56;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

export default function MobileMeasurementPanel({
  orderNoteData,
  orderActive,
  mapClickable,
  oneFeature,
  setOneFeature,
  setOrderActive,
  nearmapDisabled,
  nearmapLoader,
  nearMapActive,
  setOrderNoteKey,
  setNearMapActive,
  path,
  result,
  setPath,
  selected,
  setSelected,
  measurementUnit,
  uploadedLayer,
  viewId,
  hex2rgba,
  modalShow,
  showPopup,
  isPreview,
  toolbarRef,
  hoverMapKey,
  layersCount,
  handleError,
  setZoomLayer,
  setModalShow,
  setHoverData,
  outerViewName,
  handleSuccess,
  selectedLayers,
  setLayersCount,
  setHoverMapKey,
  setUploadedLayer,
  setSelectedLayers,
  setLayersRestrict,
  multiClickKey,
  isContextInteraction,
  handleAccordianToggle,
  setHoverOrderFeatureId,
  setSelectedHoverFeature,
  totalFeatureMeasurement,
  setHoverOrderFeatureElement,
  elem,
  setMultipleContextInteraction,
  setOrderNotesModalShow,
  setOrderNoteLayerVisible,
  toggleMapClickable,
  toggleOrderNoteMarkerVisible,
  orderNotesLayer,
  setOrderNoteData,
}) {
  const [open, setOpen] = useState(false);
  const [alignment, setAlignment] = useState('layers');

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleToggleAlignment = (e) => {
    setAlignment(e.target.value);
    if (e.target.value === 'notes') {
      setOrderNotesModalShow(true);
      setOrderNoteLayerVisible(true);
    } else if (e.target.value === 'layers') {
      toggleMapClickable(false);
      setOrderNotesModalShow(false);
      setOrderNoteLayerVisible(false);
      toggleOrderNoteMarkerVisible(false);
    }
  };

  return (
    <div className="mobile-measurement-panel-container">
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: orderNoteData ? '100%' : '30%',
            overflow: 'visible',
          },
          '.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop': {
            display: "none !important"
          },
          '.css-919eu4': {
            display: "none !important"
          }
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        disableSwipeToOpen={false}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        swipeAreaWidth={drawerBleeding}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            right: 0,
            left: 0,
            position: 'absolute',
            visibility: 'visible',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Puller />
          <ToggleButtonGroup
            exclusive
            color="primary"
            value={alignment}
            aria-label="Platform"
            onChange={handleToggleAlignment}
            className="layers-notes-switcher"
          >
            <ToggleButton className="switcher-switch-1" value="layers">
              Layers
            </ToggleButton>
            <ToggleButton
              className="switcher-switch-2"
              value="notes"
              onClick={() => {
                setOrderNotesModalShow(true);
                setOrderNoteLayerVisible(true);
              }}
            >
              Notes
            </ToggleButton>
          </ToggleButtonGroup>
        </StyledBox>
        {alignment === 'layers' ? (
          <div className="mobile-measurement-layers-inner-container">
            <div className="mobile-measurement-head">
              <div
                className="toggle-all-layers-mobile"
                style={{ alignItems: 'center' }}
              >
                <div className="mc-checkbox">
                  <input
                    type="checkbox"
                    checked={orderActive || oneFeature}
                    onChange={() => {
                      if (oneFeature) {
                        setOneFeature(false);
                      }
                      setOrderActive(!orderActive);
                    }}
                  />
                </div>
                <div>All show/hide</div>
              </div>
              <div
                className="toggle-all-layers-mobile-two"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {nearmapLoader ? (
                  <div className="nearmap-loader"></div>
                ) : (
                  <div className="mc-checkbox">
                    <input
                      disabled={nearmapDisabled}
                      type="checkbox"
                      checked={nearMapActive}
                      onChange={() => {
                        setNearMapActive(!nearMapActive);
                      }}
                    />
                  </div>
                )}
                <div>HD Image</div>
              </div>
            </div>
            <div
              style={{ marginTop: '1em' }}
              className="measurement-panel-data"
            >
              <ParcelAccordian
                path={path}
                number={-1}
                result={result}
                setPath={setPath}
                selected={selected}
                orderActive={orderActive}
                setSelected={setSelected}
                measurementUnit={measurementUnit}
              />
              {uploadedLayer.map((item, index) => {
                return (
                  <Accordian
                    key={index}
                    number={index}
                    viewId={viewId}
                    name={item.name}
                    data={item.data}
                    type={item.type}
                    layerId={item.id}
                    hex2rgba={hex2rgba}
                    selected={selected}
                    modalShow={modalShow}
                    showPopup={showPopup}
                    isPreview={isPreview}
                    visible={item.visible}
                    toolbarRef={toolbarRef}
                    hoverMapKey={hoverMapKey}
                    setSelected={setSelected}
                    layersCount={layersCount}
                    handleError={handleError}
                    fillColor={item.fillColor}
                    setZoomLayer={setZoomLayer}
                    setModalShow={setModalShow}
                    setHoverData={setHoverData}
                    outerViewName={outerViewName}
                    uploadedLayer={uploadedLayer}
                    handleSuccess={handleSuccess}
                    setOneFeature={setOneFeature}
                    strokeColor={item.strokeColor}
                    fillOpacity={item.fillOpacity}
                    selectedLayers={selectedLayers}
                    setLayersCount={setLayersCount}
                    setHoverMapKey={setHoverMapKey}
                    otterfeatureId={item.featureId}
                    strokeWeight={item.strokeWeight}
                    measurementUnit={measurementUnit}
                    strokeOpacity={item.strokeOpacity}
                    setUploadedLayer={setUploadedLayer}
                    setSelectedLayers={setSelectedLayers}
                    setLayersRestrict={setLayersRestrict}
                    multiClickKey={multiClickKey}
                    isContextInteraction={isContextInteraction}
                    handleAccordianToggle={handleAccordianToggle}
                    setHoverOrderFeatureId={setHoverOrderFeatureId}
                    setSelectedHoverFeature={setSelectedHoverFeature}
                    featureMeasurement={totalFeatureMeasurement(item.data)}
                    setHoverOrderFeatureElement={setHoverOrderFeatureElement}
                    elem={elem}
                    setMultipleContextInteraction={
                      setMultipleContextInteraction
                    }
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <MobileOrderNotes
            mapClickable={mapClickable}
            setOrderNoteKey={setOrderNoteKey}
            orderNotesLayer={orderNotesLayer}
            setOrderNoteData={setOrderNoteData}
          />
        )}
      </SwipeableDrawer>
    </div>
  );
}
