import React from 'react';
import './imageview.css';

const ImageView = ({ imageSrc, onClose, onDownload }) => {
  return (
<div className="container">
  <div className="modal">
    <div className='download_Cross_div' >
    
    <button  onClick={onDownload} className="download__button">
    <box-icon type='solid' name='download'></box-icon>
    </button>
    <button  onClick={onClose} className="close-button" >
    <box-icon name='x'></box-icon>
    </button>
    </div>
    <div className='preview_box'>
    <img src={imageSrc} alt="Image" className="image" />
    </div>
    
  </div>
</div>

  );
};

export default ImageView;
