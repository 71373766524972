import {
  Combobox,
  ComboboxList,
  ComboboxInput,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import React from "react";
import "./AddressSearch.css";
import { TOAST_TYPE } from "../../utils/constants";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { useScreenType } from "../../utils/commonMethods";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useLocation, useNavigate } from "react-router-dom";
import usePlacesAutocomplete from "use-places-autocomplete";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { useAddressContext } from "../../context/AddressContext";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { ReactComponent as ArrowIcon } from "../../assets/Arrow.svg";

export default function AddressSearch({ handleError, handleSuccess }) {
  const isMobile = useScreenType();
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ["us", "CA"] },
    },
  });

  console.log(data,  value, "dataOptions")
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const { setAddress, setLatLng } = useAddressContext();

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    try {
      const results = await getGeocode({ address }).catch((error) => {
        console.error("Error in getGeocode:", error);
      });

      if (results && results.length > 0) {
        setValue(address, false);
        setAddress(address);
        console.log("hi", results);
        const { lat, lng } = await getLatLng(results[0]);
        setLatLng([lng, lat]);
        if (path === "/home" || path === "/order") {
          navigate("/order");
        }
        clearSuggestions();
      } else {
        handleSuccess(
          TOAST_TYPE.WARNING,
          "Ensure the address is complete and accurate."
        );
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter" && value) {
      handleSelect(value);
    }
  };

  const handleClick = () => {
    if (value) {
      handleSelect(value);
    }
  };

  const locateMe = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      handleSelect(`${latitude},${longitude}`);
    });
  };

  console.log(path, "path in map")

  return (
    <Combobox onSelect={handleSelect}>

    {/* <div className="search-wrapper"> */}
      {/* this is for correctly position of suggesstion div */}
    
      {/* <div className="searchbox "> */}
       
        <div className="search">

          <SearchOutlinedIcon className="search-icon" />
          <ComboboxInput
            value={value}
            onChange={handleInput}
            placeholder="Search Address or Lat Long"
            onKeyPress={handleKeyPress}
          />
          <MyLocationIcon
          onClick={locateMe}
          className="locate-icon "
          sx={path === "/home" ? { right: "8%", marginTop: "1.5%" } : undefined}
        />
        <ArrowIcon onClick={handleClick} className="arrow-icon" />
        </div>
        
      {/* </div> */}
      <ComboboxPopover
        className={
          path === "/home" && !isMobile
            ? "search-suggestions-container"
            : path === "/order" && !isMobile
            ? "search-suggestions-container-b"
            : path === "/home" && isMobile
            ? "search-suggestions-container-c"
            : path === "/order" && isMobile
            ? "search-suggestions-container-d"
            : path === "/diy/order" && !isMobile
            ? "search-suggestions-container-e"
            : path === "/explore" && !isMobile
            ? "search-suggestions-container-e"
            : undefined
        }
      >
        <ComboboxList className="search-suggestions-list">
          {status === "OK" &&
            data.map((item, index) => (
              <div key={index} className="search-suggestions-item">
                <FmdGoodIcon />
                <ComboboxOption key={item.id} value={item.description} />
              </div>
            ))}
        </ComboboxList>
      </ComboboxPopover>
    
    </Combobox>
  );
}
