import "./Project.css";
import axios from "axios";
import Menu from "@mui/material/Menu";
import ProgressBar from "./ProgressBar";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import AuthToken from "../../context/AuthToken";
import ClearIcon from "@mui/icons-material/Clear";
import "react-loading-skeleton/dist/skeleton.css";
import IconButton from "@mui/material/IconButton";
import { useSearchParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { useRef, useState, useEffect,useCallback } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import { FormControlLabel, FormGroup } from "@material-ui/core";
import { ORDER_STATUS, TOAST_TYPE } from "../../utils/constants";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { MOLE_ORDER_TASK, MOLE_ORDER_FILE,MOLE_ORDER_URL, FETCH_FEATURE_URL } from "../../helper/ApiUrl";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddAttachment from "../../components/AddAttachment/AddAttachment";
// 
import EditIcon from '@mui/icons-material/Edit';
import DeleteConfirmIcon  from '../../assets/ConfirmDelete.svg'

import Pagination from "../../components/Pagination/Pagination";
import { Link } from 'react-router-dom';





const Project = ({ handleSuccess, handleError }) => {
  const keys = ["name"];
  const dateRef = useRef();
  const innerRef = useRef();
  const outerRef = useRef();
  const loaderRef = useRef();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState();
  const [anchorEl2, setAnchorEl2] = useState();
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const [query, setQuery] = useState("");
  const [action, setAction] = useState("");
  const [orderId, setOrderId] = useState("");
  const [openDate, setOpenDate] = useState(false);
  const [projectList, setProjectList] = useState();
  const [isCreated, setIsCreated] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const end_Date = searchParams.get("end_date") || "";
  const start_Date = searchParams.get("start_date") || "";
  const [snowFeatures, setSnowFeatures] = useState(false);
  const [featureSelected, setFeatureSelected] = useState([]);
  // const [endDate, setEndDate] = useState({ endDate: end_Date });
  const [availableFeatures, setAvailableFeatures] = useState([]);
  const [projectDescription, setProjectDescription] = useState(null);
  const [landscapeFeatures, setLandscapeFeatures] = useState(false);
  // const [startDate, setStartDate] = useState({ startDate: start_Date });

// for date picker start
// const [openDate, setOpenDate] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // const [projectList, setProjectList] = useState([]);
  // const dateRef = useRef();

  const newhandleDateFilter = () => {
    // Call your API with the start and end date
    axios({
      url: `${MOLE_ORDER_URL}`,
      method: "GET",
      headers: {
        Authorization: `Token ${AuthToken()}`,
        Accept: "application/json",
      },
      params: {
        p:1,
        psz:100,
        start_date: startDate,
        end_date: endDate,
      },
    })
      .then((res) => {
        setProjectList(res.data.data);
        
      })
      .catch((err) => {
        console.error(err);
      });
      setOpenDate(false);
      
  };

  const newclearDateFilter = () => {
    setStartDate("");
    setEndDate("");
    // setProjectList([]);
    fetchAllBulkProject(); 
  };



// for date picker end


  // for the seaching and debouncing start
  const [debouncedQuery, setDebouncedQuery] = useState(query)

  useEffect(() => {
   
    const timerId = setTimeout(() => {
      setDebouncedQuery(query);
    }, 500); // 500ms debounce delay

    if(query==="")   fetchAllBulkProject(); 

    return () => {
      clearTimeout(timerId);
    };
   
  }, [query]);

  useEffect(() => {
    if (debouncedQuery) {
      fetchSeachedProject(debouncedQuery);
    }
  }, [debouncedQuery]);

  const fetchSeachedProject = useCallback(
    (searchQuery) => {
      axios({
        url: `${MOLE_ORDER_URL}?name=${searchQuery}`,
        method: "GET",
        headers: {
          Authorization: `Token ${AuthToken()}`,
          Accept: "application/json",
        },
      })
        .then((res) => {
          setProjectList(res.data.data);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [MOLE_ORDER_URL]
  );


   // for the seaching and debouncing end




  //store status for show only delete buttons if status === uploaded
  const[currentProjectStatus, setCurrentProjectStatus]=useState("")

  // edit
  const [currentEdit,setCurrentEdit] = useState("");
  const [currentEditedItem,setCurrentEditedItem] = useState("");
  const [currentEditedDes,setCurrentEditedDes] = useState(null);
  

  const [idForUpdated,setIdForUpdate]= useState("")
       //to show already choosen id on update eachFeature.id
  const handleEdit = (project,item)=>{
    // console.log(project?.id," click on update pencil");
    const FeatureIDsForUpdate = project?.feature_data?.map(feature => feature.id) || [];

    setFeatureSelected( FeatureIDsForUpdate)
    FeatureIDsForUpdate.map((eachid)=>  checkCheckBox(eachid))
    // // checkCheckBox(item.id)
    // console.log(FeatureIDsForUpdate,"FeatureIDsForUpdate click on update pencil");
    // console.log(availableFeatures,"FeatureIDsForUpdate click on update pencil");

    //FeatureIDsForUpdate.push(eachFeature.id))
    
    // console.log(project?.feature_data ," project?.feature_data click on update pencil");
    setIdForUpdate(project?.id)
    setAction("edit");
    outerRef.current.style.opacity = 1;
    outerRef.current.style.pointerEvents = "auto";
    // console.log(ind);
    setCurrentEdit(project?.name);
    setCurrentEditedItem(project?.name);
    setCurrentEditedDes(project?.description)
    setProjectName(project?.name)
    setText(project?.description)
 
  }

  const handleUpdateTitle = (value)=>{
    console.log(value,"value in hadleUpdateTitle")
    // setCurrentEditedItem((prev)=>{
    //   return [...prev,value]
    // })
    setCurrentEditedItem(value)
    setProjectName(value)
  }
  useEffect(()=>{console.log(currentEditedItem,"currentEditedItem")},[currentEditedItem])

  const handleUpdateDescription = (value)=>{
       setText(value)
       setCurrentEditedDes(value)
       setProjectDescription(value)
    
  }



  // to counter the character
  let charCount;
  //  let  showReadMore
  // to counter the character

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  // for readmore readless
  // const [isExpanded, setIsExpanded] = useState(false);
  const [expandedProjects, setExpandedProjects] = useState({});
  const [updateName,setupdateName] = useState();
 useEffect(()=>{
  console.log(updateName,"updateName")
 },[updateName])

  

  const toggleReadMore = (projectID) => {
    setExpandedProjects((prevState) => ({
      ...prevState,
      [projectID]: !prevState[projectID],
    }));
  };

  // const toggleReadMore = (projectID) => {

  //   setIsExpanded(!isExpanded);
  // };

  // geting 10words
  const getFirstTenWords = (text) => {
    // const words = text.split(" ");
    // return words.length > 10 ? words.slice(0, 10).join(" ") + "..." : text;

    return (
      text.split(" ").slice(0, 10).join(" ") +
      (text.split(" ").length > 10 ? "..." : "")
    );
  };

  // today
  const isMoreThanTenWords = (text) => {
    return text.split(" ").length > 10;
  };

  // for readmore readless end

  // counter for textarea word

  // const [text, setText] = useState('');

  // const handleChange = (e) => {
  //    setProjectDescription(e.target.value)
  //    setText(e.target.value);
  //   const newText = e.target.value;
  //   const wordCount = newText.split(/\s+/).filter(word => word.length > 0).length;

  //   if (wordCount <= maxWords) {
  //     setText(newText);
  //   }
  // };

  // const wordCount = text.split(/\s+/).filter(word => word.length > 0).length;
  // const maxWords = 500;

  // counter for textarea word end code

  // counter for the character
  const [text, setText] = useState("");
  const maxChars = 500;


  const handleChange = (e) => {
  if( charCount <=maxChars) setProjectDescription(e.target.value);
    //    setText(e.target.value);
    const newText = e.target.value;

    // ---
    // Trim the leading and trailing whitespace
    // const trimmedText = newText.trim();
    // console.log("trimmedtext", trimmedText);

    // Count the characters in the trimmed text
    const textWithoutSpaces = newText.replace(/\s+/g, "");
    // console.log("textWithoutSpaces", textWithoutSpaces);

    charCount = textWithoutSpaces.length;

    //  const charCount = trimmedText.length;
    // console.log("charCount", charCount);

    // --
    // var regex = /[a-zA-Z0-9]/g; // only count letters and numbers

    //     charCounts= newText.match(regex).length;

    if (charCount <= maxChars) {
    console.log(charCount ," charcouont")
      setProjectDescription(newText);
      setText(newText);
    }
  };

  // const[UpdateDescTextLenght,SetUpadateDescTextLenght] =useState(0)
  // const descUpdateLenght = () => {
   
    
  //     const newText =value;
  
  //     const textWithoutSpaces = newText.replace(/\s+/g, "");

  
  //     charCount = textWithoutSpaces.length;
  
      
  
  //     if (charCount <= maxChars) {
  //     console.log(charCount ," charcouont")
  //       setProjectDescription(newText);
  //       setText(newText);
  //     }
  //   };
  // counter for the character

  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) => item[key].toLowerCase().includes(query))
    );
  };

  const getAvailableFeatures = () => {
    axios({
      url: FETCH_FEATURE_URL,
      method: "GET",
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if ("data" in res) {
          const data = res.data.data;
          setAvailableFeatures([...data]);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  useEffect(() => {
    getAvailableFeatures();
  }, []);

  const checkCheckBox = (id) => {
  console.log(id ,"ID in checbox")
    let flag = false;
    featureSelected.map((item) => {
      if (id === item.id) flag = true;
    });
    return flag;
  };

  useEffect(() => {
    if (featureSelected?.length > 0) {
      const allSnowFeaturesSelected = availableFeatures
        .filter((feature) => feature.segment === "snow")
        .every((snowFeature) =>
          featureSelected.some((selected) => selected.id === snowFeature.id)
        );
      if (allSnowFeaturesSelected) setSnowFeatures(true);
      const allLandscapeFeaturesSelected = availableFeatures
        .filter((feature) => feature.segment === "landscape")
        .every((landscapeFeature) =>
          featureSelected.some(
            (selected) => selected.id === landscapeFeature.id
          )
        );
      if (allLandscapeFeaturesSelected) setLandscapeFeatures(true);
    }
  }, [featureSelected]);

  const selectSnowFeatures = () => {
    let snowFeaturesArr = [];
    if (!snowFeatures) {
      availableFeatures.map((feature) => {
        if (feature.segment === "snow" && !featureSelected.includes(feature))
          snowFeaturesArr.push(feature);
      });
      setFeatureSelected([...featureSelected, ...snowFeaturesArr]);
      setSnowFeatures(true);
    } else {
      const updatedFeatureSelected = featureSelected.filter((selected) => {
        return !availableFeatures.some(
          (available) =>
            available.segment === "snow" && available.id === selected.id
        );
      });
      setFeatureSelected(updatedFeatureSelected);
      setSnowFeatures(false);
    }
  };

  const selectLandscapeFeatures = () => {
    let landscapeFeaturesArr = [];
    if (!landscapeFeatures) {
      availableFeatures.map((feature) => {
        if (
          feature.segment === "landscape" &&
          !featureSelected.includes(feature)
        )
          landscapeFeaturesArr.push(feature);
      });
      setFeatureSelected([...featureSelected, ...landscapeFeaturesArr]);
      setLandscapeFeatures(true);
    } else {
      const updatedFeatureSelected = featureSelected.filter((selected) => {
        return !availableFeatures.some(
          (available) =>
            available.segment === "landscape" && available.id === selected.id
        );
      });
      setFeatureSelected(updatedFeatureSelected);
      setLandscapeFeatures(false);
    }
  };

  

  const getProjectList = () => {
    axios({
      url: MOLE_ORDER_URL,
      method: "GET",
      headers: {
        Authorization: `Token ${AuthToken()}`,
        

      },
    })
      .then((res) => {
        setProjectList(res.data.data);
        setRowCount(res.data.count); // for pagination
        setPageCount(res.data.total_pages);// for pagination
        console.log(res, "response data data")
        console.log(res.data.total_orders, " total order response data data")

        console.log(res.data.data, " MOLE_ORDER_URL in bulk order")
      })
      .catch((err) => {
        handleError(err);
      });
  };

  useEffect(() => {
    getProjectList();
  }, [isCreated]);

  const createProject = () => {
    if (
      !projectName ||
      // !projectDescription ||
      !(featureSelected && featureSelected.length > 0)
    ) {
     
      let errorMessage = "Please fill in the following fields:";
      let isFirstEmptyField = true;

      if (!projectName) {
        errorMessage += isFirstEmptyField ? " Project Name" : ", Project Name";
        isFirstEmptyField = false;
      }

      // if (!projectDescription) {
      //   errorMessage += isFirstEmptyField
      //     ? " Project Description"
      //     : ", Project Description";
      //   isFirstEmptyField = false;
      // }
      // if (!projectDescription) {
      //   setProjectDescription(" ")
      // }
      

      if (!(featureSelected && featureSelected.length > 0)) {
        errorMessage += isFirstEmptyField
          ? " Feature Selection"
          : ", Feature Selection";
        isFirstEmptyField = false;
      }

      handleSuccess(TOAST_TYPE.WARNING, errorMessage);
      return;
    }

    const featureIds = featureSelected.map((item) => item.id) || [];

    const projectData = {
      name: projectName,
      feature_ids: featureIds,
   
      description: projectDescription,
    };

    axios({
      url: MOLE_ORDER_URL,
      method: "POST",
      data: projectData,
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setProjectName("");
          setSnowFeatures(false);
          setFeatureSelected([]);
          setIsCreated(!isCreated);
          setProjectDescription(null);
          setLandscapeFeatures(false);
          
          setCurrentEditedItem("");
          setCurrentEditedDes(null);
          setCurrentEdit("")
          navigate(`/project/${res.data.data.id}`);
        } else {
          handleError(TOAST_TYPE.ERROR, "Something went wrong!");
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };
const MakeDescNull = async ()=>{
  setProjectDescription(null)
  setCurrentEditedDes(null)
}
  const updateProject = () => {
   
    
    // if (projectName && projectDescription && featureSelected?.length > 0) {
  if(projectDescription === ""){
    MakeDescNull()
    console.log(projectName,"projectName", projectDescription,"projectDescription",currentEditedDes,"currentEditedDes" )
    console.log(projectDescription.length,"projectDescription lenght" )
    
  }
    console.log(projectName,"projectName", projectDescription,"projectDescription" )
    if (projectName ||  projectDescription ) {

      // console.log(projectName,"projectName",projectDescription,"projectDescription")
      // const featureIds = [];

      // featureSelected.map((item) => {
      //   featureIds.push(item.id);
      // });

      const projectData = {
        name: projectName,
        // feature_ids: featureIds,
        description: projectDescription,
       
      };
     
      axios({
        url: `${MOLE_ORDER_URL}/${idForUpdated}`,
        method: "PATCH",
        data: projectData,
        headers: {
          Authorization: `Token ${AuthToken()}`,
        },
      })
        .then((res) => {
          if (res.status === 200 || 201) {
            getProjectList()
            setProjectName("");
            setSnowFeatures(false);
            setFeatureSelected([]);
            setIsCreated(!isCreated);
            setProjectDescription(null);
            setLandscapeFeatures(false);

           

         
          
          
            setCurrentEditedItem("");
            setCurrentEditedDes(null);
            setCurrentEdit("");
            setText("")




            outerRef.current.style.opacity = 0;
            outerRef.current.style.pointerEvents = "none";
            handleSuccess(
              TOAST_TYPE.SUCCESS,
              "Bulk project updated successfully!"
            );
          }
        })
        .catch((err) => {
          handleError(err);
        });
    } else {
      console.log("run")
      handleSuccess(TOAST_TYPE.WARNING, "Please select all given fields!");
    }
  };

  const handleAction = () => {
    if (action === "create") {
      createProject();
    } else if (action === "edit") {
      updateProject();
    }
  };


    
       
   


  // HA  Project editName handler:
  const EditNameHandler = (id, projectList)=>{
                   setupdateName(
                    (prev)=>prev?.map((prevList)=>(prevList.id === id ? projectList : prevList))
                   )

                   
                  
                //  const particular =  projectList.filter(project => project?.id === id)
                //  console.log(particular ,"particular getting")

            
              setAction("edit");
              outerRef.current.style.opacity = 1;
              outerRef.current.style.pointerEvents = "auto";

            //   setAction('edit');
            //   setProjectName(project?.name);
            //   outerRef.current.style.opacity = 1;
            //   setFeatureSelected(project?.feature_data);
            //   setProjectDescription(project?.description);
            //   outerRef.current.style.pointerEvents = 'auto';
            //  console.log("click")
  }
  const calculateTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hr ${remainingMinutes} min`;
  };

  const handleClickOutSide = (e) => {
    if (dateRef.current)
      if (!dateRef.current.contains(e.target)) setOpenDate(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
  }, []);

  const handleDateFilter = () => {
    if (startDate.startDate && endDate.endDate) {
      // Convert start and end dates to Date objects
      const start = new Date(startDate.startDate);
      const end = new Date(endDate.endDate);

      if (end < start) {
        handleSuccess(
          TOAST_TYPE.WARNING,
          "End date cannot be earlier than start date"
        );
        return;
      }

      const newList = projectList.filter((item) => {
        const objDate = new Date(item.created_by);
        const itemYear = objDate.getFullYear();
        const itemMonth = objDate.getMonth() + 1; // Months are zero-based
        const itemDay = objDate.getDate();
        const itemDateString = `${itemYear}-${itemMonth
          .toString()
          .padStart(2, "0")}-${itemDay.toString().padStart(2, "0")}`;

        // Extract year, month, and day from the start and end dates
        const startYear = start.getFullYear();
        const startMonth = start.getMonth() + 1; // Months are zero-based
        const startDay = start.getDate();
        const startDateString = `${startYear}-${startMonth
          .toString()
          .padStart(2, "0")}-${startDay.toString().padStart(2, "0")}`;

        const endYear = end.getFullYear();
        const endMonth = end.getMonth() + 1; // Months are zero-based
        const endDay = end.getDate();
        const endDateString = `${endYear}-${endMonth
          .toString()
          .padStart(2, "0")}-${endDay.toString().padStart(2, "0")}`;

        return (
          itemDateString >= startDateString && itemDateString <= endDateString
        );
      });

      setProjectList(newList);
      searchParams.set("start_date", startDate.startDate);
      searchParams.set("end_date", endDate.endDate);
      setSearchParams(searchParams);
    } else {
      // Handle the condition where either start date or end date is not selected
      console.log("Please select both start and end dates");
    }
    setOpenDate(false);
  };

  const clearDateFilter = () => {
    setStartDate({ startDate: "" });
    setEndDate({ endDate: "" });
    searchParams.delete("start_date");
    searchParams.delete("end_date");
    setSearchParams(searchParams);
    setIsCreated(!isCreated);
  };

  // const LinearProgressWithLabel = (props) => {
  //   return (
  //     <Box
  //       sx={{
  //         width: '70%',
  //         display: 'flex',
  //         alignItems: 'start',
  //         flexDirection: 'column',
  //       }}
  //     >
  //       <Box sx={{ minWidth: 35, marginBottom: '1%', color: '#06372a' }}>
  //         <Typography variant="body2">
  //           {`${Math.round(props.value)}%`} Completed
  //         </Typography>
  //       </Box>
  //       <Box sx={{ width: '100%', mr: 1 }}>
  //         <LinearProgress color="inherit" variant="determinate" {...props} />
  //       </Box>
  //     </Box>
  //   );
  // };

  // const downloadExcel = () => {
    
  //   axios({
  //     url: `${MOLE_ORDER_URL}/${orderId}/export`,
  //     method: "GET",
  //     responseType: "arraybuffer", // Set the response type to arraybuffer
  //     headers: {
  //       Authorization: `Token ${AuthToken()}`,
  //     },
  //   })
  //     .then((res) => {
        
  //       const blob = new Blob([res.data], {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       });
  //       const url = window.URL.createObjectURL(blob);

  //       // Create a link element
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.download = `${projectName.toLowerCase()}.xlsx`;

  //       // Append the link to the body
  //       document.body.appendChild(link);

        // Trigger a click on the link
        // link.click();
        // handleSuccess(
        //   TOAST_TYPE.SUCCESS,
        //   "Excel Downloaded Successfully!"
        // );
        // Add toastify
         // Update the toast to show success
  //       // Trigger a click on the link
  //       link.click();
  //       handleSuccess(
  //         TOAST_TYPE.SUCCESS,
  //         "Excel Downloaded Successfully!"
  //       );
  //       // Add toastify
  //        // Update the toast to show success
      
  //       // Clean up by removing the link from the DOM
  //       document.body.removeChild(link);
  //       loaderRef.current.style.opacity = 0;
  //     })
  //     .catch((err) => {
  //       handleError(err);
  //       loaderRef.current.style.opacity = 0;
        
  //     });
  // };

  
  // test Ask User where to download-----------HA

  const downloadExcel = async () => {
    try {
      // Fetch the file data using Axios
      const response = await axios({
        url: `${MOLE_ORDER_URL}/${orderId}/export`,
        method: "GET",
        responseType: "arraybuffer", // Set the response type to arraybuffer
        headers: {
          Authorization: `Token ${AuthToken()}`,
        },
      });
  
      // Create a Blob from the response data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
  
      // Create a link element to download the file
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${projectName.toLowerCase()}.xlsx`;
      document.body.appendChild(link);
      link.click();
      
      // Clean up
      URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
  
      // Show success message after the file is saved
      handleSuccess(TOAST_TYPE.SUCCESS, "Excel Downloaded Successfully!");
      loaderRef.current.style.opacity = 0;
    } catch (e) {
      if (e.name === 'AbortError') {
        // User canceled the file save dialog
        handleSuccess(TOAST_TYPE.INFO, "Download canceled by the user.");
      } else {
        console.error('Error:', e);
        handleSuccess(TOAST_TYPE.ERROR, "Download Failed!");
      }
      loaderRef.current.style.opacity = 0;
    }
  };
  
  

  // test-----------HA

  
  


  // Delete Bulk Order
  // const deleteBulkDraftOrder = () => {
  //   let deleteIds = '';
  //   selectedIds.forEach((id) => (deleteIds += id + '%2C'));
  //   deleteIds = deleteIds.slice(0, deleteIds.length - 3);
  //   axios
  //     .delete(`${CREATE_PROPERTY_URL}?id=${deleteIds}`, {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //         Accept: 'application/json',
  //       },
  //     })
  //     .then((response) => {
  //       getOrderList();
  //       setSelectedIds([]);
  //       handleSuccess(TOAST_TYPE.SUCCESS, 'Order Deleted Successfully!');
  //     })
  //     .catch((error) => {
  //       console.error(error.message);
  //     });
  // };

const [deleteIdBulkOrder,setDeleteIDBulkOrder]  = useState("") 

const deleteBulkOrder =()=>{
  document.querySelector(".deleteBulkBox").style.display="block"
  console.log("Delete first")
  console.log(orderId," {project?.id} Delete first")
  setDeleteIDBulkOrder(orderId)

  
}
const deleteBulkOrderConfirm=()=>{

  console.log(deleteIdBulkOrder,"orderId Confirm")

   console.log(typeof(parseInt(deleteIdBulkOrder)),"Delete dffffffffffff")
  
  axios
      .delete(`${MOLE_ORDER_URL}/${deleteIdBulkOrder}?bulk_order_id=true`, {
        headers: {
          Authorization: `Token ${AuthToken()}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
          console.log(response,"delete response")
        handleSuccess(TOAST_TYPE.SUCCESS, ' Bulk Order Deleted Successfully!');
        setDeleteIDBulkOrder("")
        getProjectList()
        document.querySelector(".deleteBulkBox").style.display="none"

      })
      .catch((error) => {
        console.error(error.message);
      });


}


  const requestImage = () => {
    const data = {
      bulk_order_id:orderId
    }
    axios({

      // url: `${MOLE_ORDER_URL}/${orderId}/image-request`,
      url: `${MOLE_ORDER_FILE}/download`,

     
      method: "POST",
      data:data,
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        handleSuccess(TOAST_TYPE.SUCCESS, "Image Request Sent");
        //  handleSuccess(TOAST_TYPE.INFO, "To check your image status, click the request image button.");
        setTimeout(() => {
          handleSuccess(TOAST_TYPE.INFO, "To check your image status, click the request image button.");
      }, 3000);
       
       handleSuccess(TOAST_TYPE.SUCCESS, res?.data?.msg);
      })
      .catch((err) => {
        // console.log(err.response.data.message,"error msg")
        // console.log(err,"error msg")
         handleError(err);
        //  handleError(TOAST_TYPE.ERROR,"No Measurement Completed Order Found");
        
      });
  };



const [statusData, setStatusData] = useState({})

const[showNewAdd, setShowNewAdded] =useState(false)
const[isFailed,setIsFailed]=useState(false)
const[isAddedImage,setIsAddedImage]=useState(false)
const[isClicked,setisClicked]=useState(false)
const[CalCompleted,setCalCompleted]=useState()
const[CalInProgress,setCalInProgress]=useState()
const[CalTotal,setCalTotal]=useState()

const failedImagehandle =()=>{
  setIsAddedImage(false)
  requestImage();
  setIsFailed(false)
}  
const AddedImagehandle =()=>{
  setIsFailed(false)
  requestImage();
  setIsAddedImage(false)
}  
const openProgressBox =()=>{
  setisClicked(!isClicked)
  if(isClicked){
     document.querySelector(".ProgressBox").style.display="block"
  }

}  
useEffect(() => {
  setIsFailed(false)
  setIsAddedImage(false)
  if (statusData) {
    failedOrAdded();
  }
}, [statusData, CalInProgress, CalCompleted, CalTotal]);

const failedOrAdded=()=>{
  console.log("failed or Added ")
  let statusFailed = statusData?.data?.failed
  if(statusFailed > 0 && statusData?.data?.in_progress == 0 ){
    console.log("this line statusFailed > 0 && statusData?.data?.in_progress == 0  ")
    setIsFailed(true)
    return
  }
  if((CalInProgress + CalCompleted) === CalTotal ) {
    console.log("this line (CalInProgress + CalCompleted) === CalTotal ")
    setIsFailed(false)
    setIsAddedImage(false)
    return
  }
  if((CalInProgress + CalCompleted) < CalTotal){
    // NewOrder Moved
    console.log("this line (CalInProgress + CalCompleted) < CalTotal ")
    setIsAddedImage(true)
    // requestImage();
  }
 
 

  // ------------------------------above three is enough---------------
  // if(statusFailed){

  //   requestImage();
  // }
  
  // // "total": 155,
  // "in_progress": 107,
  // "downloaded": 1,
  // "completed": 45,
  //   107+ 45 =152    155
  //   107+ 47 =154  <  155 + Add new data 10 = 165 
  if((CalInProgress + CalCompleted) !== CalTotal ) {
    // again call nhi krna chahta hu kyuki mera procee me
    // ye kabhi chalega nhi kyuki ye kabhi not equal nhi hoga
    // return
  }
  if((CalInProgress + CalCompleted) === CalTotal && CalInProgress!= 0 ) {
    // (CalInProgress + CalCompleted) === CalTotal ye cheez hamesha true rhegi , but jab process 
    // inprogress me=0 nhi mtlb khuch chal rha h ,
    // return sbkhuch done
    // return
  }
  // inprogress= 0,
  // 
       
 
}


const checkStatus = ()=>{
  
   console.log(orderId,"orderId in checkstatus")

  axios({
    url:`${MOLE_ORDER_TASK}/progress/${orderId}`,
    method :"GET",
    headers:{
      Authorization:`Token ${AuthToken()}`
    }
    
  })
  .then((res)=>{

    // console.log("1")
    let status = res.status
    let taskId  = res.data.taskId
    console.log(taskId,"taskId")
    
    // console.log(status)
    // if(status === 404){
     
    //   handleError(TOAST_TYPE.ERROR,res.data)
    //   console.log("Bulk Order Not found")

    // }
    // if(status === 400){
    //  requestImage()
    // }
    // "data": {
    //     "total": 17,
    //     "in_progress": 0,
    //     "completed": 2,
    //     "failed": 0
    // }
    
    if(status===200){
       setStatusData(res.data)
       setCalCompleted(res?.data.data.completed)
       setCalInProgress(res?.data.data.in_progress)
       setCalTotal(res?.data.data.total)
       console.log(res?.data.data.completed,"comppleted",res?.data.data.in_progress,"in_progress",res?.data.data.total,"total")
      //  failedOrAdded()
       setOrderId(orderId)
       
  
      //  console.log(orderId,"orderId in response set-------")
     
       document.querySelector(".ProgressBox").style.display="block"
       document.querySelector(".ProgressOverlay").style.display="block"

      //  console.log(orderId,"orderId in response set")

    }
   })
   .catch((err)=>{


    if (err.response) {
      let status = err.response.status;
    if (status === 400) {
        requestImage();
      } else {
    handleError(err);
      }
    } else {
        handleError(err);
    }
   
   })
}

let percentage = Math.round((statusData?.data?.completed / statusData?.data?.total) * 100);
if(percentage > 100){
  percentage=100
}
// let percentage = Math.round((104/ 155) * 100);

  const progressBarStyle = {
    width: `${percentage}%`,
    backgroundColor: '#519259',
    height: '100%',
    borderRadius: 'inherit',
    textAlign: 'center',
    lineHeight: '30px',
    color: 'white'
   };



  // pagination start
  const [rowCount, setRowCount] = useState(0);
  
  const pageNum = searchParams.get("page") || 1;

  const [pageCount, setPageCount] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [inputValue, setInputValue] = useState("10");
  const [invalidInput, setInvalidInput] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [jumpToInput, setJumpToInput] = useState(currentPage);


  const [activeTag, setActiveTag] = useState(false);








  const handleKeyDown = (event) => {
    const input = event.target.value;
    if (event.key === "Enter") {
      event.preventDefault();
      if (input) {
        if (input < 0) {
          setJumpToInput(1);
          setCurrentPage(1);
        } else if (input > pageCount) {
          setJumpToInput(pageCount);
          setCurrentPage(pageCount);
        } else setCurrentPage(input);
      }
    }
  };


  const handlePageChange = (data) => {
    console.log(data, "handle page change data")
    let currentPage = data.selected + 1;
    setCurrentPage(currentPage);
    searchParams.set("page", currentPage);
    setSelectedIds([]);
    setActiveTag(!activeTag);
    setSearchParams(searchParams);

    // const tableContainer = document.getElementById("yourTableContainerId");

    const tableContainer = document.querySelector(".project-list-container");
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }
  };


  const fetchAllBulkProject =()=>{
    
  // const defaultParams = {
  //   all: false,
  //   // name: '',
  //   // status: '',
  //   // ops_status: '',
  //   // start_date: '',
  //   // end_date: '',
  //   // created_by_id: '',
  //   // updated_by_id: '',
  //   // p: 1,
  //   // psz: 10,
  // };

 
  // const queryParams = { ...defaultParams };

  // Construct query string from parameters
  // const queryString = Object.keys(queryParams)
  //   .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
  //   .join('&');

  // axios({
  //   // url: `${MOLE_ORDER_URL}?${queryString}`,
  //   url: `${MOLE_ORDER_URL}?all`,
  //   method: "GET",
  //   headers: {
  //     Authorization: `Token ${AuthToken()}`,
  //   },
  // })
  axios({
    // url: `${MOLE_ORDER_URL}?${queryString}`,
    url: `${MOLE_ORDER_URL}?p=${currentPage}&psz=${inputValue}`,
    method: "GET",
    headers: {
      Authorization: `Token ${AuthToken()}`,
      Accept: 'application/json',
    },
  })
  
    .then((res) => {
      setProjectList(res.data.data);
      setRowCount(res.data.count); // for pagination
      setPageCount(res.data.total_pages); // for pagination
      console.log(res.data.data, "All respons data data");
      console.log(res.data.total_orders, "total order response data data");
      console.log(res.data.data, "MOLE_ORDER_URL in bulk order");
    })
    .catch((err) => {
      handleError(err);
    });
};


  
  

  useEffect(()=>{
    fetchAllBulkProject()
  },[currentPage,inputValue])

  const UploadeBulkProjectorderPage= true

  // pagination end

  return (
    <div style={{ height:" 100%"}} >
  
     <div className="project-main-container"  style={{ height:" 85%"}}>
      <div className="project-title-container">
        <h2>Projects</h2>
      </div>
      <div className="project-filter-button-container">
        <div className="project-filter-left-container">
          <div className="project-search-container">
            <input
              type="text"
              placeholder="Search by Name"
              onChange={(e) => setQuery(e.target.value)}
            />
            <SearchOutlinedIcon className="orders-search-icon" />
          </div>

          <div
            class="project-date-select"
            onClick={() => setOpenDate(!openDate)}
            ref={dateRef}
          >
            <span>Created On</span>
            {openDate ? (
              <KeyboardArrowUpIcon className="order-filter-icon" />
            ) : (
              <KeyboardArrowDownIcon className="order-filter-icon" />
            )}
            {openDate ? (
              <div
                onClick={(e) => e.stopPropagation()}
                className="project-date-filter order-date-filter"
              >
                <h4>Date</h4>
                <div className="order-date-filter-one">
                  <span>From</span>
                  <input
                    type="date"
                    // value={startDate.startDate}
                    // onChange={(event) =>
                    //   setStartDate({ startDate: event.target.value })
                    // }

                    value={startDate}
                    onChange={(event) => setStartDate(event.target.value)}

                  />
                </div>
                <div className="order-date-filter-two">
                  <span>To</span>
                  <input
                    type="date"
                    // value={endDate.endDate}
                    // onChange={(event) =>
                    //   setEndDate({ endDate: event.target.value })
                    // }
                    value={endDate}
                    onChange={(event) => setEndDate(event.target.value)}
                  />
                </div>
                <hr />
                <button
                  onClick={() => {
                    if (startDate && endDate) {
                      newhandleDateFilter();
                    } else {
                      handleSuccess(
                        TOAST_TYPE.WARNING,
                        "Please select a proper date!"
                      );
                    }
                  }}
                >
                  ✔ Apply
                </button>
              </div>
            ) : null}
          </div>
          {startDate && endDate ? (
            <div className="project-clear-filter">
              <span>Created On.</span>
              <ClearIcon
                className="close-filter-icon"
                onClick={newclearDateFilter}
              />
            </div>
          ) : null}
          
        </div>
        <div className="create-new-project">
          <button
            onClick={() => {
              setAction("create");
              outerRef.current.style.opacity = 1;
              outerRef.current.style.pointerEvents = "auto";
              setProjectName("");
            }}
          >
            Create New
          </button>
        </div>
      </div>
      <div className="project-list-container">
        {!projectList ? (
          <Skeleton
            count={4}
            style={{
              height: "25%",
              marginBottom: "33px",
            }}
          />
        ) : projectList?.length < 1 ? (
          <div className="no-bulk-project-container">
            <h2>No Bulk Project is Created</h2>
            <p>Click below button to create bulk project</p>
            <button
              onClick={() => {
                setAction("create");
                outerRef.current.style.opacity = 1;
                outerRef.current.style.pointerEvents = "auto";
              }}
            >
              Create Bulk Project
            </button>
          </div>
        ) : (
          projectList.map((project, index) => {
            return (
              <div className="project-list-item" key={project?.id}>
                <div className="project-list-item-info">
                  <p>
                    <span>Project ID</span>: {project?.id}
                  </p>
                  <p>
                    <span>Project Name</span>: {project?.name}
                    {/* HA */}
                    {/* <EditIcon className="ProjectNameEdit" style={{fontSize:"0.8rem", cursor:"pointer" }}  onClick={ EditNameHandler}/> */}
                    <span id="EditIconDiv" onClick={ ()=> handleEdit(project)}>
                    <div id="EditDivIcon">
                    <EditIcon className="ProjectNameEdit" style={{fontSize:"0.8rem", cursor:"pointer" }}  />
                    <span className="Edit__TEXT" id="Edit__TEXT">Edit</span>
                      </div>   
                    </span>
                  </p>

                  <p>
                    <span>Features</span>: {""}
                    {project?.feature_data.map((feature, index) => {
                      return (
                        <>
                          {feature.name}
                          {index < project?.feature_data?.length - 1 ? (
                            <span>{","} </span>
                          ) : null}
                        </>
                      );
                    })}
                  </p>

                  {/*  
                  

                       <p>
        {isExpanded ? paragraph : `${paragraph.substring(0, 100)}...`}
      </p>
      <button onClick={toggleReadMore}>
        {isExpanded ? 'Read Less' : 'Read More'}
      </button>
 */}
                  {/* {
  console.log("project?.description ",project?.description.substring(0, 10) )
} */}

                  {/* workiing  */}
                  {/* {project?.description && (
                    <p>
                      <span>Description</span>: {project?.description}
                  </p>
                  )} */}
                  {/* workiing  */}

                  {project?.description && (
                    <p>
                      <span>Description</span>:{/* {project?.description}  */}
                      {/* {isExpanded ? paragraph : `${paragraph.substring(0, 100)}...`} */}
                      {/* below code is working */}
                      {/* {isExpanded ? project?.description : `${project?.description.substring(0, 10)}...`}  */}
                      {/* {isExpanded ? project.description : getFirstTenWords(project?.description)} */}
                      {/* {console.log(getFirstTenWords(project?.description),"10 words")} */}
                      {/* {  isExpanded ? project.description : getFirstTenWords(project?.description)} */}
                      {/* {showReadMore && ( */}
                      {/* <button style={{border:"none" , background:"none", color:"green", cursor:'pointer'}} onClick={toggleReadMore(project?.id)}> 
                        {isExpanded ? 'Read Less' : 'Read More'}
                       
                    </button> */}
                      {/* )} */}
                      {/* {expandedProjects[project.id] ? project.description : getFirstTenWords(project?.description)}
                  <button 
            style={{ border: "none", background: "none", color: "green", cursor: 'pointer' }} 
            onClick={() => toggleReadMore(project?.id)}
          >
            {   expandedProjects[project.id] ? 'Read Less' : 'Read More'}
            
                  </button> */}
                      {expandedProjects[project.id] ||
                      !isMoreThanTenWords(project.description)
                        ? project.description
                        : getFirstTenWords(project?.description)}
                      {isMoreThanTenWords(project.description) && (
                        <button
                          style={{
                            border: "none",
                            background: "none",
                            color: "green",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleReadMore(project?.id)}
                        >
                          {expandedProjects[project.id]
                            ? "Read Less"
                            : "Read More"}
                        </button>
                      )}
                    </p>
                  )}
                </div>
                <div style={{ width: "25%", marginRight: "20%" ,display:"flex",justifyContent:"center",alignItems:"center" }}>
                  {project.status === ORDER_STATUS.DRAFT ? (
                    <div className="project-list-item-progres-bar">
                      <ProgressBar
                        draft={project?.draft_orders}
                        total={project?.total_orders}
                        inProgress={project?.in_progress}
                        completed={project?.completed_orders}
                      />
                    </div>
                  ) : null}
                </div> 
            
                <div className="project-list-item-action">

                  <div className="project-list-item-action-one">
                    <p>{project?.created_at.slice(0, 10)}</p>

                    {(project.status === ORDER_STATUS.DRAFT ||
                      project.status === "uploaded" ||
                      project.status === "completed") && (
                      <div
                        onClick={() => {
                          setOrderId(project?.id);
                          console.log("click div")
                          setCurrentProjectStatus(project.status)
                        }}
                      >
                        <IconButton
                          aria-label="more"
                          sx={{ padding: 0 }}
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            let targetData = e.currentTarget;
                            setAnchorEl(targetData);
                            setProjectName(project?.name);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                           {/* {
                            console.log((project.status !== "uploaded"), "project.status !==" )  
                            
                           }
                           { console.log(currentProjectStatus, "project.status !== ") } */}
                        {(currentProjectStatus !== "uploaded") ? (
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            sx: {
                              boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2)', // Custom box-shadow
                            },
                            style: {
                              maxHeight: 48 * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                         
                  
                   
                          <MenuItem
                            onClick={() => {
                              handleClose();

                              //  requestImage();
                              checkStatus();

                            }}
                            className="order-action-menu-item fullWidthInBulkorder"
                            // sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            Request Images
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              downloadExcel();
                              loaderRef.current.style.opacity = 1;
                            }}
                            className="order-action-menu-item fullWidthInBulkorder"
                          >
                            Download as Excel
                          </MenuItem>
                           <MenuItem
                            onClick={() => {
                              handleClose();
                              deleteBulkOrder();
                              
                            }}
                            className="order-action-menu-item fullWidthInBulkorder"
                          >
                            Delete
                          </MenuItem>
                        </Menu>
                         
                         

                    ) : (
                      <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              deleteBulkOrder();
                              
                            }}
                            className="order-action-menu-item"
                          >
                            Delete
                          </MenuItem>

                      </Menu>
                        
                      )
                        }

                      </div>
                    )}

                    {/* show only delete button if project status is uploaded(it means there is nothing is uploaded still-- Conditional rendering not working with MUI menuitem so that define separately */}
                   
                    {/* {(
                     project.status === "uploaded" &&(project.status !== ORDER_STATUS.DRAFT ||
                    
                      project.status !== "completed")
                  ) && (
                      <div
                        onClick={() => {
                          setOrderId(project?.id);
                        }}
                      >


                    {(project.status === ORDER_STATUS.DRAFT || project.status === "completed") && (
                      <div onClick={() => { setOrderId(project?.id);}}>

                        <IconButton
                          aria-label="more"
                          sx={{ padding: 0 }}
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => {
                            let targetData = e.currentTarget;
                            setAnchorEl(targetData);
                            setProjectName(project?.name);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                         
                    
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              deleteBulkOrder();
                              
                            }}
                            className="order-action-menu-item"
                          >
                            Delete
                          </MenuItem>

                  

                        </Menu>

                        
                      </div>
                    )} */}

                    {/* show only delete button if project status is uploaded(it means there is nothing is uploaded still-- Conditional rendering not working with MUI menuitem so that define separately */}
                    {/* {project.status !== ORDER_STATUS.DRAFT &&
                      project.status !== 'completed' && (
                        <div
                          className="project-list-item-action-dropdown"
                          onClick={() => {
                            setOrderId(project?.id);
                          }}
                        >
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open2 ? 'long-menu' : undefined}
                            aria-expanded={open2 ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              setAnchorEl2(e.currentTarget);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl2}
                            open={open2}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: 48 * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            <MenuItem
                              className="order-action-menu-item"
                              disabled
                            >
                              <AddAttachment
                                id={orderId}
                                maxSize={20}
                                attachmentForBulk={true}
                                attachments={attachments}
                                handleError={handleError}
                                handleSuccess={handleSuccess}
                                setAttachments={setAttachments}
                              />
                            </MenuItem>
                          </Menu>
                        </div>
                      )} */}
                  </div>


{/*    project.status === "uploaded"  */}














{/*  */}









                  <div className="project-list-item-action-buttons">
                    <a href={`/project/${project?.id}`} style={{ width: "100%" }} >
                      <button style={{ width: "100%", padding: "4% 10%" }}>
                        View Project
                      </button>
                    </a>
                    {/* <button
                      disabled
                      onClick={() => {
                        setAction('edit');
                        setProjectName(project?.name);
                        outerRef.current.style.opacity = 1;
                        setFeatureSelected(project?.feature_data);
                        setProjectDescription(project?.description);
                        outerRef.current.style.pointerEvents = 'auto';
                      }}
                    >
                      Edit
                    </button> */}
                  </div>


                  <div className="project-list-item-status">
                    {project?.status[0].toUpperCase() +  project?.status.slice(1)}
                  </div>
                 
                  {/* { true &&
                    <div className="project-list-item-status">
                  
                      <button style={{ width: "100%", padding: "4% 10%" ,cursor:"pointer"}}
                      onClick={openProgressBox}
                      >
                       
                        Generating...
                      </button>
                    
                  </div>} */}

                </div>
              </div>
            );
          })
        )}
      </div>
      <div className="create-project-outer-container" ref={outerRef}>
        <div className="create-project-inner-container" ref={innerRef}>
          <div className="raise-feedback-inner-top">
            <span
              onClick={() => {
                setProjectName("");
                setFeatureSelected([]);
                setSnowFeatures(false);
                setProjectDescription(null);
                setLandscapeFeatures(false);
                setCurrentEditedItem("");
                setCurrentEditedDes(null);
                setCurrentEdit("");
                setText("")
                
                
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = "none";
              }}
              style={{fontSize:"1.6rem"}}
            >
              &#215;
            </span>
          </div>
          <div className="create-project-inner-mid">
            <div className="create-project-name">
              {/* <label htmlFor="name">Name*</label> */}
              <label htmlFor="name" className="to__give__btmMargin">
                {/* {action==="create"?"Enter a short" : "Enter a short"} name for the Bulk Project Name{action==="create"?"*":null} */}
                {action==="create"?"" : "Update "}Bulk Project Name{action==="create"?"*":null}
              </label>
             
             { action ==="create" ? <input
                type="text"
                 value={projectName}
                // value={project?.name}
                
                maxLength={30}
                placeholder="15 Character Name"
                onChange={(e) => setProjectName(e.target.value)}
              />: 
              <input
                type="text"
                // value={updateName}
                // value={currentEdit}
                value={currentEditedItem}
              
                
                 placeholder="Update name"
                // onChange={(e) => setProjectName(e.target.value)}
                onChange={(e)=>handleUpdateTitle(e.target.value)}
              /> }
              {/* {console.log(projectList,"projectList")} */}
              {/* <input
                type="text"
                value={projectName}
                // placeholder="Enter a name for bulk property"
                placeholder="15 Characters Name"
                onChange={(e) => setProjectName(e.target.value)}
              /> */}
            </div>
            <div className="create-project-description">
              <label htmlFor="description" className="to__give__btmMargin">Description{action==="create"?"":null}</label>
              <div style={{position:"relative",marginBottom:"0.8rem"}}>
              { action ==="create" ?
                <textarea
                value={text}
                onChange={handleChange}
                name="description"
                // value={projectDescription} 
                // placeholder="Enter a brief project description"Enter a short description about the project
                rows={5}
                placeholder="Enter a brief project description"
                maxLength={500}
                //onChange={(e) => setProjectDescription(e.target.value)}
              />  : 
               <textarea
                // value={text}
                  value={currentEditedDes}
                // onChange={handleChange}
                 onChange={(e)=>handleUpdateDescription(e.target.value)}
                 name="description"
                 rows={8}
                // value={projectDescription}
                placeholder="Update Description about the project"
                maxLength={500}
                
              />  
              }
              {/* counter div */}
              <div  id="counter__div"
               
              >
                {/* {wordCount}/{maxWords} */}
                {/* {projectDescription.length}/{maxChars} */}
                {/* {projectDescription.replace(/\s+/g, '').length}/{maxChars} */}
                {text?.length}/{maxChars}
              </div>

              </div>
              {/* counter div */}
            </div>
{/* 
            {featureSelected.map((feature, index) => (
          <span key={index} className="selected-feature" style={{width:"200px ",height:"200px"}}>
            
            {feature}
          </span>
        ))} */}
            {(action === "create" ) && (
              <div className="create-project-features">
                <div style={{display:"flex",justifyContent:"space-between"}}> <label htmlFor="features" className="to__give__btmMargin">Features*</label> <span  style={{ fontSize:"0.8rem",letterSpacing: "0.09em",fontWeight:"600"}}><span  style={{ fontSize:"0.8rem" ,letterSpacing: "0.09em",fontWeight:"600"}}> Selected Features</span> {featureSelected.length}/21 {console.log(featureSelected,"featureSelected")}</span> </div>
                <div className="snow-landscape-segment-cont">
                  <FormGroup row={false}>
                    <FormControlLabel
                      sx={{ marginTop: "5px" }}
                      className="features-info-input"
                      control={
                        <input
                          type="checkbox"
                          value="Okay"
                          checked={snowFeatures}
                          onChange={() => {
                            if (action === "create" || action ==="edit") {
                              selectSnowFeatures();
                            }
                          }}
                        />
                      }
                      label="Select Snow Features"
                    />
                  </FormGroup>
                  <FormGroup row={false}>
                    <FormControlLabel
                      className="features-info-input"
                      control={
                        <input
                          type="checkbox"
                          value="Okay"
                          checked={landscapeFeatures}
                          onChange={(e) => {
                            if (action === "create" || action==="edit" ) {
                              selectLandscapeFeatures();
                            }
                          }}
                        />
                      }
                      label="Select Landscape Features"
                    />
                     
                  </FormGroup>
                </div>
                <div className="create-project-features-list">
                  <div className="feature-info-container create-project-feature-info-container">
                    <div className="features-container">
                      <FormGroup row={false}>
                        {availableFeatures
                          .filter((feature) => feature.creator === "otter")
                          .map((item) => (
                            <FormControlLabel
                              key={item.id}
                              className="features-info-input"
                              control={
                                <input
                                  type="checkbox"
                                  value={item.id}
                                  checked={checkCheckBox(item.id)}
                                  onChange={(e) => {
                                    if (action === "create" || action === "edit") {
                                      if (e.target.checked) {
                                        setFeatureSelected([
                                          ...featureSelected,
                                          item,
                                        ]);
                                      } else {
                                        setFeatureSelected(
                                          featureSelected.filter(
                                            (feature) => feature.id !== item.id
                                          )
                                        );
                                      }
                                    }
                                  }}
                                />
                              }
                              label={item.name}
                            />
                          ))}
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="create-project-inner-bottom">
            <div>
              <button
                onClick={() => {
                  setProjectName("");
                  setFeatureSelected([]);
                  setSnowFeatures(false);
                  setProjectDescription(null);
                  setLandscapeFeatures(false);
                  setCurrentEditedItem("");
                  setCurrentEditedDes(null);
                  setCurrentEdit("");
                  setText("")
                  outerRef.current.style.opacity = 0;
                  outerRef.current.style.pointerEvents = "none";
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleAction();
                }}
              >
                {action === "create" ? "Create" : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>

     {/* Update the bulk order form  */}

     
     
   
     

     {/* Update the bulk order form  */}

      


      <div ref={loaderRef} className="loader_outer-container">
        <div className="main_loader_container">
          <span className="bulk_loader"></span>
        </div>
      </div>

      {/* delete Bulk Order form */}
      <div className="deleteBulkBox " style={{display:"none" ,position:"absolute",top:"50%",left:"50%", transform:"translate(-50%,-50%)", background: "#ffff",borderRadius: "20px",
padding:"1rem",  boxShadow: "rgba(16, 17, 17, 0.5) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px"

      }}>
      <div style={{width:"100%",display:"flex",justifyContent:"end"}} ><span className="" style={{cursor:"pointer",position:"",top:"0.8rem", right:"1rem",fontSize:"1.6rem"}}  onClick={()=> document.querySelector(".deleteBulkBox").style.display="none"}>&#215;</span>
      </div>
      <div style={{display:"flex",justifyContent:"center", height:"2rem"}}><img className='' style={{height:"100%"}} src={ DeleteConfirmIcon}   / ></div>
     <div className="Remove__Margin_of__p"
      style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
     <p style={{fontSize:"1.4rem",fontWeight:"bold"}}>  Confirmation</p>
      <p> Are you sure? You want to delete this property order?</p>
      <p style={{fontSize:"0.8rem" ,marginTop:"-0.2rem"}}>This action can't be undone.</p>

     </div>
     
      <div className="view-modal-bttns " id="view__model__BTTNS" style={{marginTop:"0 !important"}}
      >
        <button className="cancle" onClick={()=> document.querySelector(".deleteBulkBox").style.display="none"}>Cancel</button>
        <button className="delete"  id="BulkOrderDeleteBtn" onClick={deleteBulkOrderConfirm}>Delete</button>
      </div>

      </div>

      {/* toastify */}

    {/* for the progress show  */}

   <div style={{display:"none" ,position:"absolute",top:"50%",left:"50%", transform:"translate(-50%,-50%)",
   cursor:"pointer",
    background: "rgb(0 0 0 / 45%)",width:'100vw',height:"100vh",zIndex:"502"}}
   className="ProgressOverlay"
   onClick={()=>{
    document.querySelector('.ProgressOverlay').style.display="none"
    document.querySelector('.ProgressBox').style.display="none"
   
   }}
   >
   </div>

    <div className="ProgressBox" style={{display:"none" ,position:"absolute",top:"50%",left:"50%", transform:"translate(-50%,-50%)", background: "#ffff",borderRadius: "20px",
          padding:"1rem",  boxShadow: "rgba(16, 17, 17, 0.5) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",zIndex:"503"

      }}>
      <div style={{width:"100%",display:"flex",justifyContent:"end"}} ><span className="" style={{cursor:"pointer",position:"",top:"0.8rem", right:"1rem",fontSize:"1.6rem"}}  onClick={()=> {document.querySelector(".ProgressBox").style.display="none"
      document.querySelector('.ProgressOverlay').style.display="none"
      }}>&#215;</span>
      </div>
     <div className="Remove__Margin_of__p"
      style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
     <p style={{fontSize:"1.4rem",fontWeight:"bold"}}> {statusData?.data?.in_progress != 0  ? (
    <p>Images Generating</p>
  ) : (
    <p>Images Generated</p>
  )}
  </p>
     {/* <div className="loading-dots">
     <p style={{fontSize:"1.4rem",fontWeight:"bold"}}>
     Images Processing
     <span>.</span><span>.</span><span>.</span>
     </p>
      
    </div>    */}
    {/* <p>{statusData?.data?.in_progress}/{statusData?.data?.total}</p> */}
<div>
  {statusData?.data?.in_progress != 0  ? (
    <p  className="loading-dots"  style={{textAlign:"center"}}>
     <span>.</span><span>.</span><span>.</span>
    </p>
  ) : (
    <p style={{textAlign:"center"}}><box-icon name='check-circle' type='solid' color='#519259' size='md' ></box-icon></p>
  )}
  
  <p style={{textAlign:"center" ,fontSize:"0.8rem" , fontWeight:"700"}}>
    {statusData?.data?.in_progress != 0 
      ? `Remaining Image: ${ statusData?.data?.in_progress} `
      : `Generated Image: ${
          statusData?.data?.completed > statusData?.data?.total
          ? statusData?.data?.total
          : statusData?.data?.completed
      } `
    } <br />Total Image: {statusData?.data?.total}
  </p>      
       
</div>
 <div style={{ width: '100%', backgroundColor: '#e0e0df', borderRadius: '10px', height: '30px', margin: '10px 0' }}>
      <div style={progressBarStyle}>
        {`${percentage}%`}
      </div>
    </div>
 {/* <p style={{ textAlign: "center", fontSize: "0.8rem", fontWeight: "700" }}>
      Expected Time: {calculateTime(3 * (statusData?.data?.completed || 0))}
  </p> */}


{
  isFailed &&
  <div style={{display:"flex",width:"100%",gap:"0.4rem",justifyContent:"center",alignItems:"center",marginTop:"0.4rem"}}>
  <span style={{fontSize:"0.8rem",color:"gray",textAlign:"center",marginBottom:"0"}}>
   Failed Some Images
  </span>

  <button style={{background:"#519259",color:"white" ,padding:"0.4rem 0.6rem",border:"none",borderRadius:"5px",cursor:"pointer"}}
  onClick={failedImagehandle}
  >
  Generate Again
  </button> 
  </div>
}
{ isAddedImage &&
  <div style={{display:"flex",width:"100%",gap:"0.4rem",justifyContent:"center",alignItems:"center",marginTop:"0.4rem"}}>
 {/* <span style={{fontSize:"0.8rem",color:"gray",textAlign:"center",marginBottom:"0"}}> */}
  {/* New Orders Added */}
  {/* </span>  */}
  <button
  style={{background:"#519259",color:"white" ,padding:"0.4rem 0.6rem",border:"none",borderRadius:"5px",cursor:"pointer"}}
  onClick={AddedImagehandle}
  >Generate Remaining Images</button>
  </div>
}
{
  statusData?.data?.completed > 0? (
    <p style={{fontSize:"0.8rem",color:"gray"}}>
      <span >See the Downloaded Images{' '}</span>
      {console.log(orderId,"orderId in before here ")}
      <Link to={`/mydocs/${orderId}`} style={{color: "blue", textDecoration: "underline"}}>
        here
      </Link>
    </p>
  ) : <p style={{fontSize:"0.8rem",color:"gray",textAlign:"center",marginBottom:"0"}}>
  Generate images only for measurement completed orders.
     <p style={{fontSize:"0.6rem",color:"gray",textAlign:"center",margin:"0"}}>Note: It may take some time.</p> 
    </p>
}

     </div>
    </div>
    {/* </div>  */}


    </div>
 
    <div  style={{ height:" 15%"}} >
    <Pagination
        invalidInput={invalidInput}
        jumpToInput={jumpToInput}
        setJumpToInput={setJumpToInput}
        handleKeyDown={handleKeyDown}
        handlePageChange={handlePageChange}
        pageCount={pageCount}
        rowCount={rowCount}
        currentPage={currentPage}
        inputValue={inputValue}
        setInputValue={setInputValue}
        UploadeBulkProjectorderPage={UploadeBulkProjectorderPage}
      />
     </div>
    
    </div>
  );
};

export default Project;
