import axios from "axios";
import * as turf from "@turf/turf";
import { transform } from "ol/proj";
import { CONVERT } from "../../utils/constants";
import AuthToken from "../../context/AuthToken";
import { ORDER_URL } from "../../helper/ApiUrl";

const getNewLayerData = (
  id,
  viewId,
  newLayerId,
  stopLoader,
  setDownloadData,
  setUploadedLayer
) => {
  const token = AuthToken();
  axios
    .get(`${ORDER_URL}${id}/view/${viewId}/layer/${newLayerId}`, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
      },
    })
    .then((response) => {
      if (response.data.data) {
        const layer = response.data.data;
        setDownloadData((prevState) => [...prevState, layer]);
        const layerData = [];
        const singleLayerData = [];
        const totalFeaturesData = layer.data.features;
        try {
          if (layer.type === "polygon") {
            for (let i = 0; i < totalFeaturesData.length; i++) {
              const featureData = [];
              const allDataWithHoles = [];
              const poly = totalFeaturesData[i].geometry.coordinates[0];
              const measurement = parseFloat(
                turf.area(totalFeaturesData[i].geometry) * CONVERT.sqm_sqft
              ).toFixed(2);
              for (let k = 0; k < poly.length; k++) {
                featureData.push(
                  transform(
                    [parseFloat(poly[k][0]), parseFloat(poly[k][1])],
                    "EPSG:4326",
                    "EPSG:3857"
                  )
                );
              }
              allDataWithHoles.push(featureData);
              if (totalFeaturesData[i].geometry.coordinates.length > 1) {
                for (
                  let hole = 1;
                  hole < totalFeaturesData[i].geometry.coordinates.length;
                  hole++
                ) {
                  const holeFeatureData = [];
                  for (
                    let singleHole = 0;
                    singleHole <
                    totalFeaturesData[i].geometry.coordinates[hole].length;
                    singleHole++
                  ) {
                    holeFeatureData.push(
                      transform(
                        [
                          parseFloat(
                            totalFeaturesData[i].geometry.coordinates[hole][
                              singleHole
                            ][0]
                          ),
                          parseFloat(
                            totalFeaturesData[i].geometry.coordinates[hole][
                              singleHole
                            ][1]
                          ),
                        ],
                        "EPSG:4326",
                        "EPSG:3857"
                      )
                    );
                  }
                  allDataWithHoles.push(holeFeatureData);
                }
              }
              singleLayerData.push({
                id: i + 1,
                data: allDataWithHoles,
                visible: true,
                measurement: measurement,
              });
            }
          } else if (layer.type === "line") {
            for (let i = 0; i < totalFeaturesData.length; i++) {
              const featureData = [];
              const poly = totalFeaturesData[i].geometry.coordinates;
              const line = turf.lineString(poly);
              const measurement = (
                turf.length(line, { units: "miles" }) * CONVERT.miles_ft
              ).toFixed(2);
              for (let k = 0; k < poly.length; k++) {
                featureData.push(
                  transform(
                    [parseFloat(poly[k][0]), parseFloat(poly[k][1])],
                    "EPSG:4326",
                    "EPSG:3857"
                  )
                );
              }
              singleLayerData.push({
                id: i + 1,
                data: featureData,
                visible: true,
                measurement: measurement,
              });
            }
          } else if (layer.type === "point") {
            for (let i = 0; i < totalFeaturesData.length; i++) {
              const featureData = [];
              const poly = totalFeaturesData[i].geometry.coordinates;
              const measurement = parseFloat(
                turf.area(totalFeaturesData[i].geometry) / CONVERT.sqm_sqft
              ).toFixed(2);
              featureData.push(
                transform(
                  [parseFloat(poly[0]), parseFloat(poly[1])],
                  "EPSG:4326",
                  "EPSG:3857"
                )
              );
              singleLayerData.push({
                id: i + 1,
                data: featureData,
                visible: true,
                measurement: measurement,
              });
            }
          }
          layerData.push({
            id: newLayerId,
            name: layer.name,
            type: layer.type,
            data: singleLayerData,
            color: layer.color,
            visible: true,
            strokeColor: layer.color,
            fillColor: layer.color,
            fillOpacity: 0.35,
            strokeWeight: 3,
            featureId: layer.feature_id,
          });
        } catch (err) {
          console.log(err);
        }
        setUploadedLayer((prevState) => [...prevState, layerData[0]]);
      }
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default getNewLayerData;
