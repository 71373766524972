import {
    faFileAlt,
    faFilePdf,
    faFileWord,
    faFileExcel,
    faFileAudio,
    faFileVideo,
    faFilePowerpoint,
} from '@fortawesome/free-regular-svg-icons';
import path from 'path';
import './MobileOrderNotes.css';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { Typography, Box } from '@mui/material';
import NoteIcon from '@mui/icons-material/Note';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommentFormat from '../OrderNotesCommentSection/CommentFormat';

export const MobileOrderNotes = ({
    mapClickable,
    setOrderNoteKey,
    orderNotesLayer,
    setOrderNoteData,
}) => {

    const userTimezone = moment.tz.guess();
    const [open, setOpen] = useState(false);

    const FileIcon = ({ url }) => {
        let extname = path.extname(url);
        let icon;
        switch (extname) {
            case '.pdf':
                icon = faFilePdf;
                break;
            case '.doc':
            case '.docx':
                icon = faFileWord;
                break;
            case '.ppt':
            case '.pptx':
                icon = faFilePowerpoint;
                break;
            case '.xls':
            case '.xlsx':
                icon = faFileExcel;
                break;
            case '.jpg':
            case '.jpeg':
            case '.png':
            case '.gif':
                return <img src={url} alt={url} />;
            case '.mp3':
            case '.wav':
                icon = faFileAudio;
                break;
            case '.mp4':
            case '.mov':
                icon = faFileVideo;
                break;
            default:
                icon = faFileAlt;
                break;
        }
        return <FontAwesomeIcon className="doc-type-attachment-icon" icon={icon} />;
    };

    const IconWithText = ({ text, iconName }) => {
        return (
            <Box position="relative" display="inline-block">
                {iconName}
                <Typography
                    variant="body1"
                    component="span"
                    sx={{
                        top: '50%',
                        left: '50%',
                        width: '100%',
                        color: 'white',
                        fontSize: '0.7rem',
                        textAlign: 'center',
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    {text}
                </Typography>
            </Box>
        );
    };

    return (
        <div className='mobile-measurement-notes-inner-container'>
            <div className="mobile-order-notes-list-container">
                {orderNotesLayer &&
                    orderNotesLayer.map((item, key) => {
                        const userLocalTime = moment
                            .utc(item.created_at)
                            .tz(userTimezone)
                            .format('h:mm A, MMM DD, YYYY');
                        return (
                            <div
                                key={key}
                                className="order-notes-list-item"
                                style={{ margin: "0 0.5em 0.5em 0.5em", border: "2px solid #519259" }}
                                onClick={() => {
                                    if (!mapClickable) {
                                        setOrderNoteData(item);
                                        setOrderNoteKey(key + 1);
                                    }
                                }}
                            >
                                <div className="order-notes-list-item-top">
                                    <div className="notes-list-item-top-one">
                                        <Avatar
                                            sx={{
                                                width: '25px',
                                                height: '25px',
                                                fontSize: '0.9rem',
                                                bgcolor: `#1B9C85`,
                                            }}
                                        >
                                            {item.created_by.name.charAt(0).toUpperCase()}
                                        </Avatar>
                                        <div>{item.created_by.name}</div>
                                    </div>
                                    <div className="notes-list-item-top-two">
                                        <IconWithText
                                            text={key + 1}
                                            iconName={
                                                <NoteIcon
                                                    sx={{
                                                        color: `#1B9C85`,
                                                    }}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="order-notes-list-item-mid">
                                    <p>{item.title}</p>
                                    <span>
                                        <CommentFormat comment={item.description} />
                                    </span>
                                </div>
                                <div className="order-notes-list-item-bottom">
                                    <div className="attachments-cont">
                                        {item.attachments &&
                                            Array.isArray(item.attachments) &&
                                            item.attachments.map((attachment, key) => {
                                                return (
                                                    <div key={key} className="attachment-item-cont">
                                                        <FileIcon
                                                            className="attachment-item"
                                                            url={attachment}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div className="note-time">Created Time : {userLocalTime}</div>
                            </div>
                        );
                    })}
            </div>
        </div>
    )
}
