import {
  LOGIN_URL,
  SIGNUP_URL,
  USER_INFO_URL,
  INVITE_MEMBER,
  RESEND_OTP_URL,
  OTP_VERIFY_URL,
} from '../../helper/ApiUrl';
import axios from 'axios';
import './SignUpContainer.css';
import OTPInput from 'otp-input-react';
import Button from '@mui/material/Button';
import { MuiTelInput } from 'mui-tel-input';
import ReCAPTCHA from 'react-google-recaptcha';
import { TOAST_TYPE } from '../../utils/constants';
import { useOTPContext } from '../../context/OTPContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useRef, useState, useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTokenContext } from '../../context/TokenContext';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import CustomButton from '../CustomButton/CustomButton';

const mobileInputStyle = {
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #06372a',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    '& fieldset': {
      border: '0.5px solid #519259',
    },
  },
};

const inputStyle = {
  '&& .MuiOutlinedInput-root': {
    paddingRight: '5px',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #06372a',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  '& input': {
    height: '31.25px',
    padding: '0px',
    paddingLeft: '10px',
  },
};

export default function SignUpContainer({
  handleError,
  setInvitedBy,
  handleSuccess,
}) {
  const captcha = useRef(null);
  const navigate = useNavigate();
  const url = window.location.href;
  const [OTP, setOTP] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const prod = process.env.REACT_APP_PROD;
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [phone, setPhone] = React.useState('');
  const { REACT_APP_CAPTCHA_KEY } = process.env;
  const inviteId = searchParams.get('invite_id');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [termsActive, setTermsActive] = useState(true);
  const [signUpButton, setSignUpButton] = useState(true);
  const [reTypePassword, setReTypePassword] = useState('');
  const { otpContainer, setOtpContainer } = useOTPContext();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { authToken, setAuthToken, rememberMe } = useTokenContext();
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [startTimer, setStartTimer] = useState(false);
  let timer;

  // timer functionality

  function updateTime() {
    if (seconds < 30 && startTimer === true) {
      setSeconds(seconds + 1);
      setMinutes(0);
    }
  }

  useEffect(() => {
    timer = setInterval(updateTime, 1000);
    return () => clearInterval(timer);
  });

  useEffect(() => {
    setOtpContainer(true);
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmVisibility = () => {
    setConfirmVisible(!confirmVisible);
  };

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const contactHandler = (newPhone) => {
    setPhone(newPhone);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const retypePasswordHandler = (e) => {
    setReTypePassword(e.target.value);
  };

  const handleReCaptcha = () => {
    if (captcha.current.getValue()) {
      setSignUpButton(false);
    }
  };

  const handleSignUpFormSubmit = (e) => {
    e.preventDefault();
    if (phone) {
      setSignUpButton(true);
      if (password !== reTypePassword) {
        handleSuccess(TOAST_TYPE.WARNING, 'Password not matched');
        setSignUpButton(false);
        return;
      }

      let formData = new FormData();
      formData.append('email', email);
      formData.append('contact_number', phone);
      formData.append('first_name', name);
      formData.append('password', password);

      let invite_id = '';

      if (inviteId) {
        invite_id += `?invite_id=${inviteId}`;
      }

      axios({
        url: `${SIGNUP_URL}${invite_id}`,
        method: 'POST',
        data: formData,
      })
        .then((res) => {
          if (inviteId) {
            logInInvitedUser();
          } else {
            setOtpContainer(false);
            setStartTimer(true);

          }
        })
        .catch((err) => {
          setSignUpButton(false);
          handleError(err);
        });
    } else {
      handleSuccess(TOAST_TYPE.WARNING, 'Contact Number is Mandatory');
    }
  };

  const handleOtpFormSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('email', email);
    formData.append('otp', OTP);

    axios({
      url: OTP_VERIFY_URL,
      method: 'POST',
      data: formData,
    })
      .then((res) => {
        navigate('/otp-verified');
      })
      .catch((err) => {
        setSignUpButton(false);
        handleError(err);
      });
  };

  const resendOtp = () => {
    axios({
      url: RESEND_OTP_URL,
      method: 'POST',
      data: { email: email },
    })
      .then((res) => {
        if ('data' in res) {
          handleSuccess(TOAST_TYPE.SUCCESS, 'OTP sent successfully');
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const getInvitedUserDetails = () => {
    axios
      .get(`${INVITE_MEMBER}/${inviteId}`, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setInvitedBy(response.data.data);
        setEmail(response.data.data.email);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  useEffect(() => {
    if (inviteId) getInvitedUserDetails();
  }, [inviteId]);

  const logInInvitedUser = () => {
    let formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    axios({
      url: LOGIN_URL,
      method: 'POST',
      data: formData,
    })
      .then((res) => {
        if (!res.data.success) {
          navigate('/signup');
        } else {
          setAuthToken(res.data.data.token);
          setIsLoggedIn(true);
          // if (url.includes(prod)) {
          if (url.includes(process.env.REACT_APP_DEV)) {
            window.analytics.identify(email, {
              email,
            });
            window.analytics.track('User Logged In');
          }
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const getUserInfo = () => {
    axios
      .get(USER_INFO_URL, {
        headers: {
          Authorization: `Token ${authToken}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.data.data.organization === null) {
          localStorage.setItem('role', response.data.data.role);
          navigate('/organization');
        } else {
          localStorage.setItem('role', response.data.data.role);
          if (rememberMe) {
            localStorage.setItem('authToken', authToken);
          } else {
            sessionStorage.setItem('authToken', authToken);
          }
          if (response.data.data.role === 'otter_user')
            navigate('/upload/order');
          else navigate('/home');
          window.location.reload(false);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  if (isLoggedIn) {
    getUserInfo();
  }

  const SignupButton = () => (
    <Button
      disabled={signUpButton || termsActive}
      // disabled={termsActive}
      type="submit"
      variant="contained"
      id="signup-btn"
    >
      Sign Up
    </Button>
  );

  return (
    <div className="signup-container">
      {otpContainer ? (
        <form onSubmit={handleSignUpFormSubmit}>
          <h2 className="signup-title">Sign Up</h2>
          <div className="signup-one">
            <div>
              {/* <label>Name</label> */}
              <TextField
                required
                margin="dense"
                variant="outlined"
                value={name}
                onChange={nameHandler}
                label="Name"
                id="outlined-name-required"
              />
            </div>
          </div>
          <div className="signup-two">
            {/* <label>Email</label> */}
            <TextField
              required
              margin="dense"
              variant="outlined"
              value={email}
              label="Email"
              // id="outlined-required"
              onChange={emailHandler}
              readOnly={inviteId ? true : false}
            />
          </div>
          <div className="signup-five">
            <div className="contact-cont">
              {/* <label>Contact Number</label> */}
              <MuiTelInput
                // id="signup-contact-number"
                required
                margin="dense"
                variant="outlined"
                value={phone}
                continents={['NA']}
                sx={mobileInputStyle}
                defaultCountry={'US'}
                // className="contact-input"
                onChange={contactHandler}
                preferredCountries={['US', 'CA']}
              />
            </div>
          </div>
          <div className="signup-three">
            <div className="signup-three-left">
              {/* <label>Password</label> */}
              <TextField
                // id="signup-password"
                required
                margin="dense"
                variant="outlined"
                // sx={inputStyle}
                value={password}
                label="Password"
                onChange={passwordHandler}
                type={passwordVisible ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility}>
                        {passwordVisible ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="signup-three-left">
              {/* <label>Confirm Password</label> */}
              <TextField
                // id="signup-confirm-password"
                required
                margin="dense"
                variant="outlined"
                // sx={inputStyle}
                value={reTypePassword}
                label="Confirm Password"
                onChange={retypePasswordHandler}
                type={confirmVisible ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleConfirmVisibility}>
                        {confirmVisible ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="term-con-container">
            <input
              type="checkbox"
              onChange={() => setTermsActive(!termsActive)}
            />
            <p>
              I agree to the{' '}
              <Link
                target="_blank"
                className="term-con"
                to="/terms-and-conditions"
              >
                Terms & Conditions
              </Link>
            </p>
          </div>
          <div className="signup-captcha">
            <ReCAPTCHA
              type="image"
              ref={captcha}
              onChange={handleReCaptcha}
              sitekey={REACT_APP_CAPTCHA_KEY}
            />
          </div>
          <div className="signup-four">
            {/* <CustomButton
              ButtonComponent={SignupButton}
              isDisabled={isLoggedIn}
            /> */}
            <SignupButton />
          </div>
          <div className="signup-to-login">
            <p style={{ margin: '1em 0 0.5em 0' }}>
              Already have an Account?{' '}
              <Link to="/">
                <span style={{  color:" #519259",
                                 fontWeight: "600", cursor: 'pointer',textDecoration:"underline" }}>Sign In</span>
              </Link>
            </p>
          </div>
          <div className="signup-to-login email-us-for-otp">
            <p>
              Having trouble logging in or signing up? Email us at{' '}
              <a href="mailto:info@ottermap.com ">
                <span>info@ottermap.com</span>
              </a>
            </p>
          </div>

        </form>
      ) : (
        <div className="enter-otp-screen">
          <button
            className="backToDashboard-mobile-button backToLogin-button"
            onClick={() => setOtpContainer(true)}
          >
            <ArrowBackIcon />
          </button>
          <h2 className="otp-screen-title">OTP Verification</h2>
          <form onSubmit={handleOtpFormSubmit}>
            <div className="otp-screen-one">
              <div className="otp-screen-one-top">
                <label>We’ve sent an OTP on your email.</label>
              </div>
              <div className="otp-screen-one-bottom">
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                />
              </div>
            </div>
            <div className="signup-to-login to-otp">
              <p>
                Don't receive one? <span onClick={resendOtp} className={seconds < 30 ? 'resend-disable' : 'resend-active'}>Resend OTP</span> {minutes}:{seconds} seconds
              </p>
            </div>
            <div className="otp-screen-devider">
              <hr />
            </div>
            <div className="otp-screen-two">
              <button type="submit" variant="contained">
                Submit
              </button>
            </div>
            <div className="signup-to-login email-us-for-otp">
              <p>
                Still did not receive it? Please check your spam or drop us an
                email at <span>info@ottermap.com</span>
              </p>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
