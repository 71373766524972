import axios from 'axios';
import AuthToken from '../../context/AuthToken';
import { ORDER_URL } from '../../helper/ApiUrl';

const getLayersData = (
  id,
  viewId,
  searchParams,
  setResetLayer,
  setLayersData,
  setSearchParams,
  setUploadedLayer
) => {
  const token = AuthToken();
  axios
    .get(`${ORDER_URL}${id}/view/${viewId}/layer/info`, {
      headers: {
        // Authorization: `Token ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => {
      setResetLayer([]);
      setUploadedLayer([]);
      if (viewId) {
        searchParams.set('view_id', viewId);
        setSearchParams(searchParams);
      }
      setLayersData(response.data.data);
    })
    .catch((error) => {
      console.error(error.message);
    });
};

export default getLayersData;
