import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import './CustomModal.css';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({cancel, submit, title, description, icon, setOpen, open, cancelText, submitText,h, w, minH, maxH, minW, maxW, }) {


  const handleClose = () => {
    setOpen(false);
  };

  const dialogStyles = {
    width: w ?? `100% !important`,
    minWidth: minW ?? `350px !important`,
    maxWidth: maxW ?? `30vw !important`,
    height: h ?? `100% !important`,
    minHeight: minH ?? `200px !important`,
    maxHeight: maxH ??  `20vh !important`,
    borderRadius: "20px !important",
    '@media (min-width: 2560px)': {
      minWidth: `25vw !important`,
      // Optionally, you can uncomment the following styles
      // maxWidth: '60vw !important',
      // maxHeight: '30vh !important',
    },
  };


  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        PaperProps={{
          sx: dialogStyles,
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={{display: "flex", width: "100%", justifyContent: "flex-end"}}><IconButton sx={{marginRight: "5px"}} size="small" onClick={() => setOpen(false)}><CloseIcon fontSize="inherit"  /></IconButton></Box>
        <DialogTitle sx={{textAlign: "center", fontSize: "clamp(20px, 1.5vw, 1.5vw)", padding: "0px"}}>
        {icon}
        <Typography sx={{ fontSize: "clamp(20px, 1.5vw, 1.5vw)"}}>{title}</Typography>
        </DialogTitle>
        <DialogContent
        >
          <DialogContentText sx={{textAlign: "center",fontSize: "clamp(14px, 1vw, 1vw)" }} id="alert-dialog-slide-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{display: "flex", justifyContent: "center"}}>
          {cancelText && <Button variant="outlined" className='ottermap-button-outlined' onClick={cancel ? cancel : handleClose}>{cancelText}</Button>}
          {submitText && <Button variant="contained" className='ottermap-button' onClick={submit}>{submitText}</Button>}
        </DialogActions>
      </Dialog>
  );
}