import axios from 'axios';
import './OpenLayersIdPage.css';
import { unByKey } from 'ol/Observable';
import LayerGroup from 'ol/layer/Group';
import Tooltip from '@mui/material/Tooltip';
import AuthToken from '../../context/AuthToken';
import { Select, Modify } from 'ol/interaction';
import { useSearchParams } from 'react-router-dom';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import DeleteIcon from '@mui/icons-material/Delete';
import SplitIcon from '@mui/icons-material/GridOff';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { altShiftKeysOnly } from 'ol/events/condition';
import MoveIcon from '@mui/icons-material/DriveFileMove';
import { useLoader } from '../../context/LoaderProvider';
import { useScreenType } from '../../utils/commonMethods';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { STATUS, TOAST_TYPE } from '../../utils/constants';
import { useUserContext } from '../../context/UserContext';
import NavigationIcon from '@mui/icons-material/Navigation';
import { useLayerContext } from '../../context/LayerContext';
import MapHeader from '../../components/MapHeader/MapHeader';
import rotateMap from '../../OpenLayers/OLControls/RotateMap';
import MapLeftBar from '../../components/MapLeftBar/MapLeftBar';
import setNearmap from '../../OpenLayers/OLFeatures/SetNearmap';
import selectedStyle from '../../OpenLayers/OLStyles/EditStyle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoveFeature from '../../OpenLayers/OLFeatures/MoveFeature';
import ExportImage from '../../OpenLayers/OLFeatures/ExportImage';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { useUndoRedoContext } from '../../context/UndoRedoContext';
import getLayerData from '../../OpenLayers/OLFeatures/GetLayerData';
import renderParcel from '../../OpenLayers/OLFeatures/RenderParcel';
import handleZoomIn from '../../OpenLayers/OLControls/ZoomInControl';
import { Fill, Stroke, Style, Circle as CircleStyle } from 'ol/style';
import FeedbackForm from '../../components/FeedbackForm/FeedbackForm';
import initializeMap from '../../OpenLayers/OLFeatures/InitializeMap';
import getLayersData from '../../OpenLayers/OLFeatures/GetLayersData';
import hoverOnParcel from '../../OpenLayers/OLFeatures/HoverOnParcel';
import calculateArea from '../../OpenLayers/OLFeatures/CalculateArea';
import MenuOpenRightIcon from '@mui/icons-material/KeyboardArrowRight';
import handleZoomOut from '../../OpenLayers/OLControls/ZoomOutControl';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import cutLayer from '../../OpenLayers/DIYInteractions/CutInteraction';
import LineAxisOutlinedIcon from '@mui/icons-material/LineAxisOutlined';
import OrderNoteForm from '../../components/OrderNoteForm/OrderNoteForm';
import editLayer from '../../OpenLayers/DIYInteractions/EditInteraction';
import drawLayer from '../../OpenLayers/DIYInteractions/DrawInteraction';
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import getNewLayerData from '../../OpenLayers/OLFeatures/GetNewLayerData';
import getFeedbackData from '../../OpenLayers/OLFeatures/GetFeedbackData';
import getUsersDetails from '../../OpenLayers/OLFeatures/GetUsersDetails';
import getNearmapImage from '../../OpenLayers/OLFeatures/GetNearmapImage';
import hoverEffect from '../../OpenLayers/OLInteractions/HoverInteraction';
import resetRotation from '../../OpenLayers/OLControls/MapRotationControl';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import handleLayerTypeMap from '../../OpenLayers/OLControls/MapViewControl';
import renderOrderLayer from '../../OpenLayers/OLFeatures/RenderOrderLayer';
import initializeMarker from '../../OpenLayers/OLFeatures/InitializeMarker';
import OrderNoteModal from '../../components/OrderNoteModal/OrderNoteModal';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import getOrderNotesData from '../../OpenLayers/OLFeatures/GetOrderNotesData';
import renderAreaFeature from '../../OpenLayers/OLFeatures/RenderAreaFeature';
import showFeedbackLayer from '../../OpenLayers/OLFeatures/ShowFeedbackLayer';
import ImageryDropdown from '../../components/ImageryDropdown/ImageryDropdown';
import setFeedbackOverlay from '../../OpenLayers/OLFeatures/SetFeedbackOverlay';
import latLongToCoordinates from '../../OpenLayers/OLFeatures/LatlatLongToCoord';
import ClickInteraction from '../../OpenLayers/OLInteractions/ClickInteraction';
import removeAllInteraction from '../../OpenLayers/OLFeatures/RemoveInteraction';
import SplitInteraction from '../../OpenLayers/DIYInteractions/SplitInteraction';
import showOrderNotesLayer from '../../OpenLayers/OLFeatures/ShowOrderNotesLayer';
import AddingFeedbackOnMap from '../../OpenLayers/OLFeatures/AddingFeedbackOnMap';
import setOrderNoteOverlay from '../../OpenLayers/OLFeatures/SetOrderNoteOverlay';
import hoverOnOrderFeature from '../../OpenLayers/OLFeatures/HoverOnOrderFeature';
import AddingOrderNoteOnMap from '../../OpenLayers/OLFeatures/AddingOrderNoteOnMap';
import MarkOrderAsPreviewed from '../../OpenLayers/OLFeatures/MarkOrderAsPreviewed';
import handleOrderVisiblity from '../../OpenLayers/OLFeatures/HandleOrderVisibility';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import redirectionToFeedback from '../../OpenLayers/OLFeatures/RedirectionToFeedback';
import handleLayerTypeSatellite from '../../OpenLayers/OLControls/SatelliteViewControl';
import redirectionToOrderNote from '../../OpenLayers/OLFeatures/RedirectionToOrderNote';
import { MobileOrderNoteForm } from '../../components/OrderNoteForm/MobileOrderNoteForm';
import { ORDER_URL, REMOVE_HOLES_URL, FILE_UPLOAD, STUDIO_URL } from '../../helper/ApiUrl';
import LayersTypeContainer from '../../components/LayersTypeContainer/LayersTypeContainer';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import ToolBarToday from '../../components/ToolBar/ToolBarToday';
import TB_logo from './../../assets/TB_logo.png';

const OpenLayersIdPage = ({
  handleError,
  handleSuccess,
  handleCustomError,
  handleCustomSuccess,
  
}) => {
  const token = AuthToken();
  const orderRef = useRef();
  const parcelRef = useRef();
  const markerRef = useRef();
  const { id } = useParams();
  const notesModal = useRef();
  const notesButton = useRef();
  const mapElement = useRef();
  const toolbarRef = useRef();
  const contentRef = useRef();
  const tileGridLyr = useRef();
  const viewPanelRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const measurementRef = useRef();
  const [map, setMap] = useState();
  const nearmapImageRef = useRef();
  const tempOrderNoteRef = useRef();
  const isMobile = useScreenType();
  const completedGridLyr = useRef();
  const feedbackMarkerRef = useRef();
  const outerOrderNoteRef = useRef();
  const search = useLocation().search;
  const [step, setStep] = useState(2);
  const orderNotesMarkerRef = useRef();
  const pngWithMeasureRefs = useRef([]);
  const { userInfo } = useUserContext();
  const tempFeedbackMarkerRef = useRef();
  const [viewId, setViewId] = useState(null);
  const [mapKey, setMapKey] = useState();
  const [status, setStatus] = useState(null);
  const [popup, showPopup] = useState(false);
  const [address, setAddress] = useState(null);
  const [imageDate, setImageDate] = useState();
  const [center, setCenter] = useState([0, 0]);
  const [hoverElem, setHoverElem] = useState();
  const [orderNote, setOrderNote] = useState('');
  const { startLoader, stopLoader } = useLoader();
  const [bulkOrderId, setBulkOrderId] = useState();
  const [newLayerId, setNewLayerId] = useState([]);
  const [hoverMapKey, setHoverMapKey] = useState();
  const [layersData, setLayersData] = useState([]);
  const [layersCount, setLayersCount] = useState();
  const [zoomLayer, setZoomLayer] = useState(true);
  const [isOpenBtn, setIsOpenBtn] = useState(true);
  const [isCloseBtn, setIsCloseBtn] = useState(true);
  const [isPreview, setIsPreview] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [placedTime, setPlacedTime] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [propertyCost, setPropertyCost] = useState();
  const [polygonList, setPolygonList] = useState([]);
  const [orderNoteKey, setOrderNoteKey] = useState();
  const [parcelEdit, setParcelEdit] = useState(false);
  const [viewDelete, setViewDelete] = useState(false);
  const [deleteNote, setDeleteNote] = useState(false);
  const [areaFeatures, setAreaFeatures] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [orderActive, setOrderActive] = useState(true);
  const [usersDetails, setUsersDetails] = useState([]);
  const [previewStatus, setPreviewStatus] = useState();
  const [hoverParcelId, setHoverParcelId] = useState();
  const [uploadedLayer, setUploadedLayer] = useState([]);
  const [feedbackLayer, setFeedbackLayer] = useState([]);
  const [nearmapcoords, setNearmapcoords] = useState([]);
  const [studioStatus, setStudioStatus] = useState(null);
  const [featureMenuElem, setFeatureMenuElem] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const view_id = searchParams.get('view_id');
  const [direction, setDirection] = useState();
  const [feedbackAdded, setFeedbackAdded] = useState(false);
  const [feedbackMarker, setFeedbackMarker] = useState(null);
  const [orderNoteData, setOrderNoteData] = useState(null);
  const [mapClickable, toggleMapClickable] = useState(false);
  const feedbackId = new URLSearchParams(search).get('fdbkid');
  const orderNoteId = new URLSearchParams(search).get('ordntid');
  const [userAdded, setUserAdded] = useState(false);
  const [cutLayersName, setCutLayersName] = useState([]);
  const [featureSelected, setFeatureSelected] = useState([]);
  const [moveFeatureData, setMoveFeatureData] = useState([]);
  const [newViewCreated, setNewViewCreated] = useState(false);
  const prevfdbk = new URLSearchParams(search).get('prevfdbk');
  const [previewFeedback, setPreviewFeedback] = useState(false);
  const [navigateAddress, setNavigateAddress] = useState(null);
  const [nearmapImageDate, setNearmapImageDate] = useState('');
  const [multiClickKey, setMultiClickKey] = useState();
  const [singleClickKey, setSingleClickKey] = useState();
  const [orderNumber, setOrderNumber] = useState();
  const [isPreviewApproved, setIsPreviewApproved] = useState();
  const [multipleSelectKey, setMultipleSelectKey] = useState();
  const [nearmapSurveyData, setNearmapSurveyData] = useState([]);
  const [showNearmapImage, toggleNearmapImage] = useState(false);
  const [hoverParcelElement, setHoverParcelElement] = useState();
  const [feedbackEditable, setFeedbackEditable] = useState(false);
  const [showMoveFeatureList, setShowMoveFeatureList] = useState();
  const [hoverOrderFeatureId, setHoverOrderFeatureId] = useState();
  const [contextInteraction, isContextInteraction] = useState();
  const [feedbackModalShow, setFeedbackModalShow] = useState(false);
  const [orderNotesModalShow, setOrderNotesModalShow] = useState(false);
  const [orderNotesLayer, setOrderNotesLayer] = useState([]);
  const [selectedHoverFeature, setSelectedHoverFeature] = useState();
  const [outerViewName, setOuterViewName] = useState('Default View');
  const [allFeedbackResolved, setAllFeedbackResolved] = useState(false);
  const [feedbackLayerVisible, setFeedbackLayerVisible] = useState(false);
  const [orderNoteMarkerVisible, toggleOrderNoteMarkerVisible] =
    useState(false);
  const [orderNoteLayerVisible, setOrderNoteLayerVisible] = useState(false);
  const [multipleMoveFeatureData, setMultipleMoveFeatureData] = useState([]);
  const [hoverOrderFeatureElement, setHoverOrderFeatureElement] = useState();
  const [feedbackMarkerVisible, toggleFeedbackMarkerVisible] = useState(false);
  const bulkStatus = localStorage.getItem('bulkStatus');
  const [bulkOrderStatus, setBulkOrderStatus] = useState(
    bulkStatus ? bulkStatus.split(',') : []
  );
  const [multipleContextInteraction, setMultipleContextInteraction] =
    useState(false);
  const [isStudioOrder, setIsStudioOrder] = useState(location.pathname[1] === "u" || false);

  const { setUndoStack, setRedoStack, currentPointerIdx, setCurrentPointerIdx } = useUndoRedoContext();
  const [resetLayer, setResetLayer] = useState([]);

  const handleReset = () => {
    removeAllInteraction(map);
    let stateUpdate = JSON.parse(JSON.stringify(resetLayer));
    setUploadedLayer(stateUpdate);
    setUndoStack([]);
    setRedoStack([]);
  };

  const {
    layerID,
    setOlMap,
    layerName,
    layerType,
    setLayerStyle,
    cutInteraction,
    editInteraction,
    splitInteraction,
    lineInteraction,
    pointInteraction,
    cutAllInteraction,
    setCutInteraction,
    polygonInteraction,
    setLineInteraction,
    setEditInteraction,
    setSplitInteraction,
    setPointInteraction,
    setCutAllInteraction,
    setPolygonInteraction,
    multipleSelectInteraction,
    setMultipleSelectInteraction,
  } = useLayerContext();

  parcelRef.current = new LayerGroup({
    title: 'Parcel',
    layers: [],
  });

  orderRef.current = new LayerGroup({
    title: 'Order',
    layers: [],
  });

  markerRef.current = new LayerGroup({
    title: 'Marker',
    layers: [],
  });

  nearmapImageRef.current = new LayerGroup({
    title: 'NearmapImage',
    layers: [],
  });

  feedbackMarkerRef.current = new LayerGroup({
    title: 'FeedbackMarker',
    layers: [],
  });

  tempFeedbackMarkerRef.current = new LayerGroup({
    title: 'TempFeedbackMarker',
    layers: [],
  });

  tileGridLyr.current = new LayerGroup({
    title: 'Tile Imagery',
    layers: [],
  });

  completedGridLyr.current = new LayerGroup({
    title: 'Completed Grid Layer',
    layers: [],
  });

  orderNotesMarkerRef.current = new LayerGroup({
    title: 'OrderNotesMarker',
    layers: [],
  });

  tempOrderNoteRef.current = new LayerGroup({
    title: 'TempOrderNote',
    layers: [],
  });

  measurementRef.current = new LayerGroup({
    title: 'Measurement',
    layers: [],
  });

  const [hoverData, setHoverData] = useState({
    id: null,
    name: null,
    measurement: null,
    measurement_type: null,
  });

  const [parcelStyle, setParcelStyle] = useState([
    new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgb(255, 255, 255,0.2)',
      }),
    }),
  ]);

  const select = new Select({
    wrapX: false,
    style: selectedStyle,
    condition: altShiftKeysOnly,
  });

  const modify = new Modify({
    features: select.getFeatures(),
  });

  const addEditInteraction = () => {
    document.getElementsByClassName('edit-parcel-button-active')[0].click();
  };

  useEffect(() => {
    if (prevfdbk) setPreviewFeedback(prevfdbk);
  }, [prevfdbk]);

// useEffect(()=>{   console.log(polygonList," polygonList useEffect OpenLayerIDPAGE MOFIFYEND")},[polygonList])
// // 4-06-2024 passign polygonList here
// const updateThePolygonList =(List)=>{ setPolygonList(List) }
//   const editPolygon = ( List ) => {
//     console.log(List," in OpenLayerIDPAGE MOFIFYEND polygonlist in edit parcel")
//     if(List && List.length>0)  {
//       // setPolygonList((List)=>List)
//       updateThePolygonList(List)
//     } 
  
//     setZoomLayer(false);
//     setParcelEdit(true);
//     removeAllInteraction(map);
//     map.addInteraction(select);
//     map.addInteraction(modify);
//     // document.body.classList.remove('cut-cursor');
//     // document.body.classList.remove('split-cursor');
//     // document.body.classList.remove('crosshair-cursor');
//     // document.body.classList.add('edit-cursor');

//   const mapContainer = document.querySelector('.ol-viewport');

//   mapContainer.classList.remove('crosshair-cursor');
//   mapContainer.classList.remove('split-cursor');
//   mapContainer.classList.remove('cut-cursor');
//   mapContainer.classList.add('edit-cursor');
//     // let mapContainer = document.getElementById('map-cont');
//     // mapContainer.classList.add('edit-cursor');

//     map.getLayers().forEach((layer) => {
//       if (layer.get('title') && layer.get('title') === 'Parcel') {
//         var childLayers = layer.getLayers().getArray();
//         var length = childLayers.length;
//         for (var i = 0; i < length; i++) {
//           var lay = childLayers[i];
//           lay.getSource().forEachFeature(function (feature) {
//             select.getFeatures().push(feature);
//           });
//         }
//       }
//     });
//     console.log(polygonList," Before MOFIFYEND inedit polygon list ")
//     modify.on('modifyend', function (e) {
//       const id = e.features.getArray()[0].getId();
//       var coords = e.features.getArray()[0].getGeometry().getCoordinates();

//       if (coords.length > 1) {
//         console.log("this if block run ")
//         const data = { parcel: coords };
//         axios({
//           url: `${REMOVE_HOLES_URL}`,
//           method: 'post',
//           timeout: 8000,
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           data: data,
//         })
//           .then((res) => {
//             let updatedPolygon = polygonList.map((item) => {
//               if (item.id === id) {
//                 return {
//                   ...item,
//                   data: res.data.data,
//                   measurement: calculateArea(coords),
//                 };
//               }
//               return item;
//             });
//             console.log(updatedPolygon , "updatedPolygon in editpolygon openlayer")
//             setPolygonList(updatedPolygon);
//             setParcelStyle(selectedStyle);
//             removeAllInteraction(map);
//             addEditInteraction();
//           })
//           .catch((err) => console.error(err));
//       }
//       console.log(polygonList," After MOFIFYEND inedit polygon list ")

//       let updatedPolygon = polygonList.map((item) => {
//         if (item.id === id) {
//           return {
//             ...item,
//             data: coords,
//             measurement: calculateArea(coords),
//           };
//         }
//         return item;
//       });
//       setPolygonList(updatedPolygon);
//       setParcelStyle(selectedStyle);
//       removeAllInteraction(map);
//       addEditInteraction();
//     });
//   };

//////////// --------- All the logic that depends on the updated polygonList is moved inside the setPolygonList functional update form ---/
const updateThePolygonList = (List) => {
  setPolygonList(List);
};

const editPolygon = (List) => {
  console.log(List, " in OpenLayerIDPAGE MOFIFYEND polygonlist in edit parcel");

  if (List && List.length > 0) {
    updateThePolygonList(List);
  }

  setZoomLayer(false);
  setParcelEdit(true);
  removeAllInteraction(map);
  map.addInteraction(select);
  map.addInteraction(modify);

  const mapContainer = document.querySelector('.ol-viewport');
  mapContainer.classList.remove('crosshair-cursor', 'split-cursor', 'cut-cursor');
  mapContainer.classList.add('edit-cursor');

  map.getLayers().forEach((layer) => {
    if (layer.get('title') && layer.get('title') === 'Parcel') {
      var childLayers = layer.getLayers().getArray();
      var length = childLayers.length;
      for (var i = 0; i < length; i++) {
        var lay = childLayers[i];
        lay.getSource().forEachFeature((feature) => {
          select.getFeatures().push(feature);
        });
      }
    }
  });

  console.log(polygonList, " Before MOFIFYEND in edit polygon list ");

  modify.on('modifyend', function (e) {
    const id = e.features.getArray()[0].getId();
    var coords = e.features.getArray()[0].getGeometry().getCoordinates();

    if (coords.length > 1) {
      console.log("this if block run ");
      const data = { parcel: coords };

      axios({
        url: `${REMOVE_HOLES_URL}`,
        method: 'post',
        timeout: 8000,
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
      .then((res) => {
        setPolygonList((prevPolygonList) => {
          let updatedPolygon = prevPolygonList.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                data: res.data.data,
                measurement: calculateArea(coords),
              };
            }
            return item;
          });
          console.log(updatedPolygon, "updatedPolygon in editpolygon openlayer");
          return updatedPolygon;
        });
        setParcelStyle(selectedStyle);
        removeAllInteraction(map);
        addEditInteraction();
      })
      .catch((err) => console.error(err));
    } else {
      setPolygonList((prevPolygonList) => {
        let updatedPolygon = prevPolygonList.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              data: coords,
              measurement: calculateArea(coords),
            };
          }
          return item;
        });
        return updatedPolygon;
      });
      setParcelStyle(selectedStyle);
      removeAllInteraction(map);
      addEditInteraction();
    }

    console.log(polygonList, " After MOFIFYEND in edit polygon list ");
  });
};


///////////////

  useLayoutEffect(() => {
    startLoader();
  }, []);

  const getParcelData = () => {
    axios
      .get(`${ORDER_URL}${id}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status == 403) {
          stopLoader();
          navigate('/4O3');
        }
        const data = response.data.data;
        setStatus(data.status);
        setStudioStatus(data.studio_status);
        setPreviewStatus(data.preview_data);
        setPlacedTime(data.order_placed_time);
        setAddress(data.address);
        setOrderNumber(data.id);
        setImageDate(data.imagery_date);
        setNearmapImageDate(data.imagery_date);
        setIsPreviewApproved(data.is_approved);
        setPropertyCost(data.property_cost);
        if (data.attachments) {
          setAttachments(data.attachments);
        }
        setNavigateAddress({
          prev_data: data.prev_data,
          next_data: data.next_data,
        });
        if (data.status !== STATUS.MEASUREMENT_COMPLETED) stopLoader();
        if (data.status === STATUS.MEASUREMENT_COMPLETED) getViewsData();
        setOrderNote(data.order_note);
        if (!data.parcel_confirm) {
          setStep(2);
        } else {
          setStep(3);
        }
        setCenter([data.long, data.lat]);
        setBulkOrderId(data.bulk_order_id);
        setCoordinates(data.parcel_data.geometry.coordinates);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getViewsData = () => {
    axios
      .get(`${ORDER_URL}${id}/view`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        const viewData = response.data.data;
        const findView = viewData.find((view) => view.id == view_id);
        if (view_id && findView) {
          const view = viewData.filter((view) => view.id == view_id);
          setViewId(view[0].id);
          setOuterViewName(view[0].name);
        } else {
          setViewId(response.data.data[0].id);
          setOuterViewName(response.data.data[0].name);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  
  useEffect(()=>{
    console.log(`  nearmapSurveyData OrderPage------ ${ nearmapSurveyData}`)
  },[nearmapSurveyData])

  useEffect(() => {
    if (outerViewName !== 'Default View') {
      getViewsData();
    }
  }, [viewDelete]);

  useEffect(() => {
    newLayerId.map((layerId) => {
      getNewLayerData(
        id,
        viewId,
        layerId.id,
        stopLoader,
        setDownloadData,
        setUploadedLayer
      );
    });
  }, [newLayerId]);

  useEffect(() => {
    setLayersCount(layersData.length);
    layersData.forEach((layer) => {
      const layerId = layer.id;
      getLayerData(
        id,
        viewId,
        layerId,
        stopLoader,
        setResetLayer,
        setDownloadData,
        setUploadedLayer
      );
    });
  }, [layersData]);

  useEffect(() => {
    if (viewId)
      getLayersData(
        id,
        viewId,
        searchParams,
        setResetLayer,
        setLayersData,
        setSearchParams,
        setUploadedLayer
      );
  }, [viewId]);

  useEffect(() => {
    if (bulkOrderId && bulkOrderStatus && bulkOrderStatus.length)
      searchParams.set('status', bulkOrderStatus);
  }, [bulkOrderId]);

  useEffect(() => {
    if (status === STATUS.DRAFT || status === STATUS.IN_PROGRESS) {
      searchParams.delete('view_id');
      setSearchParams(searchParams);
    }
  }, [status, view_id]);

  useEffect(() => {
    initializeMap(mapElement, setMap);
    getViewsData();
    getParcelData();
    getUsersDetails(token, setUsersDetails);
  }, []);

  useEffect(() => {
    const elem = document.getElementById('popup-cont');
    setHoverElem(elem);
    initializeMarker(
      map,
      center,
      hex2rgba,
      hoverMapKey,
      setHoverData,
      showPopup,
      markerRef,
      setHoverMapKey,
      setSelectedHoverFeature,
      elem
    );
  }, [center]);

  useEffect(() => {
    latLongToCoordinates(coordinates, setPolygonList);
  }, [coordinates]);

  useEffect(() => {
    if (map)
      map.getLayers().forEach(function (layer) {
        if (layer && layer.get('title') == 'NearmapImage') {
          if (showNearmapImage) layer.setVisible(true);
          else layer.setVisible(false);
        }
      });
  }, [showNearmapImage]);

  useEffect(() => {
    if (location.pathname[1] === 'p') {
      setIsPreview(true);
      if (previewStatus && status === STATUS.IN_PROGRESS) {
        setStatus(STATUS.MEASUREMENT_COMPLETED);
        getViewsData();
      }
    }
  }, [previewStatus]);

  useEffect(() => {
    if (!orderNoteId || orderNoteId === 'undefined') {
      if (location.pathname[1] === 'p')
        navigate(`/preview/order/${id}?view_id=${viewId}`);
      else if (location.pathname[1] === 'u')
        navigate(`/upload/studio/${id}`);
      else navigate(`/order/${id}?view_id=${viewId}`);
    } else setOrderNoteLayerVisible(true);
  }, [orderNoteId]);

  useEffect(() => {
    if (!orderNotesModalShow) setIsOpenBtn(true);
  }, [orderNotesModalShow]);

  useEffect(() => {
    if (feedbackId || orderNoteId) unByKey(hoverMapKey);
  }, [hoverMapKey]);

  useEffect(() => {
    if (feedbackId && feedbackId !== 'undefined') {
      setFeedbackLayerVisible(true);
    }
  }, [feedbackId, previewFeedback]);

  useEffect(() => {
    if (orderNoteId) {
      orderNotesLayer.map((item, index) => {
        if (item.id === parseInt(orderNoteId)) {
          setOrderNoteData(item);
        } else {
          if (location.pathname[1] === 'p')
            navigate(
              `/preview/order/${id}?view_id=${viewId}&ordntid=${orderNoteId}`
            );
          else
            navigate(`/order/${id}?view_id=${viewId}&ordntid=${orderNoteId}`);
        }
      });
    }
  }, [orderNotesLayer]);

  useEffect(() => {
    if (feedbackId) {
      feedbackLayer.map((item) => {
        if (item.id === parseInt(feedbackId)) {
          setFeedbackMarker(item);
        } else {
          if (location.pathname[1] === 'p')
            navigate(
              `/preview/order/${id}?view_id=${viewId}&fdbkid=${feedbackId}`
            );
          else navigate(`/order/${id}?view_id=${viewId}&fdbkid=${feedbackId}`);
        }
      });
    }
  }, [feedbackLayer]);

  useEffect(() => {
    if (feedbackAdded || allFeedbackResolved)
      if (feedbackModalShow) setFeedbackLayerVisible(true);
      else setFeedbackLayerVisible(false);
    getFeedbackData(id, token, setFeedbackLayer);
  }, [feedbackLayerVisible]);

  useEffect(() => {
    if (orderNotesModalShow) setOrderNoteLayerVisible(true);
    getOrderNotesData(id, token, setOrderNotesLayer);
  }, [orderNoteLayerVisible, userAdded]);

  useEffect(() => {
    if (feedbackLayer && feedbackLayer.length)
      showFeedbackLayer(
        map,
        viewId,
        feedbackLayerVisible,
        location,
        id,
        navigate,
        feedbackLayer,
        setFeedbackMarker,
        feedbackMarkerRef,
        toggleFeedbackMarkerVisible,
        previewFeedback,
        feedbackId
      );
    if (map)
      map.on('click', function (e) {
        map.getOverlays().forEach(function (overlay) {
          overlay.setPosition(undefined);
        });
        var feature = map.forEachFeatureAtPixel(e.pixel, function (feature) {
          return feature;
        });
        if (feature)
          feedbackLayer.map((feedback, index) => {
            if (feedback.id === feature.get('id')) setFeedbackMarker(feedback);
          });
        else if (previewFeedback === 'false')
          toggleFeedbackMarkerVisible(false);
        else toggleOrderNoteMarkerVisible(false);
      });
  }, [feedbackLayerVisible, feedbackAdded, feedbackLayer]);

  useEffect(() => {
    if (orderNotesLayer && orderNotesLayer.length)
      showOrderNotesLayer(
        map,
        viewId,
        orderNoteLayerVisible,
        location,
        navigate,
        id,
        orderNotesLayer,
        setOrderNoteData,
        orderNotesMarkerRef,
        toggleOrderNoteMarkerVisible,
        orderNoteId
      );
    if (map)
      map.on('click', function (e) {
        map.getOverlays().forEach(function (overlay) {
          overlay.setPosition(undefined);
        });
        var feature = map.forEachFeatureAtPixel(e.pixel, function (feature) {
          return feature;
        });
        if (feature)
          orderNotesLayer.map((order, index) => {
            if (order.id === feature.get('id')) setOrderNoteData(order);
          });
        else toggleOrderNoteMarkerVisible(false);
      });
  }, [orderNotesLayer, orderNoteLayerVisible]);

  //user click on add feedback to add marker on map
  useEffect(() => {
    var element = document.getElementById('map-cont');
    if (feedbackLayerVisible)
      AddingFeedbackOnMap(
        mapClickable,
        element,
        map,
        setMapKey,
        setFeedbackMarker,
        tempFeedbackMarkerRef,
        mapKey
      );
    else if (orderNoteLayerVisible)
      AddingOrderNoteOnMap(
        mapClickable,
        element,
        map,
        setMapKey,
        tempOrderNoteRef,
        setOrderNoteData,
        mapKey,
        userInfo
      );
    if (!mapClickable) {
      if (map)
        map.getLayers().forEach((layer) => {
          if (
            layer &&
            layer.get('title') &&
            (layer.get('title') === 'TempOrderNote' ||
              layer.get('title') === 'TempFeedbackMarker')
          ) {
            map.removeLayer(layer);
          }
        });
      element.classList.remove('cursor-change');
      element.classList.remove('cursor-change-order-note');
      unByKey(mapKey);
    }
  }, [mapClickable]);

  useEffect(() => {
    if (!feedbackModalShow && map) {
      unByKey(hoverMapKey);
      hoverEffect(map, hex2rgba, hoverMapKey, setHoverMapKey, hoverElem);
    }
    redirectionToFeedback(
      map,
      viewId,
      feedbackMarkerVisible,
      navigate,
      location,
      id,
      setFeedbackMarker
    );
  }, [feedbackMarkerVisible]);

  useEffect(() => {
    if (!orderNotesModalShow && map) {
      unByKey(hoverMapKey);
      hoverEffect(map, hex2rgba, hoverMapKey, setHoverMapKey, hoverElem);
    }
    redirectionToOrderNote(
      map,
      viewId,
      location,
      navigate,
      id,
      orderNoteMarkerVisible,
      setOrderNoteData
    );
  }, [orderNoteMarkerVisible]);

  //whenever feedbackMarker has value overlay is set
  useEffect(() => {
    const container = document.getElementById('feedbackBackPopUp');
    setFeedbackOverlay(
      map,
      viewId,
      toggleFeedbackMarkerVisible,
      feedbackMarker,
      navigate,
      container,
      feedbackLayerVisible,
      previewFeedback
    );
  }, [feedbackMarker]);

  useEffect(() => {
    if (!isMobile) {
      const container = document.getElementById('orderNotePopUp');
      setOrderNoteOverlay(
        map,
        orderNoteData,
        viewId,
        container,
        navigate,
        isMobile,
        orderNoteLayerVisible,
        toggleOrderNoteMarkerVisible
      );
    }
    if (isMobile) setOrderNotesModalShow(false);
  }, [orderNoteData]);

  useEffect(() => {
    if (map) {
      renderParcel(
        map,
        status,
        zoomLayer,
        parcelRef,
        polygonList,
        parcelStyle,
        setNearmapcoords
      );
    }
  }, [polygonList, parcelStyle]);

  useEffect(() => {
    if (map) {
      renderAreaFeature(map, areaFeatures, measurementRef);
    }
  }, [areaFeatures]);

  useEffect(() => {
    if (map) {
      setOlMap(map);
      if (status === STATUS.DIY || status === STATUS.MEASUREMENT_COMPLETED || status === STATUS.IN_PROGRESS) {
        renderOrderLayer(map, hex2rgba, orderRef, uploadedLayer);
      }
      stopLoader();
    }
  }, [uploadedLayer, layersData]);


  const hex2rgba = (hex, alpha) => {
    if (hex) {
      const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`;
    }
  };

  useEffect(() => {
    handleOrderVisiblity(map, uploadedLayer, orderActive, setUploadedLayer);
  }, [orderActive]);

  const handleUpload = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      axios
        .post(FILE_UPLOAD, formData, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (response.data.url) {
            ExportImage(
              response.data.url,
              id,
              handleSuccess,
              handleError,
              token
            );
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      handleSuccess(
        TOAST_TYPE.WARNING,
        'Please choose download PNG to upload!'
      );
    }
  };

  // const downloadPng = () => {
  //   if (map) {
  //     map.once('rendercomplete', function () {
  //       const mapCanvas = document.createElement('canvas');
  //       const size = map.getSize();
  //       mapCanvas.width = size[0];
  //       mapCanvas.height = size[1];
  //       const mapContext = mapCanvas.getContext('2d');
  
  //       Array.prototype.forEach.call(
  //         document.querySelectorAll('.ol-layer canvas'),
  //         function (canvas) {
  //           if (canvas.width > 0) {
  //             const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
  //             mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
  //             let matrix;
  //             const transform = canvas.style.transform;
  //             matrix = transform.match(/^matrix\(([^\(]*)\)$/)[1].split(',').map(Number);
  //             CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
  //             mapContext.drawImage(canvas, 0, 0);
  //           }
  //         }
  //       );
  
  //       mapContext.globalAlpha = 1;
  //       mapContext.setTransform(1, 0, 0, 1, 0, 0);
  
       
  //       const logo = new Image();
  //       logo.src = `${process.env.PUBLIC_URL}/images/logo.png`; 
  //       logo.onload = function () {
  //         const padding = 10;
  //         const logoWidth = 150;
  //         const logoHeight = 50;
  //         const x = 10;
  //         const y = 10;
  //         const backgroundColor = '#ecffe0';
          
  //         // Draw the background for the logo
  //         mapContext.fillStyle = backgroundColor;
  //         mapContext.fillRect(x, y, logoWidth + 2 * padding, logoHeight + 2 * padding);
  
  //         // Draw the logo image with padding
  //         mapContext.drawImage(logo, x + padding, y + padding, logoWidth, logoHeight);
  
  //         // Proceed with downloading the image
  //         var link = document.createElement('a');
  //         var base64ImageData = mapCanvas.toDataURL('image/png');
  //         var binaryImageData = atob(base64ImageData.split(',')[1]);
  //         var uint8Array = new Uint8Array(binaryImageData.length);
  //         for (var i = 0; i < binaryImageData.length; i++) {
  //           uint8Array[i] = binaryImageData.charCodeAt(i);
  //         }
  //         var blob = new Blob([uint8Array], { type: 'image/png' });
  //         var file = new File([blob], 'image.png', { type: 'image/png' });
  //         link.href = mapCanvas.toDataURL('image/png');
  //         link.download = address;  
  //         link.click();
  //         handleUpload(file); 
  //       };
  //     });
  //     map.renderSync();
  //   }
  // };
  

  const downloadPng = () => {
    if (map) {
      map.once('rendercomplete', function () {
        const mapCanvas = document.createElement('canvas');
        const size = map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        const mapContext = mapCanvas.getContext('2d');
        Array.prototype.forEach.call(
          document.querySelectorAll('.ol-layer canvas'),
          function (canvas) {
            if (canvas.width > 0) {
              const opacity =
                canvas.parentNode.style.opacity || canvas.style.opacity;
              mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
              let matrix;
              const transform = canvas.style.transform;
              matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(',')
                .map(Number);
              CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix
              );
              mapContext.drawImage(canvas, 0, 0);
            }
          }
        );
        
        mapContext.globalAlpha = 1;
        mapContext.setTransform(1, 0, 0, 1, 0, 0);
        var link = document.createElement('a');
        var base64ImageData = mapCanvas.toDataURL('image/png');
        var binaryImageData = atob(base64ImageData.split(',')[1]);
        var uint8Array = new Uint8Array(binaryImageData.length);
        for (var i = 0; i < binaryImageData.length; i++) {
          uint8Array[i] = binaryImageData.charCodeAt(i);
        }
        var blob = new Blob([uint8Array], { type: 'image/png' });
        var file = new File([blob], 'image.png', { type: 'image/png' });
        link.href = mapCanvas.toDataURL('image/png');
        link.download = address;
        link.click();
        handleUpload(file);
      });
      map.renderSync();
    }
  };

  useEffect(() => {
    if (hoverParcelElement && hoverParcelId) {
      hoverOnParcel(map, hoverParcelElement, hoverParcelId, parcelEdit);
    }
  }, [hoverParcelId, hoverParcelElement]);

  useEffect(() => {
    if (hoverOrderFeatureElement && Number(hoverOrderFeatureId[0])) {
      hoverOnOrderFeature(
        map,
        hex2rgba,
        hoverOrderFeatureElement,
        hoverOrderFeatureId,
        setHoverData,
        showPopup,
        editInteraction
      );
    }
  }, [hoverOrderFeatureId]);

  useEffect(() => {
    if (!nearmapImageDate) {
      if (status === STATUS.DRAFT || status === STATUS.DIY) {
        if (nearmapcoords.length && nearmapcoords[0] != Infinity) {
          getNearmapImage(id, token, nearmapcoords, setNearmapSurveyData);
        }
      }
    }
  }, [nearmapcoords]);

  useEffect(() => {
    if (nearmapImageDate === 'satellite') {
      map.getLayers().forEach((layer) => {
        if (layer && layer.get('title') === 'Tile Imagery') {
          map.removeLayer(layer);
        }
      });
    } else if (status === STATUS.DRAFT ) {
      if (nearmapcoords.length)
        nearmapcoords.map((item) => {
          setNearmap(map, nearmapImageDate, item, tileGridLyr);
        });
    }
    // else if ( status === STATUS.MEASUREMENT_COMPLETED){
    //   axios
    //   .get(`${ORDER_URL}${id}/parcel-break`, {
    //       headers: {
    //           Authorization: `Token ${token}`,
    //           Accept: "application/json",
    //       },
    //   })
    //   .then((response) => {
    //       // setNearmapLoader(true);
    //       if (response.data.all_polygons.length) {
    //           if(response.data.all_polygons.length>19){ // 19 due to rate limiting of api
    //               map.addLayer(completedGridLyr.current);
    //               // setNearMapActive(true);
    //               // setNearmapDisabled(false);
             
    //               if (nearmapcoords.length)
    //                 nearmapcoords.map((item) => {
    //                   setNearmap(map, nearmapImageDate, item, tileGridLyr);
    //                 });
    //           }
           
    //       }
    //       else {
    //           // setNearmapLoader(false)
    //           // setNearmapDisabled(true);
    //       }
    //   })
    //   .catch((error) => {
    //       console.error(error.message);
    //   });
    
    // }
  }, [nearmapcoords, nearmapImageDate]);

  const deleteLayerFeature = (featureId, layerId) => {
    const featureID = featureId[0].get('id');
    axios
      .delete(
        `${ORDER_URL}${id}/view/${viewId}/layer/${layerId}/layer-data/${featureID}`,
        {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        }
      )
      .then((response) => {
        map.removeOverlay(map.getOverlayById(3));
        uploadedLayer.map((layer) => {
          layer.data = layer.data.filter(
            (feature) => feature.id != `${featureID}`
          );
        });
        setUploadedLayer([...uploadedLayer]);
        let stateUpdate = JSON.parse(JSON.stringify(uploadedLayer));
        setResetLayer(stateUpdate);
        handleSuccess(TOAST_TYPE.SUCCESS, 'Feature Deleted Successfully!');
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const OpenMoveFeatureBar = () => {
    let x = true;
    if (moveFeatureData.length)
      uploadedLayer.map((item, index) => {
        if (
          item.id !== parseInt(moveFeatureData[1]) &&
          item.type === moveFeatureData[2]
        )
          x = false;
      });
    else if (multipleMoveFeatureData.length)
      uploadedLayer.map((item, index) => {
        if (
          item.id !== multipleMoveFeatureData[1] &&
          item.type === multipleMoveFeatureData[2]
        )
          x = false;
      });
    if (x) setShowMoveFeatureList(false);
    else setShowMoveFeatureList(true);
  };

  useEffect(() => {
    if (showMoveFeatureList) setShowMoveFeatureList(false);
  }, [moveFeatureData, multipleMoveFeatureData]);

  useEffect(() => {
    if (!multipleSelectInteraction && multipleMoveFeatureData.length) {
      let col = multipleMoveFeatureData[0][0].get('color');
      multipleMoveFeatureData[0].map((item) => {
        item.setStyle(
          new Style({
            stroke: new Stroke({
              color: hex2rgba(col, 1).toString(),
              width: 3,
            }),
            fill: new Fill({
              color: hex2rgba(col, 0.2).toString(),
            }),
            image: new CircleStyle({
              radius: 3,
              fill: new Fill({
                color: col,
              }),
            }),
          })
        );
      });
    }
  }, [multipleSelectInteraction]);

  useEffect(() => {
    if (map) {
      if (outerViewName === 'Default View' && !isStudioOrder) {
        map.removeOverlay(map.getOverlayById(3));
        isContextInteraction(false);
      } else isContextInteraction(true); 
    }
  }, [map, outerViewName]);

  useEffect(() => {
    const elem = document.getElementById('feature-menu-cont');
    setFeatureMenuElem(elem);
  }, []);


  const mapLeftBar = (
    <MapLeftBar
      map={map}
      step={step}
      path={polygonList}
      elem={hoverElem}
      status={status}
      viewId={viewId}
      orderNumber={orderNumber}
      setStep={setStep}
      bulkOrderId={bulkOrderId}
      setPath={setPolygonList}
      address={address}
      hex2rgba={hex2rgba}
      orderNote={orderNote}
      imageDate={imageDate}
      isPreview={isPreview}
      contentRef={contentRef}
      parcelRef={parcelRef}
      setViewId={setViewId}
      showPopup={showPopup}
      setStatus={setStatus}
      viewDelete={viewDelete}
      notesModal={notesModal}
      notesButton={notesButton}
      toolbarRef={toolbarRef}
      editPolygon={editPolygon}
      placedTime={placedTime}
      setPlacedTime={setPlacedTime}
      downloadPng={downloadPng}
      attachments={attachments}
      layersCount={layersCount}
      orderActive={orderActive}
      mapClickable={mapClickable}
      hoverMapKey={hoverMapKey}
      handleError={handleError}
      setHoverData={setHoverData}
      setOrderNote={setOrderNote}
      setZoomLayer={setZoomLayer}
      propertyCost={propertyCost}
      downloadData={downloadData}
      viewPanelRef={viewPanelRef}
      studioStatus={studioStatus}
      orderNoteData={orderNoteData}
      setViewDelete={setViewDelete}
      setParcelEdit={setParcelEdit}
      setNewLayerId={setNewLayerId}
      feedbackLayer={feedbackLayer}
      uploadedLayer={uploadedLayer}
      outerViewName={outerViewName}
      handleSuccess={handleSuccess}
      isStudioOrder={isStudioOrder}
      setHoverMapKey={setHoverMapKey}
      setAttachments={setAttachments}
      setParcelStyle={setParcelStyle}
      setPolygonList={setPolygonList}
      setOrderActive={setOrderActive}
      setLayersCount={setLayersCount}
      newViewCreated={newViewCreated}
      setStudioStatus={setStudioStatus}
      featureSelected={featureSelected}
      navigateAddress={navigateAddress}
      setPropertyCost={setPropertyCost}
      orderNotesLayer={orderNotesLayer}
      setOrderNoteKey={setOrderNoteKey}
      nearmapImageDate={nearmapImageDate}
      completedGridLyr={completedGridLyr}
      setUploadedLayer={setUploadedLayer}
      nearmapcoords={nearmapcoords}
      setNearmapcoords={setNearmapcoords}
      setOuterViewName={setOuterViewName}
      setFeedbackAdded={setFeedbackAdded}
      setOrderNoteData={setOrderNoteData}
      setHoverParcelId={setHoverParcelId}
      setNewViewCreated={setNewViewCreated}
      feedbackModalShow={feedbackModalShow}
      setFeedbackMarker={setFeedbackMarker}
      toggleMapClickable={toggleMapClickable}
      toggleNearmapImage={toggleNearmapImage}
      setFeedbackEditable={setFeedbackEditable}
      orderNotesModalShow={orderNotesModalShow}
      setFeatureSelected={setFeatureSelected}
      handleCustomError={handleCustomError}
      handleCustomSuccess={handleCustomSuccess}
      setNearmapImageDate={setNearmapImageDate}
      setFeedbackModalShow={setFeedbackModalShow}
      isContextInteraction={isContextInteraction}
      setHoverParcelElement={setHoverParcelElement}
      setHoverOrderFeatureId={setHoverOrderFeatureId}
      setOrderNotesModalShow={setOrderNotesModalShow}
      setAllFeedbackResolved={setAllFeedbackResolved}
      setSelectedHoverFeature={setSelectedHoverFeature}
      setFeedbackLayerVisible={setFeedbackLayerVisible}
      setOrderNoteLayerVisible={setOrderNoteLayerVisible}
      setHoverOrderFeatureElement={setHoverOrderFeatureElement}
      toggleFeedbackMarkerVisible={toggleFeedbackMarkerVisible}
      setMultipleContextInteraction={setMultipleContextInteraction}
      toggleOrderNoteMarkerVisible={toggleOrderNoteMarkerVisible}
      tileGridLyr={tileGridLyr}
    />
  );

  useEffect(() => {
    if (multipleContextInteraction === false) {
      setMultipleMoveFeatureData([]);
      unByKey(multiClickKey);
      unByKey(multipleSelectKey);
    }

    if (
      map &&
      multipleContextInteraction === false &&
      contextInteraction
    ){
      ClickInteraction(
        map,
        featureMenuElem,
        setMoveFeatureData,
        contextInteraction,
        setSingleClickKey
      )
    }

      // try to figure out the move and delete
      // if (
      //   isStudioOrder &&
      //   outerViewName == "Default View"
        
      // ){ 
      //   ClickInteraction(
      //     map,
      //     featureMenuElem,
      //     setMoveFeatureData,
      //     contextInteraction,
      //     setSingleClickKey
      //   );
      // }
       

    else if (
      multipleContextInteraction === true ||
      contextInteraction === false
    )
      unByKey(singleClickKey);
  }, [multipleContextInteraction, contextInteraction, map, outerViewName]);

  const toggleSlideBtn = () => {
    setIsOpenBtn(!isOpenBtn);
    setIsCloseBtn(!isCloseBtn);
  };
  
  

  if (map) {
    const view = map.getView();
    view.on('propertychange', (event) => {
      if (event.key === 'rotation') {
        const rotation = view.getRotation();
        const rotationInDegrees = (rotation * 180) / Math.PI;
        setDirection(rotationInDegrees);
      }
    });
  }

  useEffect(() => {
    const elementsToHide = document.querySelectorAll('.hideOnScreenshot');
    // console.log(elementsToHide, "elem")
    pngWithMeasureRefs.current = Array.from(elementsToHide);
  }, [uploadedLayer]);

  console.log(uploadedLayer, "uploadedLayer")

  const closeDiyUndoRedoTool = () => {
    unByKey(currentPointerIdx);
    unByKey(hoverMapKey);
    // setLayerStyle(null); // commented out because this leads to no highlighting background for selected feature in the measurement panel
    setCutInteraction(false);
    setCutAllInteraction(false);
    setEditInteraction(false);
    setLineInteraction(false);
    setPointInteraction(false);
    setSplitInteraction(false);
    isContextInteraction(true);
    setMultipleContextInteraction(false);
    removeAllInteraction(map);
    setPolygonInteraction(false);
    setMultipleSelectInteraction(false);
    // toolbarRef.current.style.display = 'none';
    document.body.classList.remove('cut-cursor');
    document.body.classList.remove('edit-cursor');
    document.body.classList.remove('split-cursor');
    document.body.classList.remove('crosshair-cursor');
  };

  return (
    <div className="openlayers-id-page-map-container">
    
      {!isMobile && (
        <MapHeader
          id={id}
          map={map}
          viewId={viewId}
          status={status}
          address={address}
          hex2rgba={hex2rgba}
          isPreview={isPreview}
          hoverElem={hoverElem}
          notesModal={notesModal}
          toolbarRef={toolbarRef}
          contentRef={contentRef}
          bulkOrderId={bulkOrderId}
          layersCount={layersCount}
          handleReset={handleReset}
          orderNumber={orderNumber}
          downloadPng={downloadPng}
          hoverMapKey={hoverMapKey}
          notesButton={notesButton}
          handleError={handleError}
          areaFeatures={areaFeatures}
          setZoomLayer={setZoomLayer}
          viewPanelRef={viewPanelRef}
          mapClickable={mapClickable}
          downloadData={downloadData}
          isStudioOrder={isStudioOrder}
          setResetLayer={setResetLayer}
          outerViewName={outerViewName}
          uploadedLayer={uploadedLayer}
          handleSuccess={handleSuccess}
          cutLayersName={cutLayersName}
          feedbackLayer={feedbackLayer}
          measurementRef={measurementRef}
          setHoverMapKey={setHoverMapKey}
          bulkOrderStatus={bulkOrderStatus}
          setAreaFeatures={setAreaFeatures}
          navigateAddress={navigateAddress}
          setCutLayersName={setCutLayersName}
          setFeedbackAdded={setFeedbackAdded}
          setUploadedLayer={setUploadedLayer}
          setFeedbackMarker={setFeedbackMarker}
          feedbackModalShow={feedbackModalShow}
          setBulkOrderStatus={setBulkOrderStatus}
          toggleMapClickable={toggleMapClickable}
          handleCustomSuccess={handleCustomSuccess}
          setFeedbackEditable={setFeedbackEditable}
          setFeedbackModalShow={setFeedbackModalShow}
          isContextInteraction={isContextInteraction}
          setOrderNotesModalShow={setOrderNotesModalShow}
          setAllFeedbackResolved={setAllFeedbackResolved}
          setFeedbackLayerVisible={setFeedbackLayerVisible}
          setOrderNoteLayerVisible={setOrderNoteLayerVisible}
          pngWithMeasureRefs={pngWithMeasureRefs}
          toggleFeedbackMarkerVisible={toggleFeedbackMarkerVisible}
          toggleOrderNoteMarkerVisible={toggleOrderNoteMarkerVisible}
          setMultipleContextInteraction={setMultipleContextInteraction}
        />
      )}
      <div className="ol-order-id-page"  ref={contentRef} >
        <div className="client-logo-div">
          <img className='client-logo-image'  src={TB_logo} alt="client logo" />
        </div>
        {!isMobile && !isStudioOrder &&
        (status === STATUS.DRAFT || status === STATUS.IN_PROGRESS) ? (
          <ProgressBar
            step={step}
            status={status}
            setStep={setStep}
            featureSelected={featureSelected}
          />
        ) : null}
        {isPreview ? (
          previewStatus ? (
            mapLeftBar
          ) : (
            <div>No preview data found</div>
          )
        ) : (
          mapLeftBar
        )}


        <div ref={mapElement} id="map-cont" className="main-ol-container">
          {/* {outerViewName != 'Default View' ? (
            <div className="badge">
              <i className="left"></i>
              <i className="right"></i>
              BETA
            </div>
          ) : null} */}
          {feedbackMarker ? (
            <div>
              <div id="feedbackBackPopUp" className="ol-popup">
                <FeedbackForm
                  handleError={handleError}
                  feedbackAdded={feedbackAdded}
                  feedbackLayer={feedbackLayer}
                  handleSuccess={handleSuccess}
                  feedbackMarker={feedbackMarker}
                  setFeedbackAdded={setFeedbackAdded}
                  setFeedbackLayer={setFeedbackLayer}
                  feedbackEditable={feedbackEditable}
                  toggleMapClickable={toggleMapClickable}
                  setFeedbackEditable={setFeedbackEditable}
                  setFeedbackLayerVisible={setFeedbackLayerVisible}
                  toggleFeedbackMarkerVisible={toggleFeedbackMarkerVisible}
                />
              </div>
            </div>
          ) : null}
          {orderNoteData ? (
            isMobile ? (
              <MobileOrderNoteForm
                userAdded={userAdded}
                handleError={handleError}
                setUserAdded={setUserAdded}
                usersDetails={usersDetails}
                orderNoteKey={orderNoteKey}
                handleSuccess={handleSuccess}
                orderNoteData={orderNoteData}
                setOrderNoteData={setOrderNoteData}
                toggleMapClickable={toggleMapClickable}
                setOrderNoteLayerVisible={setOrderNoteLayerVisible}
                toggleOrderNoteMarkerVisible={toggleOrderNoteMarkerVisible}
              />
            ) : (
              <div className=''>
                <div id="orderNotePopUp" className="ol-popup">
                  <OrderNoteForm
                    userAdded={userAdded}
                    deleteNote={deleteNote}
                    handleError={handleError}
                    setUserAdded={setUserAdded}
                    usersDetails={usersDetails}
                    handleSuccess={handleSuccess}
                    orderNoteData={orderNoteData}
                    setDeleteNote={setDeleteNote}
                    outerOrderNoteRef={outerOrderNoteRef}
                    toggleMapClickable={toggleMapClickable}
                    setOrderNoteLayerVisible={setOrderNoteLayerVisible}
                    toggleOrderNoteMarkerVisible={toggleOrderNoteMarkerVisible}
                  />
                </div>
                {/* <OrderNoteForm
                    userAdded={userAdded}
                    deleteNote={deleteNote}
                    handleError={handleError}
                    setUserAdded={setUserAdded}
                    usersDetails={usersDetails}
                    handleSuccess={handleSuccess}
                    orderNoteData={orderNoteData}
                    setDeleteNote={setDeleteNote}
                    outerOrderNoteRef={outerOrderNoteRef}
                    toggleMapClickable={toggleMapClickable}
                    setOrderNoteLayerVisible={setOrderNoteLayerVisible}
                    toggleOrderNoteMarkerVisible={toggleOrderNoteMarkerVisible}
                  /> */}
                <div
                  ref={outerOrderNoteRef}
                  className="review-and-submit-warning-outer"
                >
                  <div
                    className="wrapperPopUpBox"
                    style={{ display: 'block' }}
                  >
                    <div className="raise-feedback-inner-top">
                      <span
                        onClick={() => {
                          outerOrderNoteRef.current.style.opacity = 0;
                          outerOrderNoteRef.current.style.pointerEvents =
                            'none';
                        }}
                      >
                       <box-icon name='x'></box-icon>
                      </span>
                    </div>
                    <div className="Remove_margins_of_p">
                    <p>Confirmation</p>
                      <p>
                        Delete this Order Note?
                      </p>
                    </div>
                    <div className="view-modal-bttn" id="view__model__BTNS">
                      <button  className="cancel" 
                        onClick={() => {
                          outerOrderNoteRef.current.style.opacity = 0;
                          outerOrderNoteRef.current.style.pointerEvents =
                            'none';
                        }}
                      >
                        Cancel
                      </button>
                      <button    className="delete" id="DeleteBtn"
                        onClick={() => {
                          setDeleteNote(true);
                          outerOrderNoteRef.current.style.opacity = 0;
                          outerOrderNoteRef.current.style.pointerEvents =
                            'none';
                        }}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : null}




          <div className="toolbar-container" ref={toolbarRef}>
            <div className="toolbar-inner-container hideOnScreenshot">
              <div
                className={`toolbar-item ${
                  layerType !== 'line'
                    ? 'toolbar-item-disable'
                    : layerType === 'line' && lineInteraction
                    ? 'toolbar-item-active'
                    : ''
                }`}
                onClick={() => {
                  if (layerType === 'line')
                    drawLayer(
                      id,
                      map,
                      layerName,
                      layerType,
                      viewId,
                      layerID,
                      setZoomLayer,
                      handleSuccess,
                      uploadedLayer,
                      setUploadedLayer,
                      setUndoStack,
                      setRedoStack,
                    );
                  unByKey(hoverMapKey);
                  setLineInteraction(true);
                  setCutInteraction(false);
                  setCutAllInteraction(false);
                  setEditInteraction(false);
                  setPointInteraction(false);
                  isContextInteraction(false);
                  setMultipleContextInteraction(false);
                  setSplitInteraction(false);
                  setPolygonInteraction(false);
                  setMultipleSelectInteraction(false);
                }}
              >
                <Tooltip title="Draw-Line">
                  <LineAxisOutlinedIcon
                    className={`toolbar-icon ${
                      layerType !== 'line' ? 'toolbar-icon-disable' : ''
                    }`}
                  />
                </Tooltip>
              </div>
              <div
                className={`toolbar-item ${
                  layerType !== 'point'
                    ? 'toolbar-item-disable'
                    : layerType === 'point' && pointInteraction
                    ? 'toolbar-item-active'
                    : ''
                }`}
                onClick={() => {
                  if (layerType === 'point')
                    drawLayer(
                      id,
                      map,
                      layerName,
                      layerType,
                      viewId,
                      layerID,
                      setZoomLayer,
                      handleSuccess,
                      uploadedLayer,
                      setUploadedLayer,
                      setUndoStack,
                      setRedoStack,
                    );
                  unByKey(hoverMapKey);
                  setLineInteraction(false);
                  setCutInteraction(false);
                  setCutAllInteraction(false);
                  setCutAllInteraction(false);
                  setEditInteraction(false);
                  setPointInteraction(true);
                  isContextInteraction(false);
                  setMultipleContextInteraction(false);
                  setSplitInteraction(false);
                  setPolygonInteraction(false);
                  setMultipleSelectInteraction(false);
                }}
              >
                <Tooltip title="Draw-Point">
                  <RadioButtonCheckedOutlinedIcon
                    className={`toolbar-icon ${
                      layerType !== 'point' ? 'toolbar-icon-disable' : ''
                    }`}
                  />
                </Tooltip>
              </div>
              <div
                className={`toolbar-item ${
                  layerType !== 'polygon'
                    ? 'toolbar-item-disable'
                    : layerType === 'polygon' && polygonInteraction
                    ? 'toolbar-item-active'
                    : ''
                }`}
                onClick={() => {
                  if (layerType === 'polygon')
                    drawLayer(
                      id,
                      map,
                      layerName,
                      layerType,
                      viewId,
                      layerID,
                      setZoomLayer,
                      handleSuccess,
                      uploadedLayer,
                      setUploadedLayer,
                      setUndoStack,
                      setRedoStack,
                    );
                  unByKey(hoverMapKey);
                  setLineInteraction(false);
                  setCutInteraction(false);
                  setCutAllInteraction(false);
                  setEditInteraction(false);
                  setPointInteraction(false);
                  isContextInteraction(false);
                  setMultipleContextInteraction(false);
                  setSplitInteraction(false);
                  setPolygonInteraction(true);
                  setMultipleSelectInteraction(false);
                }}
              >
                <Tooltip title="Draw-Polygon">
                  <RectangleOutlinedIcon
                    className={`toolbar-icon ${
                      layerType !== 'polygon' ? 'toolbar-icon-disable' : ''
                    }`}
                  />
                </Tooltip>
              </div>
              <div
                className={`toolbar-item ${
                  editInteraction ? 'toolbar-item-active' : ''
                }`}
                onClick={() => {
                  editLayer(
                    id,
                    map,
                    layerName,
                    layerType,
                    viewId,
                    layerID,
                    setZoomLayer,
                    uploadedLayer,
                    setUploadedLayer,
                    setUndoStack,
                    setRedoStack,
                    toolbarRef
                  );
                  unByKey(hoverMapKey);
                  setLineInteraction(false);
                  setCutInteraction(false);
                  setCutAllInteraction(false);
                  setEditInteraction(true);
                  setPointInteraction(false);
                  isContextInteraction(false);
                  setMultipleContextInteraction(false);
                  setSplitInteraction(false);
                  setPolygonInteraction(false);
                  setMultipleSelectInteraction(false);
                }}
              >
                <Tooltip title="Modify">
                  <EditOutlinedIcon className="toolbar-icon" />
                </Tooltip>
              </div>
              <div
                className={`toolbar-item ${
                  layerType !== 'polygon'
                    ? 'toolbar-item-disable'
                    : layerType === 'polygon' && cutInteraction
                    ? 'toolbar-item-active'
                    : ''
                }`}
                onClick={() => {
                  if (!cutInteraction) {
                    cutLayer(
                      map,
                      layerName,
                      layerType,
                      setUndoStack,
                      setRedoStack,
                      setZoomLayer,
                      handleSuccess,
                      uploadedLayer,
                      cutLayersName,
                      setCutLayersName,
                      setUploadedLayer,
                      setCurrentPointerIdx
                    );
                    unByKey(hoverMapKey);
                    unByKey(currentPointerIdx);
                    setCutInteraction(true);
                    setCutAllInteraction(false);
                    setLineInteraction(false);
                    setEditInteraction(false);
                    setPointInteraction(false);
                    setSplitInteraction(false);
                    isContextInteraction(false);
                    setMultipleContextInteraction(false);
                    setPolygonInteraction(false);
                    setMultipleSelectInteraction(false);
                  }
                }}
              >
                <Tooltip title="Cut">
                  <ContentCutOutlinedIcon
                    className={`toolbar-icon ${
                      layerType !== 'polygon' ? 'toolbar-icon-disable' : ''
                    }`}
                  />
                </Tooltip>
              </div>
              <div
                className={`toolbar-item ${
                  layerType !== 'polygon'
                    ? 'toolbar-item-disable'
                    : layerType === 'polygon' && splitInteraction
                    ? 'toolbar-item-active'
                    : ''
                }`}
                onClick={() => {
                  SplitInteraction(
                    id,
                    map,
                    layerName,
                    layerType,
                    viewId,
                    layerID,
                    setZoomLayer,
                    handleSuccess,
                    uploadedLayer,
                    setUploadedLayer,
                    setUndoStack,
                    setRedoStack,
                  );
                  unByKey(hoverMapKey);
                  setLineInteraction(false);
                  setCutInteraction(false);
                  setCutAllInteraction(false);
                  setEditInteraction(false);
                  setPointInteraction(false);
                  setSplitInteraction(true);
                  // unpleSelectKey;
                  isContextInteraction(false);
                  setMultipleContextInteraction(false);
                  setPolygonInteraction(false);
                  setMultipleSelectInteraction(false);
                }}
              >
                <Tooltip title="Split">
                  <SplitIcon
                    className={`toolbar-icon ${
                      layerType !== 'polygon' ? 'toolbar-icon-disable' : ''
                    }`}
                  />
                </Tooltip>
              </div>
              <div className="toolbar-item" style={{ border: 'none' }}>
                <Tooltip
                    title="Close"
                    onClick={() => {
                    unByKey(hoverMapKey);
                    setLayerStyle(null);
                    removeAllInteraction(map);
                    setCutInteraction(false);
                    setEditInteraction(false);
                    setLineInteraction(false);
                    setPointInteraction(false);
                    setSplitInteraction(false);
                    setPolygonInteraction(false);
                    setMultipleSelectInteraction(false);
                    toolbarRef.current.style.display = 'none';

                    // const mapContainer = document.getElementById('map-cont');
                                  const mapContainer = document.querySelector('.ol-viewport');

                                mapContainer.classList.remove('edit-cursor');
                                mapContainer.classList.remove('split-cursor');
                                mapContainer.classList.remove('cut-cursor');
                                mapContainer.classList.remove('crosshair-cursor');

                    // document.body.classList.remove('cut-cursor');
                    // document.body.classList.remove('edit-cursor');
                    // document.body.classList.remove('split-cursor');
                    // document.body.classList.remove('crosshair-cursor');
                    
                   
                  

                    hoverEffect(
                      map,
                      hex2rgba,
                      hoverMapKey,
                      setHoverMapKey,
                      hoverElem
                    );
                    isContextInteraction(true);
                    setMultipleContextInteraction(false);
                    // unpleSelectKey;
                  }}
                >
                  <CloseOutlinedIcon className="toolbar-icon" />
                </Tooltip>
              </div>
            </div>
          </div>

          {/* today 30-5-2024 toolbartry */}
{/*           
          <ToolBarToday
        toolbarRef={toolbarRef}
        layerType={layerType}
        lineInteraction={lineInteraction}
        pointInteraction={pointInteraction}
        polygonInteraction={polygonInteraction}
        editInteraction={editInteraction}
        cutInteraction={cutInteraction}
        splitInteraction={splitInteraction}
        hoverMapKey={hoverMapKey}

        setHoverMapKey={setHoverMapKey}
        currentPointerIdx={currentPointerIdx}
        
        
        map={map}
        id={id}
        layerName={layerName}
        viewId={viewId}
        layerID={layerID}
        uploadedLayer={uploadedLayer}
        setUploadedLayer={setUploadedLayer}
        setZoomLayer={setZoomLayer}
        handleSuccess={handleSuccess}
        drawLayer={drawLayer}
        editLayer={editLayer}
        cutLayer={cutLayer}
        SplitInteraction={SplitInteraction}
        unByKey={unByKey}
        setLineInteraction={setLineInteraction}
        setCutInteraction={setCutInteraction}
        setCutAllInteraction={setCutAllInteraction}
        setEditInteraction={setEditInteraction}
        setPointInteraction={setPointInteraction}
        isContextInteraction={isContextInteraction}
        setMultipleContextInteraction={setMultipleContextInteraction}
        setSplitInteraction={setSplitInteraction}
        setPolygonInteraction={setPolygonInteraction}
        setMultipleSelectInteraction={setMultipleSelectInteraction}
        setLayerStyle={setLayerStyle}
        removeAllInteraction={removeAllInteraction}
        hoverEffect={hoverEffect}
        hex2rgba={hex2rgba}
        hoverElem={hoverElem}
        cutLayersName={cutLayersName}
        setCutLayersName={setCutLayersName}
        setUndoStack={setUndoStack}
        setRedoStack={setRedoStack}
        setCurrentPointerIdx={setCurrentPointerIdx}
      />  */}
          {/* today 30-5-2024 toolbartry end */}



          {isPreview && previewStatus ? (
            isPreviewApproved === false ? (
              <div
                onClick={() =>
                  MarkOrderAsPreviewed(id, handleSuccess, setIsPreviewApproved)
                }
                className="mark-preview-container"
                style={{ cursor: 'pointer' }}
              >
                <div>Mark as Previewed</div>
              </div>
            ) : null
          ) : null}
          {status === STATUS.DRAFT &&
          nearmapSurveyData &&
          polygonList.length &&
          !isMobile ? (
            <div className="image-date-cont">
              <ImageryDropdown
                nearmapSurveyData={nearmapSurveyData}
                setNearmapImageDate={setNearmapImageDate}
              />
            </div>
          ) : null}
          <div id="popup-cont" className="popup"></div>
          <div id="measurement_tooltip" className="measuring_tooltip"></div>
          <div id="feature-menu-cont" className="feature-menu">
            <div className="feature-inner-cont">
              <div
                onClick={OpenMoveFeatureBar}
                style={{ borderBottom: '1px solid rgb(234, 227, 227)' }}
              >
                <MoveIcon className="feature-menu-icon" />
                <span>Move</span>
                <MenuOpenRightIcon
                  style={{ margin: '0 0 0 1em' }}
                  className="feature-menu-icon"
                />
              </div>
              <div
                onClick={() =>
                  deleteLayerFeature(moveFeatureData[0], moveFeatureData[1])
                }
              >
                <DeleteIcon className="feature-menu-icon" />
                <span>Delete</span>
              </div>
            </div>
            {showMoveFeatureList ? (
              <div id="feature-list-cont" className="feature-list-cont">
                <div className="feature-list-inner-cont">
                  {moveFeatureData.length
                    ? uploadedLayer.map((item, index) => {
                        if (
                          item.id !== parseInt(moveFeatureData[1]) &&
                          item.type === moveFeatureData[2]
                        )
                          return (
                            <div
                              key={index}
                              id={item.id}
                              onClick={() =>
                                MoveFeature(
                                  id,
                                  map,
                                  viewId,
                                  item.id,
                                  moveFeatureData[0],
                                  moveFeatureData[1],
                                  handleSuccess,
                                  handleError,
                                  uploadedLayer,
                                  setUploadedLayer,
                                  setMultipleContextInteraction,
                                  setMultipleSelectInteraction,
                                  isContextInteraction
                                )
                              }
                            >
                              {item.name}
                            </div>
                          );
                      })
                    : multipleMoveFeatureData.length
                    ? uploadedLayer.map((item, index) => {
                        if (
                          item.id !== multipleMoveFeatureData[1] &&
                          item.type === multipleMoveFeatureData[2]
                        )
                          return (
                            <div
                              key={index}
                              id={item.id}
                              onClick={() =>
                                MoveFeature(
                                  id,
                                  map,
                                  viewId,
                                  item.id,
                                  multipleMoveFeatureData[0],
                                  multipleMoveFeatureData[1],
                                  handleSuccess,
                                  handleError,
                                  uploadedLayer,
                                  setUploadedLayer,
                                  setMultipleContextInteraction,
                                  setMultipleSelectInteraction,
                                  isContextInteraction
                                )
                              }
                            >
                              {item.name}
                            </div>
                          );
                      })
                    : null}
                </div>
              </div>
            ) : null}
          </div>
          <div className="backToDashboard-mobile-container">
            <button
              className="backToDashboard-mobile-button"
              onClick={() => navigate(`/dashboard`)}
            >
              <ArrowBackIcon />
            </button>
            <div className="mobile-address-container">
              <span>{address}</span>
            </div>
          </div>
          <div className="map-buttons hideOnScreenshot">
            <div className="map-layer-button">
              <LayersTypeContainer
                map={map}
                handleLayerTypeMap={handleLayerTypeMap}
                handleLayerTypeSatellite={handleLayerTypeSatellite}
              />
              <div className="map-rotation-buttons">
                <Tooltip title="Rotate map 90deg left" placement="top-start">
                  <Rotate90DegreesCcwIcon
                    className="map-control-icon"
                    onClick={() => rotateMap(map, -90)}
                  />
                </Tooltip>
                <Tooltip title="Rotate map 90deg right" placement="top-start">
                  <Rotate90DegreesCwIcon
                    className="map-control-icon"
                    onClick={() => rotateMap(map, 90)}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="map-zoom-buttons">
              <Tooltip
                title="Use Alt/Option+Shift and Drag to rotate the map. Press this button to reset."
                placement="top"
              >
                <button onClick={() => resetRotation(map)}>
                  <NavigationIcon
                    className="map-control-icon"
                    sx={{ rotate: `${direction}deg` }}
                  />
                </button>
              </Tooltip>
              <button onClick={() => handleZoomIn(map)}>
                <ZoomInIcon className="map-control-icon" />
              </button>
              <button onClick={() => handleZoomOut(map)}>
                <ZoomOutIcon className="map-control-icon" />
              </button>
            </div>
          </div>
          {status === STATUS.MEASUREMENT_COMPLETED && !isMobile ? (
            <div ref={notesButton} className="notes-slide-open-btn">
              <button
                onClick={() => {
                  unByKey(hoverMapKey);
                  setOrderNotesModalShow(true);
                  toggleSlideBtn();
                  setOrderNoteLayerVisible(true);
                  setFeedbackModalShow(false);
                  setFeedbackLayerVisible(false);
                  toggleMapClickable(false);
                  toggleFeedbackMarkerVisible(false);
                  notesButton.current.style.display = 'none';
                  notesModal.current.style.display = 'block';
                  var element = document.getElementById('map-cont');
                  closeDiyUndoRedoTool();
                  element.classList.remove('cursor-change');
                }}
              >
                <KeyboardDoubleArrowLeftIcon className="notes-arrow-open-icon" />
                <span>Notes</span>
              </button>
            </div>
          ) : null}
          {isMobile && orderNoteLayerVisible ? (
            <div
              className={`${ mapClickable ? 'mobile-add-note-div-active' : 'mobile-add-note-div-deactive'}`}
              onClick={() => toggleMapClickable(!mapClickable)}
            >
              <NoteAddIcon />
            </div>
          ) : null}
        </div>




        <div ref={notesModal} className="notes-slide-content">
          <OrderNoteModal
            hex2rgba={hex2rgba}
            hoverElem={hoverElem}
            notesModal={notesModal}
            hoverMapKey={hoverMapKey}
            notesButton={notesButton}
            mapClickable={mapClickable}
            toggleSlideBtn={toggleSlideBtn}
            setHoverMapKey={setHoverMapKey}
            orderNotesLayer={orderNotesLayer}
            setOrderNoteData={setOrderNoteData}
            toggleMapClickable={toggleMapClickable}
            setOrderNotesModalShow={setOrderNotesModalShow}
            setOrderNoteLayerVisible={setOrderNoteLayerVisible}
            toggleOrderNoteMarkerVisible={toggleOrderNoteMarkerVisible}
          />
        </div>
      </div>
    </div>
  );
};

export default OpenLayersIdPage;
