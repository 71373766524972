import axios from 'axios';
import './SuspendedPage.css';
import { useNavigate } from 'react-router-dom';
import AuthToken from '../../context/AuthToken';
import React, { useState, useEffect } from 'react';
import { ACCESS_DENIED_URL } from '../../helper/ApiUrl';

const SuspendedPage = () => {
  const navigate = useNavigate();
  const [suspensionData, setSuspensionData] = useState();

  const accessDenied = () => {
    axios({
      url: ACCESS_DENIED_URL,
      method: 'GEt',
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setSuspensionData(res.data);
        } else {
          navigate('/home');
        }
      })
      .catch((err) => {
        navigate('/home');
      });
  };

  useEffect(() => {
    accessDenied();
  }, []);

  return (
    <div className="suspended-main-container">
      <div class="txt1">{suspensionData?.header?.toUpperCase()}</div>
      <div class="txt2">{suspensionData?.reason}</div>
      <div id="orbit-system">
        <div class="system">
          {/* <div class="satellite-orbit">
            <div class="satellite">SUSPENDED</div>
          </div> */}
          <div class="planet">
            <img
              alt="boy"
              src="https://cdn4.iconfinder.com/data/icons/security-soft-3/512/user_lock_security_person_account_profile_locked-512.png"
              height="200px"
            />{' '}
          </div>
        </div>
      </div>
      <div class="txt3">For more information please </div>
      <a href="mailto:info@ottermap.com ">
        <div class="button">Contact with us</div>
      </a>
    </div>
  );
};

export default SuspendedPage;
