import axios from "axios";
import { ORDER_URL } from "../../helper/ApiUrl";
import AuthToken from "../../context/AuthToken";

const deleteLayerFeature = (
  id,
  viewId,
  layerId,
  featureId,
  uploadedLayer,
  setUploadedLayer
) => {
  const token = AuthToken();
  axios
    .delete(
      `${ORDER_URL}${id}/view/${viewId}/layer/${layerId}/layer-data/${featureId}`,
      {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      uploadedLayer.map((layer) => {
        layer.data = layer.data.filter(
          (feature) => feature.id != `${featureId}`
        );
      });
      setUploadedLayer([...uploadedLayer]);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default deleteLayerFeature;
