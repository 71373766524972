import React from 'react';
import axios from 'axios';
import './UserSetting.css';
import Avatar from '@mui/material/Avatar';
import { useEffect, useRef } from 'react';
import { Tooltip } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useState, useCallback } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import AuthToken from '../../context/AuthToken';
import { TOAST_TYPE } from '../../utils/constants';
import CircularProgress from '@mui/joy/CircularProgress';
import { useScreenType } from '../../utils/commonMethods';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FILE_UPLOAD, USER_INFO_URL } from '../../helper/ApiUrl';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField } from '@mui/material';

import CloudUploadGreen  from '../../assets/CloudGreenIconn.svg'


const inputStyle = {
  '&& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    padding: '4px',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #06372a',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  '& input': {
    padding: '0px',
    height: '31.25px',
    paddingLeft: '10px',
    borderRadius: '5px',
  },
};

const UserSetting = ({ handleError, handleSuccess }) => {
  const token = AuthToken();
  const outerRef = useRef();
  const innerRef = useRef();
  const isMobile = useScreenType();
  const maxFileSize = 10 * 1024 * 1024;
  const [userData, setUserData] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [password, setPassword] = useState('');
  const [profilePic, setProfilePic] = useState(null);
  const [confirmPass, setConfirmPass] = useState('');
  const [isUploaded, setIsUploaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isProgressive, setIsProgressive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmVisibility = () => {
    setConfirmVisible(!confirmVisible);
  };

  const getUserInfo = () => {
    axios
      .get(USER_INFO_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setProfilePic(response.data.data.profile_image);
        setUserData(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    axios
      .patch(USER_INFO_URL, userInfo, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          handleSuccess(TOAST_TYPE.SUCCESS, 'Account has been updated!');
        } else {
          handleError(TOAST_TYPE.ERROR, 'Something went wrong!');
        }
      })
      .catch((error) => {
        handleError(TOAST_TYPE.ERROR, error.message);
      });
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    if (password === confirmPass) {
      const data = {
        password: password,
      };
      axios
        .patch(USER_INFO_URL, data, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setPassword('');
            setConfirmPass('');
            handleSuccess(TOAST_TYPE.SUCCESS, 'Password changed successfully!');
          } else {
            handleError(TOAST_TYPE.ERROR, 'Something went wrong!');
          }
        })
        .catch((error) => {
          handleError(TOAST_TYPE.ERROR, error.message);
        });
    } else {
      handleSuccess(TOAST_TYPE.WARNING, 'Password is not matching!');
    }
  };

  const handleClickOutSide = (e) => {
    if (innerRef.current) {
      if (!innerRef.current.contains(e.target)) {
        outerRef.current.style.opacity = 0;
        outerRef.current.style.pointerEvents = 'none';
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0)
      setErrorMessage('Select not more than 1 file');
    let file = acceptedFiles[0];
    if (file.size > maxFileSize)
      setErrorMessage('File size is greater than 10mb');
    else {
      setSelectedFile(file);
      setErrorMessage('');
    }
  }, []);

  const handleUpload = () => {
    if (selectedFile) {
      setIsProgressive(true);
      const formData = new FormData();
      formData.append('file', selectedFile);
      axios
        .post(FILE_UPLOAD, formData, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (response.data.url) {
            setUserInfo({
              ...userInfo,
              profile_image: response.data.url,
            });
            setProfilePic(response.data.url);
            setSelectedFile(undefined);
            setIsProgressive(false);
            outerRef.current.style.opacity = 0;
            outerRef.current.style.pointerEvents = 'none';
            setIsUploaded(!isUploaded);
            handleSuccess(TOAST_TYPE.SUCCESS, 'Profile Picture Uploaded.');
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      handleSuccess(TOAST_TYPE.WARNING, 'Please choose file to upload!');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
  });

  return (
    <div className="user-setting-container">
      <div className="user-setting-main">
        <div className="user-setting-left">
          <h3>Update Information</h3>
          <div className="user-profile-pic">
            <div className="profile-pic-heading">
              <h4>Profile Pic</h4>
            </div>
            <div className="user-profile-pic-content">
              <Avatar
                className="profile-pic-image"
                alt="profile-pic"
                src={
                  profilePic
                    ? profilePic
                    : 'https://i.pinimg.com/736x/9a/64/8a/9a648ac91d323b0931448ab34055f967.jpg'
                }
              />
              <button
                onClick={() => {
                  outerRef.current.style.opacity = 1;
                  outerRef.current.style.pointerEvents = 'auto';
                }}
              >
                <EditIcon className="profile-pic-edit-icon" />
              </button>
            </div>
          </div>
          <div className="user-setting">
            <form onSubmit={handleUpdate}>
              <div className="user-setting-one">
                <div>
                  <label>First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    autoComplete="off"
                    defaultValue={userData.name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="last_name"
                    autoComplete="off"
                    defaultValue={userData.last_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="user-setting-two">
                <div>
                  <label>Email</label>
                  <br />
                  <input
                    type="text"
                    readOnly={true}
                    autoComplete="off"
                    name="contact_number"
                    value={userData.email}
                  />
                </div>
                <div>
                  <label>Phone</label>
                  <br />
                  <input
                    type="text"
                    name="contact_number"
                    autoComplete="off"
                    defaultValue={userData.contact_number}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="user-setting-three">
                <div>
                  <label>Address</label>
                  <textarea
                    type="text"
                    name="address"
                    autoComplete="off"
                    defaultValue={userData.address}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="user-setting-five">
                <button type="submit" className="saveButton">
                  ✓ Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <hr />
        <div className="user-setting-right">
          <h3>Change Password</h3>
          <div className="user-setting-password-change">
            <div className="user-setting-four-div">
              <label>Password</label>
              <TextField
                required
                margin="dense"
                variant="outlined"
                // sx={inputStyle}
                value={password}
                type={passwordVisible ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility}>
                        {passwordVisible ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="user-setting-four-div">
              <label>Confirm Password</label>
              <TextField
                required
                margin="dense"
                variant="outlined"
                  // sx={inputStyle}
                value={confirmPass}
                type={confirmVisible ? 'text' : 'password'}
                onChange={(e) => setConfirmPass(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleConfirmVisibility}>
                        {confirmVisible ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className="user-setting-password-change-button">
            <button onClick={handleUpdatePassword} className="saveButton">
              ✓ Submit
            </button>
          </div>
        </div>
      </div>
      <div ref={outerRef} className="file-upload-outer-container">
        <div ref={innerRef} className="file-upload-inner-container">
          <div className="file-upload-inner-top">
            <h4>Upload Media</h4>
            <span
              onClick={() => {
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = 'none';
              }}
            >
              X
            </span>
          </div>
          <div className="file-upload-container">
            <div className="dropzone-container" {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="dropzone-text">
                Drag and drop file here, or click here to select file (max size:{' '}
                {maxFileSize / 1024 / 1024} MB)
              </p>
              <div className='clouduploadbox' style={{width:"100%",height:"auto" }}>
                       <img src={ CloudUploadGreen}   style={{maxWidth:"80%" }} />
                       
                       </div>
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            {selectedFile ? (
              <div className="file-list-container">
                <h2 className="file-list-heading">Selected File:</h2>
                <ul className="file-list">
                  <Tooltip title={selectedFile.name}>
                    <li
                      key={selectedFile.name}
                      className="file-item"
                      style={{ width: isMobile ? '100%' : undefined }}
                    >
                      <div className="file-item-img-container">
                        <img
                          className="file-image"
                          src={URL.createObjectURL(selectedFile)}
                          alt={selectedFile.name}
                        />
                      </div>
                      <div className="file-info">
                        <p className="file-name">
                          {selectedFile.name.slice(0, 12)}
                        </p>
                        <p className="file-size">{selectedFile.size} bytes</p>
                        <button
                          className="file-remove-button"
                          onClick={() => setSelectedFile(undefined)}
                        >
                          Remove
                        </button>
                      </div>
                    </li>
                  </Tooltip>
                </ul>
              </div>
            ) : null}
          </div>
          <div className="file-upload-inner-bottom">
            <button
              onClick={() => {
                setSelectedFile(undefined);
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = 'none';
              }}
            >
              Discard
            </button>
            <button onClick={handleUpload}>
              {isProgressive ? (
                <CircularProgress
                  size="sm"
                  value={25}
                  color="success"
                  determinate={false}
                />
              ) : null}
              <span className="file-upload-progress-span">Upload</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSetting;
