import './SideBar.css';
import axios from 'axios';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import AuthToken from '../../context/AuthToken';
import HelpIcon from '@mui/icons-material/Help';
import React, { useState, useEffect } from 'react';
import { TOAST_TYPE } from '../../utils/constants';
import LogoutIcon from '@mui/icons-material/Logout';
import MyDocs from '@mui/icons-material/FolderCopy';
import { RAISE_FEEDBACK } from '../../helper/ApiUrl';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { useNavigate, useLocation } from 'react-router-dom';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { useFeedbackContext } from '../../context/FeedbackContext';
import { ReactComponent as SiteIcon } from '../../assets/icon.svg';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import useLogout from '../../utils/customHooks/logout.js';


const SideBar = ({ handleError, handleSuccess }) => {
  const token = AuthToken();
  const handleLogout = useLogout();
  const location = useLocation();
  const url = window.location.href;
  const [feedbackDes, setFeedbackDes] = useState('');
  const { feedbackInnerRef, feedbackOutterRef } = useFeedbackContext();


  const logoutBtnClick = () => {
    handleLogout()
  };

  const handleClickOutSide = (e) => {
    if (feedbackInnerRef.current) {
      if (!feedbackInnerRef.current.contains(e.target)) {
        feedbackOutterRef.current.style.opacity = 0;
        feedbackOutterRef.current.style.pointerEvents = 'none';
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  const submitFeedback = () => {
    if (feedbackDes) {
      let payment = '';
      const feedbackData = {
        description: feedbackDes,
        page: url,
      };
      if (location.pathname === '/payment') {
        payment = '?topic=Payment';
      }
      axios
        .post(`${RAISE_FEEDBACK}${payment}`, feedbackData, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((res) => {
          setFeedbackDes('');
          feedbackOutterRef.current.style.opacity = 0;
          handleSuccess(TOAST_TYPE.SUCCESS, res.data.message);
          feedbackOutterRef.current.style.pointerEvents = 'none';
        })
        .catch((err) => {
          handleError(err);
        });
    } else {
      handleSuccess(TOAST_TYPE.WARNING, 'Please add some comment!');
    }
  };

  return (
    <div className="sidebar-main-container">
      <div className="sidebar-top-container">
        <Tooltip title="Home" placement="right">
          <a href="/home">
            <SiteIcon className="otter-map-logo" />
          </a>
        </Tooltip>
        <Tooltip title="Dashboard" placement="right">
          <a href="/dashboard">
            {' '}
            <MapsHomeWorkIcon className="sidebar-icon" />
          </a>
        </Tooltip>
        <Tooltip title="Place Order" placement="right">
          <a href="/order">
            {' '}
            <AddHomeWorkIcon className="sidebar-icon" />
          </a>
        </Tooltip>
        <Tooltip title="Orders in progress" placement="right">
          <a href="/dashboard?status=in_progress">
            {' '}
            <EventRepeatIcon className="sidebar-icon" />
          </a>
        </Tooltip>
        <Tooltip title="DIY" placement="right">
          {/* <Badge badgeContent="BETA" className="diy-badge"> */}
          <a href="/diy/order">
            <ConstructionOutlinedIcon className="sidebar-icon" />
          </a>
          {/* </Badge> */}
        </Tooltip>
        <Tooltip title="Projects" placement="right">
          <a href="/project">
            {' '}
            <DocumentScannerIcon className="sidebar-icon" />
          </a>
        </Tooltip>
        <Tooltip title="My Docs" placement="right">
          <a href="/mydocs">
            {' '}
            <MyDocs className="sidebar-icon" />
          </a>
        </Tooltip>
        <Tooltip title="Help" placement="right">
          <a
            href="https://ionian-walrus-f46.notion.site/Ottermap-Product-Wiki-6d8314f8e14c45b1aa27748e06800855"
            target="_blank"
          >
            {' '}
            <HelpIcon className="sidebar-icon" />
          </a>
        </Tooltip>
        {/* <Tooltip
          title="Raise Feedback"
          onClick={() => {
            feedbackOutterRef.current.style.opacity = 1;
            feedbackOutterRef.current.style.pointerEvents = "auto";
          }}
        >
          <FeedbackIcon className="sidebar-icon" />
        </Tooltip> */}
      </div>
      <div className="sidebar-bottom-container">
        <Tooltip title="Logout" placement="right">
          <LogoutIcon onClick={logoutBtnClick} className="sidebar-icon" />
        </Tooltip>
        <Tooltip title="User settings" placement="right">
          <a href="/setting/user">
            {' '}
            <Avatar
              className="sidebar-icon"
              alt="Cindy Baker"
              src="https://i.pinimg.com/736x/9a/64/8a/9a648ac91d323b0931448ab34055f967.jpg"
            />
          </a>
        </Tooltip>
      </div>
      {/* <div ref={feedbackOutterRef} className="raise-feedback-outter-container">
        <div ref={feedbackInnerRef} className="raise-feedback-inner-container">
          <div className="raise-feedback-inner-top">
            <span
              onClick={() => {
                feedbackOutterRef.current.style.opacity = 0;
                feedbackOutterRef.current.style.pointerEvents = "none";
              }}
            >
              X
            </span>
          </div>
          <div className="raise-feedback-inner-mid">
            <textarea
              value={feedbackDes}
              onChange={(e) => setFeedbackDes(e.target.value)}
              placeholder="We value your comments and suggestions..."
            />
          </div>
          <div className="raise-feedback-inner-bottom">
            <button onClick={submitFeedback}>Submit</button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SideBar;
