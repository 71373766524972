import {
  faFileAlt,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFilePowerpoint,
} from "@fortawesome/free-regular-svg-icons";
import path from "path";
import axios from "axios";
import "./OrderNoteForm.css";
import { useEffect, useRef } from "react";
import Menu from "@mui/material/Menu";
import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import AuthToken from "../../context/AuthToken";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import { TOAST_TYPE } from "../../utils/constants";
import MediaDialog from "../MediaDialog/MediaDialog";
import VideocamIcon from "@mui/icons-material/Videocam";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MentionsInput, Mention } from "react-mentions";
import { ORDER_URL, LOGIN_URL } from "../../helper/ApiUrl";
import { useUserContext } from "../../context/UserContext";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CircularProgress from "@mui/material/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import OrderNotesCommentSection from "../OrderNotesCommentSection/OrderNotesCommentSection";

// Textfield Icon
import TextFieldsIcon from "@mui/icons-material/TextFields";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { SketchPicker } from "react-color";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

//
const mentionStyles = {
  color: "wheat",
};

// Toolbar Modules

var toolbarOptions = [
  // ["bold", "italic", "underline"],

  // ["link"],
  [ { size: [] }],
  [{ list: "ordered" }, { list: "bullet" }], 
  [{ color: [] }],

  // [{ align: [] }],

  // ["clean"],
];

const Toolbarmodule = {
  toolbar: toolbarOptions,
};
// Toolbar Modules
// MENTION MODULES
// import mention from "quill-mention";
// const atValues = [
//   { id: 1, value: "Fredrik Sundqvist" },
//   { id: 2, value: "Patrik Sjölin" }
// ];
// const hashValues = [
//   { id: 3, value: "Fredrik Sundqvist 2" },
//   { id: 4, value: "Patrik Sjölin 2" }
// ];
// const handleChange = (value, delta) => {
//   console.log(value, delta);
// };
//   const modules={{
//   mention: {
//     allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
//     mentionDenotationChars: ["@", "#"],
//     source: function(searchTerm, renderList, mentionChar) {
//       let values;

//       if (mentionChar === "@") {
//         values = atValues;
//       } else {
//         values = hashValues;
//       }

//       if (searchTerm.length === 0) {
//         renderList(values, searchTerm);
//       } else {
//         const matches = [];
//         for (i = 0; i < values.length; i++)
//           if (
//             ~values[i].value
//               .toLowerCase()
//               .indexOf(searchTerm.toLowerCase())
//           )
//             matches.push(values[i]);
//         renderList(matches, searchTerm);
//       }
//     }
//   }
// }}

//
export default function OrderNoteForm({
  userAdded,
  deleteNote,
  handleError,
  setUserAdded,
  usersDetails,
  handleSuccess,
  orderNoteData,
  setDeleteNote,
  outerOrderNoteRef,
  toggleMapClickable,
  setOrderNoteLayerVisible,
  toggleOrderNoteMarkerVisible,
}) {
  const token = AuthToken();
  const { id } = useParams();
  const url = window.location.href;
  const { userInfo } = useUserContext();
  const [users, setUsers] = useState([]);
  const prod = process.env.REACT_APP_PROD;
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogMedia, setDialogMedia] = useState();
  const [userAssigned, setUserAssigned] = useState();
  const [attachments, setAttachments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [orderNoteTitle, setOrderNoteTitle] = useState();
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [userToBeAssigned, setUserToBeAssigned] = useState();
  const [addingOrderNote, setAddingOrderNote] = useState(false);
  const [mentionUserAnchor, setMentionUserAnchor] = useState(null);
  const [orderNoteEditable, setOrderNoteEditable] = useState(false);
  const [orderNoteDescription, setOrderNoteDescription] = useState();
  //
  const [title, setTitle] = useState("");
  const [modifiedNoteTitle, setModifiedNoteTitle] = useState(" ");
  const [description, setDescription] = useState("");
  const [fontStyle, setFontStyle] = useState("h1");
  const [color, setColor] = useState("#000000");
  const [bullets, setBullets] = useState(false);

  const [titleSize, setTitleSize] = useState(false);
  const [Colorbox, setColorBox] = useState(false);
  ///
  const [descriptionColor, setDescriptionColor] = useState("#000000");
  const [savedData, setSavedData] = useState(null);

  const getFontSize = (fontSize) => {
    switch (fontSize) {
      case "h1":
        return "2em";
      case "h2":
        return "1.5em";
      case "h3":
        return "1.17em";
      case "h4":
        return "1em";
      case "h5":
        return "0.83em";
      case "h6":
        return "0.67em";
      default:
        return "1em";
    }
  };

  // code for ReactQull

  // const [Qtitle, QsetTitle] = useState('');
  const [Qdescription, QsetDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [Qdata, QsetData] = useState([]);
  const [QsavedData, QsetSavedData] = useState([]);

  const containerStyle = {
    borderRadius: '5px',
    overflow: 'hidden', 
    border: '1px solid #ccc',
    marginBottom: '.5rem'
  };

  const editorStyle = {
    backgroundColor: 'white',
    padding: '10px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px'
  };


  // useEffect(() => {
  //   const localData = localStorage.getItem("textEditorData");
  //   if (localData) {
  //     setSavedData(JSON.parse(localData));
  //   }
  // }, []);
  // useEffect(() => {
  //   console.log(QsavedData, "QsavedData");
  // }, [QsavedData]);

  // const handleFiles = (event) => {
  //   setFiles([...event.target.files]);
  // };

  // const handleSave = () => {
  //   console.log(Qdescription, " click Qdescription");
  // };

  useEffect(() => {
    console.log(orderNoteDescription, "orderNoteDescription");
  }, [orderNoteDescription]);

  useEffect(() => {
    console.log(Qdescription, "Qdescription");
  }, [Qdescription]);
  // const handleSave = async () => {

  //   const formData = new FormData();
  //   // formData.append('title', title);
  //   formData.append('description', Qdescription);
  //   // files.forEach((file) => {
  //   //   formData.append('files', file);
  //   // });

  //   const response = await axios.post('http://localhost:5000/api/data', formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     },
  //   });

  //   const newData = response.Qdata;
  //   const updatedData = [...Qdata, newData];
  //   QsetData(updatedData);
  //   localStorage.setItem('textEditorData', JSON.stringify(updatedData));
  // };

  // code for ReactQull
  const renderTitle = () => {
    const Tag = fontStyle;

    let styleData = <Tag style={{ color }}>{orderNoteTitle}</Tag>;
    // let styleData = <h1 style={{
    //   color: savedData.titleColor,
    //   fontSize: getFontSize(),
    // }}>{orderNoteTitle}</h1>;

    console.log(styleData, "styleData");

    setModifiedNoteTitle(styleData);
    setOrderNoteTitle(styleData);
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  useEffect(() => {
    // console.log("this", orderNoteTitle, "orderNoteTitle getting");
    console.log(
      "this",
      modifiedNoteTitle,
      typeof modifiedNoteTitle,
      "modifiedNoteTitle getting"
    );
  }, [orderNoteTitle, modifiedNoteTitle]);
  // HA

  const open = Boolean(anchorEl);
  const mentionUserAchorOpen = Boolean(mentionUserAnchor);

  const mentionsInputStyles = {
    control: {
      backgroundColor: orderNoteEditable ? "#FFFFFF" : "#E4EFE9",
      padding: "0.5rem 1rem",
      fontSize: 14,
      // height: "4.5em",
      height: "1.8em",
      border: orderNoteEditable
        ? "1px solid #696969"
        : "1px solid rgb(174, 195, 155)",
      borderRadius: "13px",
    },
    suggestions: {
      list: {
        backgroundColor: "#e7ffd8",
        color: "#000",
        fontSize: "0.8rem",
        width: "10rem",
      },
      item: {
        padding: "0.5rem 1rem",
        "&focused": {
          backgroundColor: "#ffffff",
        },
      },
    },
  };

  const handleViewMoreOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMentionUserAnchorClose = () => setMentionUserAnchor(null);

  useEffect(() => {
    usersDetails.map((item, index) => {
      users.push({
        id: item.id,
        display: item.name,
      });
    });
    setOrderNoteTitle(orderNoteData.title);
    setOrderNoteDescription(orderNoteData.description);
    if (orderNoteData.user_ids) {
      const obj = usersDetails.filter(
        (x) => x.id === orderNoteData.user_ids[0]
      );
      if (obj.length) setUserAssigned({ id: obj[0].id, name: obj[0].name });
    } else setUserAssigned();
    setAttachments(orderNoteData.attachments);
    if (!orderNoteData.hasOwnProperty("id")) {
      setAddingOrderNote(true);
      setOrderNoteEditable(true);
    } else {
      setAddingOrderNote(false);
      setOrderNoteEditable(false);
    }
  }, [orderNoteData]);

  const titleHandler = (e) => setOrderNoteTitle(e.target.value);
  const addOrderNote = (e) => {
    e.preventDefault();

    // HA
    renderTitle();
    // const Tag = fontStyle;
    //  <Tag style={{ color }}>{title}</Tag>;
    //

    var arr = "";
    console.log(orderNoteData.hasOwnProperty("id"), "true or not ");

    if (orderNoteData.hasOwnProperty("id")) {
      let formData = new FormData();
      formData.append("title", orderNoteTitle);

      // console.log(modifiedNoteTitle,"modifiedNoteTitle in add Orednote ")
      // formData.append("title", modifiedNoteTitle); // HA
      formData.append("description", orderNoteDescription);
      // formData.append("color", color) ;//HA
      // formData.append("FontSize", fontStyle) ;//HA
      let temp = {
        metaData: { color: color, fontSize: fontStyle },
      };

      formData.append("metaData", ...temp);

      if (attachments)
        formData.append("attachments", JSON.stringify(attachments));
      if (userToBeAssigned) {
        arr += `${String(userToBeAssigned.id)}`;
        formData.append("user_ids", arr);
      }

      console.log(formData, "formData"); //HA
      axios({
        url: `${ORDER_URL}${id}/notes/${orderNoteData.id}`,
        method: "PUT",
        data: formData,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => {
          if ("data" in res) {
            setUserAdded(!userAdded);
            handleSuccess(
              TOAST_TYPE.SUCCESS,
              "Order Note successfully updated"
            );
            // if (url.includes(prod)) {
            if (url.includes(process.env.REACT_APP_DEV)) {
              window.analytics.track("Order Note Updated", {
                order_note_id: orderNoteData.id,
                creator: orderNoteData.created_by,
              });
            }
          }
        })
        .catch((err) => {
          handleError(err);
        });
    } else {
      let formData = new FormData();
      formData.append("title", orderNoteTitle);
      formData.append("description", orderNoteDescription);

      formData.append("lat", orderNoteData.lat);
      formData.append("long", orderNoteData.long);

      // formData.append("title", modifiedNoteTitle); // HA
      // formData.append("color", color) ;//HA
      // formData.append("FontSize", fontStyle) ;//HA
      // console.log(formData,"formData")//HA

      let meta_data = {
        color: color,
        fontSize: fontStyle,
        Qdescription: Qdescription,
      };
      //  let metaData = [ color, fontStyle]

      formData.append("meta_data", JSON.stringify(meta_data));

      if (attachments)
        formData.append("attachments", JSON.stringify(attachments));
      if (userToBeAssigned) {
        arr += `${String(userToBeAssigned.id)}`;
        formData.append("user_ids", arr);
      }
      axios({
        url: `${ORDER_URL}${id}/notes`,
        method: "POST",
        data: formData,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => {
          if ("data" in res) {
            setUserAdded(!userAdded);
            handleSuccess(TOAST_TYPE.SUCCESS, "Order Note successfully added");
            // if (url.includes(prod)) {
            if (url.includes(process.env.REACT_APP_DEV)) {
              window.analytics.track("Order Note Created", {
                order_note_id: orderNoteData.id,
                creator: orderNoteData.created_by,
              });
            }
          }
        })
        .catch((err) => {
          handleError(err);
        });
    }
    toggleOrderNoteMarkerVisible(false);
    setOrderNoteLayerVisible(false);
    toggleMapClickable(false);
    setOrderNoteTitle("");
    setOrderNoteDescription("");
  };

  const onCloseClick = (e) => {
    e.preventDefault();
    toggleOrderNoteMarkerVisible(false);
    setOrderNoteEditable(false);
  };

  const setData = (e) => {
    let element = document.getElementById("attachment-loader");
    element.classList.remove("attachment-loader-hide");
    element.classList.add("attachment-loader-show");
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    axios({
      url: `${LOGIN_URL}file/upload?folder=notes`,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        if ("data" in res) {
          if (!attachments) setAttachments([res.data.file_url]);
          else setAttachments([...attachments, res.data.file_url]);
          handleSuccess(TOAST_TYPE.SUCCESS, "File added successfully");
          element.classList.remove("attachment-loader-show");
          element.classList.add("attachment-loader-hide");
        }
      })
      .catch((err) => {
        handleError(err);
        element.classList.remove("attachment-loader-show");
        element.classList.add("attachment-loader-hide");
      });
  };

  const deleteAttachment = (item) => {
    var arr = attachments;
    const index = arr.indexOf(item);
    if (index !== -1) {
      arr.splice(index, 1);
      setAttachments([...arr]);
    }
  };

  const editOrderNote = () => {
    setOrderNoteEditable(true);
    handleClose();
  };

  const deleteOrderNote = () => {
    axios({
      url: `${ORDER_URL}${id}/notes/${orderNoteData.id}`,
      method: "DELETE",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        if ("data" in res) {
          handleSuccess(TOAST_TYPE.SUCCESS, "Order Note successfully deleted");
          // if (url.includes(prod)) {
          if (url.includes(process.env.REACT_APP_DEV)) {
            window.analytics.track("Order Note Deleted");
          }
        }
      })
      .catch((err) => {
        handleError(err);
      });
    setDeleteNote(false);
    toggleOrderNoteMarkerVisible(false);
    setOrderNoteLayerVisible(false);
    setOrderNoteTitle("");
    setOrderNoteDescription("");
  };

  useEffect(() => {
    if (deleteNote) deleteOrderNote();
  }, [deleteNote]);

  const FileIcon = ({ url }) => {
    let extname = path.extname(url);
    let icon;
    switch (extname) {
      case ".pdf":
        icon = faFilePdf;
        break;
      case ".doc":
      case ".docx":
        icon = faFileWord;
        break;
      case ".ppt":
      case ".pptx":
        icon = faFilePowerpoint;
        break;
      case ".xls":
      case ".xlsx":
        icon = faFileExcel;
        break;
      case ".jpg":
      case ".jpeg":
      case ".png":
      case ".gif":
        return <img style={{ cursor: "pointer" }} src={url} alt={url} />;
      case ".mp3":
      case ".wav":
        icon = faFileAudio;
        break;
      case ".mp4":
      case ".mov":
        icon = faFileVideo;
        break;
      default:
        icon = faFileAlt;
        break;
    }
    return <FontAwesomeIcon className="doc-type-attachment-icon" icon={icon} />;
  };

  const tagUser = (id, display) => {
    const user = { id: id, name: display };
    if (!mentionedUsers.some((mentionedUser) => mentionedUser.id === user.id))
      setMentionedUsers((prevMentionedUsers) => [...prevMentionedUsers, user]);
  };

  const handleOpenDialog = (item) => {
    let p = path.extname(item);
    if (
      p !== ".jpg" &&
      p !== ".jpeg" &&
      p !== ".png" &&
      p !== ".gif" &&
      p !== ".mp4" &&
      p !== ".mov"
    )
      window.open(item, "_blank");
    else {
      setDialogMedia(item);
      setOpenDialog(true);
    }
  };

  useEffect(() => {
    if (orderNoteDescription) {
      const regex = /@\[([^)]+)\]\((\d+)\)/g;
      const matches = orderNoteDescription.match(regex);
      if (matches) {
        const allUsers = matches.map((match) => {
          const userId = parseInt(match.match(/\((\d+)\)/)[1]);
          const userName = match.match(/\[([^)]+)\]/)[1];
          return { id: userId, name: userName };
        });
        setMentionedUsers(allUsers);
      } else {
        setMentionedUsers([]);
      }
    }
  }, [orderNoteDescription]);

  return (
    <>
      <div className="overlay-container">
        <div className="ordernote-infowindow-container">
          <form onSubmit={addOrderNote}>
            <div className="ordernote-title-input-container">
              <div className="ordernote-title-input">
                <input
                  type="text"
                  placeholder="Title"
                  required
                  style={
                    orderNoteEditable
                      ? { background: "#FFFFFF" }
                      : { background: "#E7FFD8", border: "1px solid #E7FFD8" }
                  }
                  disabled={!orderNoteEditable}
                  onChange={titleHandler}
                  value={orderNoteTitle}
                />
                {orderNoteData.created_by &&
                userInfo.email === orderNoteData.created_by.email ? (
                  <div>
                    {!addingOrderNote && (
                      <MoreVertIcon
                        color="success"
                        className="more-vert-icon"
                        onClick={handleViewMoreOptions}
                      />
                    )}
                    <Menu
                      className="menu-cont"
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        className="menu-cont-users-item"
                        onClick={editOrderNote}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        className="menu-cont-users-item"
                        onClick={() => {
                          handleClose();
                          outerOrderNoteRef.current.style.opacity = 1;
                          outerOrderNoteRef.current.style.pointerEvents =
                            "auto";
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="ordernote-description-input-container">
              <div className="ordernote-description-input">
                {/* New code  ReactQUill */}

                <div
                  className="editor-container"
                  style={containerStyle}

                >
                  <ReactQuill
                    value={Qdescription}
                    onChange={QsetDescription}
                    modules={Toolbarmodule}
                    style={editorStyle}
                  />
                </div>

                {/* New code  ReactQUill */}
                <MentionsInput
                  style={mentionsInputStyles}
                  value={orderNoteDescription}
                  onChange={(e) => setOrderNoteDescription(e.target.value)}
                  type="text"
                  className="orderNote-mention-cont"
                  placeholder="Mention to someone"
                  disabled={!orderNoteEditable}
                  required
                >
                  <Mention
                    style={mentionStyles}
                    trigger="@"
                    data={users}
                    displayTransform={(id, display) => `@${display}`}
                    onAdd={tagUser}
                     className="orderNote-mention-innercont"
                  />
                </MentionsInput>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0.4em 0",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {userToBeAssigned ? (
                      <span style={{ fontSize: "0.6em", marginLeft: "0.5em" }}>
                        Assign to{" "}
                        {userToBeAssigned
                          ? userToBeAssigned.id === userInfo.id
                            ? "You"
                            : userToBeAssigned.name
                          : ""}
                      </span>
                    ) : userAssigned ? (
                      <span style={{ fontSize: "0.6em", marginLeft: "0.5em" }}>
                        Assigned to{" "}
                        {userAssigned.id === userInfo.id
                          ? "You"
                          : userAssigned.name}
                      </span>
                    ) : mentionedUsers.length ? (
                      <span style={{ fontSize: "0.6em", marginLeft: "0.5em" }}>
                        Assign to{" "}
                        {userToBeAssigned
                          ? userToBeAssigned.id === userInfo.id
                            ? "You"
                            : userToBeAssigned.name
                          : ""}
                      </span>
                    ) : null}
                    {userInfo.email === orderNoteData.created_by.email &&
                    (mentionedUsers.length || userAssigned) &&
                    orderNoteEditable ? (
                      <KeyboardArrowDownIcon
                        style={{ cursor: "pointer" }}
                        onClick={(e) => setMentionUserAnchor(e.currentTarget)}
                      />
                    ) : null}
                  </div>
                </div>
                <Menu
                  className="menu-cont-tag-users"
                  id="basic-menu-assign"
                  anchorEl={mentionUserAnchor}
                  open={mentionUserAchorOpen}
                  onClose={handleMentionUserAnchorClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {mentionedUsers.map((item, key) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          setUserToBeAssigned(item);
                          handleMentionUserAnchorClose();
                        }}
                        className="menu-cont-mention-users-item"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ marginLeft: "0.3em" }}>
                            <div>{item.name}</div>
                            <div>{item.email}</div>
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })}
                </Menu>
              </div>
            </div>
            <div className="order-note-actions-cont">
              <input
                type="file"
                disabled={!orderNoteEditable}
                id="imgupload"
                style={{ display: "none" }}
                accept="image/*"
                onChange={setData}
              />
              <Tooltip
                placement="top"
                title={
                  orderNoteEditable ? "Add Image" : "Edit note to Add File"
                }
              >
                <label htmlFor="imgupload">
                  <ImageIcon
                    style={
                      orderNoteEditable
                        ? { color: "green", cursor: "pointer" }
                        : { color: "grey" }
                    }
                  />
                </label>
              </Tooltip>
              <input
                type="file"
                disabled={!orderNoteEditable}
                id="vidupload"
                style={{ display: "none" }}
                accept="video/*"
                onChange={setData}
              />
              <Tooltip
                placement="top"
                title={
                  orderNoteEditable ? "Add Video" : "Edit note to Add File"
                }
              >
                <label htmlFor="vidupload">
                  <VideocamIcon
                    style={
                      orderNoteEditable
                        ? { color: "green", cursor: "pointer" }
                        : { color: "grey" }
                    }
                  />
                </label>
              </Tooltip>
              <input
                type="file"
                disabled={!orderNoteEditable}
                id="attachmentupload"
                style={{ display: "none" }}
                onChange={setData}
              />
              <Tooltip
                placement="top"
                title={
                  orderNoteEditable ? "Add Document" : "Edit note to Add File"
                }
              >
                <label htmlFor="attachmentupload">
                  <AttachmentIcon
                    style={
                      orderNoteEditable
                        ? { color: "green", cursor: "pointer" }
                        : { color: "grey" }
                    }
                  />
                </label>
              </Tooltip>

              {/* New Code for the font and color  */}
              <Tooltip
                placement="top"
                title={orderNoteEditable ? "Add Title Size" : "Edit Title Size"}
              >
                <label htmlFor="">
                  <TextFieldsIcon
                    style={
                      orderNoteEditable
                        ? { color: "green", cursor: "pointer" }
                        : { color: "grey" }
                    }
                    onClick={() => {
                      setTitleSize(!titleSize);
                    }}
                  />
                  <div>
                    {titleSize ? (
                      <select
                        value={fontStyle}
                        onChange={(e) => setFontStyle(e.target.value)}
                      >
                        <option value="h1">H1</option>
                        <option value="h2">H2</option>
                        <option value="h3">H3</option>
                        <option value="h4">H4</option>
                        <option value="h5">H5</option>
                        <option value="h6">H6</option>
                      </select>
                    ) : null}
                  </div>
                </label>
              </Tooltip>

              {/* for color */}
              <Tooltip
                placement="top"
                title={
                  orderNoteEditable ? "Add Title Color " : "Edit Title Color"
                }
              >
                <label htmlFor="#">
                  <FormatColorTextIcon
                    style={
                      orderNoteEditable
                        ? { color: "green", cursor: "pointer" }
                        : { color: "grey" }
                    }
                    onClick={() => {
                      setColorBox(!Colorbox);
                    }}
                  />
                  {/* <input type="color" value={descriptionColor} onChange={(e) => setDescriptionColor(e.target.value)} /> */}
                  <div>
                    {" "}
                    {Colorbox ? (
                      <SketchPicker
                        color={color}
                        onChangeComplete={handleColorChange}
                      />
                    ) : null}{" "}
                  </div>
                </label>
              </Tooltip>
              {/* bullet point */}

              {/* New Code for the font and color  */}
            </div>
            <div className="attachments-cont">
            
              {attachments &&
                Array.isArray(attachments) &&
                attachments.map((item, key) => {
                  return (
                    <div
                      key={key}
                      onClick={() => handleOpenDialog(item)}
                      className="attachment-item-cont"
                    >
                      {orderNoteEditable && (
                        <CloseIcon
                          className="delete-attachment"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteAttachment(item);
                          }}
                        />
                      )}
                      <FileIcon url={item} />
                    </div>
                  );
                })}
              <div id="attachment-loader" className="attachment-loader-hide">
                <CircularProgress color="success" />
              </div>
            </div>
            <MediaDialog
              dialogMedia={dialogMedia}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
            />
            {orderNoteData.hasOwnProperty("id") && (
              <OrderNotesCommentSection
                orderNoteData={orderNoteData}
                usersDetails={usersDetails}
                orderNoteEditable={orderNoteEditable}
              />
            )}
            <div className="ordernote-buttons-container">
              <button
                className="ordernote-cancel-button"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              {orderNoteEditable && (
                <button className="ordernote-submit-button" type="submit">
                  Submit
                </button>
              )}
            </div>

            {/* testing for the data */}
            <div>{/* {renderTitle()} */}</div>
          </form>
        </div>
      </div>
    </>
  );
}
