import {
  faFileAlt,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFilePowerpoint,
} from '@fortawesome/free-regular-svg-icons';
import path from 'path';
import axios from 'axios';
import './MobileOrderNoteForm.css';
import Menu from '@mui/material/Menu';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import { useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import AuthToken from '../../context/AuthToken';
import { Typography, Box } from '@mui/material';
import NoteIcon from '@mui/icons-material/Note';
import CloseIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import { TOAST_TYPE } from '../../utils/constants';
import MediaDialog from '../MediaDialog/MediaDialog';
import VideocamIcon from '@mui/icons-material/Videocam';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MentionsInput, Mention } from 'react-mentions';
import React, { useRef, useState, useEffect } from 'react';
import { ORDER_URL, LOGIN_URL } from '../../helper/ApiUrl';
import { useUserContext } from '../../context/UserContext';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CircularProgress from '@mui/material/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import OrderNotesCommentSection from '../OrderNotesCommentSection/OrderNotesCommentSection';

const mentionStyles = {
  color: 'wheat',
};

export const MobileOrderNoteForm = ({
  userAdded,
  handleError,
  setUserAdded,
  usersDetails,
  orderNoteKey,
  orderNoteData,
  handleSuccess,
  setOrderNoteData,
  toggleMapClickable,
  setOrderNoteLayerVisible,
  toggleOrderNoteMarkerVisible,
}) => {
  const menuRef = useRef();
  const token = AuthToken();
  const { id } = useParams();
  const url = window.location.href;
  const { userInfo } = useUserContext();
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(true);
  const [dialogMedia, setDialogMedia] = useState();
  const [attachments, setAttachments] = useState([]);
  const [userAssigned, setUserAssigned] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [orderNoteTitle, setOrderNoteTitle] = useState();
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [userToBeAssigned, setUserToBeAssigned] = useState();
  const [addingOrderNote, setAddingOrderNote] = useState(false);
  const [mentionUserAnchor, setMentionUserAnchor] = useState(null);
  const [orderNoteEditable, setOrderNoteEditable] = useState(false);
  const [orderNoteDescription, setOrderNoteDescription] = useState();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const mentionUserAchorOpen = Boolean(mentionUserAnchor);

  const mentionsInputStyles = {
    control: {
      backgroundColor: orderNoteEditable ? '#FFFFFF' : '#E4EFE9',
      padding: '0.5rem 1rem',
      fontSize: 14,
      height: '4.5em',
      border: orderNoteEditable
        ? '1px solid #696969'
        : '1px solid rgb(174, 195, 155)',
      borderRadius: '13px',
    },
    suggestions: {
      list: {
        backgroundColor: '#e7ffd8',
        color: '#000',
        fontSize: '0.8rem',
        width: '10rem',
      },
      item: {
        padding: '0.5rem 1rem',
        '&focused': {
          backgroundColor: '#ffffff',
        },
      },
    },
  };

  const onCloseClick = (e) => {
    e.preventDefault();
    setOrderNoteData(null);
    toggleOrderNoteMarkerVisible(false);
    setOrderNoteEditable(false);
  };

  const handleOpenDialog = (item) => {
    let p = path.extname(item);
    if (
      p !== '.jpg' &&
      p !== '.jpeg' &&
      p !== '.png' &&
      p !== '.gif' &&
      p !== '.mp4' &&
      p !== '.mov'
    )
      window.open(item, '_blank');
    else {
      setDialogMedia(item);
      setOpenDialog(true);
    }
  };

  const deleteAttachment = (item) => {
    var arr = attachments;
    const index = arr.indexOf(item);
    if (index !== -1) {
      arr.splice(index, 1);
      setAttachments([...arr]);
    }
  };

  const FileIcon = ({ url }) => {
    let extname = path.extname(url);
    let icon;
    switch (extname) {
      case '.pdf':
        icon = faFilePdf;
        break;
      case '.doc':
      case '.docx':
        icon = faFileWord;
        break;
      case '.ppt':
      case '.pptx':
        icon = faFilePowerpoint;
        break;
      case '.xls':
      case '.xlsx':
        icon = faFileExcel;
        break;
      case '.jpg':
      case '.jpeg':
      case '.png':
      case '.gif':
        return <img style={{ cursor: 'pointer' }} src={url} alt={url} />;
      case '.mp3':
      case '.wav':
        icon = faFileAudio;
        break;
      case '.mp4':
      case '.mov':
        icon = faFileVideo;
        break;
      default:
        icon = faFileAlt;
        break;
    }
    return <FontAwesomeIcon className="doc-type-attachment-icon" icon={icon} />;
  };

  const handleMentionUserAnchorClose = () => setMentionUserAnchor(null);

  const tagUser = (id, display) => {
    const user = { id: id, name: display };
    if (!mentionedUsers.some((mentionedUser) => mentionedUser.id === user.id))
      setMentionedUsers((prevMentionedUsers) => [...prevMentionedUsers, user]);
  };

  const editOrderNote = () => {
    handleClose();
    setOrderNoteEditable(true);
  };

  const handleClickOutSide = (e) => {
    if (menuRef.current) {
      if (!menuRef.current.contains(e.target)) {
        menuRef.current.style.display = 'none';
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  const handleClose = () => {
    menuRef.current.style.display = 'none';
  };

  const IconWithText = ({ text, iconName }) => {
    return (
      <Box position="relative" display="inline-block">
        {iconName}
        <Typography
          variant="body1"
          component="span"
          sx={{
            top: '50%',
            left: '50%',
            width: '100%',
            color: 'white',
            fontSize: '0.7rem',
            textAlign: 'center',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {text}
        </Typography>
      </Box>
    );
  };

  const deleteOrderNote = () => {
    axios({
      url: `${ORDER_URL}${id}/notes/${orderNoteData.id}`,
      method: 'DELETE',
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        if ('data' in res) {
          setUserAdded(!userAdded);
          handleSuccess(TOAST_TYPE.SUCCESS, 'Order Note successfully deleted');
          setOrderNoteData(null);
          // if (url.includes(prod)) {
          if (url.includes(process.env.REACT_APP_DEV)) {
            window.analytics.track('Order Note Deleted');
          }
        }
      })
      .catch((err) => {
        handleError(err);
      });
    toggleOrderNoteMarkerVisible(false);
    setOrderNoteLayerVisible(false);
    setOrderNoteTitle('');
    setOrderNoteDescription('');
    handleClose();
  };

  const addOrderNote = (e) => {
    e.preventDefault();
    var arr = '';
    if (orderNoteData.hasOwnProperty('id')) {
      let formData = new FormData();
      formData.append('title', orderNoteTitle);
      formData.append('description', orderNoteDescription);
      if (attachments)
        formData.append('attachments', JSON.stringify(attachments));
      if (userToBeAssigned) {
        arr += `${String(userToBeAssigned.id)}`;
        formData.append('user_ids', arr);
      }
      axios({
        url: `${ORDER_URL}${id}/notes/${orderNoteData.id}`,
        method: 'PUT',
        data: formData,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => {
          if ('data' in res) {
            setUserAdded(!userAdded);
            handleSuccess(
              TOAST_TYPE.SUCCESS,
              'Order Note successfully updated'
            );
            // if (url.includes(prod)) {
            if (url.includes(process.env.REACT_APP_DEV)) {
              window.analytics.track('Order Note Updated', {
                order_note_id: orderNoteData.id,
                creator: orderNoteData.created_by,
              });
            }
          }
        })
        .catch((err) => {
          handleError(err);
        });
    } else {
      let formData = new FormData();
      formData.append('title', orderNoteTitle);
      formData.append('description', orderNoteDescription);
      formData.append('lat', orderNoteData.lat);
      formData.append('long', orderNoteData.long);
      if (attachments)
        formData.append('attachments', JSON.stringify(attachments));
      if (userToBeAssigned) {
        arr += `${String(userToBeAssigned.id)}`;
        formData.append('user_ids', arr);
      }
      axios({
        url: `${ORDER_URL}${id}/notes`,
        method: 'POST',
        data: formData,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => {
          if ('data' in res) {
            setUserAdded(!userAdded);
            handleSuccess(TOAST_TYPE.SUCCESS, 'Order Note successfully added');
            // if (url.includes(prod)) {
            if (url.includes(process.env.REACT_APP_DEV)) {
              window.analytics.track('Order Note Created', {
                order_note_id: orderNoteData.id,
                creator: orderNoteData.created_by,
              });
            }
          }
        })
        .catch((err) => {
          handleError(err);
        });
    }
    toggleOrderNoteMarkerVisible(false);
    // setOrderNoteLayerVisible(false);
    toggleMapClickable(false);
    // setOrderNoteTitle('');
    // setOrderNoteDescription('');
  };

  const setData = (e) => {
    let element = document.getElementById('attachment-loader');
    element.classList.remove('attachment-loader-hide');
    element.classList.add('attachment-loader-show');
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    axios({
      url: `${LOGIN_URL}file/upload?folder=notes`,
      method: 'POST',
      data: formData,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        if ('data' in res) {
          if (!attachments) setAttachments([res.data.file_url]);
          else setAttachments([...attachments, res.data.file_url]);
          handleSuccess(TOAST_TYPE.SUCCESS, 'File added successfully');
          element.classList.remove('attachment-loader-show');
          element.classList.add('attachment-loader-hide');
        }
      })
      .catch((err) => {
        handleError(err);
        element.classList.remove('attachment-loader-show');
        element.classList.add('attachment-loader-hide');
      });
  };

  useEffect(() => {
    usersDetails.map((item, index) => {
      users.push({
        id: item.id,
        display: item.name,
      });
    });
    setOrderNoteTitle(orderNoteData.title);
    setOrderNoteDescription(orderNoteData.description);
    if (orderNoteData.user_ids) {
      const obj = usersDetails.filter(
        (x) => x.id === orderNoteData.user_ids[0]
      );
      if (obj.length) setUserAssigned({ id: obj[0].id, name: obj[0].name });
    } else setUserAssigned();
    setAttachments(orderNoteData.attachments);
    if (!orderNoteData.hasOwnProperty('id')) {
      setAddingOrderNote(true);
      setOrderNoteEditable(true);
    } else {
      setAddingOrderNote(false);
      setOrderNoteEditable(false);
    }
  }, [orderNoteData]);

  useEffect(() => {
    if (!open) setOrderNoteData(null);
  }, [open])

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onOpen={toggleDrawer(true)}
      onClose={toggleDrawer(false)}
    >
      <div className="mobile-order-note-container">
        <div className="mobile-order-note-head">
          <button className="close-feature-mobile-button" onClick={onCloseClick}>
            <KeyboardArrowDownIcon />
          </button>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button
              className="ordernote-submit-button"
              style={{ marginRight: '0.5em' }}
              type="submit"
              disabled={!orderNoteEditable}
              onClick={addOrderNote}
            >
              Submit
            </button>
            {orderNoteData.created_by &&
              userInfo.email === orderNoteData.created_by.email ? (
              <div>
                {!addingOrderNote && (
                  <MoreVertIcon
                    color="success"
                    className="more-vert-icon"
                    onClick={() => (menuRef.current.style.display = 'block')}
                  />
                )}
                <div ref={menuRef} className="monf-edit-delete-menu">
                  <MenuItem
                    className="menu-cont-users-item"
                    onClick={editOrderNote}
                    sx={{
                      zIndex: 999,
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    className="menu-cont-users-item"
                    onClick={deleteOrderNote}
                  >
                    Delete
                  </MenuItem>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="order-notes-list-item-top">
          <div className="notes-list-item-top-one">
            <Avatar
              sx={{
                width: '25px',
                height: '25px',
                fontSize: '0.9rem',
                bgcolor: `#1B9C85`,
              }}
            >
              {orderNoteData.created_by.name.charAt(0).toUpperCase()}
            </Avatar>
            <div>{orderNoteData.created_by.name}</div>
          </div>
          <div className="notes-list-item-top-two">
            <IconWithText
              text={orderNoteKey ? orderNoteKey : "-"}
              iconName={
                <NoteIcon
                  sx={{
                    color: `#1B9C85`,
                  }}
                />
              }
            />
          </div>
        </div>
        <div className="mobile-ordernote-infowindow-container">
          <div className="ordernote-title-input-container">
            <div className="ordernote-title-input">
              <input
                type="text"
                placeholder="Title"
                required
                style={
                  orderNoteEditable
                    ? { background: '#FFFFFF' }
                    : { background: '#E7FFD8', border: '1px solid #E7FFD8' }
                }
                disabled={!orderNoteEditable}
                defaultValue={orderNoteTitle}
                onChange={(e) => setOrderNoteTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="ordernote-description-input-container">
            <div className="ordernote-description-input">
              <MentionsInput
                style={mentionsInputStyles}
                value={orderNoteDescription}
                onChange={(e) => setOrderNoteDescription(e.target.value)}
                type="text"
                className="orderNote-mention-cont"
                placeholder="Description"
                disabled={!orderNoteEditable}
              >
                <Mention
                  style={mentionStyles}
                  trigger="@"
                  data={users}
                  displayTransform={(id, display) => `@${display}`}
                  onAdd={tagUser}
                />
              </MentionsInput>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0.4em 0',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {userToBeAssigned ? (
                    <span style={{ fontSize: '0.6em', marginLeft: '0.5em' }}>
                      Assign to{' '}
                      {userToBeAssigned
                        ? userToBeAssigned.id === userInfo.id
                          ? 'You'
                          : userToBeAssigned.name
                        : ''}
                    </span>
                  ) : userAssigned ? (
                    <span style={{ fontSize: '0.6em', marginLeft: '0.5em' }}>
                      Assigned to{' '}
                      {userAssigned.id === userInfo.id
                        ? 'You'
                        : userAssigned.name}
                    </span>
                  ) : mentionedUsers.length ? (
                    <span style={{ fontSize: '0.6em', marginLeft: '0.5em' }}>
                      Assign to{' '}
                      {userToBeAssigned
                        ? userToBeAssigned.id === userInfo.id
                          ? 'You'
                          : userToBeAssigned.name
                        : ''}
                    </span>
                  ) : null}
                  {userInfo.email === orderNoteData.created_by.email &&
                    (mentionedUsers.length || userAssigned) &&
                    orderNoteEditable ? (
                    <KeyboardArrowDownIcon
                      onClick={(e) => setMentionUserAnchor(e.currentTarget)}
                    />
                  ) : null}
                </div>
              </div>
              <Menu
                className="menu-cont-tag-users"
                id="basic-menu-assign"
                anchorEl={mentionUserAnchor}
                open={mentionUserAchorOpen}
                onClose={handleMentionUserAnchorClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {mentionedUsers.map((item, key) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        setUserToBeAssigned(item);
                        handleMentionUserAnchorClose();
                      }}
                      className="menu-cont-mention-users-item"
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'start',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div style={{ marginLeft: '0.3em' }}>
                          <div>{item.name}</div>
                          <div>{item.email}</div>
                        </div>
                      </div>
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          </div>
          <div className="mobile-order-note-actions-cont">
            <label for="imgupload">
              <ImageIcon
                style={
                  orderNoteEditable
                    ? { color: 'green', cursor: 'pointer' }
                    : { color: 'grey' }
                }
              />
              <input
                type="file"
                disabled={!orderNoteEditable}
                id="imgupload"
                style={{ opacity: "0" }}
                accept="image/*"
                onChange={setData}
              />
            </label>
            <label htmlFor="vidupload">
              <VideocamIcon
                style={
                  orderNoteEditable
                    ? { color: 'green', cursor: 'pointer' }
                    : { color: 'grey' }
                }
              />
              <input
                type="file"
                disabled={!orderNoteEditable}
                id="vidupload"
                style={{ opacity: "0" }}
                accept="video/*"
                onChange={setData}
              />
            </label>
            <label htmlFor="attachmentupload">
              <AttachmentIcon
                style={
                  orderNoteEditable
                    ? { color: 'green', cursor: 'pointer' }
                    : { color: 'grey' }
                }
              />
              <input
                type="file"
                disabled={!orderNoteEditable}
                id="attachmentupload"
                style={{ opacity: "0" }}
                onChange={setData}
              />
            </label>
          </div>
          <div style={{ marginTop: "0.3em" }} className="attachments-cont">
            {attachments &&
              Array.isArray(attachments) &&
              attachments.map((item, key) => {
                return (
                  <div
                    key={key}
                    onClick={() => handleOpenDialog(item)}
                    className="attachment-item-cont"
                  >
                    {orderNoteEditable && (
                      <CloseIcon
                        className="delete-attachment"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteAttachment(item);
                        }}
                      />
                    )}
                    <FileIcon url={item} />
                  </div>
                );
              })}
            <div id="attachment-loader" className="attachment-loader-hide">
              <CircularProgress color="success" />
            </div>
          </div>
        </div>
        <MediaDialog
          dialogMedia={dialogMedia}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
        <div className='mobile-order-note-comment-cont'>
          {orderNoteData.hasOwnProperty('id') && (
            <OrderNotesCommentSection
              orderNoteData={orderNoteData}
              usersDetails={usersDetails}
              orderNoteEditable={orderNoteEditable}
            />
          )}
        </div>
      </div>
    </Drawer>
  );
};
