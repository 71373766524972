import axios from 'axios';
import './LoginContainer.css';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { TOAST_TYPE } from '../../utils/constants';
import { useNavigate, Link } from 'react-router-dom';
import CustomButton from '../CustomButton/CustomButton';
import { useOTPContext } from '../../context/OTPContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTokenContext } from '../../context/TokenContext';
import CircularProgress from '@mui/material/CircularProgress';
import { LOGIN_URL, USER_INFO_URL } from '../../helper/ApiUrl';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField } from '@mui/material';

const inputStyle = {
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #06372a',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    '& fieldset': {
      border: 'none',
    },
  },
  '& input': {
    height: '45px',
    padding: '0px',
    paddingLeft: '10px',
    outline: 'none',
  },
};

export default function LoginContainer({ handleError, handleSuccess }) {
  const url = window.location.href;
  const [email, setEmail] = useState('');
  const prod = process.env.REACT_APP_PROD;
  const { setOtpContainer } = useOTPContext();
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginAvailable, setIsLoginAvailable] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { authToken, setAuthToken, rememberMe, setRememberMe } =
    useTokenContext();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const rememberMeHandler = () => {
    setRememberMe(!rememberMe);
  };

  const navigate = useNavigate();

  const handleLoginFormSubmit = (e) => {
    e.preventDefault();
    if (!email.length || !password.length) {
      handleSuccess(
        TOAST_TYPE.WARNING,
        `${!email.length ? 'Email' : 'Password'} is required.`
      );
      return;
    }
    setIsLoginAvailable(false);
    let formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    axios({
      url: LOGIN_URL,
      method: 'POST',
      data: formData,
    })
      .then((res) => {
        if (!res.data.success) {
          setOtpContainer(false);
          navigate('/signup');
          setIsLoginAvailable(true);
        } else {
          setAuthToken(res.data.data.token);
          localStorage.setItem('authToken', res.data.data.token);
          setIsLoggedIn(true);
          // if (url.includes(prod)) {
          if (url.includes(process.env.REACT_APP_DEV)) {
            window.analytics.identify(email, {
              email,
            });
            window.analytics.track('User Logged In');
          }
        }
      })
      .catch((err) => {
        // setLoginButton(false);
        handleError(err);
        setIsLoginAvailable(true);
      });
  };

  const getUserInfo = () => {
    axios
      .get(USER_INFO_URL, {
        headers: {
          Authorization: `Token ${authToken}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.data.data.organization === null) {
          localStorage.setItem('role', response.data.data.role);
          localStorage.setItem('authToken', authToken);
          navigate('/organization');
        } else {
          localStorage.setItem('role', response.data.data.role);
          if (rememberMe) {
            localStorage.setItem('authToken', authToken);
          } else {
            sessionStorage.setItem('authToken', authToken);
          }
          if (response.data.data.role === 'otter_user')
            navigate('/upload/order');
          else navigate('/home');
          window.location.reload(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 402) {
          navigate('/account-suspension');
        } else {
          handleError(error);
        }
      })
      .finally(() => setIsLoginAvailable(true));
  };

  if (isLoggedIn) {
    getUserInfo();
  }

  const LoginButton = () => (
    <Button
      onClick={handleLoginFormSubmit}
      variant="contained"
      className="login-btn"
      id="login-button"
      disabled={!isLoginAvailable}
    >
      {isLoginAvailable ? (
        'Login'
      ) : (
        <CircularProgress size={20} style={{ color: '#ffffff' }} />
      )}
    </Button>
  );

  return (
    <div className="login-container">
      <form>
        <h2 className="login-title">Login</h2>
        <div className="email-field">
          {/* <label>Phone no./Email ID</label> */}
          <TextField
              required
              margin="dense"
              variant="outlined"
            type="email"
            value={email}

            // id="outlined-required"
            onChange={emailHandler}
            // placeholder="Phone no./Email ID"
            label="Phone no./Email ID"
          />
        </div>
        <div className="password-field">
          {/* <label>Password</label> */}
          <TextField
            required
            margin="dense"
            variant="outlined"
            // sx={inputStyle}
            value={password}
            // placeholder="Password"
            label="password"
            // id="passowrd-description-login"
            onChange={passwordHandler}
            type={passwordVisible ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility}>
                    {passwordVisible ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="remember-forgot-container">
          {/* <div className="remember-container">
            <input
              type="checkbox"
              value={rememberMe}
              onChange={rememberMeHandler}
              className="remember-me-checkbox"
            />
            <p>Remember me</p>
          </div> */}
          <div className="forgot-container">
            <Link to="/forgot-password" className="forgot-password">
              Forgot password?
            </Link>
          </div>
        </div>
        <div className="login-btn-div">
          {/* <CustomButton
            ButtonComponent={LoginButton}
            isDisabled={isLoginAvailable}
          /> */}
          <LoginButton />
        </div>
        <div className="login-to-signup">
          <p style={{ margin: '1em 0 0.5em 0' }}>
            Don't have an Account?{' '}
            <Link to="/signup">
              <span>Sign Up</span>
            </Link>
          </p>
        </div>
        <div className="signup-to-login email-us-for-otp">
          <p>
            Having trouble logging in or signing up? Email us at{' '}
            <a href="mailto:info@ottermap.com ">
              <span>info@ottermap.com</span>
            </a>
          </p>
        </div>
      </form>
    </div>
  );
}
