import axios from "axios";
import "./FeatureManagement.css";
import { SketchPicker } from "react-color";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import AuthToken from "../../context/AuthToken";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { TOAST_TYPE } from "../../utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import { FETCH_FEATURE_URL } from "../../helper/ApiUrl";
import React, { useEffect, useRef, useState } from "react";

const FeatureManagement = ({ handleError, handleSuccess }) => {
  const token = AuthToken();
  const [size, setSize] = useState(1);
  const customFeatureInnerRef = useRef();
  const deleteFeatureInnerRef = useRef();
  const customFeatureOutterRef = useRef();
  const deleteFeatureOutterRef = useRef();
  const [creator, setCreator] = useState();
  const [active, setActive] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [featureId, setFeatureId] = useState();
  const [featureName, setFeatureName] = useState("");
  const [featureList, setFeatureList] = useState([]);
  const [featureColor, setFeatureColor] = useState();
  const [editActive, setEditActive] = useState(false);
  const [featureType, setFeatureType] = useState("line");
  const [openPalette, changeOpenPalette] = useState(false);
  const [editFeatureName, setEditFeatureName] = useState("");
  const [editFeatureType, setEditFeatureType] = useState([]);
  const [editFeatureColor, setEditFeatureColor] = useState();
  const [backgroundColor, setBackgroundColor] = useState("#FFF");
  const [openPaletteTwo, changeOpenPaletteTwo] = useState(false);

  // Select Style
  function handleFocus() {
    setSize(5);
    setFadeIn(true);
    setBackgroundColor("#FFF");
  }

  function handleBlur() {
    setSize(1);
    setFadeIn(false);
    setBackgroundColor("#FFF");
  }

  function handleChange(e) {
    setSize(1);
    setBackgroundColor("#FFF");
    document.activeElement.blur();
    setFeatureType(e.target.value);
    setEditFeatureType(e.target.value);
  }

  function handleMouseOver() {
    if (size === 1) {
      setBackgroundColor("rgb(247, 247, 247)");
    }
  }

  function handleMouseOut() {
    if (size === 1) {
      setBackgroundColor("#FFF");
    }
  }

  const getAvailableFeatures = () => {
    axios
      .get(FETCH_FEATURE_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        setFeatureList(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAvailableFeatures();
  }, [active]);

  const addFeature = () => {
    if (featureName && featureColor) {
      const featureData = {
        name: featureName,
        feature_type: featureType,
        color_code: featureColor.toUpperCase(),
      };
      axios
        .post(FETCH_FEATURE_URL, featureData, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: "application/json",
          },
        })
        .then((response) => {
          setActive(!active);
          setFeatureName("");
          setFeatureColor("");
          setFeatureType("line");
          if (openPalette) {
            changeOpenPalette(!openPalette);
          }
          customFeatureOutterRef.current.style.opacity = 0;
          customFeatureOutterRef.current.style.pointerEvents = "none";
          handleSuccess(TOAST_TYPE.SUCCESS, "Feature Added Successfully!");
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      handleSuccess(TOAST_TYPE.WARNING, "Please select all given fields!");
    }
  };

  const editOtterFeature = () => {
    const data = { color_code: editFeatureColor.toUpperCase() };
    axios
      .put(`${FETCH_FEATURE_URL}/${featureId}/color`, data, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        setFeatureId("");
        setActive(!active);
        setEditActive(false);
        setEditFeatureColor("");
        handleSuccess(
          TOAST_TYPE.SUCCESS,
          "Feature Color Changed Successfully!"
        );
        if (openPaletteTwo) {
          changeOpenPaletteTwo(!openPaletteTwo);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const editUserFeature = () => {
    const featureData = {
      name: editFeatureName,
      feature_type: editFeatureType,
      color_code: editFeatureColor.toUpperCase(),
    };
    axios
      .put(`${FETCH_FEATURE_URL}/${featureId}`, featureData, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        setFeatureId("");
        setActive(!active);
        setEditActive(false);
        setEditFeatureName("");
        setEditFeatureType("");
        setEditFeatureColor("");
        if (openPaletteTwo) {
          changeOpenPaletteTwo(!openPaletteTwo);
        }
        handleSuccess(TOAST_TYPE.SUCCESS, "Feature Updated Successfully!");
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const deleteFeature = () => {
    axios
      .delete(`${FETCH_FEATURE_URL}/${featureId}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        setFeatureId("");
        setActive(!active);
        setFeatureName("");
        setFeatureColor("");
        deleteFeatureOutterRef.current.style.opacity = 0;
        deleteFeatureOutterRef.current.style.pointerEvents = "none";
        handleSuccess(TOAST_TYPE.SUCCESS, "Feature Deleted Successfully!");
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleClickOutSide = (e) => {
    if (customFeatureInnerRef.current) {
      if (!customFeatureInnerRef.current.contains(e.target)) {
        customFeatureOutterRef.current.style.opacity = 0;
        customFeatureOutterRef.current.style.pointerEvents = "none";
      }
    }
    if (deleteFeatureInnerRef.current) {
      if (!deleteFeatureInnerRef.current.contains(e.target)) {
        deleteFeatureOutterRef.current.style.opacity = 0;
        deleteFeatureOutterRef.current.style.pointerEvents = "none";
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
  }, []);

  const openColorPicker = () => {
    changeOpenPalette(!openPalette);
  };

  const openColorPickerTwo = () => {
    changeOpenPaletteTwo(!openPaletteTwo);
  };

  const handleChangeComplete = (color) => {
    setFeatureColor(color.hex);
    setEditFeatureColor(color.hex);
  };

  return (
    <div className="feature-manage-container">
      <div className="feature-manage-container-left">
        <div className="feature-manage-container-left-one">
          <h4>Property Features</h4>
        </div>
        <div className="feature-manage-container-left-two">
          <button
            className="feature-manage-container-left-two-btn"
            onClick={() => {
              customFeatureOutterRef.current.style.opacity = 1;
              customFeatureOutterRef.current.style.pointerEvents = "auto";
            }}
          >
            <AddIcon className="feature-manage-add-icon" />
            <span>Custom Feature</span>
          </button>
          <div
            ref={customFeatureOutterRef}
            className="add-custom-feature-modal-outter"
          >
            <div
              ref={customFeatureInnerRef}
              className="add-custom-feature-modal-inner"
            >

              <div className="add-custom-feature-modal-inner-top">
              <div style={{display:"flex",justifyContent:"end"}}><span style={{cursor:"pointer"}}
                  onClick={() => {
                    customFeatureOutterRef.current.style.opacity = 0;
                    customFeatureOutterRef.current.style.pointerEvents = "none";
                  }}
                >
                    <box-icon name='x'></box-icon>
                </span>
                </div>
                <h5>Add Custom Feature</h5>
                
              </div>
              <div className="add-custom-feature-modal-inner-mid">
                <div className="add-custom-feature-modal-inner-mid-left">
                  <div className="add-custom-feature-modal-inner-mid-one">
                    <label>Name</label>
                    <input
                      type="text"
                      value={featureName}
                      onChange={(e) => setFeatureName(e.target.value)}
                    />
                  </div>
                  <div className="add-custom-feature-modal-inner-mid-two">
                    <label>Select Type</label>
                    <select
                      size={size}
                      value={featureType}
                      onBlur={handleBlur}
                      onFocus={handleFocus}
                      onChange={handleChange}
                      style={{ backgroundColor }}
                      onMouseOut={handleMouseOut}
                      onMouseOver={handleMouseOver}
                      className={fadeIn ? "fadeIn" : "fadeOut"}
                    >
                      <option value="line">Line</option>
                      <option value="point">Point</option>
                      <option value="polygon">Polygon</option>
                    </select>
                  </div>
                  <div className="add-custom-feature-modal-inner-mid-three">
                    <label>Select Color</label>
                    <div
                      className="coloredDiv"
                      style={{
                        background: `${featureColor}`,
                      }}
                      onClick={openColorPicker}
                    ></div>

                    {openPalette && (
                      <div className="feature-sketch-picker-container">
                        <div className="sketch-picker">
                          <SketchPicker
                            color={featureColor}
                            onChangeComplete={handleChangeComplete}
                          />
                        </div>
                        <CloseIcon
                          onClick={openColorPicker}
                          className="sketch-picker-close-icon"
                        />
                      </div>
                    )}

                  </div>

                  <div></div>
                </div>
                <hr />
                <div className="add-custom-feature-modal-inner-mid-right">
                  <label>Preview</label>
                  <div>
                    <img
                      src={`/images/feature_type_${featureType}.png`}
                      alt="feature_type_preview"
                    />
                  </div>
                </div>
              </div>
              <div className="add-custom-feature-modal-inner-bottom">
                <button onClick={addFeature}>
                  <span>Create Feature</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="feature-manage-container-left-three">
          {featureList &&
            featureList.map((feature) => {
              return (
                <div className="feature-manage-list-item">
                  <div
                    className="feature-manage-list-item-one"
                    style={{ background: `${feature.color_code}` }}
                  ></div>
                  <div className="feature-manage-list-item-two">
                    <p>{feature.name}</p>
                  </div>
                  <div className="feature-manage-list-item-three">
                    <Tooltip title="Edit">
                      <EditIcon
                        className="feature-manage-list-icon"
                        onClick={() => {
                          setEditActive(true);
                          setFeatureId(feature.id);
                          setCreator(feature.creator);
                          setEditFeatureName(feature.name);
                          setEditFeatureColor(feature.color_code);
                          setEditFeatureType(feature.feature_type);
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      title={
                        feature.creator == "otter" ? "Not Allowed" : "Delete"
                      }
                    >
                      <DeleteIcon
                        className={
                          feature.creator == "otter"
                            ? "feature-manage-list-icon-delete-disable"
                            : "feature-manage-list-icon-delete"
                        }
                        onClick={() => {
                          if (feature.creator != "otter") {
                            setEditActive(false);
                            setFeatureId(feature.id);
                            deleteFeatureOutterRef.current.style.opacity = 1;
                            deleteFeatureOutterRef.current.style.pointerEvents =
                              "auto";
                          }
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          ref={deleteFeatureOutterRef}
          className="delete-feature-modal-outter"
        >
          <div
            ref={deleteFeatureInnerRef}
            className="delete-feature-modal-inner"
          >
            <div className="add-custom-feature-modal-inner-top" style={{textAlign:"center"}}>
            <div style={{display:"flex",justifyContent:"end"}}><span style={{cursor:"pointer"}}
                onClick={() => {
                  deleteFeatureOutterRef.current.style.opacity = 0;
                  deleteFeatureOutterRef.current.style.pointerEvents = "none";
                }}
              >
                <box-icon name='x'></box-icon>
              </span></div>
              <h5>Delete Feature</h5>
              
            </div>
            <div className="revoke-invite-inner-mid">
              <p style={{textAlign:"center"}}>
                Are you sure you want to delete this feature! This action can
                not be undone.
              </p>
            </div>
            <div className="revoke-invite-inner-bottom">
              <button
                className="resend-invite-btn"
                onClick={() => {
                  deleteFeatureOutterRef.current.style.opacity = 0;
                  deleteFeatureOutterRef.current.style.pointerEvents = "none";
                }}
              >
                Cancel
              </button>
              <button
                className="revoke-invite-btn"
                onClick={() => {
                  deleteFeature();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="feature-manage-container-right">
        {editActive ? (
          <div className="feature-manage-container-right-main">
            <div className="feature-manage-container-right-top">
              <div className="feature-manage-container-right-top-one">
                <h4>Edit Property Feature</h4>
              </div>
              <div className="feature-manage-container-right-top-two">
                <label>Name</label>
                <input
                  type="text"
                  value={editFeatureName}
                  readOnly={creator == "otter" ? true : false}
                  onChange={(e) => setEditFeatureName(e.target.value)}
                />
              </div>
              <div className="feature-manage-container-right-top-three">
                <div className="feature-manage-container-right-top-three-left">
                  <label>Change Type</label>
                  <select
                    size={size}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={editFeatureType}
                    onChange={handleChange}
                    style={{ backgroundColor }}
                    onMouseOut={handleMouseOut}
                    onMouseOver={handleMouseOver}
                    className={fadeIn ? "fadeIn" : "fadeOut"}
                    disabled={creator == "otter" ? true : false}
                  >
                    <option value="line">Line</option>
                    <option value="point">Point</option>
                    <option value="polygon">Polygon</option>
                  </select>
                </div>
                <div className="feature-manage-container-right-top-three-right">
                  <label>Change Color</label>
                  <div 
                    style={{
                      background: `${editFeatureColor}`,
                      border: `2px solid ${editFeatureColor}`,
                    }}
                    className="feature-manage-container-right-top-three-right-div  coloredDiv"
                    onClick={openColorPickerTwo}
                  ></div>

{openPaletteTwo && (
                      <div className="feature-sketch-picker-container-two">
                        <div className="sketch-picker">
                          <SketchPicker
                            color={editFeatureColor}
                            onChangeComplete={handleChangeComplete}
                          />
                        </div>
                        <CloseIcon
                          onClick={openColorPickerTwo}
                          className="sketch-picker-close-icon"
                        />
                      </div>
                    )}
                  <div>
                    
                  </div>
                </div>
              </div>
              <div className="feature-manage-container-right-top-four">
                <button
                  onClick={() => {
                    if (creator == "otter") {
                      editOtterFeature();
                    } else {
                      editUserFeature();
                    }
                  }}
                >
                  Save
                </button>
                <button onClick={() => setEditActive(false)}>Discard</button>
              </div>
            </div>
            <hr />
            <div className="feature-manage-container-right-bottom">
              <div className="feature-manage-container-right-top-one">
                <h4>Preview</h4>
              </div>
              <div className="feature-manage-container-right-bottom-two">
                <img
                  src={`/images/feature_type_${editFeatureType}.png`}
                  alt="feature_type_preview"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="feature-manage-container-right-message">
            <h5>Click on edit icon to customize your feature</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeatureManagement;
