import './ImageryDropdown.css';
import React, { useEffect, useState } from 'react';

export default function ImageryDropdown({
  nearmapSurveyData,
  setNearmapImageDate,
}) {
  const [date, setDate] = useState('');

  useEffect(() => {
    if (nearmapSurveyData.length) {
      setDate(nearmapSurveyData[0].captureDate);
      setNearmapImageDate(nearmapSurveyData[0].captureDate);
    } else setDate('satellite');
  }, [nearmapSurveyData]);

  return (
    <>
      <div className="imagery-dropdown-cont">
        <div id="select-imagery-head">Select Imagery</div>
        <select
          onChange={(e) => {
            setNearmapImageDate(e.target.value);
            setDate(e.target.value);
          }}
          className="imagery-dropdown-select"
          name="nearmap-image-date"
          id="nearmap-image-date"
          value={date}
        >
          <option value="satellite">Satellite</option>
          {nearmapSurveyData.map((item, index) => (
            <option value={item.captureDate} key={index}>
              {item.captureDate}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
