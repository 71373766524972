import path from 'path';
import React from 'react';
import "./MediaDialog.css";
import Slide from '@mui/material/Slide';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MediaDialog = ({ dialogMedia, openDialog, setOpenDialog }) => {

    const FileIcon = ({ dialogMedia }) => {
        let extname = path.extname(dialogMedia);
        let icon;
        switch (extname) {
            case '.jpg':
            case '.jpeg':
            case '.png':
            case '.gif':
                return <img className='dialog-img' src={dialogMedia} alt="image" />
            case '.mp4':
            case '.mov':
                return (
                    <video className='dialog-img' controls>
                        <source src={dialogMedia} type="video/mp4" />
                        <source src={dialogMedia} type="video/quicktime" />
                        Your browser does not support the video tag.
                    </video>
                );
            default:
                icon = faFileAlt;
                break;
        }
        return <FontAwesomeIcon style={{ width: "80%", height: "80%" }} className='dialog-img' icon={icon} />;
    };

    return (
        <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            keepMounted
            TransitionComponent={Transition}
        >
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "#519259", marginBottom: "0.5em" }}>
                <Tooltip title={path.basename(dialogMedia)}><span className='dialog-title'>{path.basename(dialogMedia)}</span></Tooltip>
                <IconButton style={{ color: "#ffffff" }} edge="end" onClick={() => setOpenDialog(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <FileIcon dialogMedia={dialogMedia} />
            </DialogContent>
        </Dialog>
    )
}

export default MediaDialog;