import {
  ORDER_URL,
  ORDER_LIST_URL,
  TEAM_DETAILS_URL,
} from '../../helper/ApiUrl';
import axios from 'axios';
import './OrdersTable.css';
import OrderTags from './OrderTags';
import Menu from '@mui/material/Menu';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Skeleton from 'react-loading-skeleton';
import { Typography, Box } from '@mui/material';
import NoteIcon from '@mui/icons-material/Note';
import AuthToken from '../../context/AuthToken';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import 'react-loading-skeleton/dist/skeleton.css';
import IconButton from '@mui/material/IconButton';
import { STATUS_VAL } from '../../utils/constants';
import { TOAST_TYPE } from '../../utils/constants';
import OrdersMobileTable from './OrdersMobileTable';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate, useLocation } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import TableContainer from '@mui/material/TableContainer';
import React, { useRef, useState, useEffect } from 'react';
import { ADD_TAG_URL, UPDATE_TAGS_URL,MOLE_ORDER_URL } from '../../helper/ApiUrl';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
// import 'boxicons'


const OrdersTable = ({
  status,
  userId,
  bulkId,
  menuBar,
  minArea,
  maxArea,
  endDate,
  setIsNot,
  addToRefs,
  startDate,
  pageCount,
  orderList,
  activeTag,
  setStatus,
  setUserId,
  setMinArea,
  setMaxArea,
  setEndDate,
  filterState,
  setMenuBar,
  selectedIds,
  handleError,
  isAllSelected,
  setStartDate,
  setActiveTag,
  setOrderList,
  criteriaName,
  handleSuccess,
  setSelectedIds,
  selectTagArray,
  setCriteriaName,
  handleUserFilter,
  handleTagsFilter,
  handleAreaFilter,
  setIsAllSelected,
  handleDateFilter,
  setSelectTagArray,
  handleStatusFilter,
  selectedStatus,
  setSelectedStatus
}) => {
  const tagRef = useRef();
  const token = AuthToken();
  const options = ['Delete'];
  const location = useLocation();
  const navigate = useNavigate();
  const suggestionRef = useRef();
  const deleteOrderRef = useRef();
  const selectTagLeftRef = useRef();
  const selectTagRightRef = useRef();
  const deleteOrderInnerRef = useRef();
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const [actionId, setActionId] = useState();
  const [allTags, setAllTags] = useState([]);
  const [tagName, setTagName] = useState('');
  const [orderId, setOrderID] = useState('');
  const [orderTags, setOrderTags] = useState([]);
  const [criteria, setCriteria] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectTag, setSelectTag] = useState(true);
  const [operation, setOperation] = useState(true);
  const [tagFilter, setTagFilter] = useState(true);
  const [actionStatus, setActionStatus] = useState();
  const [usersDetails, setUsersDetails] = useState([]);
 

  const handleClose = () => {
    setAnchorEl(null);
  };

  const finalTagArray = allTags.filter((item) => {
    if (orderTags) {
      return !orderTags.find((tag) => item.name === tag);
    } else {
      return allTags;
    }
  });

  const handleArrowClickBar = (val) => {
    if (menuBar.length) {
      if (menuBar[0] === val) {
        setMenuBar({});
      } else {
        setMenuBar([val]);
      }
    } else setMenuBar([val]);
  };

  const getAllTags = () => {
    axios
      .get(`${ADD_TAG_URL}/tags`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setAllTags(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getAllTags();
  }, [activeTag]);

  const getTagsByOrderId = () => {
    if (orderId) {
      axios
        .get(`${UPDATE_TAGS_URL}/${orderId}/tags`, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (response.data.data) {
            setOrderTags(response.data.data);
          } else {
            setOrderTags([]);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  useEffect(() => {
    getTagsByOrderId();
  }, [orderId, activeTag]);

  const addNewTag = (tagData) => {
    axios
      .post(`${ADD_TAG_URL}/tags`, tagData, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setTagName('');
        setActiveTag(!activeTag);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const updateOldTags = (tagData) => {
    axios
      .put(`${UPDATE_TAGS_URL}/${orderId}`, tagData, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setActiveTag(!activeTag);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const addTagOnClick = (tagName) => {
    const tagData = { tags: [...orderTags, tagName] };
    updateOldTags(tagData);
  };

  const addTagOnEnter = (event) => {
    const newTag = allTags.find((tag) => tag.name === tagName);
    const tagData = {
      name: tagName.toLocaleLowerCase(),
      order_id: orderId,
    };
    if (!newTag) {
      if (event.key === 'Enter') {
        addNewTag(tagData);
        // tagRef.current.style.display = 'none';
        tagRef.current.focus();
      }
    }
  };

  const removeTagOnClick = (tagName) => {
    const tagsLeft = orderTags.filter((tag) => tag !== tagName);
    const tagData1 = { tags: tagsLeft };
    const tagData2 = { tags: null };
    if (tagsLeft) {
      updateOldTags(tagData1);
    }
    if (!tagsLeft) {
      updateOldTags(tagData2);
    }
  };

  const handleFocus = () => {
    suggestionRef.current.style.display = 'block';
  };

  const handleSearch = (data) => {
    const array = data.filter((item) =>
      item.name.toLowerCase().includes(tagName)
    );
    return array;
  };

  const handleClickOutSide = (e) => {
    if (selectTagLeftRef.current && selectTagRightRef.current) {
      if (!selectTagLeftRef.current.contains(e.target)) {
        setOperation(true);
        setCriteria(true);
      } else {
        setOperation(false);
      }

      if (!selectTagRightRef.current.contains(e.target)) {
        setSelectTag(true);
        setTagFilter(true);
      } else {
        setSelectTag(false);
      }
    } else {
      setOperation(true);
      setSelectTag(true);
    }
    if (tagRef.current) {
      if (!tagRef.current.contains(e.target)) {
        tagRef.current.style.display = 'none';
        suggestionRef.current.style.display = 'none';
      }
    }
    if (deleteOrderInnerRef.current) {
      if (!deleteOrderInnerRef.current.contains(e.target)) {
        deleteOrderRef.current.style.opacity = 0;
        deleteOrderRef.current.style.pointerEvents = 'none';
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  const handleSelctedTags = (tagName) => {
    if (selectTagArray.includes(tagName)) {
      setSelectTagArray(selectTagArray.filter((item) => item !== tagName));
    } else {
      setSelectTagArray([...selectTagArray, tagName]);
    }
  };

  const getUsersDetails = () => {
    axios
      .get(TEAM_DETAILS_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setUsersDetails(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getUsersDetails();
  }, []);

  const deleteDraftOrder = () => {
    console.log(actionId,"actionId ")
    axios
      .delete(`${ORDER_URL}${actionId}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setMenuBar({});
        setActiveTag(!activeTag);
        handleSuccess(TOAST_TYPE.SUCCESS, 'Order Deleted Successfully!');
      })
      .catch((error) => {
        console.log(error.message," error message ")
        console.error(error.message);
      });
  };
 

  const fetchMoreData = () => {
    axios
      .get(`${ORDER_LIST_URL}?p=${pageNumber}&psz=10${filterState}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setOrderList([...orderList, ...response.data.data]);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate('/access-denied');
        } else {
          console.error(error.message);
        }
      });
  };

  useEffect(() => {
    if (pageNumber > 1) fetchMoreData();
  }, [pageNumber]);


  const handleAllCheckboxChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      selectedIds = [];
      orderList.map((item) => selectedIds.push(item.id));
      setSelectedIds([...selectedIds]);
      let statusArray = []
      orderList.map((item) => statusArray.push(item.status));
      setSelectedStatus([...statusArray]);
    } else {
      setSelectedIds([]);
      setSelectedStatus([]);
    }
    if (isAllSelected !== undefined) {
      setIsAllSelected(false);
    }
  };

  const handleSingleCheckboxChange = (e, id, stat) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedIds([...selectedIds, id]);
      setSelectedStatus([...selectedStatus, stat]);
    } else {
      const newList = selectedIds.filter((item) => item !== id);
      setSelectedIds(newList);
      const newStatus = status.filter((item) => item !== stat);
      setSelectedStatus(newStatus);
    }
  };

  // const formatDate = () => {
  //   // Get the date input value
  //   var inputDate = document.getElementById('dateInput').value;

  //   // Create a Date object from the input value
  //   var dateObject = new Date(inputDate);

  //   // Get the components of the date
  //   var month = dateObject.getMonth() + 1; // Months are zero-based
  //   var day = dateObject.getDate();
  //   var year = dateObject.getFullYear();

  //   // Format the date as mm-dd-yyyy
  //   var formattedDate =
  //     (month < 10 ? '0' : '') +
  //     month +
  //     '-' +
  //     (day < 10 ? '0' : '') +
  //     day +
  //     '-' +
  //     year;

  //   // Set the formatted date back to the input field
  //   document.getElementById('dateInput').value = formattedDate;
  // };




  const IconWithText = ({ text, iconName }) => {
    return (
      <Box position="relative" display="inline-block">
        {iconName}
        <Typography
          variant="body1"
          component="span"
          sx={{
            top: '50%',
            left: '50%',
            width: '100%',
            color: 'white',
            fontSize: '0.7rem',
            textAlign: 'center',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {text}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <OrderTags
        tagRef={tagRef}
        orderTags={orderTags}
        removeTagOnClick={removeTagOnClick}
        suggestionRef={suggestionRef}
        handleFocus={handleFocus}
        tagName={tagName}
        addTagOnEnter={addTagOnEnter}
        setTagName={setTagName}
        finalTagArray={finalTagArray}
        handleSearch={handleSearch}
        addTagOnClick={addTagOnClick}
        allTags={allTags}
      />
      <TableContainer className="order-table-container">
        <Table>
          <TableHead>
            <TableRow
              sx={{
                display: ' grid',
                gridTemplateColumns:
                  '3% 8% 25.4% 7.6% 13.4% 12.7% 13.5% 11.4% 5%',
                padding: '0 0.5rem',
              }}
            >
              <TableCell className="order-cell-heading">
                <Tooltip title="Select All" placement="top">
                  <input
                    type="checkbox"
                    checked={
                      selectedIds?.length === orderList?.length &&
                        orderList?.length !== 0
                        ? true
                        : false
                    }
                    onChange={(e) => handleAllCheckboxChange(e)}
                  />
                </Tooltip>
              </TableCell>
              <TableCell className="order-cell-heading">Order Id</TableCell>
              <TableCell className="order-cell-heading">
                <div>
                  <span>Address</span>
                </div>
              </TableCell>
              <TableCell className="order-cell-heading" ref={addToRefs}>
                <div
                  className="order-cell-heading-div"
                  onClick={() => handleArrowClickBar('TAGS_FILTER')}
                >
                  <span>Tags</span>
                  <FilterAltIcon className="order-filter-icon" />
                </div>
                {menuBar[0] === 'TAGS_FILTER' ? (
                  <div className="tags-filter-div">
                    <h3>Filter</h3>
                    <div className="tags-filter-div-inner">
                      <div
                        className="tags-filter-div-inner-left"
                        ref={selectTagLeftRef}
                      >
                        <div
                          className={
                            operation || criteria
                              ? 'select-operation-btn'
                              : 'select-operation-btn open'
                          }
                          onClick={() => {
                            if (!operation) setCriteria(!criteria);
                          }}
                        >
                          <span className="btn-text">{criteriaName}</span>
                          <span className="arrow-dwn">
                            <KeyboardArrowDownIcon className="order-filter-icon" />
                          </span>
                        </div>
                        <ul className="tags-operation-list-items">
                          <li
                            className="tag-item"
                            onClick={() => {
                              setIsNot('in');
                              setOperation(true);
                              setCriteriaName('Is');
                            }}
                          >
                            <span className="tag-item-text">Is</span>
                          </li>
                          <li
                            className="tag-item"
                            onClick={() => {
                              setIsNot('not_in');
                              setOperation(true);
                              setCriteriaName('Is Not');
                            }}
                          >
                            <span className="tag-item-text">Is Not</span>
                          </li>
                        </ul>
                      </div>
                      <div
                        className="tags-filter-div-inner-right"
                        ref={selectTagRightRef}
                      >
                        <LocalOfferOutlinedIcon className="tag-icon" />
                        <div
                          className={
                            selectTag || tagFilter
                              ? 'select-btn'
                              : 'select-btn open'
                          }
                          onClick={() => {
                            if (!selectTag) setTagFilter(!tagFilter);
                          }}
                        >
                          <span className="btn-text">Select Tag</span>
                          <span className="arrow-dwn">
                            <KeyboardArrowDownIcon className="order-filter-icon" />
                          </span>
                        </div>
                        <ul className="tags-list-items">
                          {allTags &&
                            allTags.map((tag, index) => {
                              return (
                                <li
                                  key={tag.id}
                                  value={tag.name}
                                  className="tag-item"
                                  onClick={(e) => handleSelctedTags(tag.name)}
                                >
                                  <span className="tag-checkbox">
                                    <input
                                      type="checkbox"
                                      checked={
                                        selectTagArray.includes(tag.name)
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        handleSelctedTags(tag.name)
                                      }
                                      className="tag-item-checkbox"
                                    />
                                  </span>
                                  <span className="tag-item-text">
                                    {tag.name}
                                  </span>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className="tags-filter-div-two">
                      <button
                        onClick={() => {
                          if (selectTagArray.length > 0) {
                            handleTagsFilter();
                            handleArrowClickBar('TAGS_FILTER');
                          } else {
                            handleSuccess(
                              TOAST_TYPE.WARNING,
                              'Please select one of the following options!'
                            );
                          }
                        }}
                      >
                        ✔ Apply
                      </button>
                    </div>
                  </div>
                ) : null}
              </TableCell>
              <TableCell className="order-cell-heading" ref={addToRefs}>
                <div
                  className="order-cell-heading-div"
                  onClick={() => handleArrowClickBar('AREA_DROPDOWN')}
                >
                  <span>Area (acres)</span>
                  {menuBar[0] === 'AREA_DROPDOWN' ? (
                    <KeyboardArrowUpIcon className="order-filter-icon" />
                  ) : (
                    <KeyboardArrowDownIcon className="order-filter-icon" />
                  )}
                </div>
                {menuBar[0] === 'AREA_DROPDOWN' ? (
                  <div className="order-area-filter">
                    <h4>Area</h4>
                    <div className="order-area-filter-val">
                      <input
                        type="text"
                        value={minArea}
                        onChange={(e) => setMinArea(e.target.value)}
                      />
                      <span>acres</span>
                    </div>
                    <span>to</span>
                    <div className="order-area-filter-val">
                      <input
                        type="text"
                        value={maxArea}
                        onChange={(e) => setMaxArea(e.target.value)}
                      />
                      <span>acres</span>
                    </div>
                    <hr />
                    <button
                      onClick={() => {
                        if (minArea && maxArea) {
                          handleAreaFilter();
                        } else {
                          handleSuccess(
                            TOAST_TYPE.WARNING,
                            'Please fill minimum and maximum area!'
                          );
                        }
                      }}
                    >
                      ✔ Apply
                    </button>
                  </div>
                ) : null}
              </TableCell>
              <TableCell className="order-cell-heading" ref={addToRefs}>
                <div
                  className="order-cell-heading-div"
                  onClick={() => handleArrowClickBar('NAME_DROPDOWN')}
                >
                  <span>Created By</span>
                  {menuBar[0] === 'NAME_DROPDOWN' ? (
                    <KeyboardArrowUpIcon className="order-filter-icon" />
                  ) : (
                    <KeyboardArrowDownIcon className="order-filter-icon" />
                  )}
                </div>
                {menuBar[0] === 'NAME_DROPDOWN' ? (
                  <div className="order-status-filter order-name-filter-main">
                    <h4 style={{margin: "10px"}}>User Name</h4>
                    <div className="filter-by-user-div">
                      {usersDetails &&
                        usersDetails.map((user) => {
                          return (
                            <div
                              className="order-user-filter-one"
                              onClick={() => {
                                if (userId.includes(`${user.id}`)) {
                                  setUserId(
                                    userId.filter((e) => e !== `${user.id}`)
                                  );
                                } else {
                                  setUserId([...userId, `${user.id}`]);
                                }
                              }}
                            >
                              <input
                                checked={ userId.includes(`${user.id}`) ? true : false}
                                style={{width: "16px", height: "16px"}}
                                name={user.id}
                                type="checkbox"
                                onChange={(e) => {
                                  if (userId.includes(`${user.id}`)) {
                                    setUserId(
                                      userId.filter((e) => e !== `${user.id}`)
                                    );
                                  } else {
                                    setUserId([...userId, `${user.id}`]);
                                  }
                                }}
                              />
                              <span>{user.name}</span>
                            </div>
                          );
                        })}
                    </div>
                    <hr />
                    <div style={{margin: "10px"}}>
                    <button
                      onClick={() => {
                        if (userId.length > 0) {
                          handleUserFilter();
                        } else {
                          handleSuccess(
                            TOAST_TYPE.WARNING,
                            'Please select one of the following options!'
                          );
                        }
                      }}
                    >
                      ✔ Apply
                    </button>
                    </div>
                  </div>
                ) : null}
              </TableCell>
              <TableCell className="order-cell-heading" ref={addToRefs}>
                <div
                  className="order-cell-heading-div"
                  onClick={() => handleArrowClickBar('DATE_DROPDOWN')}
                >
                  <span id="CreatedOn" > Created On.</span>
                  {menuBar[0] === 'DATE_DROPDOWN' ? (
                    <KeyboardArrowUpIcon className="order-filter-icon" />
                  ) : (
                    <KeyboardArrowDownIcon className="order-filter-icon" />
                  )}
                </div>
                {menuBar[0] === 'DATE_DROPDOWN' ? (
                  <div className="order-date-filter">
                    <h4>Date</h4>
                    <div className="order-date-filter-one">
                      <span>From</span>
                      <input
                        type="date"
                        id="dateInput"
                        value={startDate.startDate}
                        onChange={(event) =>
                          setStartDate({ startDate: event.target.value })
                        }
                      />
                    </div>
                    <div className="order-date-filter-two">
                      <span>To</span>
                      <input
                        type="date"
                        id="dateInput"
                        value={endDate.endDate}
                        onChange={(event) =>
                          setEndDate({ endDate: event.target.value })
                        }
                      />
                    </div>
                    <hr />
                    <button
                      onClick={() => {
                        if (startDate.startDate && endDate.endDate) {
                          handleDateFilter();
                        } else {
                          handleSuccess(
                            TOAST_TYPE.WARNING,
                            'Please select a proper date!'
                          );
                        }
                      }}
                    >
                      ✔ Apply
                    </button>
                  </div>
                ) : null}
              </TableCell>
              <TableCell className="order-cell-heading" ref={addToRefs}>
                <div
                  className="order-cell-heading-div"
                  onClick={() => handleArrowClickBar('STATUS_DROPDOWN')}
                >
                  <span> Status</span>
                  {menuBar[0] === 'STATUS_DROPDOWN' ? (
                    <KeyboardArrowUpIcon className="order-filter-icon" />
                  ) : (
                    <KeyboardArrowDownIcon className="order-filter-icon" />
                  )}
                </div>
                {menuBar[0] === 'STATUS_DROPDOWN' ? (
                  <div className="order-status-filter">
                    <h4>Property Status</h4>
                    <div
                      className="order-status-filter-one"
                      onClick={() => {
                        if (status.includes('draft')) {
                          setStatus(status.filter((e) => e !== 'draft'));
                        } else {
                          setStatus([...status, 'draft']);
                        }
                      }}
                    >
                      <input
                        name="draft"
                        type="checkbox"
                        style={{width: "16px", height: "16px"}}
                        checked={status.includes('draft') ? true : false}
                        onChange={() => {
                          if (status.includes('draft')) {
                            setStatus(status.filter((e) => e !== 'draft'));
                          } else {
                            setStatus([...status, 'draft']);
                          }
                        }}
                      />
                      <span>Draft</span>
                    </div>
                    <div
                      className="order-status-filter-two"
                      onClick={() => {
                        if (status.includes('in_progress')) {
                          setStatus(status.filter((e) => e !== 'in_progress'));
                        } else {
                          setStatus([...status, 'in_progress']);
                        }
                      }}
                    >
                      <input
                        type="checkbox"
                        name="in_progress"
                        style={{width: "16px", height: "16px"}}
                        checked={status.includes('in_progress') ? true : false}
                        onChange={() => {
                          if (status.includes('in_progress')) {
                            setStatus(
                              status.filter((e) => e !== 'in_progress')
                            );
                          } else {
                            setStatus([...status, 'in_progress']);
                          }
                        }}
                      />
                      <span>Inprogress</span>
                    </div>
                    <div
                      className="order-status-filter-three"
                      onClick={() => {
                        if (status.includes('measurement_completed')) {
                          setStatus(
                            status.filter((e) => e !== 'measurement_completed')
                          );
                        } else {
                          setStatus([...status, 'measurement_completed']);
                        }
                      }}
                    >
                      <input
                        type="checkbox"
                        name="measurement_completed"
                        style={{width: "16px", height: "16px"}}
                        checked={
                          status.includes('measurement_completed')
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          if (status.includes('measurement_completed')) {
                            setStatus(
                              status.filter(
                                (e) => e !== 'measurement_completed'
                              )
                            );
                          } else {
                            setStatus([...status, 'measurement_completed']);
                          }
                        }}
                      />
                      <span>Complete</span>
                    </div>
                    <div
                      className="order-status-filter-three"
                      onClick={() => {
                        if (status.includes('diy')) {
                          setStatus(status.filter((e) => e !== 'diy'));
                        } else {
                          setStatus([...status, 'diy']);
                        }
                      }}
                    >
                      <input
                        type="checkbox"
                        name="diy"
                        style={{width: "16px", height: "16px"}}
                        checked={status.includes('diy') ? true : false}
                        onChange={(e) => {
                          if (status.includes('diy')) {
                            setStatus(status.filter((e) => e !== 'diy'));
                          } else {
                            setStatus([...status, 'diy']);
                          }
                        }}
                      />
                      <span>DIY</span>
                    </div>
                    <hr />
                    <button
                      onClick={() => {
                        if (status.length > 0) {
                          handleStatusFilter();
                        } else {
                          handleSuccess(
                            TOAST_TYPE.WARNING,
                            'Please select one of the following options!'
                          );
                        }
                      }}
                    >
                      ✔ Apply
                    </button>
                  </div>
                ) : null}
              </TableCell>
              <TableCell className="order-cell-heading" ref={addToRefs}>
                <div>{/* <span>Action</span> */}</div>
                {/* <div ref={deleteOrderRef} className="no-layers-info-container">
                  <div
                    ref={deleteOrderInnerRef}
                    className="delete-layer-inner-modal"
                  >
                    <div className="view-modal-top">
                      <span
                        onClick={() => {
                          deleteOrderRef.current.style.opacity = 0;
                          deleteOrderRef.current.style.pointerEvents = 'none';
                        }}
                      >
                        X
                      </span>
                    </div>
                    <div className="no-layer-mid-container">
                      <p>
                        Are you sure? You want to delete this order! This action
                        can't be undone.
                      </p>
                    </div>
                    <div className="view-modal-bttns">
                      <button
                        onClick={() => {
                          deleteOrderRef.current.style.opacity = 0;
                          deleteOrderRef.current.style.pointerEvents = 'none';
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          deleteDraftOrder();
                          deleteOrderRef.current.style.opacity = 0;
                          deleteOrderRef.current.style.pointerEvents = 'none';
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div> */}
                <div ref={deleteOrderRef} className="no-layers-info-container">
                  <div
                    ref={deleteOrderInnerRef}
                    className="wrapperPopUpBox" style={{display:"block"}}
                  >
                    <div className="CloseIconPopUpBox">
                      <span
                        onClick={() => {
                          deleteOrderRef.current.style.opacity = 0;
                          deleteOrderRef.current.style.pointerEvents = 'none';
                        }}
                      >
                      <box-icon name='x'></box-icon>
                      </span>
                    </div>
                    <div className="ImgBoxPopUpBox">
                      <box-icon name='error-alt' type='solid' flip='horizontal' animation='tada' color='#edba83' size="lg"></box-icon>
                       </div>
                    <div className="Remove_margins_of_p">
                      <p> Are you sure?</p> 
                       <p> You want to delete this order!</p>
                       <p>This action can't be undone.</p>

                    </div>
                    <div className="view-modal-bttn "  id="view__model__BTNS">
                      <button className="cancel"
                        onClick={() => {
                          deleteOrderRef.current.style.opacity = 0;
                          deleteOrderRef.current.style.pointerEvents = 'none';
                        }}
                      >
                        Cancel
                      </button>
                      <button className="delete" id="DeleteBtn"
                        onClick={() => {
                          deleteDraftOrder();
                          deleteOrderRef.current.style.opacity = 0;
                          deleteOrderRef.current.style.pointerEvents = 'none';
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <div
          id="yourTableContainerId"
          style={{
            height: '93%',
            overflowY: 'scroll',
          }}
        >
          {!orderList ? (
            <Skeleton
              count={50}
              style={{
                marginBottom: '33px',
              }}
            />
          ) : orderList.length < 1 ? (
            <div className="alternate-table-container">
              <h2>No property found :(</h2>
              {location?.pathname?.includes('order') && (
                <button onClick={() => navigate('/order')}>Place Order</button>
              )}
            </div>
          ) : (
            orderList.map((order) => {
              return (
                <a
                  key={order.id}
                  target="_blank"
                  className="order-details-anchor"
                  href={`/order/${order.order_id}`}
                  style={
                    selectedIds?.includes(order.id)
                      ? { background: '#EEEEEE' }
                      : undefined
                  }
                >
                  <div key={order.id} className="order-details-bulk">
                    <div className="order-cell">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          handleSingleCheckboxChange(e, order.id, order.status)
                        }
                        checked={selectedIds?.includes(order.id) ? true : false}
                      />
                    </div>
                    <div className="order-cell">{order.id}</div>
                    <div className="order-cell">
                      <div className="order-address-div">
                        <div className="order-address-div-one">
                          <div className="address-div-one-top">
                            <Tooltip title={order.address} placement="top">
                              {order.address.length < 30 ? (
                                <p>{order.address}</p>
                              ) : (
                                <p>{order.address.slice(0, 30)}...</p>
                              )}
                            </Tooltip>
                          </div>
                          <div className="address-div-one-bottom">
                            {order.tags &&
                              order.tags.map((tag, index) => {
                                if (index < 3) {
                                  return (
                                    <span
                                      key={index}
                                      style={{
                                        fontWeight: 600,
                                        color: '#06372a',
                                        marginTop: '5px',
                                        background: '#f9f490',
                                      }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setActiveTag(!activeTag);
                                        setOrderID(order.order_id);
                                        tagRef.current.style.display = 'block';
                                      }}
                                    >
                                      {tag}
                                    </span>
                                  );
                                }
                              })}
                            {order.tags && order.tags.length > 3 ? (
                              <div
                                className="extra-tags-count"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setActiveTag(!activeTag);
                                  setOrderID(order.order_id);
                                  tagRef.current.style.display = 'block';
                                }}
                              >
                                +{order.tags.length - 3}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="order-address-div-two">
                          {order.notes > 0 ? (
                            <IconWithText
                              text={order.notes}
                              iconName={
                                <NoteIcon
                                  sx={{
                                    color: `#1B9C85`,
                                  }}
                                />
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="order-cell">
                      <Tooltip
                        title="Add Tags"
                        placement="right"
                        onClick={(e) => {
                          e.preventDefault();
                          setOrderID(order.order_id);
                          tagRef.current.style.display = 'block';
                        }}
                      >
                        <LocalOfferOutlinedIcon className="tag-icon" />
                      </Tooltip>
                    </div>
                    <div className="order-cell">{order.area}</div>
                    <div className="order-cell">{order.creator}</div>
                    <div className="order-cell">
                      {order.created_at.slice(0, 10)}
                    </div>
                    <div
                      style={{ textAlign: 'left' }}
                      className={
                        STATUS_VAL[order.status] === 'DRAFT'
                          ? 'draft-color order-cell'
                          : STATUS_VAL[order.status] === 'IN PROGRESS'
                            ? 'inprogress-color order-cell'
                            : STATUS_VAL[order.status] === 'MEASUREMENT COMPLETED'
                              ? 'completed-color order-cell'
                              : STATUS_VAL[order.status] === 'DIY'
                                ? 'diy-color order-cell'
                                : 'order-cell'
                      }
                    >
                      {STATUS_VAL[order.status]}
                    </div>
                    <div className="order-cell" align="center">
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={open ? 'long-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={(e) => {
                          e.preventDefault();
                          setActionId(order.order_id);
                          setActionStatus(order.status);
                          setAnchorEl(e.currentTarget);
                        }}
                        sx={{ padding: 0, margin: 0 }}
                      >
                        <MoreVertIcon sx={{ padding: 0, margin: 0 }} />
                      </IconButton>
                      <Menu
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        PaperProps={{
                          sx: {
                            boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2)', // Custom box-shadow
                          },
                        }}
                      >
                        {options.map((option) => (
                          <MenuItem
                            disabled={actionStatus === 'draft' || actionStatus === 'diy' ? false : true}
                            key={option}
                            onClick={() => {
                              handleClose();
                              if (actionStatus === 'draft' || actionStatus === 'diy') {
                               
                                deleteOrderRef.current.style.opacity = 1;
                                deleteOrderRef.current.style.pointerEvents =
                                  'auto';
                               document.querySelector(".wrapperPopUpBox").style.display = "block";
                               document.querySelector(".wrapperPopUpBox").style.display="block"
                              // document.querySelector(".wrapperPopUpBox").style.background="green"

                              } else {
                                handleSuccess(
                                  TOAST_TYPE.WARNING,
                                  'You can only delete draft Orders!'
                                );
                              }
                            }}
                            className="order-action-menu-item"
                          >
                            {option}
                          </MenuItem>
                        ))}

                     
                      </Menu>
                    </div>
                  </div>
                </a>
              );
            })
          )}
        </div>
      </TableContainer>
      <OrdersMobileTable
        tagRef={tagRef}
        orderTags={orderTags}
        removeTagOnClick={removeTagOnClick}
        suggestionRef={suggestionRef}
        tagName={tagName}
        addTagOnEnter={addTagOnEnter}
        setTagName={setTagName}
        finalTagArray={finalTagArray}
        handleSearch={handleSearch}
        addTagOnClick={addTagOnClick}
        allTags={allTags}
        orderList={orderList}
        usersDetails={usersDetails}
        userId={userId}
        setUserId={setUserId}
        handleUserFilter={handleUserFilter}
        setMinArea={setMinArea}
        setMaxArea={setMaxArea}
        handleAreaFilter={handleAreaFilter}
        setPageNumber={setPageNumber}
        pageNumber={pageNumber}
        pageCount={pageCount}
        setActionId={setActionId}
        setActionStatus={setActionStatus}
        options={options}
        actionStatus={actionStatus}
        handleSuccess={handleSuccess}
        setOrderID={setOrderID}
        activeTag={activeTag}
        setActiveTag={setActiveTag}
        IconWithText={IconWithText}
        status={status}
        setStatus={setStatus}
        deleteDraftOrder={deleteDraftOrder}
        handleStatusFilter={handleStatusFilter}
      />
    </>
  );
};

export default OrdersTable;
