import { Point } from "ol/geom";
import { Style } from "ol/style";
import Icon from "ol/style/Icon";
import Feature from "ol/Feature";
import { toLonLat } from "ol/proj";
import { unByKey } from "ol/Observable";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import orderNoteMarkerIcon from "../../assets/orderNotesMarker.svg";
import { useEffect } from "react";


const AddingOrderNoteOnMap = (mapClickable, element, map, setMapKey, tempOrderNoteRef, setOrderNoteData, mapKey, userInfo) => {
    if (mapClickable) {
        element.classList.add("cursor-change-order-note");
        let key = map.on("click", function (e) {
            map.getLayers().forEach((layer) => {
                if (
                    layer &&
                    layer.get("title") &&
                    layer.get("title") === "TempOrderNote"
                ) {
                    map.removeLayer(layer);
                }
            });
            var feature = map.forEachFeatureAtPixel(e.pixel, function (feature) {
                return feature;
            });
            if (!feature || (feature.get("geometry") && !feature.get("title"))) {
                const iconFeature = new Feature({
                    geometry: new Point(e.coordinate),
                    id: 10,
                });
                const iconStyle = new Style({
                    image: new Icon({
                        anchor: [0.5, 20],
                        anchorXUnits: "fraction",
                        anchorYUnits: "pixels",
                        src: orderNoteMarkerIcon,
                    }),
                });
                iconFeature.setStyle(iconStyle);
                const coordinate = e.coordinate;
                setOrderNoteData({
                    title: "",
                    description: "",
                    created_by: { name: userInfo.name, email: userInfo.email },
                    long: toLonLat(coordinate)[0],
                    lat: toLonLat(coordinate)[1],
                    attachements: [],
                    meta_data: { color:" " ,fontSize:" ", Qdescription: " "},
                  
                    // metaData: [ ],
                    

                    // color: " ",  //HA
                    // fontSize:" " //HA
                });
                const vecSource = new VectorSource({
                    features: [iconFeature],
                });
                const vectorLayer = new VectorLayer({
                    source: vecSource,
                });
                tempOrderNoteRef.current.getLayers().push(vectorLayer);
                map.addLayer(tempOrderNoteRef.current);
            }
        });
        setMapKey(key);
    } else {
        if (map)
            map.getLayers().forEach((layer) => {
                if (
                    layer &&
                    layer.get("title") &&
                    layer.get("title") === "TempOrderNote"
                ) {
                    map.removeLayer(layer);
                }
            });
        element.classList.remove("cursor-change-order-note");
        unByKey(mapKey);
    }
}

export default AddingOrderNoteOnMap;