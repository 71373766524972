import axios from "axios";
import AuthToken from "../../context/AuthToken";
import { ORDER_URL } from "../../helper/ApiUrl";
import { TOAST_TYPE } from "../../utils/constants";

export default function MoveFeature(id, map, viewId, targetLayerId, features, sourceLayerId, handleSuccess, handleError, uploadedLayer, setUploadedLayer, setMultipleContextInteraction, setMultipleSelectInteraction, isContextInteraction) {
    const token = AuthToken();
    let featureArr = []
    features.map((item, index) => {
        featureArr.push(item.get('id'));
    })
    let data = {}
    data = { layer_ids: featureArr };
    axios({
        url: `${ORDER_URL}${id}/view/${viewId}/layer/${sourceLayerId}/layer-data/move/${targetLayerId}`,
        method: "PUT",
        data: data,
        headers: {
            Authorization: `Token ${token}`,
            Accept: "application/json",
        },
    })
        .then((response) => {
            let itemToBeMoved = [];
            uploadedLayer.map((layer) => {
                if (layer.id === sourceLayerId)
                    layer.data.map(function (item) {
                        if (featureArr.includes(item.id)) itemToBeMoved.push(item);
                    })
            });
            uploadedLayer.map((layer) => {
                if (layer.id === sourceLayerId) {
                    layer.data = layer.data.filter(
                        (feature) => feature != itemToBeMoved.find(obj => obj.id === feature.id)
                    );
                }
            });
            setUploadedLayer([...uploadedLayer]);
            if (itemToBeMoved)
                uploadedLayer.map((layer) => {
                    if (layer.id === parseInt(targetLayerId)) layer.data.push(...itemToBeMoved);
                });
            setUploadedLayer([...uploadedLayer]);
            isContextInteraction(true);
            setMultipleSelectInteraction(false);
            setMultipleContextInteraction(false);
            map.removeOverlay(map.getOverlayById(3));
            map.removeOverlay(map.getOverlayById(4));
            handleSuccess(TOAST_TYPE.SUCCESS, "Feature is moved");
        })
        .catch((error) => {
            handleError(error);
        });
}