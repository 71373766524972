import React from "react";
import axios from "axios";
import "./FeedbackModal.css";

import { useParams } from "react-router-dom";
import AuthToken from "../../context/AuthToken";
import { ORDER_URL } from "../../helper/ApiUrl";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { TOAST_TYPE, FEEDBACK_STATUS } from "../../utils/constants";

// For Upload Attachment
import { Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFilePowerpoint,
} from "@fortawesome/free-regular-svg-icons";
import path from "path";


export default function FeedbackModal({
  mapClickable,
  feedbackLayer,
  closeDiyTool,
  setFeedbackModalShow,
  setFeedbackMarker,
  setFeedbackLayerVisible,
  setFeedbackAdded,
  toggleFeedbackMarkerVisible,
  setAllFeedbackResolved,
  toggleMapClickable,
  handleSuccess,
  handleError,
  setFeedbackEditable,
}) {
  const params = useParams();
  const role = localStorage.getItem("role");

  const addMoreFeedback = () => {
    toggleMapClickable(true);
  };


  // FileIcon
  
  const FileIcon = ({ url }) => {
    let extname = path.extname(url);
    let icon;
    switch (extname) {
      case ".pdf":
        icon = faFilePdf;
        break;
      case ".doc":
      case ".docx":
        icon = faFileWord;
        break;
      case ".ppt":
      case ".pptx":
        icon = faFilePowerpoint;
        break;
      case ".xls":
      case ".xlsx":
        icon = faFileExcel;
        break;
      case ".jpg":
      case ".jpeg":
      case ".png":
      case ".gif":
        return <img src={url} alt={url} />;
      case ".mp3":
      case ".wav":
        icon = faFileAudio;
        break;
      case ".mp4":
      case ".mov":
        icon = faFileVideo;
        break;
      default:
        icon = faFileAlt;
        break;
    }
    return <FontAwesomeIcon className="doc-type-attachment-icon" icon={icon} />;
  };


  // FileIcon



  const closeModal = () => {
    closeDiyTool();
    toggleMapClickable(false);
    setFeedbackModalShow(false);
    setFeedbackLayerVisible(false);
    setFeedbackAdded(false);
    toggleFeedbackMarkerVisible(false);
  };

  const resolveAllFeedbacks = () => {
    axios({
      url: `${ORDER_URL}${params.id}/feedback/resolve`,
      method: "PUT",
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if ("data" in res) {
          setAllFeedbackResolved(true);
          setFeedbackLayerVisible(false);
          handleSuccess(TOAST_TYPE.SUCCESS, "Feedbacks marked as resolved");
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const actionFeedback = (action, id) => {
    let formData = new FormData();
    formData.append("status", action);
    axios({
      url: `${ORDER_URL}${params.id}/feedback/${id}/status`,
      method: "PUT",
      data: formData,
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if ("data" in res) {
          setAllFeedbackResolved(true);
          setFeedbackLayerVisible(false);
          handleSuccess(TOAST_TYPE.SUCCESS, `Feedback is ${action}ed`);
        }
      })
      .catch((err) => {
        // handleError(err);
      });
  };

  return (
    <div className="feedback-main-container">
      <div>
        <div className="feedback-main-head">
          {role !== "otter_user" ? (
            <div className="feedback-modal-head">
              <span>Raise Feedback/issues</span>
              <CloseIcon style={{ cursor: "pointer" }} onClick={closeModal} />
            </div>
          ) : null}
          <div className="feedback-modal-head-2">(in the map)</div>
          <div className="legend-opened" style={{ marginTop: "0.5rem" }}>
            <div style={{ background: "red" }}></div>Open feedback
          </div>
          <div className="legend-approved">
            <div style={{ background: "green" }}></div>Approved feedback
          </div>
          <div className="legend-resolved">
            <div style={{ background: "yellow" }}></div>Resolved feedback
          </div>
        </div>
        <div className="feedback-list-container">
          {feedbackLayer &&
            feedbackLayer.map((item, key) => {
              return (
                <div>
                  <div className="feedback" key={key}>
                    <div
                      className="feedback-id-container"
                      onClick={() => {
                        setFeedbackMarker(item);
                        toggleFeedbackMarkerVisible(true);
                      }}
                    >
                      <div className="feedback-id">
                        <div
                          style={
                            item.status === FEEDBACK_STATUS.OPEN ||
                              item.status === FEEDBACK_STATUS.REOPEN
                              ? { background: "red" }
                              : item.status === FEEDBACK_STATUS.APPROVE
                                ? { background: "green" }
                                : { background: "yellow", color: "black" }
                          }
                        >
                          {key + 1}
                        </div>{" "}
                        Feedback
                      </div>
                      <div className="feedback-id-container-two">
                        {item.status === FEEDBACK_STATUS.RESOLVED && (
                          <div
                            onClick={() =>
                              actionFeedback(FEEDBACK_STATUS.REOPEN, item.id)
                            }
                            className="resolve-button"
                          >
                            {/* <EditIcon className="feedback-reopen-icon" /> */}
                            Reopen
                          </div>
                        )}
                        {item.status === FEEDBACK_STATUS.OPEN ||
                          item.status === FEEDBACK_STATUS.REOPEN ||
                          item.status === FEEDBACK_STATUS.RESOLVED ? (
                          <div
                            style={
                              role === "otter_user" ? { display: "none" } : null
                            }
                            onClick={() =>
                              actionFeedback(FEEDBACK_STATUS.APPROVE, item.id)
                            }
                            className="resolve-button"
                          >
                            {/* <DoneIcon className="feedback-approve-icon" /> */}
                            Approve
                          </div>
                        ) : null}
                      </div>
                      {/* {item.status === FEEDBACK_STATUS.OPEN ||
                      item.status === FEEDBACK_STATUS.REOPEN ? (
                        <div
                          className="resolve-button"
                          onClick={() => {
                            setFeedbackMarker(item);
                            toggleFeedbackMarkerVisible(true);
                            setFeedbackEditable(true);
                          }}
                        >
                          <EditIcon className="edit-icon" />
                          Edit
                        </div>
                      ) : null} */}
                    </div>
                    <div className="title-input-container">
                      <div className="title-label">Title:</div>
                      <div className="title-input">
                        <input type="text" disabled value={item.title} />
                      </div>
                    </div>
                    <div className="description-input-container">
                      <div className="description-label">Description:</div>
                      <div className="description-input">
                        <textarea rows="3" disabled value={item.description} />
                      </div>
                    </div>
                    {/* show  attachment  */}
                    <div className="attachments-cont">
                    {item.attachments &&
                      Array.isArray(item.attachments) &&
                      item.attachments.map((attachment, key) => {
                        return (
                          <Tooltip title={path.basename(attachment)}>
                            <div key={key} className="attachment-item-cont">
                              <FileIcon
                                className="attachment-item"
                                url={attachment}
                              />
                            </div>
                          </Tooltip>
                        );
                      })}
                  </div>

                    {/* show  attachment  */}

                  </div>
                  <hr
                    style={{ borderTop: "1px dashed rgba(81, 146, 89, 0.5)" }}
                  />
                </div>
              );
            })}
        </div>
        {role !== "otter_user" ? (
          <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div onClick={addMoreFeedback} className={`add-feedback-button ${mapClickable ? "add-note-clicked" : ""}`}>
                <AddCircleOutlineIcon className="icon" />
                <span style={{ textAlign: "center" }}>Add</span>
              </div>
              {/* <hr style={{ borderTop: "1px solid rgba(81, 146, 89, 0.5)" }} /> */}
              <div
                onClick={resolveAllFeedbacks}
                className="feedback-resolved-button"
              >
                <DoneIcon className="icon" />
                <span style={{ textAlign: "center" }}>
                  Approve all
                </span>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
