import React, { useState, useEffect } from 'react';
import './FolderFile.css';
import axios from 'axios';
import { FILE_UPLOAD, MOLE_ORDER_FILE_FOLDER } from '../../helper/ApiUrl';
import AuthToken from '../../context/AuthToken';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './MyDocuments.css';
import './FolderFiles.css';
import InfoBox from './InfoBox';

import {} from '../../../src/App'
import { useNavigate, useParams } from 'react-router-dom';
import ToggleButtons from './ToggleButton';
import ImageView from './Imageview';
// import {handleSuccess, handleError} from '../../App'
import { TOAST_TYPE } from '../../utils/constants';
import PopUpBox from '../../components/PopUpBox/PopUpBox';
import { ToastContainer, toast } from 'react-toastify';



const RoutingFileExplorer = (
  // { data, setSelectedImage, setCurrentView }
  {data ,handleSuccess,handleError,FilesAndFolder}

) => {


  // console.log(data,"----",setSelectedImage,"-----",setCurrentView)
  const [currentFolder, setCurrentFolder] = useState(null);
  const [bulkOrderId, setBulkOrderId] = useState(null);
  const [folderData, setFolderData] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [layout, setLayout] = useState('list-view');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectedImageName, setSelectedImageName] = useState([]);
  const [all_URLS, setAll_URLS] = useState([]);
 
  const [isDownloading, setIsDownloading] = useState(false);
  const [isOpenZipDialog, setOpenZipDialog] = useState(false);
  const token = AuthToken();
const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false);

   // for show images preview 
   const [currentView, setCurrentView] = useState('home');
   const [selectedFolder, setSelectedFolder] = useState(null);
   const [selectedImage, setSelectedImage] = useState(null);
   const [folderName, setFolderName] = useState(null);
 


  const {folderId} = useParams()

  const handleCloseImage = () => {
    setSelectedImage(null);
    setCurrentView('folder');
  };

  const fetchData = async () => {
    console.log('called')
    try {
      const response = await axios.get(`${MOLE_ORDER_FILE_FOLDER}?bulk_oid=${folderId}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      });
      setFolderData(response.data.data);
      setDocuments(response.data.data.meta_data);
      setAll_URLS(response.data.data.meta_data.file_url)

      console.error("response.data.data:", response.data.data,"response.data.data.meta_data",response.data.data.meta_data,"response.data.data.meta_data.file_url,",response.data.data.meta_data.file_url);
    } catch (error) {
      console.error("Error fetching folder data:", error);
    }
  };
  useEffect(() => {
    if (folderId !== null) {
      // const fetchData = async () => {
      //   try {
      //     const response = await axios.get(`${MOLE_ORDER_FILE_FOLDER}?bulk_oid=${bulkOrderId}`, {
      //       headers: {
      //         Authorization: `Token ${token}`,
      //         Accept: 'application/json',
      //       },
      //     });
      //     setFolderData(response.data.data);
      //     setDocuments(response.data.data.meta_data);
      //     setAll_URLS(response.data.data.meta_data.file_url)
      //   } catch (error) {
      //     console.error("Error fetching folder data:", error);
      //   }
      // };
      fetchData();
    }
  }, [folderId]);

  useEffect(() => {
    console.log(documents," useEffect documents")
    const results = documents.filter(doc =>
      doc.file_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDocuments(results)
    // if(results){
    //   setFilteredData(results)
    // }
    ;
  }, [searchTerm, documents]);
//  const  setFilteredData=(results)=>{
//   console.log(documents," useEffect---------- documents")

//   setFilteredDocuments(results)
//  }


  const handleFolderClick = (folder) => {

    setBulkOrderId(parseInt(folder.folder_name));
    setCurrentFolder(folder);
    navigate(`/mydocs/${folder.folder_name}`)
  };

  const handleBackClick = () => {
    setCurrentFolder(null);
    setFolderData(null);
    setBulkOrderId(null);
    setSelectedItems([]);
    setSelectedFolders([])
    navigate('/mydocs')
    setLayout('list-view')
    
  };

  const previewImagehandler = (file_URL) => {
    setCurrentView('image');
    setSelectedImage(file_URL);
  };

  const handleSelectedImageNameAndDelete = (file_name) => {
    setSelectedImageName((prevSelected) =>
      prevSelected.includes(file_name)
        ? prevSelected.filter((name) => name !== file_name)
        : [...prevSelected, file_name]
    );
    // Using a callback to ensure the state is updated before deleting
    setTimeout(() => {
      handleDelete();
    }, 0);
  };

  const handleDelete = async (filename) => {
   console.log("delete clicked") 
   const filenameArr = []
   filenameArr.push(filename)

    try {
      if(filename){
        console.log(filename,"selectedImageName")
      await axios.delete(`${MOLE_ORDER_FILE_FOLDER}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
        data: { 
          bulk_oid:folderId,
          file_keys:filenameArr 
         },
      });
      // setDocuments(documents.filter(doc => !selectedItems.includes(doc.file_url)));
      fetchData()
      setSelectedItems([]);
      setSelectedImageName([])
      handleSuccess(TOAST_TYPE.SUCCESS,'Image Deleted Successfully!')
    }
    } catch (error) {
      // handleError(error);
      console.error("Error deleting files:", error);
      handleError(error)
    }
  };
  let selectedImagesforDelete =[]
  let handleSelectItemArr =[]
  // const handleSelectedImageName = (file_name) => {
  //   if (!selectedImagesforDelete.includes(file_name)) {
  //     selectedImagesforDelete.push(file_name);
  //   } else {
  //     console.log(selectedImagesforDelete, "this else selectedImagesforDelete");
  //     selectedImagesforDelete = selectedImagesforDelete.filter(
  //       (name) => name !== file_name
  //     );
  //   }
  //   // console.log(selectedImagesforDelete, "selectedImagesforDelete  Image Name array");
  //   console.log(selectedImagesforDelete, "Image Name array");
  // };

  const handleSelectItemArrfunc = (file_URL) => {
    if (!handleSelectItemArr.includes(file_URL)) {
      handleSelectItemArr.push(file_URL);
    } else {
      console.log(handleSelectItemArr, "this else handleSelectItemArr ");
      handleSelectItemArr = handleSelectItemArr.filter(
        (name) => name !== file_URL
      );
    }
    // console.log(handleSelectItemArr, "handleSelectItemArr selectedImagesforDelete ");
    console.log(handleSelectItemArr, "Image Name URl array");

  

    // setSelectedItems((prevSelected) =>
    //   prevSelected.includes(file_URL)
    //     ? prevSelected.filter((url) => url !== file_URL)
    //     : [...prevSelected, file_URL]
    // );
    // console.log(selectedItems, " selectedItems ---selectedImagesforDelete");
  };

  // const handleMultipleDelete = async () => {
   
  //   try {
  //     if(selectedImagesforDelete.length > 0){
  //       console.log(selectedImagesforDelete,"selectedImageName")
  //     await axios.delete(`${MOLE_ORDER_FILE_FOLDER}`, {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //       data: { 
  //         bulk_oid:folderId,
  //         file_keys:selectedImagesforDelete 
  //        },
  //     });
  //     // setDocuments(documents.filter(doc => !selectedItems.includes(doc.file_url)));
  //     fetchData()
  //     setSelectedItems([]);
  //     setSelectedImageName([])
  //     handleSuccess(TOAST_TYPE.SUCCESS,'Images Deleted Successfully!')
  //     selectedImagesforDelete=[]
  //     console.log( selectedImagesforDelete," selectedImagesforDelete=[]")
  //   }
  //   } catch (error) {
  //     // handleError(error)
  //     console.error("Error deleting files:", error);
  //   }
  // };
  const handleMultipleDelete = async () => {
   
    try {
      // if(selectedImagesforDelete.length > 0){
        console.log(selectedImagesforDelete,"selectedImageName")
      await axios.delete(`${MOLE_ORDER_FILE_FOLDER}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
        data: { 
          bulk_oid:folderId,
          file_keys:selectedImageName 
         },
      });
      // setDocuments(documents.filter(doc => !selectedItems.includes(doc.file_url)));
      fetchData()
      setSelectedItems([]);
      setSelectedImageName([])
      handleSuccess(TOAST_TYPE.SUCCESS,'Images Deleted Successfully!')
      selectedImagesforDelete=[]
      console.log( selectedImagesforDelete," selectedImagesforDelete=[]")
    // }
    } catch (error) {
      handleError(error)
      console.error("Error deleting files:", error);
    }
  };
  const handleLeftBtnClick=()=>{
    document.querySelector('.wrapperPopUpBox').style.display="none"
  }
  const handleDeleteFolderOpen=()=>{
     document.querySelector('.wrapperPopUpBox').style.display="block"
  }

  const handleDeleteFolder = async (folderID) => {
    const data = selectedFolders
    let FolderIDD = parseInt(folderID)
    console.log(folderID,"folderID")
    console.log(folderName,"folderName")
    
    try {
      await axios.delete(`${MOLE_ORDER_FILE_FOLDER}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
        //  data:{ bulk_oids:[FolderIDD]}
         data:{ bulk_oids:[folderName]}
        // data: { file_urls: selectedItems },
      });
      // setDocuments(documents.filter(doc => !selectedItems.includes(doc.file_url)));
      // setDocuments(documents.filter(doc => !selectedItems.includes(doc.file_url)));
     
      console.log(folderName,"folderName ye chal rha ");
      
      document.querySelector('.wrapperPopUpBox').style.display="none";
      toast.success('Folder Deleted Successfully!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        
        });
      FilesAndFolder()
      // handleSuccess(TOAST_TYPE.SUCCESS,'Images Deleted Successfully!')
      setSelectedFolders([])
      setSelectedItems([]);
      setSelectedImageName([])
      
      setFolderName(null)
     
      
    } catch (error) {
      console.error("Error deleting Folders:", error);

      // handleError(error)
    }
  };

  const handleInfo = (file_URL) => {
    console.log("File info for:", file_URL);
  };

  const handleSelectItem = (file_URL) => {
    setSelectedItems(prevSelected =>
      prevSelected.includes(file_URL)
        ? prevSelected.filter(url => url !== file_URL)
        : [...prevSelected, file_URL]
    );
  };
  const handleSelectedImageName = (file_name) => {
   
    setSelectedImageName(prevSelected =>
      prevSelected.includes(file_name)
        ? prevSelected.filter(url => url !== file_name)
        : [...prevSelected, file_name]
    );
  };
  const handleSelectFolder = (file_URL) => {
    setSelectedFolders(prevSelected =>
      prevSelected.includes(file_URL)
        ? prevSelected.filter(url => url !== file_URL)
        : [...prevSelected, file_URL]
    );
  };
 

  const handleDownloadImage = (file_URL) => {
    const downloadURL = selectedImage || file_URL ;

    if (!downloadURL) {
      console.error("No file URL or selected image available for download.");
      return;
    }
    console.log(selectedImage,"selectedImageURL",downloadURL,"downloadURL")
  
    console.log(downloadURL, "downloadURL");
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = downloadURL.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleSuccess(TOAST_TYPE.SUCCESS,'Image Downloaded Successfully!')


    // console.log(file_URL,"file_URL")
    // const link = document.createElement('a');
    // link.href = file_URL;
    // link.download = file_URL.split('/').pop();
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };

  const handleDownloadZip = async () => {
    console.log(selectedItems,"selectedItems")
    if (selectedItems.length === 0) {
      alert("Please select at least one file to download.");
      return;
    }
    setIsDownloading(true);
    try {
      const zip = new JSZip();
      const files = await Promise.all(selectedItems.map(async (url) => {
        const response = await axios.get(url, { responseType: 'blob' });
        return { name: url.split('/').pop(), content: response.data };
      }));
      files.forEach(file => zip.file(file.name, file.content));
      const blob = await zip.generateAsync({ type: 'blob' });
      saveAs(blob,`${folderId}`);
    } catch (error) {
      console.error("Error creating zip file:", error);
    } finally {
      setIsDownloading(false);
    }
  };

 // Ensure you have these states
const [progress, setProgress] = useState(0);
const [isZipping, setIsZipping] = useState(false);

const handleDownloadZips = async () => {
  setIsZipping(true);
  setProgress(0); 
  console.log(documents,"documents------------------")

  try {
    const zip = new JSZip();
    
    const files = await Promise.all(documents.map(async (doc) => {
      const response = await axios.get(doc.file_url, { responseType: 'blob' });
      return { name: doc.file_name, content: response.data };
    }));

    files.forEach(file => zip.file(file.name, file.content));
    
    const blob = await zip.generateAsync({ type: 'blob' }, (metadata) => {
      setProgress(metadata.percent); 
    });
    
    saveAs(blob, `${folderId}`);
    // saveAs(blob, `${currentFolder.folder_name}`);
    
  } catch (error) {
    console.error("Error creating zip file:", error);
    
  } finally {
    setIsZipping(false); 
    setProgress(0); 
  }
};
  return (
    <>
    <div style={{ width: "100%",height:"100%" }}
     className={`${folderId ? "my-documents-content-cont" : ""}`}
    >

    <ToggleButtons />
      {folderData ? (
        <div style={{ width: "100%" ,boxSizing:"border-box"}}
         className='documents__container'
         >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <button
                onClick={handleBackClick}
                style={{ background: "#519259", color: "white", borderRadius: "5px", padding: "0.4rem 0.6rem", border: "none", cursor: "pointer" }}
              >
                Back
              </button>
              { selectedItems.length > 0? 
                
                <button
                    onClick={handleDownloadZip}
                    
                    style={{ background: "#519259", color: "white", borderRadius: "5px", padding: "0.4rem 0.6rem", border: "none", cursor:"pointer", marginLeft: "10px" }}
                  >
                    {isDownloading ? "Downloading..." : "Multiple Downloads"}
                </button>
                :
                <button
                    onClick={handleDownloadZips}
                    
                    style={{ background: "#519259", color: "white", borderRadius: "5px", padding: "0.4rem 0.6rem", border: "none", cursor:"pointer", marginLeft: "10px" }}
                  >
                    {isDownloading ? "Downloading..." : "Download as Zip"}
                </button>
                }
                {/* --------- */}
                
                {/* --------- */}
              {selectedItems.length > 0 && (
                <>
                  {/* <button
                    onClick={handleDownloadZip}
                    disabled={isDownloading}
                    style={{ background: "#519259", color: "white", borderRadius: "5px", padding: "0.4rem 0.6rem", border: "none", cursor: isDownloading ? "not-allowed" : "pointer", marginLeft: "10px" }}
                  >
                    {isDownloading ? "Downloading..." : "Download as Zip"}
                  </button> */}
                  <button
                    onClick={handleMultipleDelete}
                    style={{ background: "#edba83", color: "white", borderRadius: "5px", padding: "0.4rem 0.6rem", border: "none", cursor: "pointer", marginLeft: "10px" }}
                  >
                    Delete Selected
                  </button>
                </>
              )}
            </div>
            <div style={{border:"1px solid #519259",overflow:"hidden",borderRadius:"5px", display:"grid",gridTemplateColumns:"85% auto"}}>
              <input
                type='text'
                placeholder='Search Images'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                style={{ width: "100%", border:'none', padding: "0.5rem" }}
              />
              <div
              style={{background:"#519259",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}
              ><box-icon name='search' color='#ffffff' ></box-icon></div>
            </div>
            <div 
             className="toggle_layout_btn_container" style={{ display: "flex", overflow: "hidden",borderRadius:"5px" }}
           >
              <button
                onClick={() => setLayout('list-view')}
                style={{ backgroundColor: layout === 'list-view' ? '#519259' : 'grey', color: 'white' }}
              >
                <box-icon name='list-ul' color='#ffffff'></box-icon>
              </button>
              <button
                onClick={() => setLayout('grid-view')}
                style={{ backgroundColor: layout === 'grid-view' ? '#519259' : 'grey', color: 'white' }}
              >
                <box-icon name='grid-horizontal' color='#ffffff'></box-icon>
              </button>
            </div>
          </div>
          <div style={{display:"flex ", justifyContent:"space-between "}}><h2>Project ID: {folderData.folder_name}{folderId}</h2> <h6>Total Images: {documents.length}</h6> {console.log(currentFolder,"CurrentFolder",documents,"documents")}</div>
          
          {filteredDocuments.length > 0 ? (
            <div className={layout === 'grid-view' ? 'grid-view' : 'list-view'}>
              {filteredDocuments.map((file, index) => (
                <div className={`Wrapper__Containerr ${layout}`} key={index}
                style={{border:`${layout === 'grid-view'? 'none' :'none' }`, borderRadius:`${layout==='grid-view'?'10px':'0'}`,borderBottom:`${layout === 'grid-view'? 'none' :'1px solid' }`,padding:`${layout === 'grid-view'? '0' :'0.8rem 0' }`}}
                >
                {  layout === 'grid-view' ?
                             <div style={{height:"100%"}}>
                                <div className="grid_img_box" style={{width:"100%",cursor:"pointer",height:"70%"}} 
                                 onClick={() => previewImagehandler(file.file_url)}
                                >
                                    <img src={file.file_url} alt={file.file_name}  style={{objectFit:"cover"}}/>
                                </div>
                                <div className="file__name_title" style={{height:"30%"}}>
                                    <h4>{file.file_name}</h4>
                                </div>
                            </div>
                : <>
                <div className='checkbox_select'>
                    <input
                      type='checkbox'
                       checked={
                        selectedItems.includes(file.file_url) && 
                        selectedImageName.includes(file.file_name)
                       }
                      onChange={() => {
                       handleSelectItem(file.file_url)
                       handleSelectedImageName(file.file_name)
                      //  handleSelectItemArrfunc(file.file_url)
                      //  handleSelectedImageName(file.file_name)
                      // handleSelectedImageNameAndDelete(file.file_name);
                      }
                      }
                    />
                  </div>
                  <div className="namediv" onClick={() => previewImagehandler(file.file_url)}>{file.file_name}</div>
                  <div className="downloaddiv checkboxdiv" title="Download" onClick={() => handleDownloadImage(file.file_url)}>
                    <box-icon type='solid' name='download'></box-icon>
                  </div>
                  <div className="previewdiv checkboxdiv" title="Preview" onClick={() =>
                  { previewImagehandler(file.file_url)
                    setSelectedImage(file.file_url)
                    
                  }
                  }>
                    <box-icon name='show-alt'></box-icon>
                  </div>
                  <div className="previewdiv checkboxdiv" title="Delete" onClick={() =>{
                    // handleSelectItem(file.file_name)
                    // handleSelectedImageName(file.file_name)
                    handleDelete(file.file_name)
                    // handleSelectedImageNameAndDelete(file.file_name);
  
                  } }>
                    <box-icon type='solid' name='trash-alt'></box-icon>
                  </div>
                </>

                }
                  {/* <div className='checkbox_select'>
                    <input
                      type='checkbox'
                      checked={selectedItems.includes(file.file_url)}
                      onChange={() => handleSelectItem(file.file_url)}
                    />
                  </div>
                  <div className="namediv">{file.file_name}</div>
                  <div className="downloaddiv checkboxdiv" title="Download" onClick={() => handleDownloadImage(file.file_url)}>
                    <box-icon type='solid' name='download'></box-icon>
                  </div>
                  <div className="previewdiv checkboxdiv" title="Preview" onClick={() => previewImagehandler(file.file_url)}>
                    <box-icon name='show-alt'></box-icon>
                  </div>
                  <div className="previewdiv checkboxdiv" title="Delete" onClick={() => handleSelectItem(file.file_url)}>
                    <box-icon type='solid' name='trash-alt'></box-icon>
                  </div> */}
                </div>
              ))}
              {isZipping && (
       <div style={{  position:"absolute"}}
       className='zipping_container'
        >     
        <div  style={{ width: "3rem", height: "3rem" , }} >
          <CircularProgressbar
            value={progress}
            text={`${Math.round(progress)}%`}
            styles={buildStyles({
              textColor: 'white',
              pathColor: 'green',
              trailColor: 'white',
            })
            
            }
          />
          
        </div>
        <p>Zipping...</p>

        </div>
      )}
            </div>
          ) : (
            <p>No images found</p>
          )}
        </div>
      ) : (
        <div style={{ width: "100%" }} 
        className='documents__container'
        >
          
          {data?.meta_data?.map((item, index) => (
            <div key={index} className='Wrapper__Containerr'>
              {item.file_type === 'folder' ? (
                <div className={`namediv`} style={{ display: "grid", gridTemplateColumns: "95% auto auto" }}>
                  <div onClick={() => handleFolderClick(item)} style={{ display: "grid", gridTemplateColumns: "auto 90%", alignItems: "center" }}>
                    <box-icon name='folder' type='solid' color='#f9b400' size='md'></box-icon>
                    <div>Project ID: {item.folder_name}</div>
                  </div>
                  <div className="downloaddiv checkboxdiv" title="Delete" onClick={() =>{
                     
                     handleSelectFolder(item.folder_name)
                    //  handleDeleteFolder(item.folder_name)
                     handleDeleteFolderOpen(item.folder_name)
                     setFolderName(item.folder_name)
                     }
                  }>
                    <box-icon type='solid' name='trash-alt'></box-icon>
                  </div>
                  {/* <div className="downloaddiv checkboxdiv" title="Info" onClick={() => handleInfo(item.file_url)}>
                    <box-icon type='solid' name='info-circle'></box-icon>
                    <div>
      <button style={{cursor:"pointer",background:"none",border:"none"}} onClick={() => setShowModal(true)}><box-icon type='solid' name='info-circle'></box-icon></button>
      {showModal && <InfoBox folderInfo={{folder_name:item.folder_name,folder_size:item.folder_size}}  onClose={() => setShowModal(false)} />}
    </div>
                  </div> */}
                </div>
              ) : (
                <div className="Wrapper__Container">
                  <div className="namediv">{item.file_name}</div>
                  <div className="downloaddiv checkboxdiv" title="Download" onClick={() => handleDownloadImage(item.file_url)}>
                    <box-icon type='solid' name='download'></box-icon>
                  </div>
                  <div className="previewdiv checkboxdiv" title="Preview" onClick={() => previewImagehandler(item.file_url)}>
                    <box-icon name='show-alt'></box-icon>
                  </div>
                  <div className="previewdiv checkboxdiv" title="Delete" 
                  // onClick={() => handleSelectItem(item.file_url)}
                  >
                    <box-icon type='solid' name='trash-alt'></box-icon>
                  </div>
                </div>
              )}
            </div>
          ))}

          {/* {true && (
       <div style={{  position:"absolute"}}
       className='zipping_container'
        >     
        <div  style={{ width: "3rem", height: "3rem" , }} >
          <CircularProgressbar
            value={progress}
            text={`${Math.round(progress)}%`}
            styles={buildStyles({
              textColor: 'white',
              pathColor: 'green',
              trailColor: 'white',
            })
            
            }
          />
          
        </div>
        <p>Zipping...</p>

        </div>
      )} */}
        </div>
      )}
    </div>

{/* -------------- */}
{currentView === 'image' && (
       
       <ImageView
         imageSrc={selectedImage}
         onClose={handleCloseImage}
         onDownload={handleDownloadImage}
       />
     )}

     {/* Delete Modal */}
     <PopUpBox

        title="Confirmation"
        message="Delete this folder?"
        additionalMsg=""
          onRightBtnfunc={handleDeleteFolder}
         onLeftBtnfunc={handleLeftBtnClick}
        leftBtnText="Cancel"
        rightBtnText="Delete"
        iconComponent={<box-icon name="error-alt" type="solid" flip="horizontal" animation="tada" color="#edba83" size="lg"></box-icon>}
    
        
     
      />

</>
  );
};

export default RoutingFileExplorer;
