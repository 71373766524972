import {
  faFileAlt,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileImage,
  faFilePowerpoint,
} from '@fortawesome/free-regular-svg-icons';
import path from 'path';
import axios from 'axios';
import './AddAttachment.css';
import Dropzone from 'react-dropzone';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import AuthToken from '../../context/AuthToken';
import { TOAST_TYPE } from '../../utils/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/joy/CircularProgress';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FILE_UPLOAD, ORDER_URL, MOLE_ORDER_URL } from '../../helper/ApiUrl';
// import UploadICONS from "../../assets/upload-regular-96.png"
import CloudUploadGreen  from '../../assets/CloudGreenIconn.svg'
import { styled } from '@mui/system';


const AddAttachment = ({
  id,
  maxSize,
  bulkOrderId,
  attachments,
  handleError,
  handleSuccess,
  setAttachments,
  attachmentForBulk,
  handleCustomError,
  handleCustomSuccess,
}) => {
  const outerRef = useRef();
  const innerRef = useRef();
  const token = AuthToken();
  const inventoryInnerRef = useRef();
  const inventoryOuterRef = useRef();
  const fileListRef = useRef(null);
  const maxFileSize = maxSize * 1024 * 1024; // 20 MB
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isProgressive, setIsProgressive] = useState(false);
  const [scrollDirection, setScrollDirection] = useState('right');

  // for custom tooltip 
  // const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  //   [`& .${tooltipClasses.tooltip}`]: {
  //     top: 0,
  //     left: 0,
  //     transform: 'translate(-50%, -50%)',
  //   },
  // }));
  
  // for custom tooltip 


   // covert byte into MB
    function convertBytes(bytes) {
      var marker = 1024; // Change to 1000 if required
      var decimal = 2; // upto decimal point
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
    
      // return bytes if less than a KB
      if(bytes < kiloBytes) return bytes + " Bytes";
      // return KB if less than a MB
      else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
      // return MB if less than a GB
      else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
      // return GB if less than a TB
      else return(bytes / gigaBytes).toFixed(decimal) + " GB";
  }
    // covert byte into MB end

  useEffect(() => {
    const handleScroll = () => {
      const container = fileListRef.current;
      if (container) {
        const isEndReached =
          container.scrollLeft >=
          container.scrollWidth - container.clientWidth - 1;

        setScrollDirection(isEndReached ? 'left' : 'right');
      }
    };

   

    


    const container = fileListRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);

      // Cleanup event listener on component unmount
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);



  const handleUpload = () => {
    if (selectedFiles && selectedFiles.length > 0) {
      setIsProgressive(true);
      selectedFiles.map((file, index) => {
        const formData = new FormData();
        formData.append('file', file);
        axios
          .post(FILE_UPLOAD, formData, {
            headers: {
              Authorization: `Token ${token}`,
              Accept: 'application/json',
            },
          })
          .then((response) => {
            if (attachmentForBulk) {
              setAttachments((prevAttachments) => [
                ...prevAttachments,
                response.data.url,
              ]);
              setSelectedFiles([]);
              setIsProgressive(false);
              handleSuccess(TOAST_TYPE.SUCCESS, 'Files uploaded successfully.');
            } else updateAttachmentList(response.data.url);
          })
          .catch((err) => {
            if (bulkOrderId) {
              handleCustomError(err);
            } else {
             
              handleError(err);
            }
          });
      });
    } else {
      if (bulkOrderId) {
        console.log('Attachment', bulkOrderId);
        handleCustomSuccess(
          TOAST_TYPE.WARNING,
          'Please choose any file to upload!'
        );
      } else {
        handleSuccess(TOAST_TYPE.WARNING, 'Please choose any file to upload!');
      }
    }
  };

  const updateAttachmentList = (url) => {
    attachments.push(url);
    setAttachments([...attachments]);
    const data = { attachments: attachments };
    const bulkData = { attachment_link: attachments[0] };
    axios
      .put(
        `${attachmentForBulk ? MOLE_ORDER_URL : ORDER_URL}${id}`,
        attachmentForBulk ? bulkData : data,
        {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        }
      )
      .then((response) => {
        setSelectedFiles([]);
        setIsProgressive(false);
        if (bulkOrderId) {
          handleCustomSuccess(
            TOAST_TYPE.SUCCESS,
            'Files uploaded successfully.'
          );
        } else {
          handleSuccess(TOAST_TYPE.SUCCESS, 'Files uploaded successfully.');
        }
      })
      .catch((err) => {
        if (bulkOrderId) {
          handleCustomError(err);
        } else {
          handleError(err);
        }
      });
  };

  const deleteAttachment = (url) => {
    const newList = attachments.filter((attachment) => attachment !== url);
    const data = { attachments: newList };
    axios
      .put(`${attachmentForBulk ? MOLE_ORDER_URL : ORDER_URL}${id}`, data, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setAttachments(newList);
        if (bulkOrderId) {
          handleCustomSuccess(TOAST_TYPE.SUCCESS, 'File deleted successfully.');
        } else {
          handleSuccess(TOAST_TYPE.SUCCESS, 'File deleted successfully.');
        }
      })
      .catch((err) => {
        if (bulkOrderId) {
          handleCustomError(err);
        } else {
          handleError(err);
        }
      });
  };

  const FileIcon = ({ extname, size }) => {
    let icon;

    switch (extname) {
      case '.pdf':
        icon = faFilePdf;
        break;
      case '.doc':
      case '.docx':
        icon = faFileWord;
        break;
      case '.ppt':
      case '.pptx':
        icon = faFilePowerpoint;
        break;
      case '.xls':
      case '.xlsx':
        icon = faFileExcel;
        break;
      case '.jpg':
      case '.jpeg':
      case '.png':
      case '.gif':
        icon = faFileImage;
        break;
      case '.mp3':
      case '.wav':
        icon = faFileAudio;
        break;
      case '.mp4':
      case '.mov':
        icon = faFileVideo;
        break;
      default:
        icon = faFileAlt;
        break;
    }

    return <FontAwesomeIcon icon={icon} size={`${size}`} />;
  };

  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  const handleFileSelect = useCallback((acceptedFiles, rejectedFiles) => {
    // Filter out files that exceed max size or are not of allowed file types
    const validFiles = acceptedFiles.filter(
      (file) =>
        file.size <= maxFileSize &&
        [
          'image/jpg',
          'image/png',
          'audio/wav',
          'video/mp4',
          'audio/mpeg',
          'image/jpeg',
          'application/pdf',
          'video/quicktime',
          'application/msword',
          'application/vnd.ms-excel',
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        ].includes(file.type)
    );
    setSelectedFiles(validFiles);

    // Display error message for rejected files
    if (rejectedFiles?.length > 0) {
      if (bulkOrderId) {

        // handleCustomSuccess(
        //   TOAST_TYPE.WARNING,
        //   rejectedFiles[0]?.errors[0]?.message
        // );
        handleSuccess(TOAST_TYPE.WARNING, "File must be under 10 MB");
      } else {

        // handleSuccess(TOAST_TYPE.WARNING, rejectedFiles[0]?.errors[0]?.message);
        handleSuccess(TOAST_TYPE.WARNING, "File must be under 10 MB");
      }
    }
  }, []);

  const handleFileDelete = (index) => {
    // Remove file from selected files list
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const handleClickOutSide = (e) => {
    if (innerRef.current) {
      if (!innerRef.current.contains(e.target)) {
        outerRef.current.style.opacity = 0;
        outerRef.current.style.pointerEvents = 'none';
      }
    }
    if (inventoryInnerRef.current) {
      if (!inventoryInnerRef.current.contains(e.target)) {
        inventoryOuterRef.current.style.opacity = 0;
        inventoryOuterRef.current.style.pointerEvents = 'none';
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  return (
    <div className="place-order-file-upload-container">
      {(attachments && attachments.length < 1) || attachmentForBulk ? (
        <button
          onClick={() => {
            outerRef.current.style.opacity = 1;
            outerRef.current.style.pointerEvents = 'auto';
          }}
          style={attachmentForBulk ? { background: 'transparent' } : undefined}
          className="file-upload-container-btn"
        >
          <AttachmentIcon className="add-more-attachments" />
          <span>Add Attachment</span>
          {attachments && attachments.length && attachmentForBulk ? (
            <span className="attachments-length">({attachments.length})</span>
          ) : null}
        </button>
      ) : (
        <div className="file-upload-container-two">
          <div className="file-upload-container-two-button">
            <button
              onClick={() => {
                outerRef.current.style.opacity = 1;
                outerRef.current.style.pointerEvents = 'auto';
              }}
            >
              <AddIcon className="add-more-attachments" />
              <span>Add More Attachment</span>
              {attachments && attachments.length ? (
                <span className="attachments-length">
                  ({attachments.length})
                </span>
              ) : null}
            </button>
          </div>
        </div>
      )}
      <div ref={outerRef} className="file-upload-outer-container">
        <div ref={innerRef} className="file-upload-inner-container" style={{background:"white",border:"none",borderRadius:"20px",boxShadow:""}}>
          <div className="file-upload-inner-top" >
            <h4>Manage your Attachment Inventory</h4>
            <span
              onClick={() => {
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = 'none';
              }}
            >
              <box-icon name='x'></box-icon>
            </span>
          </div>
          <div className="file-upload-inner-two">
            <div className="file-upload-inner-left">
              <div className="file-upload-container">
                <Dropzone
                  maxSize={maxFileSize}
                  onDrop={handleFileSelect}
                  maxFiles={attachmentForBulk ? 1 : 10}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone-container" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className="dropzone-text">
                        Drag and drop {attachmentForBulk ? 'file' : 'files'}{' '}
                        here, or click here to select{' '}
                        {attachmentForBulk ? 'file' : 'files'}
                        (max size: {maxFileSize / 1024 / 1024} MB)
                      </p>
                      <div className='UploadIconAddAttachmentBox' style={{width:"100%",height:"50%"}} ><img className='UploadIconAddAttachment' style={{height:"80%"}} src={ CloudUploadGreen}   / ></div>
                     
                    </div>
                  )}
                </Dropzone>
                {selectedFiles && selectedFiles.length > 0 && (
                  <div className="file-list-container">
                    <h4 className="file-list-heading">
                      Selected {attachmentForBulk ? 'File' : 'Files'}:
                    </h4>
                    <ul className="file-list" ref={fileListRef}>
                      {selectedFiles.map((file, index) => {
                        const extname = path.extname(file.name);
                        return (
                          // <Tooltip title={file.name} placement="top">
                          <li key={file.name} className="file-item">
                            <div className="file-item-img-container">
                              {file.type.includes('image') ? (
                                <img
                                  className="file-image"
                                  src={URL.createObjectURL(file)}
                                  alt={file.name}
                                />
                              ) : (
                                <FileIcon extname={extname} size="4x" />
                              )}
                            </div>
                            <div className="file-info">
                              <p className="file-name">
                                {file.name.slice(0, 12)}
                              </p>
                              {/* <p className="file-size">{file.size} bytes</p> */}
                              <p className="file-size">{convertBytes(file.size)}</p>
                              <button
                                className="file-remove-button"
                                onClick={() => handleFileDelete(index)}
                              >
                                Remove
                              </button>
                            </div>
                            {selectedFiles.length > 2 && (
                              <div
                                className={`scroll-indicator ${
                                  scrollDirection === 'left' ? 'left' : 'right'
                                }`}
                                id="scrollIndicator"
                              >
                                <ArrowRightAltIcon
                                  sx={{
                                    color: '#fff',
                                    marginRight: '10px',
                                    fontSize: '1.1rem',
                                  }}
                                />
                              </div>
                            )}
                          </li>
                          // </Tooltip>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {attachments && attachments.length > 0 && (
              <hr className="file-upload-inner-hr" />
            )}
            {attachments && attachments.length > 0 && (

              <div style={{display:"flex",flexDirection:"column"}}>
              <h5 style={{margin:"0 0",textAlign:"center",color:"black"}}>Uploaded Files</h5>

              <div
                className="file-upload-inner-right"
                style={
                  selectedFiles && selectedFiles.length > 0
                    ? { height: '100%' }
                    : { height: '135px' }
                }
              >
             
                <ul className="attachment-list">
                  {attachments &&
                    attachments.map((url, index) => {
                      const extname = path.extname(url);
                      
                         
                      const baseName = path.basename(url);
                      const namePart = baseName.split('_')[1];
                      

                      return (
                        <Tooltip title={baseName} placement="left-start" >
                          <li key={index}>
                            <div onClick={() => handleClick(url)}>
                              <FileIcon extname={extname} />
                             
                              {/* <span>{baseName}</span> */}
                              <span>{namePart}</span>
                            </div>
                            <DeleteIcon
                              className="delete-attachment-icon"
                              onClick={() => {
                                deleteAttachment(url);
                              }}
                            />
                          </li>
                        </Tooltip>
                      );
                    })}
                </ul>
              </div>
              </div>
            )}
          </div>
          <div className="file-upload-inner-bottom">
            <button
              onClick={() => {
                setSelectedFiles([]);
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = 'none';
              }}
            >
              Discard
            </button>
            <button onClick={handleUpload}>
              {isProgressive ? (
                <CircularProgress
                  size="sm"
                  value={25}
                  color="success"
                  determinate={false}
                />
              ) : null}
              <span className="file-upload-progress-span">Upload</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAttachment;
