import "./PolygonCheckbox.css";
import { useLocation } from "react-router-dom";
import React, { useRef, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import removeAllInteraction from "../../OpenLayers/OLFeatures/RemoveInteraction";
import PopUpBox from "../PopUpBox/PopUpBox";

export default function PolygonCheckBox({
  id,
  map,
  path,
  data,
  index,
  setPath,
  setHoverParcelId,
  setHoverParcelElement,

  // 30-5
  polygonList,
  setPolygonList,
  reActivateFunctionality,
  //07-6
  isDrawActive,
  isEditActive,
  isCutActive,
  setIsDrawActive,
  setIsEditActive,
  setIsCutActive,
}) {

  const innerRef = useRef();
  const outerRef = useRef();
  const location = useLocation();
  const pathName = location.pathname;
  // console.log(  isDrawActive, "isDrawActive", isEditActive, "isEditActive", isCutActive, "isCutActive");
const functionalUpdatePolygonList = (polygonLISTS)=>{
  // setPolygonList(polygonLISTS)
  setPolygonList(polygonLISTS);
}
const functionalUpdatePath = (updateddPath)=>{
  // setPolygonList(polygonLISTS)
  setPath(updateddPath);
}
const DeactivateAllActive=()=>{
  setIsDrawActive(false);
    setIsEditActive(false);
    setIsCutActive(false);
}
const callreactive = ()=>{
  reActivateFunctionality();
}
  const removeParcel = () => {
    const updatedPath = path.filter((prop) => prop.id !== data.id);

    // console.log(updatedPath,"updatedPath")
    // console.log(path," delete me path before setpath")
    functionalUpdatePath(updatedPath)
    // setPath(updatedPath);
    polygonList = [...updatedPath];
    // console.log(polygonList, "polygonList inside the removePArcel"); //25june
    //  setPolygonList([...polygonList]);
    // setPolygonList(polygonList);
    functionalUpdatePolygonList(polygonList)
    //  setPolygonList(updatedPath);
    console.log(polygonList, "polygonList", updatedPath, "updatedPath");

    // setPolygonList(updatedPath);
    // setPolygonList([...polygonList]);// Ensure the updated path is used
    // setPolygonList(updatedPath);// Ensure the updated path is used

    // console.log(path," delete me Path after setPath")
    // console.log(path," afdffdsfdsfdsfdfd")

    // june 07 2024 Firsrtly disable all the active functionality
    // console.log(isDrawActive, isEditActive, isCutActive, "console in removeParcel")
    // setIsDrawActive(false);
    // setIsEditActive(false);
    // setIsCutActive(false);
    // DeactivateAllActive();
    // june 07 2024 Firsrtly disable all the active functionality

    removeAllInteraction(map);
    // reActivateFunctionality(); 
    // callreactive(); 
    reActivateFunctionality(polygonList);  
  };

      // useEffect(() => {
        
      // }, [isDrawActive, isEditActive, isCutActive]);

  const handleMouseOver = (id) => {
    setHoverParcelId(id);
    setHoverParcelElement("mouseover");
  };

  const handleMouseOut = (id) => {
    setHoverParcelId(id);
    setHoverParcelElement("mouseout");
  };

  const handleClickOutSide = (e) => {
    if (innerRef.current) {
      if (!innerRef.current.contains(e.target)) {
        outerRef.current.style.opacity = 0;
        outerRef.current.style.pointerEvents = "none";
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
  }, []);

  return (
    <div
      id={id}
      className="parcel-info-container"
      onMouseOut={(e) => handleMouseOut(id)}
      onMouseOver={(e) => handleMouseOver(id)}
    >
      <button className="parcel-color"></button>
      <label>Parcel {++index} area: </label>
      <div className="parcel-area"> {data.measurement} acre</div>
      <DeleteIcon
        className="deleteIcon"
        onClick={() => {
          outerRef.current.style.opacity = 1;
          outerRef.current.style.pointerEvents = "auto";
          document.querySelector(".wrapperPopUpBox").style.display="block"
        }}
      />
      {/* <div ref={outerRef} className="review-and-submit-warning-outer">
        <div
          ref={innerRef}
          className="review-and-submit-warning-inner"
          style={{ height: "17%" }}
        >
          <div className="raise-feedback-inner-top">
            <span
              onClick={() => {
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = "none";
              }}
            >
              X
            </span>
          </div>
          <div className="review-and-submit-warning-inner-mid">
            <p
              style={
                pathName !== "/diy/order"
                  ? { fontSize: "0.8rem", margin: "10px 0px" }
                  : undefined
              }
            >
              Are you sure you want to delete this parcel?{" "}
            </p>
          </div>
          <div className="view-modal-bttns">
            <button
              onClick={() => {
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = "none";
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                removeParcel();
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = "none";
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div> */}
      <div ref={outerRef} className="review-and-submit-warning-outer">
        <div
          ref={innerRef}
          className="wrapperPopUpBox" style={{display:"block"}}
         
        >
          <div className="CloseIconPopUpBox">
            <span
              onClick={() => {
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = "none";
              }}
            >
               <box-icon name='x'></box-icon>
            </span>
          </div>
          <div className="ImgBoxPopUpBox">
                      <box-icon name='error-alt' type='solid' flip='horizontal' animation='tada' color='#edba83' size="lg"></box-icon>
          </div>
          <div className=" Remove_margins_of_p">
            <p>
              Confirmation{" "}
            </p>
            <p>Delete this parcel?</p>
          </div>
          <div className="view-modal-bttn "  id="view__model__BTNS">
            <button className="cancel"
              onClick={() => {
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = "none";
              }}
            >
              Cancel
            </button>
            <button className="delete" id="DeleteBtn"
              onClick={() => { 
                removeParcel();
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = "none";
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
{/* 
      <PopUpBox 
        title="Are you sure?"
        message="You want to delete this parcel?"
        additionalMsg=""
        // onRightBtnfunc={handleRightBtnClick}
        // onLeftBtnfunc={handleLeftBtnClick}
        leftBtnText="Cancel"
        rightBtnText="Delete"
        iconComponent={<box-icon name="error-alt" type="solid" flip="horizontal" animation="tada" color="#edba83" size="lg"></box-icon>}
    
      

      /> */}
    </div>
  );
}