import axios from "axios";
import AuthToken from "../../context/AuthToken";
import { ORDER_URL } from "../../helper/ApiUrl";

const sendLayerData = (
  id,
  name,
  viewId,
  layerId,
  frontEndId,
  backEndData,
  uploadedLayer,
  setUploadedLayer
) => {
  const token = AuthToken();
  axios
    .post(`${ORDER_URL}${id}/view/${viewId}/layer/${layerId}`, backEndData, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
      },
    })
    .then((response) => {
      uploadedLayer.map((layer) => {
        if (layer.name === name) {
          layer.data.map((item) => {
            if (item.id == frontEndId) {
              item.id = response.data.id;
            }
          });
        }
      });
      setUploadedLayer([...uploadedLayer]);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default sendLayerData;
