import {
  ORDER_URL,
  STUDIO_URL,
  VIEW_CLONE_URL,
  DELETE_VIEW_URL,
  ADD_NEW_LAYER_URL,
  USER_ORG_CREATE_URL,
} from '../../helper/ApiUrl';
import axios from 'axios';
import './MapLeftBar.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import './MeasurementPanel.css';
import { unByKey } from 'ol/Observable';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { STATUS } from '../../utils/constants';
import Accordian from '../Accordian/Accordian';
import AuthToken from '../../context/AuthToken';
import { TOAST_TYPE } from '../../utils/constants';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import ReportIcon from '@mui/icons-material/Report';
import { useScreenType } from '../../utils/commonMethods';
import React, { useEffect, useRef, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useLayerContext } from '../../context/LayerContext';
import MobileMeasurementPanel from './MobileMeasurementPanel';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import ParcelAccordian from '../ParcelAccordian/ParcelAccordian';
import { ReactComponent as Arrow } from '../../assets/close.svg';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import { totalFeatureMeasurement } from '../../utils/OrderHelper';
import { useUndoRedoContext } from '../../context/UndoRedoContext';
import { ReactComponent as CheckIcon } from '../../assets/Check.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import hoverEffect from '../../OpenLayers/OLInteractions/HoverInteraction';
import PropertyInformation from '../PropertyInformation/PropertyInformation';
import removeAllInteraction from '../../OpenLayers/OLFeatures/RemoveInteraction';
import CottageIcon from '@mui/icons-material/Cottage';
import OttermapLogo from './../../assets/logo.svg';

export default function MeasurementPanel({
  path,
  status,
  viewId,
  result,
  address,
  setPath,
  selected,
  orderNumber,
  hex2rgba,
  imageDate,
  modalShow,
  isPreview,
  resetData,
  showPopup,
  contentRef,
  setViewId,
  toolbarRef,
  viewDelete,
  isCloseBtn,
  notesModal,
  notesButton,
  oneFeature,
  orderActive,
  mapClickable,
  setSelected,
  toggleSlide,
  handleError,
  downloadPng,
  layersCount,
  setZoomLayer,
  hoverMapKey,
  studioStatus,
  setHoverData,
  setModalShow,
  downloadData,
  viewPanelRef,
  nearMapActive,
  isStudioOrder,
  orderNoteData,
  nearmapLoader,
  multiClickKey,
  handleSuccess,
  uploadedLayer,
  feedbackLayer,
  setOneFeature,
  outerViewName,
  setViewDelete,
  setNewLayerId,
  toggleSlideBtn,
  setLayersCount,
  setHoverMapKey,
  newViewCreated,
  showShareModal,
  setOrderActive,
  navigateAddress,
  orderNotesLayer,
  setOrderNoteKey,
  setStudioStatus,
  setOrderNoteData,
  nearmapDisabled,
  setNearMapActive,
  setOuterViewName,
  setFeedbackAdded,
  nearmapImageDate,
  setUploadedLayer,
  availableFeatures,
  setNewViewCreated,
  showDownloadModal,
  setFeedbackMarker,
  setShowShareModal,
  feedbackModalShow,
  toggleMapClickable,
  togglePolygonHover,
  setNearmapDisabled,
  setFeedbackEditable,
  isContextInteraction,
  setFeedbackModalShow,
  setShowDownloadModal,
  orderNotesModalShow,
  setAllFeedbackResolved,
  setOrderNotesModalShow,
  setOrderNoteLayerVisible,
  setHoverOrderFeatureId,
  setSelectedHoverFeature,
  setFeedbackLayerVisible,
  setHoverOrderFeatureElement,
  toggleFeedbackMarkerVisible,
  elem,
  setMultipleContextInteraction,
  toggleOrderNoteMarkerVisible,
}) {
  const token = AuthToken();
  const { id } = useParams();
  const innerRef = useRef();
  const outerRef = useRef();
  const viewModalRef = useRef();
  const deleteViewRef = useRef();
  const navigate = useNavigate();
  const layerSelectRef = useRef();
  const innerSelectRef = useRef();
  const isMobile = useScreenType();
  const NolayerSelectRef = useRef();
  const viewModalInnerRef = useRef();
  const deleteViewInnerRef = useRef();
  const viewRenameModalRef = useRef();
  const layerSelectInnerRef = useRef();
  const NolayerSelectInnerRef = useRef();
  const viewRenameModalInnerRef = useRef();
  const [viewData, setViewData] = useState();
  const [viewName, setViewName] = useState('');
  const [orgLogo, setOrgLogo] = useState(null);
  const [viewRename, setViewRename] = useState('');
  const [viewPanel, setViewPanel] = useState(true);
  const [viewCloneID, setViewCloneID] = useState();
  const [viewDeleteID, setViewDeleteID] = useState();
  const [viewRenameId, setViewRenameId] = useState();
  const [viewActive, setViewActive] = useState(false);
  const [selectedLayers, setSelectedLayers] = useState([]);
  const [layersRestrict, setLayersRestrict] = useState([]);
  const [viewPanelOpen, setViewPanelOpen] = useState(true);
  const [viewChangeName, setViewChangeName] = useState(null);
  const [measurementUnit, changeMeasurementUnit] = useState('sqft');
  const [viewPanelInnerOpen, setViewPanelInnerOpen] = useState(true);
  const viewFlag = viewData?.filter((view) => view.name !== outerViewName);

  const [viewCloneName, setViewCloneName] = useState(
    status == 'diy' ? 'DIY' : 'Default View'
  );

  const {
    undoStack,
    redoStack,
    setUndoStack,
    setRedoStack,
    saveUndoStack,
    saveRedoStack,
    setSaveUndoStack,
    setSaveRedoStack,
    currentPointerIdx,
  } = useUndoRedoContext();

  const {
    olMap,
    setLayerStyle,
    setCutInteraction,
    setLineInteraction,
    setEditInteraction,
    setPointInteraction,
    setSplitInteraction,
    setCutAllInteraction,
    setPolygonInteraction,
    setMultipleSelectInteraction,
  } = useLayerContext();

  const handleAccordianToggle = (num) => {
    if (selected === num) {
      return setSelected(null);
    }
    setSelected(num);
  };

  const handleClickOutSide = (e) => {
    if (viewPanelRef.current) {
      if (!viewPanelRef.current.contains(e.target)) {
        setViewPanel(true);
        setViewPanelOpen(true);
      } else {
        setViewPanel(false);
      }
    } else {
      setViewPanel(true);
    }
    if (viewRenameModalInnerRef.current) {
      if (!viewRenameModalInnerRef.current.contains(e.target)) {
        viewRenameModalRef.current.style.opacity = 0;
        viewRenameModalRef.current.style.pointerEvents = 'none';
      }
    }
    if (viewModalInnerRef.current) {
      if (!viewModalInnerRef.current.contains(e.target)) {
        viewModalRef.current.style.opacity = 0;
        viewModalRef.current.style.pointerEvents = 'none';
      }
    }
    if (innerSelectRef.current) {
      if (!innerSelectRef.current.contains(e.target)) {
        innerSelectRef.current.style.display = 'none';
      }
    }
    if (layerSelectInnerRef.current) {
      if (!layerSelectInnerRef.current.contains(e.target)) {
        layerSelectRef.current.style.opacity = 0;
        layerSelectRef.current.style.pointerEvents = 'none';
      }
    }
    if (NolayerSelectInnerRef.current) {
      if (!NolayerSelectInnerRef.current.contains(e.target)) {
        NolayerSelectRef.current.style.opacity = 0;
        NolayerSelectRef.current.style.pointerEvents = 'none';
      }
    }
    if (deleteViewInnerRef.current) {
      if (!deleteViewInnerRef.current.contains(e.target)) {
        deleteViewRef.current.style.opacity = 0;
        deleteViewRef.current.style.pointerEvents = 'none';
      }
    }
  };

  const getOrgInfo = () => {
    axios
      .get(USER_ORG_CREATE_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setOrgLogo(response.data.data.org_logo);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getOrgInfo();
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  const getViewsData = () => {
    axios
      .get(`${ORDER_URL}${id}/view`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setViewData(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });

  };

  useEffect(() => {
    getViewsData();
  }, [newViewCreated, viewActive]);

  console.log(viewCloneID, "viewCloneID", viewId, "viewId free")

  const createViewClone = () => {
    if (viewName.toLowerCase() !== viewCloneName.toLowerCase()) {
      const data = {
        name: `${viewName}`,
      };


      console.log(viewCloneID, "viewCloneID", viewId, "viewId")

      axios
        .post(`${VIEW_CLONE_URL}${id}/view/${viewCloneID ? viewCloneID : viewId}/clone`,
          data,
          {
            headers: {
              Authorization: `Token ${token}`,
              Accept: 'application/json',
            },
          }
        )
        .then((res) => {

          viewModalRef.current.style.opacity = 0;
          viewModalRef.current.style.pointerEvents = 'none';
          setViewCloneID(viewId);
          setViewId(res?.data?.id);
          setViewName('');

          setOuterViewName(viewName);
          setViewPanelOpen(!viewPanelOpen);
          setNewViewCreated(!newViewCreated);
          console.log("calling close modal")
          handleSuccess(TOAST_TYPE.SUCCESS, 'New View Created Successfully!');
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      handleSuccess(
        TOAST_TYPE.WARNING,
        'The same name view is not allowed. Please enter a unique name!'
      );
    }
  };

  const updateViewName = () => {
    const data = `name=${viewRename}`;
    axios
      .put(`${DELETE_VIEW_URL}${id}/view/${viewRenameId}`, data, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((res) => {
        setViewActive(!viewActive);
        if (outerViewName !== 'Default View') {
          setOuterViewName(viewRename);
        }
        viewRenameModalRef.current.style.opacity = 0;
        viewRenameModalRef.current.style.pointerEvents = 'none';
        handleSuccess(TOAST_TYPE.SUCCESS, 'View Name Update Successfully!');
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const deleteView = () => {
    axios
      .delete(`${DELETE_VIEW_URL}${id}/view/${viewDeleteID}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((res) => {
        console.log(viewCloneID, "viewCloneID", viewId, "viewId in delete")
        setViewName('');
        setViewRename('');
        setViewPanel(true);
        setViewActive(!viewActive);
        setViewDelete(!viewDelete);
        setViewCloneID(null)
        setViewCloneName('Default View');
        handleSuccess(TOAST_TYPE.SUCCESS, 'View Deleted Successfully!');
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const addNewLayer = () => {
    let layersId = '';
    const newLayers = selectedLayers.filter(
      (item) => !layersRestrict.includes(item)
    );
    setLayersCount(layersCount + newLayers.length);
    newLayers.forEach((item) => (layersId = layersId + item + ','));
    const newLayerId = layersId.slice(0, layersId.length - 1);
    const data = { layer_ids: `${newLayerId}` };
    axios
      .post(`${ADD_NEW_LAYER_URL}${id}/view/${viewId}/layers`, data, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setNewLayerId(response.data.data);
        layerSelectRef.current.style.opacity = 0;
        layerSelectRef.current.style.pointerEvents = 'none';
        handleSuccess(TOAST_TYPE.SUCCESS, 'New Layer Added Successfully!');
      })
      .catch((error) => {
        // handleError(error.message);
        handleError(error);
      });
  };

  const closeDiyTool = () => {
    setLayerStyle(null);
    unByKey(hoverMapKey);
    setCutInteraction(false);
    setCutAllInteraction(false);
    setEditInteraction(false);
    setLineInteraction(false);
    unByKey(currentPointerIdx);
    setPointInteraction(false);
    setSplitInteraction(false);
    isContextInteraction(true);
    removeAllInteraction(olMap);
    setPolygonInteraction(false);
    setMultipleSelectInteraction(false);
    setMultipleContextInteraction(false);
    toolbarRef.current.style.display = 'none';
    document.body.classList.remove('cut-cursor');
    document.body.classList.remove('edit-cursor');
    document.body.classList.remove('split-cursor');
    document.body.classList.remove('crosshair-cursor');
    hoverEffect(olMap, hex2rgba, hoverMapKey, setHoverMapKey, elem);
  };

  useEffect(() => {
    uploadedLayer.forEach((layer) => {
      setLayersRestrict([...layersRestrict, `${layer.featureId}`]);
      setSelectedLayers([...selectedLayers, `${layer.featureId}`]);
    });
  }, [uploadedLayer]);

  useEffect(() => {
    if (olMap) {
      olMap.getLayers().forEach(function (layer) {
        if (layer && layer.get('title') === 'Completed Grid Layer') {
          if (nearMapActive) layer.setVisible(true);
          else layer.setVisible(false);
        }
        if (layer && layer.get('title') === 'Tile Imagery') {
          if (nearMapActive) layer.setVisible(true);
          else layer.setVisible(false);
        }
      });
    }
  }, [olMap, nearMapActive]);

  const handleViewChangeDialog = (view) => {

    const hasUndo = undoStack.length > 0;
    const hasRedo = redoStack.length > 0;
    const undoChanged = hasUndo && JSON.stringify(saveUndoStack) !== JSON.stringify(undoStack[undoStack.length - 1]);
    const redoChanged = hasRedo && JSON.stringify(saveRedoStack) !== JSON.stringify(redoStack[redoStack.length - 1]);


    // setting the view clone id to the default view id
    // setViewCloneID(viewData?.filter((view) => view.name === viewCloneName)?.[0]?.id)

    if ((hasUndo || hasRedo) && (undoChanged || redoChanged)) {
      setViewChangeName(view);
      outerRef.current.style.opacity = 1;
      outerRef.current.style.pointerEvents = 'auto';
      // viewModalRef.current.style.opacity = 0;
      // viewModalRef.current.style.pointerEvents = 'none';
    } else {
      handleViewChange(view);
    }
  };

  const handleViewChange = (view) => {
    closeDiyTool();
    setUndoStack([]);
    setRedoStack([]);
    setSaveUndoStack([]);
    setSaveRedoStack([]);
    setViewChangeName(null);

    if (view === 'add view') {
      console.log(viewModalRef, "viewModalRef")
      viewModalRef.current.style.opacity = 1;
      viewModalRef.current.style.pointerEvents = 'auto';
    } else {
      setViewCloneID(view.id);
      setViewId(view.id);
      setSelectedLayers([]);
      setLayersRestrict([]);
      setViewCloneName(view.name);
      setOuterViewName(view.name);
      setViewPanelOpen(!viewPanelOpen);
    }
  };

  console.log(viewModalRef, "viewModalRef")
  const handleChangeStudioStatus = () => {
    const formData = new FormData();
    const value = studioStatus === "studio_progress" ? "studio_qc" : "studio_completed";
    formData.append('studio_status', value);
    axios
      .put(
        `${STUDIO_URL}${id}`,
        formData,
        {
          headers: {
            Authorization: `Token ${token}`,
            Accept: "application/json",
          },
        }
      )
      .then(() => {
        setStudioStatus(value);
        if (value === "studio_qc") navigate('/upload/order');
      })
      .catch((error) => {
        console.error(error);
      })
  }

  const isCustomViewSelected = viewData && viewData.filter((view) => view.name !== outerViewName).length > 0;
  return (
    <>
      {isMobile ? (
        <MobileMeasurementPanel
          orderNoteData={orderNoteData}
          orderActive={orderActive}
          mapClickable={mapClickable}
          oneFeature={oneFeature}
          setOneFeature={setOneFeature}
          setOrderActive={setOrderActive}
          nearmapDisabled={nearmapDisabled}
          nearmapLoader={nearmapLoader}
          nearMapActive={nearMapActive}
          setOrderNoteKey={setOrderNoteKey}
          setNearMapActive={setNearMapActive}
          path={path}
          result={result}
          setPath={setPath}
          selected={selected}
          setSelected={setSelected}
          measurementUnit={measurementUnit}
          uploadedLayer={uploadedLayer}
          viewId={viewId}
          hex2rgba={hex2rgba}
          modalShow={modalShow}
          showPopup={showPopup}
          isPreview={isPreview}
          toolbarRef={toolbarRef}
          hoverMapKey={hoverMapKey}
          layersCount={layersCount}
          handleError={handleError}
          setZoomLayer={setZoomLayer}
          setModalShow={setModalShow}
          setHoverData={setHoverData}
          outerViewName={outerViewName}
          handleSuccess={handleSuccess}
          selectedLayers={selectedLayers}
          setLayersCount={setLayersCount}
          setHoverMapKey={setHoverMapKey}
          setUploadedLayer={setUploadedLayer}
          setSelectedLayers={setSelectedLayers}
          setLayersRestrict={setLayersRestrict}
          multiClickKey={multiClickKey}
          isContextInteraction={isContextInteraction}
          handleAccordianToggle={handleAccordianToggle}
          setHoverOrderFeatureId={setHoverOrderFeatureId}
          setSelectedHoverFeature={setSelectedHoverFeature}
          totalFeatureMeasurement={totalFeatureMeasurement}
          setHoverOrderFeatureElement={setHoverOrderFeatureElement}
          elem={elem}
          setMultipleContextInteraction={setMultipleContextInteraction}
          setOrderNotesModalShow={setOrderNotesModalShow}
          setOrderNoteLayerVisible={setOrderNoteLayerVisible}
          toggleMapClickable={toggleMapClickable}
          toggleOrderNoteMarkerVisible={toggleOrderNoteMarkerVisible}
          orderNotesLayer={orderNotesLayer}
          setOrderNoteData={setOrderNoteData}
        />
      ) : (
        <div className="feature-visibility-container">
          {/* <PropertyInformation
            handleError={handleError}
            handleSuccess={handleSuccess}
          /> */}
          {isPreview === false ? (
            <div className="view-panel-container">

              <div style={{display: "flex", alignItems: 'center', width: "100%"}}>
              {/* views and select bar dropdown */}
              <div
                style={{
                  ...(uploadedLayer.length === layersCount && viewFlag?.length > 0
                    ? { background: '#ffffff', cursor: 'pointer' }
                    : { background: 'lightgray', cursor: 'not-allowed' }),
                  // ...(isCustomViewSelected && { "})
                }}
                className={ outerViewName === "Default View" ? "view-panel-drop-down-default" :  "view-panel-drop-down"}
                ref={viewPanelRef}
              >
                
                <Tooltip
                  title={ uploadedLayer.length === layersCount && viewFlag?.length > 0 ? '' : 'To explore additional views, please add a new view.'}
                  placement="bottom"
                >
                  <div
                    className={ (viewPanel || viewPanelOpen) ? 'select-btn' : 'select-btn open'}
                    style={{ border: "none", paddingLeft: "5px", paddingRight: "5px"}}
                    onClick={() => {
                      if (!viewPanel && uploadedLayer?.length === layersCount && viewFlag?.length > 0 )
                        setViewPanelOpen(!viewPanelOpen);
                    }}
                  >
                    <span className="view-btn-text">{outerViewName}</span>
                    {!isStudioOrder ? <span className="view-arrow-dwn">
                      <KeyboardArrowDownIcon className="view-panel-arrow-icon" />
                    </span> : null}
                  </div>
                </Tooltip>
                
                {/* select bar options list  */}
                {(viewData && viewData.filter((view) => view.name !== outerViewName).length > 0) && (
                    <ul className="view-panel-list-items">
                      <div className="view-panel-list-items-div">
                        {viewData && viewData.filter((view) => view.name !== outerViewName).map((view) => {
                              return (
                                <>
                                  <li className="view-panel-item" style={{backgroundColor: view.name === outerViewName && "#e8f6e0"}} key={view.id}>
                                    <span
                                      className="view-panel-text"
                                      style={ view.name === 'Default View' ? { width: '100%' } : { width: '80%' }}
                                      onClick={() =>
                                        handleViewChangeDialog(view)
                                      }
                                    >
                                      {view.name}
                                    </span>

                                    {/* {view.name !== 'Default View' ? (
                                      <div className="view-icon-div">
                                        <CreateIcon
                                          className="view-update-icon"
                                          onClick={() => {
                                            setViewRenameId(view.id);
                                            setViewRename(view.name);
                                            viewRenameModalRef.current.style.opacity = 1;
                                            viewRenameModalRef.current.style.pointerEvents = 'auto';
                                          }}
                                        />
                                        <DeleteIcon
                                          onClick={() => {
                                            setViewDeleteID(view.id);
                                            deleteViewRef.current.style.opacity = 1;
                                            deleteViewRef.current.style.pointerEvents = 'auto';
                                            
                                          }}
                                          className="view-delete-icon"
                                        />
                                      </div>
                                    ) : null} */}
                                  </li>
                                </>
                              );
                        })}

                        {/* delete modal  */}
                        <div
                          ref={deleteViewRef}
                          className="no-layers-info-container "
                        >
                          <div ref={deleteViewInnerRef} className="wrapperPopUpBox " style={{display:"block"}} >
                            <div className="view-modal-top add-layer-modal-close">
                              <span
                                onClick={() => {
                                  deleteViewRef.current.style.opacity = 0;
                                  deleteViewRef.current.style.pointerEvents =
                                    'none';
                                }}
                              >
                                <CloseIcon />
                              </span>
                            </div>
                            <div className="Remove_margins_of_p">
                              <ReportIcon style={{color: "#508d69", fontSize: "40px"}} />

                              <p>Confirmation</p>
                              <p>Are you sure? You want to delete this view!</p>
                            </div>
                            <div className="view-modal-bttn" id="view__model__BTNS">
                              <button className="cancel"
                                onClick={() => {
                                  deleteViewRef.current.style.opacity = 0;
                                  deleteViewRef.current.style.pointerEvents =
                                    'none';
                                }}
                              >
                                Cancel
                              </button>
                              <button  className="delete" id="DeleteBtn" 
                                onClick={() => {
                                  deleteView();
                                  deleteViewRef.current.style.opacity = 0;
                                  deleteViewRef.current.style.pointerEvents =
                                    'none';
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* rename edit modal  */}
                        <div
                          className="add-new-view-modal-backdrop"
                          ref={viewRenameModalRef}
                        >
                          <div
                            className="add-new-view-modal view-rename-modal"
                            ref={viewRenameModalInnerRef}
                          >
                            <div className="view-modal-top">
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  viewRenameModalRef.current.style.opacity = 0;
                                  viewRenameModalRef.current.style.pointerEvents =
                                    'none';
                                }}
                              >
                                
                              </span>
                            </div>
                            <div className="view-modal-mid view-rename-modal-mid">
                              <label htmlFor="view-name">View Name</label>
                              <input
                                style={{fontSize: "14px"}}
                                maxlength="15"
                                placeholder='Enter View Name (max 15 characters)'
                                required
                                type="text"
                                value={viewRename}
                                onChange={(e) => setViewRename(e.target.value)}
                              />
                            </div>
                            <div className="view-modal-bttns">
                              <button
                                onClick={() => {
                                  viewRenameModalRef.current.style.opacity = 0;
                                  viewRenameModalRef.current.style.pointerEvents =
                                    'none';
                                }}
                              >
                                Cancel
                              </button>
                              <button onClick={updateViewName}>Update</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ul>
                )}
                

                <div className="add-new-view-modal-backdrop" ref={viewModalRef}>
                  <div className="add-new-view-modal" ref={viewModalInnerRef}>
                    <div className="view-modal-top" style={{marginBottom: "0.7em" ,flexDirection:"column" }}>
                     <div  style={{display:"flex",justiContent:"end"}}><span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          viewModalRef.current.style.opacity = 0;
                          viewModalRef.current.style.pointerEvents = 'none';
                        }}
                      >
                        <CloseIcon />
                      </span></div>
                      <h5 >Add new view to edit map</h5>
                      
                    </div>
                    <div className="view-modal-mid">
                      <label  htmlFor="view-name">View Name</label>
                      <input
                         maxlength="15"
                        placeholder='Enter View Name (max 15 characters)'
                        type="text"
                        required
                        style={{fontSize: "13px !important", fontFamily: "Metropolis"}}
                        value={viewName}
                        onChange={(e) => setViewName(e.target.value)}
                      />
                    </div>
                    <div className="view-modal-bottom">
                      <label htmlFor="" >Copy Data From</label>
                      <Tooltip
                        title={
                          viewData?.filter( (view) => view.name !== viewCloneName).length > 0 ? '' : 'To get started, copy data from the default view.'
                        }
                        placement="bottom"
                      >
                        <div
                          className="view-inner-select"
                          onClick={() => {
                            if (viewPanelInnerOpen) {
                              setViewPanelInnerOpen(!viewPanelInnerOpen);
                              if (innerSelectRef.current) {
                                innerSelectRef.current.style.display = 'block';
                              }
                            } else if (!viewPanelInnerOpen) {
                              setViewPanelInnerOpen(!viewPanelInnerOpen);
                              if (innerSelectRef.current) {
                                innerSelectRef.current.style.display = 'none';
                              }
                            }
                          }}
                          style={ viewData?.filter( (view) => view.name !== viewCloneName).length === 0 ? { background: 'lightgray' } : null}
                        >
                          <span className="view-clone-name">
                            {viewCloneName}
                          </span>
                          <KeyboardArrowDownIcon className="view-panel-arrow-icon" />
                        </div>
                      </Tooltip>
                      
                      {viewData?.filter((view) => view.name !== viewCloneName).length > 0 && (
                          <ul className="view-panel-list-items inner-view-panel-list-items" style={{marginTop: "25px"}} ref={innerSelectRef} >
                            <div className="view-panel-list-items-div" style={{maxHeight: "100px"}}>
                              {viewData && viewData.filter((view) => view.name !== viewCloneName).map((view) => {
                                    return (
                                      <li
                                        key={view.id}
                                        className="view-panel-item"
                                        onClick={() => {
                                          setViewCloneID(view.id);
                                          setViewCloneName(view.name);
                                          innerSelectRef.current.style.display =
                                            'none';
                                        }}
                                      >
                                        <span className="view-panel-text">
                                          {view.name}
                                        </span>
                                      </li>
                                    );
                                  })}
                            </div>
                          </ul>
                        )}
                    </div>
                    <div className="view-modal-bttns" id="view__model__BTTNS">
                      <button
                        onClick={() => {
                          viewModalRef.current.style.opacity = 0;
                          viewModalRef.current.style.pointerEvents = 'none';
                        }}
                      >
                        Cancel
                      </button>
                      <button  className="delete" onClick={createViewClone}>Create</button>
                    </div>
                  </div>
                </div>

                
              </div>

              {/* edit and delete button for selected view  */}
              {((viewData && viewData.filter((view) => view.name !== outerViewName).length > 0 ) && outerViewName !== 'Default View') && 
              <div className='edit-delete-button-group hideOnScreenshot' >
              <div >
                
                <div className="view-panel-list-items-div">

                                    {outerViewName !== 'Default View' ? (
                                      <div className="view-icon-div">
                                        <CreateIcon
                                          className="view-update-icon"
                                          onClick={() => {
                                            setViewRenameId(viewId);
                                            setViewRename(outerViewName);
                                            viewRenameModalRef.current.style.opacity = 1;
                                            viewRenameModalRef.current.style.pointerEvents = 'auto';
                                          }}
                                        />
                                        <DeleteIcon
                                          
                                          onClick={() => {
                                            setViewDeleteID(viewId);
                                            if (deleteViewRef.current) {
                                              deleteViewRef.current.style.opacity = 1;
                                              deleteViewRef.current.style.pointerEvents = 'auto';
                                            }
                                          }}
                                          className="view-delete-icon"
                                        />
                                      </div>
                                    ) : null}
                        
                               

                        {/* delete modal  */}
                        <div
                          ref={deleteViewRef}
                          className="no-layers-info-container "
                        >
                          <div ref={deleteViewInnerRef} className=" wrapperPopUpBox" style={{display:"block"}} >
                            <div className="view-modal-top add-layer-modal-close ">
                              <span
                                onClick={() => {
                                  deleteViewRef.current.style.opacity = 0;
                                  deleteViewRef.current.style.pointerEvents =
                                    'none';
                                }}
                              >
                                <CloseIcon />
                              </span>
                            </div>
                            <div className="ImgBoxPopUpBox">
                            <box-icon name='error-alt' type='solid' flip='horizontal' animation='tada' color='#edba83' size="lg"></box-icon>
                            </div>
                            <div className="Remove_margins_of_p">
                             
                              <p>Confirmation</p>
                              <p>Are you sure? You want to delete this view!</p>
                            </div>
                            <div  className="view-modal-bttn" id="view__model__BTNS">
                              <button className="cancel"
                                onClick={() => {
                                  deleteViewRef.current.style.opacity = 0;
                                  deleteViewRef.current.style.pointerEvents =
                                    'none';
                                }}
                              >
                                Cancel
                              </button>
                              <button   className="delete" id="DeleteBtn"
                                onClick={() => {
                                  deleteView();
                                  deleteViewRef.current.style.opacity = 0;
                                  deleteViewRef.current.style.pointerEvents =
                                    'none';
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* rename edit modal  */}
                        <div
                          className="add-new-view-modal-backdrop"
                          ref={viewRenameModalRef}
                        >
                          <div
                            className="add-new-view-modal view-rename-modal"
                            ref={viewRenameModalInnerRef}
                          >
                            <div className="view-modal-top">
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  viewRenameModalRef.current.style.opacity = 0;
                                  viewRenameModalRef.current.style.pointerEvents =
                                    'none';
                                }}
                              >
                                
                              </span>
                            </div>
                            <div className="view-modal-mid view-rename-modal-mid">
                              <label htmlFor="view-name">View Name</label>
                              <input
                                maxlength="15"
                                placeholder='Enter View Name (max 15 characters)'
                                required
                                style={{fontSize: "13px  !important"}}
                                type="text"
                                value={viewRename}
                                onChange={(e) => setViewRename(e.target.value)}
                              />
                            </div>
                            <div className="view-modal-bttns">
                              <button
                                onClick={() => {
                                  viewRenameModalRef.current.style.opacity = 0;
                                  viewRenameModalRef.current.style.pointerEvents =
                                    'none';
                                }}
                              >
                                Cancel
                              </button>
                              <button onClick={updateViewName}>Update</button>
                            </div>
                          </div>
                        </div>
                </div>
                
              </div>
              </div>}

              {/* modal to add views */}
              <div className="add-new-view-modal-backdrop" ref={viewModalRef}>
                  <div className="add-new-view-modal" ref={viewModalInnerRef}>
                    <div className="view-modal-top" style={{flexDirection:"column"}} >
                    <div style={{display:"flex",justifyContent:"end"}}><span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          viewModalRef.current.style.opacity = 0;
                          viewModalRef.current.style.pointerEvents = 'none';
                        }}
                      >
                        <CloseIcon />
                      </span></div>
                      <h5>Add new view to edit map</h5>
                      
                    </div>
                    <div className="view-modal-mid">
                      <label  htmlFor="view-name">View Name</label>
                      <input
                        maxlength="15"
                        placeholder='Enter View Name (max 15 characters)'
                        type="text"
                        required
                        style={{fontSize: "13px !important", fontFamily: "Metropolis"}}
                        value={viewName}
                        onChange={(e) => setViewName(e.target.value)}
                      />
                    </div>
                    <div className="view-modal-bottom">
                      <label htmlFor="" >Copy Data From</label>
                      <Tooltip
                        title={
                          viewData?.filter( (view) => view.name !== viewCloneName).length > 0 ? '' : 'To get started, copy data from the default view.'
                        }
                        placement="bottom"
                      >
                        <div
                          className="view-inner-select"
                          onClick={() => {
                            if (viewPanelInnerOpen) {
                              setViewPanelInnerOpen(!viewPanelInnerOpen);
                              if (innerSelectRef.current) {
                                innerSelectRef.current.style.display = 'block';
                              }
                            } else if (!viewPanelInnerOpen) {
                              setViewPanelInnerOpen(!viewPanelInnerOpen);
                              if (innerSelectRef.current) {
                                innerSelectRef.current.style.display = 'none';
                              }
                            }
                          }}
                          style={ viewData?.filter( (view) => view.name !== viewCloneName).length === 0 ? { background: 'lightgray' } : null}
                        >
                          <span className="view-clone-name">
                            {viewCloneName}
                          </span>
                          <KeyboardArrowDownIcon className="view-panel-arrow-icon" />
                        </div>
                      </Tooltip>
                      
                      {viewData?.filter((view) => view.name !== viewCloneName).length > 0 && (
                          <ul className="view-panel-list-items inner-view-panel-list-items" style={{marginTop: "25px"}} ref={innerSelectRef} >
                            <div className="view-panel-list-items-div" style={{maxHeight: "100px"}}>
                              {viewData && viewData.filter((view) => view.name !== viewCloneName).map((view) => {
                                    return (
                                      <li
                                        key={view.id}
                                        className="view-panel-item"
                                        onClick={() => {
                                          setViewCloneID(view.id);
                                          setViewCloneName(view.name);
                                          innerSelectRef.current.style.display =
                                            'none';
                                        }}
                                      >
                                        <span className="view-panel-text">
                                          {view.name}
                                        </span>
                                      </li>
                                    );
                                  })}
                            </div>
                          </ul>
                        )}
                    </div>
                    <div className="view-modal-bttns">
                      <button
                        onClick={() => {
                          viewModalRef.current.style.opacity = 0;
                          viewModalRef.current.style.pointerEvents = 'none';
                        }}
                      >
                        Cancel
                      </button>
                      <button onClick={createViewClone}>Create</button>
                    </div>
                  </div>
              </div>
               
              {!isStudioOrder ?
              <div className="add-new-view-container">
                  <button
                    className="add-new-view-btn hideOnScreenshot"
                    onClick={() => handleViewChangeDialog('add view')}
                  >
                    <CreateRoundedIcon className="view-add-icon" />
                    <span>Edit Map</span>
                  </button>
              </div>
              : null}

              {studioStatus && isStudioOrder ? (
                <div
                  onClick={handleChangeStudioStatus}
                  className={`studio-status-change-btn ${studioStatus === "studio_completed" ? "studio-btn-disabled" : ""}`}
                  style={{ cursor: 'pointer' }}
                >
                  <div>
                    {studioStatus === "studio_progress" ? "Move to QC"
                      : studioStatus === "studio_qc" ? "Mark as Completed"
                        : "Marked as Completed"}
                  </div>
                </div>
              ) : null}
              
              </div>
             </div>
          ) : null}

          <div className="address-box-for-snapshot">
              <div>
                <CottageIcon style={{fontSize: "22px"}}/>
                <p>{address}</p>
              </div>
              <div className='orderId-view-box' >
              <p><span>Order ID:</span> {orderNumber}</p>
              <p><span>View:</span> {outerViewName}</p>
              </div>
          </div>

          <div className="close-btn-div" style={{ marginTop: '20px' }}>
            <span className="measurement-panel-text">Measurement Panel:</span>
            <div
              style={
                status === STATUS.DRAFT || status === STATUS.IN_PROGRESS
                  ? {
                    height: '30%',
                  }
                  : null
              }
              className={`map-leftbar-close-btn hideOnScreenshot ${isCloseBtn ? '' : 'slide-close-hidden'
                }`}
            >
              <button
                className="close-feature-container"
                onClick={() => {
                  toggleSlide();
                  toggleSlideBtn();
                }}
              >
                <Arrow />
              </button>
            </div>
          </div>

          <div className="measurement-panel-header hideOnScreenshot">
            <div>Features</div>
            <div className="measurement-panel-header-right"  style={{display: "flex", alignItems: "center"}}>
              Measurement
              {/* <select
                defaultValue="sqft"
                onClick={(e) => changeMeasurementUnit(e.target.value)}
                name="units"

                id="units"
              >
                <option value="acres">acres</option>
                <option value="sqft">sqft</option>
              </select>  */}

            <Box>
              <FormControl sx={{ m: 1, minWidth: 100, fontSize: "13px" }} size="small">
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={measurementUnit}
                  onChange={(e) => changeMeasurementUnit(e.target.value)}
                  sx={{
                    fontSize: "13px",
                    backgroundColor: "white",
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    },
                    '&.Mui-focused': {
                      backgroundColor: "white"
                    }
                  }}
                >
                  <MenuItem style={{ fontSize: "13px" }} value="acres">Acres</MenuItem>
                  <MenuItem style={{ fontSize: "13px" }} value="sqft">Sqft</MenuItem>
                </Select>
              </FormControl>
            </Box>
            </div>
          </div>

          <div className="new-toggle-feature-mc hideOnScreenshot">
            <div className="toggle-all-layers-mc">
              <Tooltip style={{ visibility: `${isStudioOrder ? "hidden" : "visible"}` }} title="Add new layer" placement="top">
                <button
                  onClick={() => {
                    closeDiyTool();
                    if (outerViewName !== 'Default View') {
                      layerSelectRef.current.style.opacity = 1;
                      layerSelectRef.current.style.pointerEvents = 'auto';
                    } else {
                      NolayerSelectRef.current.style.opacity = 1;
                      NolayerSelectRef.current.style.pointerEvents = 'auto';
                    }
                  }}
                >
                  {' '}
                  <AddIcon sx={{ fontSize: '0.9rem', color: '#fff' }} />
                </button>
              </Tooltip>
              <div className="mc-checkbox">
                <input
                  type="checkbox"
                  checked={orderActive || oneFeature}
                  onChange={() => {
                    if (oneFeature) {
                      setOneFeature(false);
                    }
                    setOrderActive(!orderActive);
                  }}
                  className="toggle-all-layers-checkbox"
                />
              </div>
              <span>All Layers</span>
            </div>
            <Tooltip
              className="toggle-all-layers-mc"
              title={nearmapDisabled === true ? 'Image not present' : ''}
            >
              <div className="toggle-all-layers">
                {nearmapLoader ? (
                  <div className="nearmap-loader"></div>
                ) : (
                  <div className="mc-checkbox">
                    <input
                      disabled={nearmapDisabled}
                      type="checkbox"
                      checked={nearMapActive}
                      onChange={() => {
                        setNearMapActive(!nearMapActive);
                      }}
                    />
                  </div>
                )}
                <div className="nearmap-image-date-details">
                  <p id='imageryP'>Imagery</p>
                  {''}
                  <p id='imageryImg'>({nearmapImageDate})</p>
                </div>
              </div>
            </Tooltip>
          </div>

          <div className="measurement-panel-data">
            <ParcelAccordian
              path={path}
              number={-1}
              result={result}
              setPath={setPath}
              selected={selected}
              orderActive={orderActive}
              setSelected={setSelected}
              measurementUnit={measurementUnit}
            />
            {uploadedLayer.map((item, index) => {
              return (
                <Accordian
                  elem={elem}
                  key={index}
                  number={index}
                  viewId={viewId}
                  name={item.name}
                  data={item.data}
                  type={item.type}
                  layerId={item.id}
                  hex2rgba={hex2rgba}
                  selected={selected}
                  modalShow={modalShow}
                  isPreview={isPreview}
                  visible={item.visible}
                  toolbarRef={toolbarRef}
                  hoverMapKey={hoverMapKey}
                  setSelected={setSelected}
                  layersCount={layersCount}
                  handleError={handleError}
                  fillColor={item.fillColor}
                  setZoomLayer={setZoomLayer}
                  setModalShow={setModalShow}
                  isStudioOrder={isStudioOrder}
                  outerViewName={outerViewName}
                  uploadedLayer={uploadedLayer}
                  handleSuccess={handleSuccess}
                  setOneFeature={setOneFeature}
                  fillOpacity={item.fillOpacity}
                  selectedLayers={selectedLayers}
                  setLayersCount={setLayersCount}
                  setHoverMapKey={setHoverMapKey}
                  otterfeatureId={item.featureId}
                  strokeWeight={item.strokeWeight}
                  measurementUnit={measurementUnit}
                  setUploadedLayer={setUploadedLayer}
                  setSelectedLayers={setSelectedLayers}
                  setLayersRestrict={setLayersRestrict}
                  isContextInteraction={isContextInteraction}
                  handleAccordianToggle={handleAccordianToggle}
                  setHoverOrderFeatureId={setHoverOrderFeatureId}
                  featureMeasurement={totalFeatureMeasurement(item.data)}
                  setHoverOrderFeatureElement={setHoverOrderFeatureElement}
                  setMultipleContextInteraction={setMultipleContextInteraction}
                />
              );
            })}
          </div>

          <div ref={layerSelectRef} className="layers-info-container">
            <div
              ref={layerSelectInnerRef}
              className="layers-info-inner-container"
            >
              <div className="view-modal-top add-layer-modal-close" style={{dispaly:"flex",flexDirection:"column"}}
              >
                <span
                  onClick={() => {
                    layerSelectRef.current.style.opacity = 0;
                    layerSelectRef.current.style.pointerEvents = 'none';
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
              <div className="layers-info-inner-container-one">
                <FormGroup row={false}>
                  {availableFeatures.map(({ id, name }, index) => (
                    <Tooltip
                      placement="left"
                      key={index}fo
                      title={
                        layersRestrict.includes(`${id}`)
                          ? 'Feature already present!'
                          : ''
                      }
                    >
                      <FormControlLabel
                        className="layers-info-input"
                        style={
                          layersRestrict.includes(`${id}`)
                            ? { backgroundColor: '#E9E8E8' }
                            : null
                        }
                        key={id}
                        control={
                          <input
                            type="checkbox"
                            checked={
                              selectedLayers.includes(`${id}`) ? true : false
                            }
                            onChange={(e) => {
                              if (!layersRestrict.includes(`${id}`)) {
                                if (selectedLayers.includes(`${id}`)) {
                                  setSelectedLayers(
                                    selectedLayers.filter((e) => e !== `${id}`)
                                  );
                                } else {
                                  setSelectedLayers([
                                    ...selectedLayers,
                                    `${id}`,
                                  ]);
                                }
                              }
                            }}
                          />
                        }
                        label={name}
                      />
                    </Tooltip>
                  ))}
                </FormGroup>
              </div>
              <div className="property-function-button">
                <button onClick={addNewLayer}>
                  <CheckIcon className="check-icon" />{' '}
                  <span>Confirm Selected Layers</span>
                </button>
              </div>
            </div>
          </div>

          <div ref={NolayerSelectRef} className="no-layers-info-container">
            <div
              ref={NolayerSelectInnerRef}
              className="wrapperPopUpBox"
                    style={{ display: 'block' }}
            >
              <div className="view-modal-top add-layer-modal-close">
                <span
                  onClick={() => {
                    NolayerSelectRef.current.style.opacity = 0;
                    NolayerSelectRef.current.style.pointerEvents = 'none';
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
              <div className="no-layer-mid-container" style={{textAlign:"center" }}>
                <p  style={{letterSpacing:"0.85px" ,fontSize:"1rem" , fontWeight:"600" }}>
                  Adding layers in Default View is not allowed.
                 </p>
                 <p style={{letterSpacing:"0.85px" ,fontSize:"1rem" , fontWeight:"600"}}>Please create a new view or use another view for adding more layers.</p>
              </div>
              <div className="view-modal-bttn" id="view__model__BTNS">
                <button  className="cancel" 
                  onClick={() => {
                    NolayerSelectRef.current.style.opacity = 0;
                    NolayerSelectRef.current.style.pointerEvents = 'none';
                  }}
                >
                  Cancel
                </button>
                <button  className="delete" id="DeleteBtn"
                  onClick={() => {
                    viewModalRef.current.style.opacity = 1;
                    NolayerSelectRef.current.style.opacity = 0;
                    viewModalRef.current.style.pointerEvents = 'auto';
                    NolayerSelectRef.current.style.pointerEvents = 'none';
                  }}
                >
                  Create
                </button>
              </div>
            </div>
          </div>

          <div ref={outerRef} className="review-and-submit-warning-outer">
            <div ref={innerRef} className="wrapperPopUpBox" style={{display:"block"}}>
              <div className="raise-feedback-inner-top">
                <span
                  onClick={() => {
                    outerRef.current.style.opacity = 0;
                    outerRef.current.style.pointerEvents = 'none';
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
              <div className="Remove_margins_of_p">
                <p> Continuing will discard your changes. </p>
                  <p> Are you sure you want to proceed?</p>
                
              </div>
              <div className="view-modal-bttn" id="view__model__BTNS">
                <button className="cancel"
                  onClick={() => {
                    setViewChangeName(null);
                    outerRef.current.style.opacity = 0;
                    outerRef.current.style.pointerEvents = 'none';
                  }}
                >
                  Cancel
                </button>
                <button className="delete" id="DeleteBtn"
                  onClick={() => {
                    handleViewChange(viewChangeName);
                    outerRef.current.style.opacity = 0;
                    outerRef.current.style.pointerEvents = 'none';
                  }}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>

          <div className='Powered-by-box'>
            <p>Powered by</p>
            <img src={OttermapLogo} alt="ottermap logo" />
          </div>
        </div>
      )}
    </>
  );
}
