import Overlay from 'ol/Overlay';
import { transform } from 'ol/proj';

const setOrderNoteOverlay = (
  map,
  orderNoteData,
  viewId,
  container,
  navigate,
  isMobile,
  orderNoteLayerVisible,
  toggleOrderNoteMarkerVisible
) => {
  const url = window.location.href;
  const prod = process.env.REACT_APP_PROD;

  if (orderNoteData && map && orderNoteLayerVisible) {
    toggleOrderNoteMarkerVisible(true);
    navigate({ search: `?view_id=${viewId}&ordntid=${orderNoteData.id}` });
    const overlay = new Overlay({
      id: 1,
      element: container,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    overlay.setPosition(
      transform(
        [orderNoteData.long, orderNoteData.lat],
        'EPSG:4326',
        'EPSG:3857'
      )
    );
    container.style.display = 'flex';
    // if (url.includes(prod)) {
    if (url.includes(process.env.REACT_APP_DEV)) {
      window.analytics.track('Order Note Viewed', {
        order_note_id: orderNoteData.id,
        creator: orderNoteData.created_by,
      });
    }

    if (isMobile) {
      var shiftFractionX = 0.43;
      var shiftFractionY = 0.35;
    } else {
      var shiftFractionX = 0.15;
      var shiftFractionY = 0.45;
    }
    var originalCenter = transform(
      [orderNoteData.long, orderNoteData.lat],
      'EPSG:4326',
      'EPSG:3857'
    );
    var mapSize = map.getSize();
    var shiftX = shiftFractionX * mapSize[0];
    var shiftY = shiftFractionY * mapSize[1];
    var originalCenterPixel = map.getPixelFromCoordinate(originalCenter);
    if (originalCenterPixel) {
      var shiftedCenterPixel = [
        originalCenterPixel[0] + shiftX,
        originalCenterPixel[1] + shiftY,
      ];
      var shiftedCenter = map.getCoordinateFromPixel(shiftedCenterPixel);
      map.getView().animate({
        duration: 1000,
        center: shiftedCenter,
      });
    }

    map.addOverlay(overlay);
  }
};

export default setOrderNoteOverlay;
