import './Footer.css';
import React from 'react';
import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { ReactComponent as SiteIcon } from '../../assets/icon.svg';

const Footer = () => {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  return (
    <div className="footer-main-container">
      <div className="navigations-container">
        <div
          className="navigation-icon"
          style={
            path === '/home'
              ? { background: '#b4e197' }
              : { background: '#fff' }
          }
        >
          <a href="/home">
            <SiteIcon className="otter-map-logo" />
          </a>
          <span className="navigation-text">Home</span>
        </div>
        <div
          className="navigation-icon"
          style={
            path === '/dashboard'
              ? { background: '#b4e197' }
              : { background: '#fff' }
          }
        >
          <a href="/dashboard">
            <MapsHomeWorkIcon className="bottombar-icon" />
          </a>
          <span className="navigation-text">Dashboard</span>
        </div>
        <div
          className="navigation-icon"
          style={
            path === '/dashboard?status=in_progress'
              ? { background: '#b4e197' }
              : { background: '#fff' }
          }
        >
          <a href="/dashboard?status=in_progress">
            <EventRepeatIcon className="bottombar-icon" />
          </a>
          <span className="navigation-text">In progress</span>
        </div>
        <div
          className="navigation-icon"
          style={
            path === '/setting/user'
              ? { background: '#b4e197' }
              : { background: '#fff' }
          }
        >
          <a href="/setting/user">
            <Avatar
              alt="Cindy Baker"
              src="https://i.pinimg.com/736x/9a/64/8a/9a648ac91d323b0931448ab34055f967.jpg"
            />
          </a>
          <span className="navigation-text">Setting</span>
        </div>
      </div>
      {/* <div className="create-button-container">
        <div className="create-button">
          <Button
            onClick={() => {
              navigate('/order');
            }}
          >
            <AddIcon
              sx={{
                color: 'white',
              }}
            />
          </Button>
        </div>
        <span className="navigation-text">New</span>
      </div> */}
    </div>
  );
};

export default Footer;
