import axios from 'axios';
import './MyDocuments.css';
import AuthToken from '../../context/AuthToken';
import { FILE_UPLOAD,MOLE_ORDER_FILE_FOLDER } from '../../helper/ApiUrl';
import 'react-loading-skeleton/dist/skeleton.css';
import React, { useEffect, useState } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
} from '@mui/material';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import DocumentContainer from '../../components/DocumentContainer/DocumentContainer';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ImageView from './Imageview';
import FileExplorer from './FolderFile';
import MyDocumentsComponent from './MyDocumentComponent';
import { Link, useNavigate } from 'react-router-dom';
import ToggleButtons from './ToggleButton';
import { FileExplorerNew } from './FolderFilesfor';
import RoutingFileExplorer from './RoutingFolderFile';




const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 200,
  maxHeight: '300px',
  margin: theme.spacing(2),
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  minHeight: '48px', // Adjust the height as needed
  alignItems: 'center',
}));

export default function BulkImagesComponent({handleError, handleSuccess}) {
  const keys = ['file_name'];
  const token = AuthToken();
  const [query, setQuery] = useState('');
  const [documents, setDocuments] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState('');


// for navigation


  // for the download as zip
  
  const [progress, setProgress] = useState(0);
  const [isZipping, setIsZipping] = useState(false);

  // for show images preview 
  const [currentView, setCurrentView] = useState('home');
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const fileTypes = [
    'PDF',
    'DOC',
    'DOCX',
    'XLS',
    'XLSX',
    'PPT',
    'PPTX',
    'TXT',
    'CSV',
    'JPEG',
    'JPG',
    'PNG',
    'GIF',
  ];

  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) => item[key].toLowerCase().includes(query))
    );
  };

  // const getMyDocuments = () => {
  //   axios
  //     .get(`${FILE_UPLOAD}`, {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //         Accept: 'application/json',
  //       },
  //     })
  //     .then((response) => {
  //       console.log(response.data.data,"get documen")
  //       setDocuments(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.error(error.message);
  //     });
  // };
  

  
  // for file and folder 
   
  const [fileFolder,setfileFolder]= useState([])

  const FilesAndFolder = () => {
    axios
      .get(`${MOLE_ORDER_FILE_FOLDER}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setfileFolder(response.data.data)
        console.log(response.data.data,"get files and folder")
        setDocuments(response.data.data);
      
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  

  useEffect(() => {
    
    FilesAndFolder();
    // getMyDocuments();
  }, []);

  const handleFileTypeChange = (event) => {
    setSelectedFileType(event.target.value);
  };

  // for image preview and download start
const documentss  = [
  
    
  {
      "file_name": "Screenshot (6).png",
      "file_url": "https://prod-otter.s3.us-west-004.backblazeb2.com/0000177d-bcee-4b52-8750-f57fe9ece247/002511/0000177d-bcee-4b52-8750-f57fe9ece247.jpg"
  },
  {
      "file_name": "Screenshot (9).png",
      "file_url": "https://prod-otter.s3.us-west-004.backblazeb2.com/0043ed63-5296-4bf0-9675-377068d41c5c/181527/0043ed63-5296-4bf0-9675-377068d41c5c.jpg"
  },
  {
      "file_name": "WhatsApp Image 2022-01-03 at 10.07.27 AM.jpeg",
      "file_url": "https://prod-otter.s3.us-west-004.backblazeb2.com/01ffdc48-eeca-43b6-a440-fab4905ee97c/190733/01ffdc48-eeca-43b6-a440-fab4905ee97c.jpg"
  },
  {
      "file_name": "224206.png",
      "file_url": "https://prod-otter.s3.us-west-004.backblazeb2.com/0043ed63-5296-4bf0-9675-377068d41c5c/181527/0043ed63-5296-4bf0-9675-377068d41c5c.jpg"
  },
  {
      "file_name": "Screenshot.png",
      "file_url": "https://prod-otter.s3.us-west-004.backblazeb2.com/007782cc-0276-41e0-b0c8-b6c53a186f0d/075348/007782cc-0276-41e0-b0c8-b6c53a186f0d.jpg"
  },
 
]


  // const filteredUrls = selectedFileType
  //   ? documents.filter((url) =>
  //       url.file_url.toLowerCase().includes(selectedFileType.toLowerCase())
  //     )
  //   : documents;
  const filteredUrls = selectedFileType
    ? documentss.filter((url) =>
        url.file_url.toLowerCase().includes(selectedFileType.toLowerCase())
      )
    : documentss;

  const handleClearFilter = () => {
    setSelectedFileType('');
  };
// download zip 
  // const downloadAsZip = async () => {
  //   setIsZipping(true);
  //   setProgress(0);
  //   const zip = new JSZip();

  //   for (let i = 0; i < documents.length; i++) {
  //     const doc = documents[i];
  //     try {
  //       const response = await axios.get(doc.file_url, { responseType: 'blob' });
  //       zip.file(doc.file_name, response.data);
  //       setProgress(((i + 1) / documents.length) * 100);
  //     } catch (error) {
  //       console.error(`Failed to download ${doc.file_name}:`, error);
  //     }
  //   }

  //   const content = await zip.generateAsync({ type: 'blob' }, (metadata) => {
  //     setProgress(metadata.percent);
  //   });

  //   saveAs(content, 'documents.zip');
  //   setIsZipping(false);
  // };

  // download zip

  const downloadFile = async (url, retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
        }
        return await response.blob();
      } catch (error) {
        if (i === retries - 1) {
          console.error(`Failed to download ${url} after ${retries} attempts:`, error);
          throw error;
        }
      }
    }
  };

  const downloadAsZip = async () => {
    setIsZipping(true);
    setProgress(0);
    const zip = new JSZip();

    for (let i = 0; i < documents.length; i++) {
      const doc = documents[i];
      try {
        const fileData = await downloadFile(doc.file_url);
        zip.file(doc.file_name, fileData);
        setProgress(((i + 1) / documents.length) * 100);
      } catch (error) {
        console.error(`Failed to download ${doc.file_name}:`, error);
      }
    }

    const content = await zip.generateAsync({ type: 'blob' }, (metadata) => {
      setProgress(metadata.percent);
      
    });

    saveAs(content, 'documents.zip');
    setIsZipping(false);
  };

    // const downloadFile = async (url, retries = 3) => {
  //   for (let i = 0; i < retries; i++) {
  //     try {
  //       const response = await fetch(url);
  //       if (!response.ok) {
  //         throw new Error(`Failed to fetch ${url}: ${response.statusText}`);
  //       }
  //       return await response.blob();
  //     } catch (error) {
  //       if (i === retries - 1) {
  //         console.error(`Failed to download ${url} after ${retries} attempts:`, error);
  //         throw error;
  //       }
  //     }
  //   }
  // };

  // const downloadAsZip = async () => {
  //   setIsZipping(true);
  //   setProgress(0);
  //   const zip = new JSZip();

  //   for (let i = 0; i < documents.length; i++) {
  //     const doc = documents[i];
  //     try {
  //       const fileData = await downloadFile(doc.file_url);
  //       zip.file(doc.file_name, fileData);
  //       setProgress(((i + 1) / (documents.length + 1)) * 100); // Update progress after each file
  //     } catch (error) {
  //       console.error(`Failed to download ${doc.file_name}:`, error);
  //     }
  //   }

  //   const content = await zip.generateAsync({ type: 'blob' }, (metadata) => {
  //     setProgress(100); // Ensure progress reaches 100% after zipping
  //   });

  //   saveAs(content, 'documents.zip');
  //   setIsZipping(false);
  //   setProgress(0); // Reset progress bar after download
  // };


 useEffect(()=>{
  console.log(currentView,"currentView")
  console.log(selectedImage,"selectedImage")

 },[currentView,selectedImage])  

// for image preview and download start


console.log(documents,"documents")
console.log(documentss,"documentss")

const handleFolderClick = (folder) => {
  setSelectedFolder(folder);
  setCurrentView('folder');
};

const handleBack = () => {
  setCurrentView('home');
  setSelectedFolder(null);
};

const handleImageClick = (image) => {
  setSelectedImage(image);
  setCurrentView('image');
};

const handleCloseImage = () => {
  setSelectedImage(null);
  setCurrentView('folder');
};

const handleDownloadImage = () => {
  console.log("this dowload is clicked")
  console.log(selectedImage,"this dowload is clicked")
  const link = document.createElement('a');
  link.href = selectedImage;
  link.download = selectedImage.split('/').pop();
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const [activeTab, setActiveTab] = useState('bulkImages');

// for image preview and download end
const navigate = useNavigate()
  return (
   <>
    
 




    {/* for the toggle button to change the Mydocument and Bulk Images generated  ---end*/}


    {/* ------------------------------------- */}
      <div className="my-documents-content-cont" >

      {/* 10Aug for routing */}
      {/* <ToggleButtons /> */}

      {/* 10Aug for routing */}
        {documents.length === 0 ? (
          <div className="alternate-table-container">
            <h1 >Loading...</h1>
          </div>
        ) : documents.length < 1 ? (
          <div className="alternate-table-container">
            <h1>No documents found :</h1>
          </div>
        ) : (
          <>
          {/* <FileExplorer  
            data={fileFolder} 
            handleDownloadImage={handleDownloadImage}
            setCurrentView={setCurrentView}
            setSelectedImage={setSelectedImage}
          /> */}
          <RoutingFileExplorer
            data={fileFolder} 
            handleDownloadImage={handleDownloadImage}
            setCurrentView={setCurrentView}
            setSelectedImage={setSelectedImage}
           
            FilesAndFolder={FilesAndFolder}
          />
          {/* search(filteredUrls).map((document, index) => {
            
            return (
              <>
              <DocumentContainer
                fileName={document.file_name}
                fileUrl={document.file_url}
               setCurrentView={setCurrentView}
               handleDownloadImage={handleDownloadImage}
               setSelectedImage={setSelectedImage}
              />
              {console.log(fileFolder,"filefolder")}
              {console.log(fileFolder,"filefolderrr")}
              <FileExplorer  data={fileFolder} />
              </>
            );
          }) */}</>
        )}
 
      </div>

   
      {currentView === 'image' && (
       
        <ImageView
          imageSrc={selectedImage}
          onClose={handleCloseImage}
          onDownload={handleDownloadImage}
        />
      )}

      </>
  );
}

