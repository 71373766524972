import './MapLeftBar.css';
import axios from 'axios';
import PlaceOrder from './PlaceOrder';
import { useParams } from 'react-router-dom';
import { STATUS } from '../../utils/constants';
import AuthToken from '../../context/AuthToken';
import ParcelSelection from './ParcelSelection';
import FeatureSelection from './FeatureSelection';
import MeasurementPanel from './MeasurementPanel';
import { useScreenType } from '../../utils/commonMethods';
import React, { useRef, useState, useEffect } from 'react';
import { ReactComponent as Arrow } from '../../assets/close.svg';
import MobileDraftProgressPanel from './MobileDraftProgressPanel';
import { FETCH_FEATURE_URL, ORDER_URL } from '../../helper/ApiUrl';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GetCostFactors from '../../OpenLayers/OLFeatures/GetCostFactors';
import setCoordsToImage from '../../OpenLayers/OLFeatures/SetCoordsToImage';

export default function MapLeftBar({
  map,
  step,
  orderNumber,
  path,
  elem,
  status,
  viewId,
  setStep,
  setPath,
  address,
  hex2rgba,
  orderNote,
  imageDate,
  isPreview,
  contentRef,
  resetData,
  parcelRef,
  setViewId,
  showPopup,
  setStatus,
  viewDelete,
  notesModal,
  notesButton,
  toolbarRef,
  parcelData,
  editPolygon,
  placedTime,
  bulkOrderId,
  setPlacedTime,
  downloadPng,
  attachments,
  layersCount,
  orderActive,
  mapClickable,
  hoverMapKey,
  handleError,
  setHoverData,
  setOrderNote,
  setZoomLayer,
  propertyCost,
  downloadData,
  viewPanelRef,
  studioStatus,
  orderNoteData,
  setViewDelete,
  setParcelEdit,
  setNewLayerId,
  feedbackLayer,
  multiClickKey,
  uploadedLayer,
  outerViewName,
  handleSuccess,
  isStudioOrder,
  setHoverMapKey,
  setAttachments,
  setParcelStyle,
  setPolygonList,
  setOrderActive,
  setLayersCount,
  newViewCreated,
  featureSelected,
  navigateAddress,
  setPropertyCost,
  orderNotesLayer,
  setOrderNoteKey,
  setStudioStatus,
  nearmapImageDate,
  completedGridLyr,
  setUploadedLayer,
  nearmapcoords,
  setNearmapcoords,
  setOuterViewName,
  setFeedbackAdded,
  setOrderNoteData,
  setHoverParcelId,
  handleCustomError,
  setNewViewCreated,
  feedbackModalShow,
  setFeedbackMarker,
  toggleMapClickable,
  toggleNearmapImage,
  setFeedbackEditable,
  handleCustomSuccess,
  orderNotesModalShow,
  setFeatureSelected,
  setNearmapImageDate,
  setFeedbackModalShow,
  isContextInteraction,
  setHoverParcelElement,
  setHoverOrderFeatureId,
  setOrderNotesModalShow,
  setAllFeedbackResolved,
  setSelectedHoverFeature,
  setFeedbackLayerVisible,
  setOrderNoteLayerVisible,
  setHoverOrderFeatureElement,
  toggleFeedbackMarkerVisible,
  setMultipleContextInteraction,
  toggleOrderNoteMarkerVisible,
  tileGridLyr,

}) {
  let interval;
  const token = AuthToken();
  const { id } = useParams();
  const params = useParams();
  const isMobile = useScreenType();
  let mapContainerRef = useRef();
  const [cost, setCost] = useState(0);
  const [time, setTime] = useState(0);
  const [open, setOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [expHours, setExpHours] = useState();
  const [blockArea, setBlockArea] = useState();
  const [timerHours, setTimerHours] = useState();
  const [expMinutes, setExpMinutes] = useState();
  const [selected, setSelected] = useState(null);
  const [modalShow, setModalShow] = useState(null);
  const [featureCost, setFeatureCost] = useState();
  const [imageryCost, setImageryCost] = useState();
  const [isOpenBtn, setIsOpenBtn] = useState(false);
  const [isCloseBtn, setIsCloseBtn] = useState(true);
  const [timerMinutes, setTimerMinutes] = useState();
  const [oneFeature, setOneFeature] = useState(false);
  const [placeOrderFe, setPlaceOrderFe] = useState(false);
  const [nearmapLoader, setNearmapLoader] = useState(true);
  const [nearmapDisabled, setNearmapDisabled] = useState();
  const [nearMapActive, setNearMapActive] = useState(false);
  const [totalFeatureCost, setTotalFeatureCost] = useState();
  const [totalImageryCost, setTotalImageryCost] = useState();
  const [showShareModal, setShowShareModal] = useState(false);
  const [availableFeatures, setAvailableFeatures] = useState([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [isDrawActive, setIsDrawActive] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);
  const [isCutActive, setIsCutActive] = useState(false);


  const result = path.reduce(
    (total, currentValue) =>
      (total = parseFloat(total) + parseFloat(currentValue.measurement)),
    0
  );

  const startTimer = () => {
    if (placeOrderFe) {
      setTimerHours(expHours);
      setTimerMinutes(expMinutes);
    } else if (time && placedTime) {
      const orderPlacedTime = new Date(`${placedTime}`).getTime();
      const countDownEndTime = orderPlacedTime + time * 60 * 60 * 1000;

      interval = setInterval(() => {
        const currentTime = new Date().getTime();
        const distance = countDownEndTime - currentTime;
        const hours = Math.floor(distance / 3600000);
        const remainingMilliseconds = distance % 3600000;
        const minutes = Math.floor(remainingMilliseconds / 60000);
        if (hours <= 0 && minutes <= 0) {
          setTimerHours('0');
          setTimerMinutes('05');
          clearInterval(interval.current);
        } else {
          setTimerHours(hours);
          setTimerMinutes(minutes);
        }
      });
    } else if (!placedTime) {
      setTimerHours('0');
      setTimerMinutes('05');
    }
  };

  const getFeatureSelected = () => {
    axios({
      url: `${ORDER_URL}${params.id}/feature`,
      method: 'GET',
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if ('data' in res) {
          const data = res.data.data;
          if (data.length) {
            setStep(4);
          }
          setFeatureSelected([...data]);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getAvailableFeatures = () => {
    axios({
      url: FETCH_FEATURE_URL,
      method: 'GET',
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if ('data' in res) {
          const data = res.data.data;
          setAvailableFeatures([...data]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculateTime = () => {
    let no_of_blocks = Math.ceil(result / blockArea);
    if (no_of_blocks) {
      let total_imagery_cost = 0;
      if (nearmapImageDate != 'satellite') {
        total_imagery_cost = imageryCost * no_of_blocks;
      }
      let feature_cost = featureSelected.length * featureCost * no_of_blocks;
      setTotalImageryCost(total_imagery_cost);
      setTotalFeatureCost(feature_cost);
      setCost(total_imagery_cost + feature_cost);

      const calculatedTime = featureSelected.length * no_of_blocks * 0.5;
      if (calculatedTime < 2) {
        if (featureSelected.length === 1) {
          setTime(featureSelected.length * no_of_blocks + 1);
          setExpHours(featureSelected.length * no_of_blocks + 1);
          setExpMinutes(featureSelected.length - featureSelected.length);
        } else if (featureSelected.length === 2) {
          setTime(featureSelected.length * no_of_blocks);
          setExpHours(featureSelected.length * no_of_blocks);
          setExpMinutes(featureSelected.length - featureSelected.length);
        } else if (featureSelected.length === 3) {
          setTime(featureSelected.length * no_of_blocks - 1);
          setExpHours(featureSelected.length * no_of_blocks - 1);
          setExpMinutes(featureSelected.length - featureSelected.length);
        } else if (featureSelected.length === 4) {
          setTime((featureSelected.length * no_of_blocks) / 2);
          setExpHours((featureSelected.length * no_of_blocks) / 2);
          setExpMinutes(featureSelected.length - featureSelected.length);
        }
      } else {
        setTime(featureSelected.length * no_of_blocks * 0.5);
        let hours = Math.floor(featureSelected.length * no_of_blocks * 0.5);
        let minutes = Math.floor(
          featureSelected.length * no_of_blocks * 0.5 * 60
        );
        setExpHours(hours);
        setExpMinutes(minutes - hours * 60);
      }
    }
  };

  const handleAllLayersCheckbox = () => {
    var count = 0;
    uploadedLayer.map((layer) => {
      if (!layer.visible) {
        count++;
      }
    });
    if (count === uploadedLayer.length) {
      setOneFeature(false);
    }
  };

  useEffect(() => {
    startTimer();
  }, [time, placedTime, placeOrderFe]);

  useEffect(() => {
    getFeatureSelected();
    getAvailableFeatures();
  }, [status]);

  useEffect(() => {
    if (blockArea && featureSelected.length) calculateTime();
  }, [blockArea, featureSelected, nearmapImageDate]);

  useEffect(() => {
    handleAllLayersCheckbox();
  }, [uploadedLayer]);

  useEffect(() => {
    if (
      (map && status === STATUS.MEASUREMENT_COMPLETED) ||
      status === STATUS.IN_PROGRESS ||
      status === STATUS.DIY
    )
      setCoordsToImage(
        id,
        map,
        token,
        completedGridLyr,
        setNearMapActive,
        setNearmapLoader,
        setNearmapDisabled,
        nearmapcoords,
        tileGridLyr,
        nearmapImageDate
      );
  }, [map, status,nearmapImageDate,nearmapcoords]);

  const toggleSlide = () => {
    setIsOpen(!isOpen);
  };

  const toggleSlideBtn = () => {
    setIsOpenBtn(!isOpenBtn);
    setIsCloseBtn(!isCloseBtn);
  };

  useEffect(() => {
    GetCostFactors(setBlockArea, setImageryCost, setFeatureCost);
  }, []);

  useEffect(() => {
    setIsDrawActive(false);
    setIsEditActive(false);
    setIsCutActive(false);
  }, []);

  const parcelSelection = (
    <ParcelSelection
      map={map}
      path={path}
      status={status}
      result={result}
      address={address}
      setStep={setStep}
      setPath={setPath}
      setOpen={setOpen}
      resetData={resetData}
      parcelRef={parcelRef}
      parcelData={parcelData}
      isCloseBtn={isCloseBtn}
      editPolygon={editPolygon}
      handleError={handleError}
      toggleSlide={toggleSlide}
      setZoomLayer={setZoomLayer}
      setParcelEdit={setParcelEdit}
      handleSuccess={handleSuccess}
      toggleSlideBtn={toggleSlideBtn}
      setParcelStyle={setParcelStyle}
      setPolygonList={setPolygonList}
      navigateAddress={navigateAddress}
      setNearmapcoords={setNearmapcoords}
      setHoverParcelId={setHoverParcelId}
      setHoverParcelElement={setHoverParcelElement}
      isDrawActive={isDrawActive}
      isEditActive={isEditActive}
      isCutActive={isCutActive}
      setIsDrawActive={setIsDrawActive}
      setIsEditActive={setIsEditActive}
      setIsCutActive={setIsCutActive}
    />
  );

  const featureSelection = (
    <FeatureSelection
      step={step}
      status={status}
      address={address}
      setStep={setStep}
      resetData={resetData}
      isCloseBtn={isCloseBtn}
      handleError={handleError}
      toggleSlide={toggleSlide}
      calculateTime={calculateTime}
      handleSuccess={handleSuccess}
      toggleSlideBtn={toggleSlideBtn}
      featureSelected={featureSelected}
      navigateAddress={navigateAddress}
      availableFeatures={availableFeatures}
      setFeatureSelected={setFeatureSelected}
    />
  );

  const placeOrder = (
    <PlaceOrder
      cost={cost}
      step={step}
      result={result}
      status={status}
      setStep={setStep}
      address={address}
      expHours={expHours}
      setStatus={setStatus}
      resetData={resetData}
      orderNote={orderNote}
      isCloseBtn={isCloseBtn}
      expMinutes={expMinutes}
      timerHours={timerHours}
      attachments={attachments}
      handleError={handleError}
      toggleSlide={toggleSlide}
      bulkOrderId={bulkOrderId}
      setOrderNote={setOrderNote}
      propertyCost={propertyCost}
      timerMinutes={timerMinutes}
      handleSuccess={handleSuccess}
      setPlacedTime={setPlacedTime}
      featureCost={totalFeatureCost}
      imageryCost={totalImageryCost}
      setAttachments={setAttachments}
      toggleSlideBtn={toggleSlideBtn}
      setPlaceOrderFe={setPlaceOrderFe}
      featureSelected={featureSelected}
      // HA
      setFeatureSelected={setFeatureSelected}
      navigateAddress={navigateAddress}
      setPropertyCost={setPropertyCost}
      nearmapImageDate={nearmapImageDate}
      handleCustomError={handleCustomError}
      handleCustomSuccess={handleCustomSuccess}
    />
  );

  return (
    <>
      {isMobile &&
        (status === STATUS.DRAFT || status === STATUS.IN_PROGRESS) ? (
        <MobileDraftProgressPanel
          step={step}
          open={open}
          setOpen={setOpen}
          parcelSelection={parcelSelection}
          featureSelection={featureSelection}
          placeOrder={placeOrder}
        />
      ) : null}
      <div
        className="map-leftbar-main-container"
        ref={mapContainerRef}
        style={
          isStudioOrder ? null
            : (status === STATUS.DRAFT && isOpen === true) ||
              (status === STATUS.IN_PROGRESS && isOpen === true)
              ? {
                left: '11%',
                width: '25%',
              }
              : status !== STATUS.MEASUREMENT_COMPLETED && status !== STATUS.DIY
                ? {
                  left: '11%',
                }
                : status === STATUS.DIY || status === STATUS.MEASUREMENT_COMPLETED
                  ? {
                    left: '5.8%',
                  }
                  : null
        }
      >
        <div
          className={`map-leftbar-open-btn ${isOpenBtn ? '' : 'slide-open-hidden'
            }`}
        >
          <button
            className="open-feature-container"
            onClick={() => {
              toggleSlide();
              toggleSlideBtn();
            }}
          >
            <Arrow style={{ rotate: '180deg' }} />
          </button>
        </div>
        <div
          className={`slide-content ${isOpen ? '' : 'slide-hidden'}`}
          onMouseOver={() => (elem.style.diaplay = 'none')}
        >
          {step === 2 && !isMobile && status === STATUS.DRAFT ? (
            parcelSelection
          ) : step === 3 && !isMobile && status === STATUS.DRAFT ? (
            featureSelection
          ) : step === 4 &&
            !isMobile &&
            (!isStudioOrder && (status === STATUS.DRAFT || status === STATUS.IN_PROGRESS)) ? (
            placeOrder
          ) : status === STATUS.MEASUREMENT_COMPLETED ||
            (status === STATUS.DIY && uploadedLayer) || isStudioOrder ? (
            <MeasurementPanel
              path={path}
              elem={elem}
              orderNumber={orderNumber}
              status={status}
              result={result}
              viewId={viewId}
              setPath={setPath}
              address={address}
              selected={selected}
              hex2rgba={hex2rgba}
              imageDate={imageDate}
              modalShow={modalShow}
              resetData={resetData}
              setViewId={setViewId}
              isPreview={isPreview}
              showPopup={showPopup}
              contentRef={contentRef}
              toolbarRef={toolbarRef}
              oneFeature={oneFeature}
              viewDelete={viewDelete}
              isCloseBtn={isCloseBtn}
              notesModal={notesModal}
              notesButton={notesButton}
              hoverMapKey={hoverMapKey}
              layersCount={layersCount}
              setSelected={setSelected}
              downloadPng={downloadPng}
              orderActive={orderActive}
              mapClickable={mapClickable}
              handleError={handleError}
              toggleSlide={toggleSlide}
              setHoverData={setHoverData}
              setZoomLayer={setZoomLayer}
              setModalShow={setModalShow}
              downloadData={downloadData}
              viewPanelRef={viewPanelRef}
              studioStatus={studioStatus}
              isStudioOrder={isStudioOrder}
              orderNoteData={orderNoteData}
              nearMapActive={nearMapActive}
              nearmapLoader={nearmapLoader}
              setViewDelete={setViewDelete}
              setNewLayerId={setNewLayerId}
              outerViewName={outerViewName}
              uploadedLayer={uploadedLayer}
              setOneFeature={setOneFeature}
              handleSuccess={handleSuccess}
              multiClickKey={multiClickKey}
              feedbackLayer={feedbackLayer}
              setLayersCount={setLayersCount}
              showShareModal={showShareModal}
              toggleSlideBtn={toggleSlideBtn}
              setHoverMapKey={setHoverMapKey}
              newViewCreated={newViewCreated}
              setOrderActive={setOrderActive}
              setStudioStatus={setStudioStatus}
              nearmapDisabled={nearmapDisabled}
              setOrderNoteKey={setOrderNoteKey}
              navigateAddress={navigateAddress}
              setNearMapActive={setNearMapActive}
              nearmapImageDate={nearmapImageDate}
              completedGridLyr={completedGridLyr}
              setOuterViewName={setOuterViewName}
              setFeedbackAdded={setFeedbackAdded}
              setUploadedLayer={setUploadedLayer}
              availableFeatures={availableFeatures}
              setShowShareModal={setShowShareModal}
              feedbackModalShow={feedbackModalShow}
              setFeedbackMarker={setFeedbackMarker}
              showDownloadModal={showDownloadModal}
              setNewViewCreated={setNewViewCreated}
              setNearmapDisabled={setNearmapDisabled}
              toggleMapClickable={toggleMapClickable}
              setNearmapImageDate={setNearmapImageDate}
              setFeedbackEditable={setFeedbackEditable}
              isContextInteraction={isContextInteraction}
              setFeedbackModalShow={setFeedbackModalShow}
              setShowDownloadModal={setShowDownloadModal}
              setHoverOrderFeatureId={setHoverOrderFeatureId}
              setAllFeedbackResolved={setAllFeedbackResolved}
              setSelectedHoverFeature={setSelectedHoverFeature}
              setFeedbackLayerVisible={setFeedbackLayerVisible}
              setHoverOrderFeatureElement={setHoverOrderFeatureElement}
              toggleFeedbackMarkerVisible={toggleFeedbackMarkerVisible}
              setMultipleContextInteraction={setMultipleContextInteraction}
              orderNotesLayer={orderNotesLayer}
              setOrderNoteData={setOrderNoteData}
              toggleNearmapImage={toggleNearmapImage}
              orderNotesModalShow={orderNotesModalShow}
              setOrderNotesModalShow={setOrderNotesModalShow}
              setOrderNoteLayerVisible={setOrderNoteLayerVisible}
              toggleOrderNoteMarkerVisible={toggleOrderNoteMarkerVisible}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
